//Styles
import { FontSizes, FontWeights } from "@fluentui/react";
// Utilities
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { flexParent, flexParentCenterAll } from "@ms/uno-controls/lib/local/utilities/Mixin.styles";
// Style constants
const smallScreenWidth = "499px";
const mediumScreenWidth = "768px";
/**
 * Helper to get styles for LandingPage
 * @param theme The uno theme to use
 */
export const getStyles = memoizeFunction((theme) => {
    const root = [
        flexParent("column"),
        flexParentCenterAll,
        {
            overflow: "hidden",
            position: "relative",
            height: "calc(100vh - 16px)", // to offset the margin of the body
            width: "calc(100vw - 8px)",
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
        },
    ];
    const gradient = {
        position: "absolute",
        background: "radial-gradient(93.29% 111.89% at 51.19% 78.92%, rgba(211, 64, 216, 0.1) 11.68%, rgba(63, 188, 250, 0.2) 47.43%, #CBD0FE 70.65%);",
        opacity: "0.6",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        width: "100%",
        minWidth: "700px",
        height: "100%",
    };
    const ellipse = {
        position: "absolute",
        background: Colors.White,
        filter: "blur(100px)",
        width: "84%",
        top: "8%",
        borderRadius: "50%",
        zIndex: 1,
        minWidth: "700px",
        maxWidth: "1886px",
        height: "100%",
        [`@media (max-width: ${mediumScreenWidth})`]: { height: "80%", top: "10%" },
    };
    const infoBox = [
        flexParent("column"),
        flexParentCenterAll,
        {
            position: "absolute",
            color: theme.palette.neutralPrimary,
            zIndex: 2,
            bottom: "10%",
            height: "100%",
            ".plannerSlogan": {
                fontSize: "66px",
                fontWeight: FontWeights.bold,
                marginBottom: "70px",
                width: "70vw",
                maxWidth: "842px",
                textAlign: "center",
                [`@media (max-width: ${mediumScreenWidth})`]: { fontSize: "52px", maxWidth: "649px" },
                [`@media (max-width: ${smallScreenWidth})`]: { fontSize: "32px", maxWidth: "243px" },
            },
            ".signInButton": [
                buttonStyles,
                {
                    fontSize: FontSizes.size18,
                    height: "48px",
                    minWidth: "160px",
                    color: Colors.White,
                    background: theme.palette.themePrimary,
                    border: `1px solid ${theme.palette.themePrimary}`,
                    marginBottom: "70px",
                    ":hover": {
                        background: theme.palette.themeDarkAlt,
                    },
                    ":active": {
                        background: theme.palette.themeDarker,
                    },
                },
            ],
            ".plannerMobileLabel": {
                fontSize: FontSizes.size14,
                marginBottom: "26px",
            },
        },
    ];
    const plannerTitle = [
        flexParent(),
        {
            alignItems: "center",
            gap: "12px",
            fontSize: FontSizes.size24,
            fontWeight: FontWeights.semibold,
            marginBottom: "70px",
            ".plannerLogo": {
                width: "32px",
            },
        },
    ];
    const appButtons = [
        flexParent("row", "wrap"),
        flexParentCenterAll,
        {
            gap: "20px",
            ".mobileButton": [
                buttonStyles,
                {
                    fontSize: FontSizes.size16,
                    color: theme.palette.neutralPrimary,
                    height: "46px",
                    border: `1px solid ${theme.palette.neutralTertiaryAlt}`,
                    background: theme.palette.white,
                    ":hover": {
                        background: theme.palette.neutralLighter,
                    },
                    ":active": {
                        background: theme.palette.neutralQuaternaryAlt,
                    },
                },
            ],
        },
    ];
    const loadingScreen = [
        flexParent("column"),
        {
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 16px)", // to offset the margin of the body
            ".loadingImage": {
                width: "96px",
            },
        },
    ];
    const styles = {
        root,
        gradient,
        ellipse,
        infoBox,
        plannerTitle,
        appButtons,
        loadingScreen,
    };
    return concatStyleSets(styles);
});
/**
 * Common styles for buttons
 */
const buttonStyles = [
    flexParent,
    flexParentCenterAll,
    {
        pointerEvents: "auto",
        cursor: "pointer",
        padding: "12px 32px 12px 32px",
        borderRadius: "52px",
        fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
        fontWeight: FontWeights.semibold,
    },
];
/**
 * Get the classnames associated with the computed styles for LandingPage
 * @param styles The styles for LandingPage component
 */
export const getClassNames = memoizeFunction((styles) => ({
    root: mergeStyles("landingPageRoot", styles.root),
    gradient: mergeStyles("landingPageGradient", styles.gradient),
    ellipse: mergeStyles("landingPageEllipse", styles.ellipse),
    infoBox: mergeStyles("landingPageInfoBox", styles.infoBox),
    plannerTitle: mergeStyles("landingPagePlannerTitle", styles.plannerTitle),
    appButtons: mergeStyles("landingPageAppButtons", styles.appButtons),
    loadingScreen: mergeStyles("landingPageLoadingScreen", styles.loadingScreen),
}));
