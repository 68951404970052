/**
 * Error codes parsed from GroupNamesValidation API call's response
 */ export var GroupNameValidationErrorCode;
(function(GroupNameValidationErrorCode) {
    /**
     * DisplayName and Mail Nickname passed all validations. Ready for use.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["OK"] = 0] = "OK";
    /**
     * DisplayName matches one of the Default Reserved Words.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["ContainsDefaultReservedWords"] = 1] = "ContainsDefaultReservedWords";
    /**
     * DisplayName or Mail Nickname do not meet Prefix and/or Suffix's requirement(s).
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["MissingPrefixSuffix"] = 2] = "MissingPrefixSuffix";
    /**
     * DisplayName matches one of customer defined blocked words.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["ContainsCustomBlockedWords"] = 3] = "ContainsCustomBlockedWords";
    /**
     * DisplayName matches one of Microsoft standard blocked words.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["ContainsMSStandardBlockedWords"] = 4] = "ContainsMSStandardBlockedWords";
    /**
     * Mail Nickname already exists, matches default reserved word or matches blocked word.
     * Combining these 3 cases together since solution is the same - append some random number and retry.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["MailNicknameNotOK"] = 5] = "MailNicknameNotOK";
    /**
     * This is the default case if we don't have a clear error code.
     */ GroupNameValidationErrorCode[GroupNameValidationErrorCode["Unknown"] = 6] = "Unknown";
})(GroupNameValidationErrorCode || (GroupNameValidationErrorCode = {}));
export var ServerInitialErrorMsg;
(function(ServerInitialErrorMsg) {
    /**
     * Error Id used when user is not allowed to access the requested entity.
     */ ServerInitialErrorMsg["Forbidden"] = "Forbidden";
    /**
     * Error Id used when the requested entity is not found.
     */ ServerInitialErrorMsg["NotFound"] = "NotFound";
    /**
     * Error id used when user tries to visit an empty group yet plan creation failed.
     */ ServerInitialErrorMsg["CreatePlanForGroupFailed"] = "CreatePlanForGroupFailed";
    /**
     * Error id used when user tries to visit an empty group yet service can create no more plans for this user.
     */ ServerInitialErrorMsg["MaximumPlansOwnedByUserReached"] = "MaximumPlansOwnedByUserReached";
    /**
     * Default error id when the error is unexpected.
     */ ServerInitialErrorMsg["UnknownError"] = "UnknownError";
})(ServerInitialErrorMsg || (ServerInitialErrorMsg = {}));
export var NetworkConnectivityError;
(function(NetworkConnectivityError) {
    NetworkConnectivityError["Offline"] = "Offline";
})(NetworkConnectivityError || (NetworkConnectivityError = {}));
export var AppErrorCode;
(function(AppErrorCode) {
    AppErrorCode["DynamicImportError"] = "DynamicImportError";
})(AppErrorCode || (AppErrorCode = {}));
export var PlannerServiceLimitErrorTypes;
(function(PlannerServiceLimitErrorTypes) {
    /** UNIFIED GROUP LIMITS */ /** The maximum number of plans a unified group can own */ PlannerServiceLimitErrorTypes["MaxPlansOwnedByGroup"] = "MaximumProjectsOwnedByUser";
    /** The maximum number of plans that can be shared with a user */ PlannerServiceLimitErrorTypes["MaxPlansSharedWithUser"] = "MaximumProjectsSharedWithUser";
    /** The maximum number of tasks that a user can be assigned */ PlannerServiceLimitErrorTypes["MaxTasksAssignedToUser"] = "MaximumTasksAssignedToUser";
    /** The maximum number of tasks that a user can create */ PlannerServiceLimitErrorTypes["MaxTasksCreatedByUser"] = "MaximumTasksCreatedByUser";
    /** TASK LIMITS */ /** Maximum number of people who can be assigned a task. */ PlannerServiceLimitErrorTypes["MaxAssigneesInTasks"] = "MaximumAssigneesInTasks";
    /** The maximum number of references allowed on task details. */ PlannerServiceLimitErrorTypes["MaxLinksOnTask"] = "MaximumReferencesOnTask";
    /** The maximum number of checklist items allowed on a task details. */ PlannerServiceLimitErrorTypes["MaxChecklistItemsOnTask"] = "MaximumChecklistItemsOnTask";
    /** PLAN LIMITS */ /** The maximum number of buckets in a plan */ PlannerServiceLimitErrorTypes["MaxBucketsInPlan"] = "MaximumBucketsInProject";
    /** Maximum number of contexts that can be added to a plan */ PlannerServiceLimitErrorTypes["MaxContextsOnPlan"] = "MaximumContextsOnPlan";
    /** The maximum number of tasks in a plan */ PlannerServiceLimitErrorTypes["MaxTasksInPlan"] = "MaximumTasksInProject";
    /** Maximum number of active tasks in plan. */ PlannerServiceLimitErrorTypes["MaxActiveTasksInPlan"] = "MaximumActiveTasksInProject";
    /** The maximum number of users that can be shared with a plan */ PlannerServiceLimitErrorTypes["MaxUsersSharedWithPlan"] = "MaximumUsersSharedWithProject";
    /** OTHER LIMITS */ /** The maximum number of UserData key value pair allowed on user details. */ PlannerServiceLimitErrorTypes["MaxUserDataCountInUserDetails"] = "MaximumUserDataCountInUserDetails";
})(PlannerServiceLimitErrorTypes || (PlannerServiceLimitErrorTypes = {}));
/**
 * Error name for updating archived entity
 */ export const UpdateArchivedEntityError = "ArchivedEntityCanNotBeUpdated";
