// Constants
import { Browser } from "@ms/uno-constants/lib/local/AppConstants";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import find from "lodash/find";
/**
 * Get the current browser
 */ export function getBrowser() {
    const userAgent = window.navigator.userAgent;
    // Please do not alter the order of these checks.
    if (userAgent.indexOf("MSIE") >= 0 || userAgent.indexOf("Trident/") >= 0) {
        return Browser.IE;
    }
    if (userAgent.indexOf("Firefox/") >= 0) {
        return Browser.Firefox;
    }
    if (userAgent.indexOf("Electron/") >= 0) {
        return Browser.Electron;
    }
    // Chromium based Edge browser has a shortened name as Edg
    if (userAgent.indexOf("Edge/") >= 0 || userAgent.indexOf("Edg/") >= 0 || find(window.navigator["userAgentData"]?.brands, (brandEntry)=>brandEntry.brand === "Microsoft Edge") != null) {
        return Browser.Edge;
    }
    // Eliminates other chromium based browsers that has both Safari and Chrome. Safari browser doesn't have 'Chrome'.
    if (userAgent.indexOf("Safari/") >= 0 && userAgent.indexOf("Chrome") < 0) {
        return Browser.Safari;
    }
    if (!!window.opr && !!window.opr.addons || !!window.opera || userAgent.indexOf(" OPR/") >= 0) {
        return Browser.Opera;
    }
    if (userAgent.indexOf("Chrome/") >= 0 && !!window.chrome && !!window.chrome.runtime) {
        return Browser.Chrome;
    }
    return Browser.Other;
}
/**
 * Function to safely open an external url by setting the window opener to null
 * Note: Refer to MSRC Case 69805: https://greymatter/MSRC/main.aspx?etc=112&id=02c3b449-537d-ec11-a841-000d3afd4924&pagetype=entityrecord#141116260
 * @param url External url to be opened in another tab
 * @param traceLogger The trace logger for logging diagnostic traces
 * @param enableExternalUrlRegexCheck the setting to enable/disable the external url regex check
 * @param target The target window to open the url in
 */ export function safelyOpenExternalUrl(url, traceLogger, enableExternalUrlRegexCheck, target) {
    // Log the all of the external urls that are being opened
    traceLogger?.logTrace(0x1e2c451d /* tag_4leu3 */ , TraceLevel.Info, "Opening external url");
    if (!isUrlValid(url)) {
        // Log the invalid external urls that are being opened
        traceLogger?.logTrace(0x1e2c451c /* tag_4leu2 */ , TraceLevel.Warning, "The external url is invalid");
        if (enableExternalUrlRegexCheck) {
            return null;
        }
    } else {
        // Log the valid external urls that are being opened
        traceLogger?.logTrace(0x1e2a004a /* tag_4k6bk */ , TraceLevel.Info, "Opening external url is valid");
    }
    const extWindow = window.open(url, target);
    if (extWindow != null) {
        extWindow.opener = null;
    }
    return extWindow;
}
/*
 * Validates whether a given URL is valid and safe.
 *
 * This function uses a regular expression to validate the URL format and ensure it does not use
 * disabled protocols such as `javascript`, `vbscript`, or `data`. It also handles URLs with
 * user:pass authentication, IP addresses, hostnames, domain names, TLDs, port numbers, and resource paths.
 *
 * @param externalUrl External url to be validated
 */ export function isUrlValid(externalUrl) {
    // RegEx was selected according to this research:
    // https://mathiasbynens.be/demo/url-regex
    const url = new RegExp("^" + // protocol identifier
    "(?:(?:https?|ftp)://)" + // disabled protocols
    "(?!((java|vb)script|data))" + "(?:" + // user:pass authentication
    "(?:\\S+(?::\\S*)?@)?" + "(?:" + // IP address dotted notation octets
    "(?:(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])" + "|" + // host name
    "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" + // domain name
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" + // TLD identifier
    "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" + // TLD may end with dot
    "\\.?" + ")" + // port number
    "(?::\\d{2,5})?" + // resource path
    "(?:[/?#]\\S*)?" + ")" + // for http://localhost
    "|" + "(?:localhost:*[a-z\\u00a1-\\uffff0-9]*)" + "$", "i");
    // Regular expression to match leading or trailing brackets
    const brackets = /^[({[}]|[)}\]]$/gi;
    const systemApp = new RegExp("(?:((mailto:[a-z\\u00a1-\\uffff0-9]+)|(tel:\\+*\\d+)))", "i");
    // Check if the externalUrl contains any leading or trailing brackets
    if (brackets.test(externalUrl)) {
        // Remove any leading or trailing brackets from the externalUrl
        externalUrl = externalUrl.replace(brackets, "");
    }
    return url.test(externalUrl) || systemApp.test(externalUrl);
}
