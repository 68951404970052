import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for AppContextStore, for use in the registry */ export const AppContextStoreActionTypes = [
    ActionType.FetchAppContextSucceeded,
    ActionType.FetchUserExtraFieldsAction,
    ActionType.FetchUserExtraFieldsFailedAction,
    ActionType.FetchUserExtraFieldsSucceededAction,
    ActionType.FetchTodoSettingsAction,
    ActionType.FetchTodoSettingsFailedAction,
    ActionType.FetchTodoSettingsSucceededAction,
    ActionType.UpdateAppThemeAction
];
