// Utilities
import { retry } from "@ms/uno-utilities/lib/local/RetryUtilities";
/**
 * Lazy loader for portfolio view instantiator
 */ export const portfolioInstantiateLoader = ()=>retry(()=>import(/* webpackChunkName: "uno-portfolio-instantiate" */ "./portfolioViewInstantiator")).then((bundle)=>{
        return bundle;
    });
/**
 * Lazy loader for portfolio view renderer
 */ export const portfolioRenderLoader = ()=>retry(()=>import(/* webpackChunkName: "uno-portfolio-render" */ "./portfolioViewRenderer")).then((bundle)=>{
        return bundle.portfolioViewRenderer;
    });
