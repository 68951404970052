// Constants
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
/**
 * Ajax client that sets correlation request headers for client-service telemetry correlation
 */ export class CorrelationSettingAjaxClient {
    async executeRequest(url, options) {
        this.setCorrelationHeader(options);
        return await this.innerAjaxClient.executeRequest(url, options);
    }
    /**
     * Set the correlation header
     * @param options request options with the request details
     */ setCorrelationHeader(options) {
        const methodName = options.telemetryConfig?.methodName;
        const apiName = options.telemetryConfig?.apiName;
        const correlationValue = generateGuid();
        this.loggers.traceLogger.logTrace(0x1e44a11d /* tag_4rke3 */ , TraceLevel.Verbose, `Setting correlation on request. [Method=${methodName}][Name=${apiName}][Correlation=${correlationValue}]`);
        const headers = new Headers(options.headers);
        // This header will allow telemetry to determine which client made the request
        headers.set(this.correlationHeaderName, correlationValue);
        // Add headers to options
        options.headers = headers;
    }
    /**
     * Initializes a new instance of the CorrelationSettingAjaxClient
     * @param innerAjaxClient Ajax client
     * @param correlationHeaderName Name of the correlation header
     * @param loggers Loggers for telemetry
     */ constructor(innerAjaxClient, correlationHeaderName, loggers){
        this.innerAjaxClient = innerAjaxClient;
        this.correlationHeaderName = correlationHeaderName;
        this.loggers = loggers;
    }
}
