// Utilities
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/**
 * Wrapper for persistent storage
 */ export class PersistentStorage {
    getItem(key) {
        const prefixedKey = this.getPrefixedKey(key);
        return this.storage.getItem(prefixedKey);
    }
    setItem(key, value) {
        try {
            const prefixedKey = this.getPrefixedKey(key);
            this.storage.setItem(prefixedKey, value);
        } catch (e) {
            this.traceLogger.logTrace(0x1e485044 /* tag_4sfbe */ , TraceLevel.Warning, `Persistent storage failure [Error=${e}]`);
        }
    }
    removeItem(key) {
        const prefixedKey = this.getPrefixedKey(key);
        return this.storage.removeItem(prefixedKey);
    }
    /**
     * Clear the store
     */ clear() {
        return this.storage.clear();
    }
    /**
     * Prefix storage keys with app level prefixes and the current userId to avoid collisions with other apps and users
     * @param key Key to apply prefix to
     */ getPrefixedKey(key) {
        let retKey = `${PersistentStorage.AppStorageKeyPrefix}-${this.schemaVersion}-${key}`;
        if (this.customPrefix) {
            retKey = `${this.customPrefix}-${retKey}`;
        }
        return retKey;
    }
    /**
     * Create a new instance of this class
     * @param storage Underlying storage implementation. Must be validated before passing in.
     */ constructor(customPrefix, storage, schemaVersion, traceLogger){
        this.customPrefix = customPrefix;
        this.storage = storage;
        this.schemaVersion = schemaVersion;
        this.traceLogger = traceLogger;
    }
}
/** App level prefix for uno storage keys */ PersistentStorage.AppStorageKeyPrefix = "uno";
