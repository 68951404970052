import { TraceLevel } from "./events/Trace.event";
export class UnhandledErrorHandler {
    /**
     * Register an unhandled error handler that will catch all errors that are not caught by a try/catch block
     * @param traceLogger trace logger
     */ static registerUnhandledErrorHandler(traceLogger) {
        const handler = new UnhandledErrorHandler(traceLogger);
        window.onerror = handler.handleOnError.bind(handler);
    }
    /**
     * Takes a string and, if it is longer than maxLength, truncates it and adds ellipsis
     * @param The string to shorten
     * @param The maximum length of the string returned by this function
     * @return A string of length &lt;= maxLength, with "..." appended if there was truncation
     */ static shortenStringAddEllipsis(str, maxLength) {
        if (str && str.length > maxLength) {
            str = str.substr(0, maxLength - 3) + "...";
        }
        return str;
    }
    /**
     * Given a function object, return the name of the function
     * For anonymous or minified functions, take the first few characters of the body
     * @param The function object
     * @return The name of the function
     */ static getMethodName(method) {
        let methodSignature;
        if (method) {
            // Pull out the method signature.
            let methodStr = method?.toString?.() ?? "InvalidMethod()";
            const functionNameStartIndex = methodStr.indexOf(" ") === 8 ? 9 : 0; // If the format is "function <name>(...", leave "function" out of our returned string
            const functionNameEndIndex = methodStr.indexOf(")") + 1;
            methodSignature = methodStr.substring(functionNameStartIndex, functionNameEndIndex);
            // Anonymous or minified function, include 50 characters of the body so we can identify the function
            if (methodSignature.indexOf("function") === 0 || methodSignature.indexOf("(") < 3) {
                const totalLength = functionNameEndIndex + UnhandledErrorHandler.MAXCHARACTEROFANONYMOUSFUNCTION;
                methodStr = methodStr.replace(/\s\s*/g, " ");
                methodSignature = UnhandledErrorHandler.shortenStringAddEllipsis(methodStr, totalLength) + (totalLength < methodStr.length ? "}" : "");
            }
        }
        return methodSignature;
    }
    /**
     * Get the current call stack as an array of strings, each element being one level of the stack
     */ getStackAsArray() {
        // Try to get the stack from a partner callback
        const stack = [];
        try {
            // Throw an error to get the stack trace
            throw new Error();
        } catch (ex) {
            // Parse the stack trace
            const stackTrace = ex.stack.split("\n");
            // Skip the first line (the error message) and the second line (this function)
            for(let i = 2; i < stackTrace.length && i < UnhandledErrorHandler.MAXCALLSTACKLEVELS + 2; i++){
                // Add the method name to the stack array
                const match = stackTrace[i].match(/at ([^\s(]+[\s(])/);
                const methodName = match ? match[1] : undefined;
                stack.push(methodName);
            }
        }
        return stack;
    }
    /**
     * Collect data about the error and send it off to the server
     * @param The error message the browser assigned to the Error
     * @param The URL to the js file that caused the error, or the page if the JS file is unavailable
     * @param The the js line number
     * @param The the js column number
     * @param The error object
     */ handleOnError(message, jsUrl, jsLine, jsCol, errorObject) {
        // This is to support TAB test framework catching product exceptions during test runs
        try {
            if (window.parent?.opener && "LogOnError" in window.parent.opener) {
                Function.prototype.apply.call(window.parent.opener["LogOnError"], window.parent.opener, [
                    message,
                    jsUrl,
                    jsLine,
                    jsCol,
                    errorObject
                ]);
            }
        } catch (e) {
        // don't do anything
        }
        // Get the callstack from the error object and using the arguments.callee.caller method
        // (second will only work in IE)
        let stack = errorObject?.stack;
        let builtStack = this.getStackAsArray().join("\n");
        // Remove >1 consecutive whitespace to shorten our callstack
        stack = stack ? stack.replace(/[ \t]{2,}/g, " ") : "";
        builtStack = builtStack.replace(/[ \t]{2,}/g, " ");
        if (this.errorCount < UnhandledErrorHandler.MAXERRORS) {
            this.traceLogger?.logTrace(0x1e44e7a2 /* tag_4ro48 */ , TraceLevel.Error, "Unhandled error", {
                builtStack: builtStack,
                col: `${jsCol}`,
                line: `${jsLine}`,
                url: jsUrl
            });
        } else if (this.errorCount === UnhandledErrorHandler.MAXERRORS) {
            this.traceLogger?.logTrace(0x1e44e7a1 /* tag_4ro47 */ , TraceLevel.Error, `Unhandled error - Hit max error limit: [ErrorLimit=${UnhandledErrorHandler.MAXERRORS}]`);
        }
        this.errorCount++;
    }
    /**
     * Create an UnhandledErrorHandler
     * @param traceLogger Optional trace logger
     */ constructor(traceLogger){
        this.errorCount = 0;
        this.traceLogger = traceLogger;
    }
}
UnhandledErrorHandler.MAXCALLSTACKLEVELS = 10;
UnhandledErrorHandler.MAXCHARACTEROFANONYMOUSFUNCTION = 50;
UnhandledErrorHandler.MAXERRORS = 100;
