import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for PlanConversionStore, for use in the registry */ export const PlanConversionStoreActionTypes = [
    ActionType.AcquireProjectTrialAndConvertPlanAction,
    ActionType.BasicPlanConversionSucceededAction,
    ActionType.ConvertBasicPlanToPremiumAction,
    ActionType.ConvertBasicPlanToPremiumSucceededAction,
    ActionType.ConvertBasicPlanToPremiumFailedAction,
    ActionType.FetchConvertPlanStatusSucceededAction,
    ActionType.FetchConvertPlanStatusFailedAction,
    ActionType.RecordBasicPlanForDowngradeNotificationAction,
    ActionType.ResetPlanConversionStatusAction,
    ActionType.TrackConversionProgressAction,
    ActionType.RecordBasicPlanForPlanUpgradedFREAction
];
