import { HttpHeaders } from "@ms/uno-constants/lib/local/HttpConstants";
import { HttpStatus, HttpStatusText } from "@ms/uno-constants/lib/local/HttpStatusConstants";
import { generateAjaxClientError } from "../utilities/ServiceUtilities";
// Telemetry
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/**
 * Ajax client that appends access token as Bearer token into requests Authorization header
 */ export class AuthenticatedAjaxClient {
    async executeRequest(url, options) {
        this.loggers.traceLogger.logTrace(0x1e45d55b /* tag_4r3v1 */ , TraceLevel.Verbose, `Reading access token for API Call`);
        try {
            const token = await this.getAccessToken(this.authResource);
            if (!token) {
                const errorText = "Empty access Token";
                this.loggers.traceLogger.logTrace(0x1e45d55a /* tag_4r3v0 */ , TraceLevel.Warning, `Read access token Failure [Reason=${errorText}]`);
                const failureResult = generateAjaxClientError(new Response(errorText, {
                    status: HttpStatus.Unauthorized,
                    statusText: HttpStatusText.Unauthorized
                }), errorText);
                return Promise.reject(failureResult);
            } else {
                this.loggers.traceLogger.logTrace(0x1e45d559 /* tag_4r3vz */ , TraceLevel.Verbose, `Read access token Success. Updating authZ header`);
                this.updateAuthorizationHeader(options, token.accessToken);
            }
        } catch (e) {
            this.loggers.traceLogger.logTrace(0x1e45d558 /* tag_4r3vy */ , TraceLevel.Warning, "Could not get an auth token refresh worker");
            const errorText = "Could not authenticate request";
            const failureResult = generateAjaxClientError(new Response(errorText, {
                status: HttpStatus.Unauthorized,
                statusText: HttpStatusText.Unauthorized
            }), errorText);
            return Promise.reject(failureResult);
        }
        return this.innerAjaxClient.executeRequest(url, options);
    }
    /**
     * Updates the authorization header value
     * @param options The current headers
     * @param token The token we received
     */ updateAuthorizationHeader(options, token) {
        const headers = new Headers(options.headers);
        headers.set(HttpHeaders.Authorization, `${AuthenticatedAjaxClient.bearerString}${token}`);
        // Add headers to options if there is no headers set yet
        options.headers = headers;
    }
    /**
     * Initializes a new AuthenticatedAjaxClient instance
     * @param ajaxClient Ajax client
     * @param getAuthTokenRefreshWorker Delegate function that returns a promise to get access token worker to be used on the request
     */ constructor(ajaxClient, authResource, loggers, getAccessToken){
        this.loggers = loggers;
        this.innerAjaxClient = ajaxClient;
        this.authResource = authResource;
        this.getAccessToken = getAccessToken;
    }
}
AuthenticatedAjaxClient.bearerString = "Bearer ";
