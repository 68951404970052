// webpack public path import must be first
import "./SetWebpackPublicPath";
// Bootstrap
import { bootstrap as teamsBootStrap } from "./teams/TeamsAppBootstrap";
import { bootstrap as webBootStrap } from "./web/WebAppBootstrap";
// Constants
import { ClientType } from "@ms/uno-constants/lib/local/AppConstants";
// This needs to match what the retail service sends down
const appName = "planner";
const settingsObject = window[appName];
if (!settingsObject) {
    logLoadingError(`No ${appName} object was found in the window. Settings will not be loaded`);
    startErrorBootstrap();
}
else {
    try {
        // Until MTRS is fixed, we need to clamp WebApp to Web manually. Eventually, this will only ever be Web
        tempWebClientTypeClamp(settingsObject);
        const clientType = settingsObject["sessionmetadata"]?.appMetadata?.clientType;
        if (clientType === ClientType.Teams) {
            teamsBootStrap();
        }
        else if (clientType === ClientType.Web) {
            const isReady = document.readyState === "interactive" || document.readyState === "complete";
            if (isReady) {
                webBootStrap();
            }
            else {
                window.onload = webBootStrap;
            }
        }
        else {
            throw new Error(`Invalid client type: ${clientType}`);
        }
    }
    catch (e) {
        logLoadingError(`Failure during bootstrap. Loading error view.`);
        startErrorBootstrap();
    }
}
/**
 * Log a loading error onto the global object
 * @param message Message to log
 */
function logLoadingError(message) {
    if (!globalThis.UnoLoadingErrors) {
        globalThis.UnoLoadingErrors = [];
    }
    globalThis.UnoLoadingErrors.push(message);
}
/**
 * Clamps the web client type to Web instead of WebApp (currently being fixed in the retail service)
 * @param settings Settings object that contains the client type
 */
function tempWebClientTypeClamp(settings) {
    const clientType = settings["sessionmetadata"]?.appMetadata?.clientType;
    if (clientType === "WebApp") {
        settings["sessionmetadata"].appMetadata.clientType = ClientType.Web;
    }
}
/**
 * Load the error app if bootstrap fails
 */
function startErrorBootstrap() {
    // Load error bootstrap
}
