import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for DeltaSyncStore, for use in the registry */ export const DeltaSyncStoreActionTypes = [
    ActionType.BasicDeltaSyncFetchScheduledAction,
    ActionType.FetchBasicDeltaSyncFeedAction,
    ActionType.FetchBasicDeltaSyncFeedFailedAction,
    ActionType.FetchBasicDeltaSyncFeedSucceededAction,
    ActionType.FetchBasicDeltaSyncTokenAction,
    ActionType.FetchBasicDeltaSyncTokenFailedAction,
    ActionType.FetchBasicDeltaSyncTokenSucceededAction,
    ActionType.DisableBasicDeltaSyncAction,
    ActionType.EnableBasicDeltaSyncAction
];
