export var AudienceGroup;
(function(AudienceGroup) {
    AudienceGroup["Automation"] = "Automation";
    AudienceGroup["Dogfood"] = "Dogfood";
    AudienceGroup["Microsoft"] = "Microsoft";
    AudienceGroup["Insiders"] = "Insiders";
    AudienceGroup["Production"] = "Production";
})(AudienceGroup || (AudienceGroup = {}));
export var TraceLevel;
(function(TraceLevel) {
    /** Critical non recoverable error */ TraceLevel[TraceLevel["Error"] = 0] = "Error";
    /** Non critical error */ TraceLevel[TraceLevel["Warning"] = 1] = "Warning";
    /** Informational and default diagnostic level */ TraceLevel[TraceLevel["Info"] = 2] = "Info";
    /** Fine grained verbose logs for detailed debugging */ TraceLevel[TraceLevel["Verbose"] = 3] = "Verbose";
})(TraceLevel || (TraceLevel = {}));
/** Id of the window property containing settings for the app */ export const SettingsObjectName = "planner";
