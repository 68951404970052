import { makeInt64DataField, makeStringDataField } from "@microsoft/oteljs";
import forEach from "lodash/forEach";
import { EventType as UTelEventType } from "../UTelLogHandler";
import { createDataField, createExtraDataField } from "../LoggingHelper";
import { PairedEventBase, EventType } from "./PairedEventBase";
import { ResultTypeEnum } from "./ResultTypeEnum";
export class ApiEvent extends PairedEventBase {
    /**
     * Logs the data using UTelJs
     * @param eventType Event to be logged
     * @param logHandler Log handler to log event
     */ _logUTel(eventType, logHandler) {
        const payload = [];
        // to differentiate between qos event and api qos event
        payload.push(makeStringDataField("QosType", "Api"));
        payload.push(makeStringDataField("WebLog_EventType", eventType === EventType.start ? "Start" : "End"));
        this.data.name && payload.push(createDataField(UTelEventType.Qos, this.data.name));
        if (this.endTime && this.startTime && this.endTime > 0 && this.startTime > 0) {
            payload.push(makeInt64DataField("Duration", this.endTime - this.startTime));
        }
        this.data.resultType != null && payload.push(makeStringDataField("ResultType", ResultTypeEnum[this.data.resultType]));
        this.data.resultCode && payload.push(makeStringDataField("ResultCode", this.data.resultCode));
        this.data.error && payload.push(makeStringDataField("Error", this.data.error));
        // extra data
        forEach(this.data.extraData, (value, key)=>{
            payload.push(createExtraDataField(UTelEventType.Qos, key, value));
        });
        // Extra metrics, if any, must be sent to extraData property. After the move to UTel 100%, we'll remove extrametrics.
        forEach(this.data.extraMetrics, (value, key)=>{
            payload.push(createExtraDataField(UTelEventType.Qos, key, value));
        });
        logHandler.log({
            type: UTelEventType.Qos,
            payload: payload
        });
    }
    constructor(startData, logHandler){
        super(startData, logHandler);
    }
}
