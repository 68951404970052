// Actions
import { PushSubRouteAction } from "@ms/uno-actions/lib/local/route/PushSubRouteAction";
import { ReplaceSubRouteAction } from "@ms/uno-actions/lib/local/route/ReplaceSubRouteAction";
import { NavigateToViewAction } from "@ms/uno-actions/lib/local/route/NavigateToViewAction";
// Constants
import { EngagementTelemetryConstants } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
// Utilities
import { generateRouteDescriptorFromRoutingDetailsForPlan, generateRouteDescriptorFromRoutingDetailsForPortfolio } from "@ms/uno-routing";
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
/**
 * Action creator dealing with routing
 */ export class RoutingActionCreator {
    async navigate(routeChangeDescriptor, skipHistoryPushState) {
        await this.dispatcher.dispatchActionAsync(new NavigateToViewAction(routeChangeDescriptor, Date.now(), skipHistoryPushState));
        // Announce the navigation to the screen reader
        const notificationModule = await this.logicModuleProviders.notification();
        let pageString = null;
        switch(routeChangeDescriptor.subRouteType){
            case SubRouteType.MyDay:
                pageString = Strings.Views_MyDayTitle;
                break;
            case SubRouteType.MyTasks:
                pageString = Strings.Views_MyTasksTitle;
                break;
            case SubRouteType.MyPlans:
                pageString = Strings.Views_MyPlansTitle;
                break;
            default:
                break;
        }
        if (pageString) {
            await notificationModule.enqueueHiddenToastAsync("Toasts_ChangeViewNotificationToastTitle", [
                pageString
            ]);
        }
    }
    async navigateToPlan(routingDetails, queryParams) {
        const planStore = await this.stores.basicPlan();
        const defaultPlanId = planStore.getDefaultPlanId();
        const flaggedEmailPlanId = planStore.getFlaggedEmailPlanId();
        await this.navigate(generateRouteDescriptorFromRoutingDetailsForPlan(routingDetails, queryParams, defaultPlanId ?? undefined, flaggedEmailPlanId ?? undefined));
        // Announce the plan navigation to the screen reader
        const notificationModule = await this.logicModuleProviders.notification();
        if (routingDetails.planName) {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPlanNotificationToastTitle", [
                routingDetails.planName
            ]);
        } else {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPlanNotificationNoNameToastTitle");
        }
        // Log engagement telemetry for opening a plan
        this.loggers.engagementLogger.logEngagement(EngagementTelemetryConstants.RoutingActionCreator, EngagementTelemetryConstants.OpenPlan, {
            PlanType: routingDetails.planType
        });
    }
    async navigateToPortfolio(routingDetails, queryParams) {
        await this.navigate(generateRouteDescriptorFromRoutingDetailsForPortfolio(routingDetails, queryParams));
        // Announce the portfolio navigation to the screen reader
        const notificationModule = await this.logicModuleProviders.notification();
        if (routingDetails.portfolioName) {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPortfolioNotificationToastTitle", [
                routingDetails.portfolioName
            ]);
        } else {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPortfolioNotificationNoNameToastTitle");
        }
        // Log engagement telemetry for opening a portfolio
        this.loggers.engagementLogger.logEngagement(EngagementTelemetryConstants.RoutingActionCreator, EngagementTelemetryConstants.OpenPortfolio);
    }
    async pushSubRoute(subRoute) {
        await this.dispatcher.dispatchActionAsync(new PushSubRouteAction(subRoute));
    }
    async replaceSubRoute(subRoute) {
        await this.dispatcher.dispatchActionAsync(new ReplaceSubRouteAction(subRoute));
    }
    /**
     * Initializes a new instance of the Router action creator.
     * @param dispatcher The dispatcher the SPA uses to dispatch actions.
     * @param logicModuleProviders Set of providers for logic modules
     * @param loggers Loggers for telemetry
     */ constructor(dispatcher, logicModuleProviders, stores, loggers){
        this.dispatcher = dispatcher;
        this.logicModuleProviders = logicModuleProviders;
        this.stores = stores;
        this.loggers = loggers;
    }
}
