// Models
export var FailedReason;
(function(FailedReason) {
    /**
     * Authentication failed
     */ FailedReason["AuthFailed"] = "AuthFailed";
    /**
     * The application timed out
     */ FailedReason["Timeout"] = "Timeout";
    /**
     * The app failed for a different reason
     */ FailedReason["Other"] = "Other";
})(FailedReason || (FailedReason = {}));
