/**
 * Constants for Graph Odata API
 */ export class GraphOdataApiConstants {
}
/** Time in ms used to aggregate batch requests */ GraphOdataApiConstants.BatchAggregationTime = 100;
/** Maximum number of requests in a graph batch call */ GraphOdataApiConstants.MaxGraphBatchRequestSize = 20;
/**
     * Maximum number of members that can be added to a group in a single request
     * https://learn.microsoft.com/en-us/graph/api/group-post-members?view=graph-rest-1.0&tabs=http#example-2-add-multiple-members-to-a-group-in-a-single-request
     */ GraphOdataApiConstants.MaxGroupMembersAddedPerRequest = 20;
export var GraphODataApiVersion;
(function(GraphODataApiVersion) {
    GraphODataApiVersion["Beta"] = "beta";
    GraphODataApiVersion["V1"] = "v1.0";
})(GraphODataApiVersion || (GraphODataApiVersion = {}));
