import { AuthError } from "@azure/msal-browser-1p";
/**
 * Error thrown when the response does not contain an account object
 */ export class NoAccountAuthError extends AuthError {
    constructor(requestId){
        super("NoAccountAuthError", "Response has no account object.");
        if (requestId) {
            this.correlationId = requestId;
        }
    }
}
