// Telemetry
import { EnumObjects, makeStringDataField } from "@microsoft/oteljs";
import { EventType, LegacyEventType, LogTenantName, UTelLogHandler } from "./UTelLogHandler";
// Constants
import { ClientAppId, ClientType } from "@ms/uno-constants/lib/local/AppConstants";
/**
 * Builds the event name. It must be in a specific format, Tenant name + App name + Event name
 * Example: Office.ProjectPlanner.Planner.Action, Office.ProjectPlanner.Planner.Performance, etc
 * @param eventName Name of an event
 * @returns An event name
 */ export function createEventIdentifier(eventName) {
    return `${LogTenantName}.Planner.${eventName}`;
}
/**
 * Builds a data field column  to keep it consistent across event tables.
 * @param type Type of an event
 * @param value Event field value
 * @returns A data field
 */ export function createDataField(type, value) {
    return makeStringDataField(`${type}_Name`, value);
}
/**
 * Builds a data field column for 'extra' data to keep it consistent across event tables.
 * @param type Type of an event
 * @param key Data field name
 * @returns A data field
 */ export function createExtraDataField(type, key, value) {
    return makeStringDataField(`${type}_ExtraData_${key}`, value);
}
/**
 * Builds the default utel event flags
 * @returns UTel event flags
 */ export function getEventFlags() {
    return {
        dataCategories: EnumObjects.DataCategories.ProductServiceUsage,
        diagnosticLevel: EnumObjects.DiagnosticLevel.RequiredServiceData
    };
}
/**
 * Returns true if the log handler is an instance of UTelLogHandler
 * @param logHandler Log handler
 */ export function isUTelLogHandler(logHandler) {
    return logHandler instanceof UTelLogHandler;
}
/**
 * Log event with provided log handlers using UTel js
 * @param event Event to log
 * @param logUTel [Optional] Log event using UTel log handler
 * @param logHandler [Optional] Log event using provided log handler
 */ export function logEvent(event, logUTel, logHandler) {
    // if logHandler is provided, log event using the logHandler
    if (logHandler && isUTelLogHandler(logHandler)) {
        logUTel?.(event, logHandler);
    }
}
/**
 * Set persistent data field to send with all events
 * @param name Data field name
 * @param value Data field value
 * @param makeDataField Function to create data field (e.g makeStringDataField, makeBooleanDataField)
 * @param logHandler [Optional] Log handler to set persistent data field
 */ export function setPersistentDataField(name, value, makeDataField, logHandler) {
    // set persistent data field for the logHandler if explicitly provided as a function parameter
    // it's to make sure that it supports new code that explicitly pass logHandler to all events' log method
    if (logHandler && isUTelLogHandler(logHandler)) {
        logHandler.setPersistentDataField(makeDataField(name, value));
    }
}
/**
 * Maps legacy event names coming from Planner and Project to new event names.
 * This will be removed once Planner and Project are migrated to use new event names.
 * @param legacyEventName Legacy event name from Planner and Project
 */ export function mapToNewEvents(legacyEventName) {
    if (Object.values(EventType).includes(legacyEventName)) {
        return legacyEventName;
    }
    switch(legacyEventName){
        case LegacyEventType.AuthError:
        case LegacyEventType.Diagnostics:
        case LegacyEventType.RequireJSError:
        case LegacyEventType.Route:
        case LegacyEventType.Trace:
        case LegacyEventType.UnhandledError:
            return EventType.Diagnostics;
        case LegacyEventType.Eupl:
        case LegacyEventType.Ptt:
            return EventType.Performance;
        case LegacyEventType.Action:
            return EventType.Action;
        case LegacyEventType.Engagement:
            return EventType.Engagement;
        case LegacyEventType.Qos:
            return EventType.Qos;
        default:
            return null;
    }
}
/**
 * Get appId to log as per the client type and host platform
 * @param clientType Client type
 * @param platform Host platform
 * */ export function getClientAppId(clientType, platform) {
    platform = platform.toLowerCase();
    switch(clientType){
        case ClientType.Teams:
            switch(platform){
                case "desktop":
                    return ClientAppId.MSTeamsDesktop;
                case "web":
                    return ClientAppId.MSTeamsWeb;
                default:
                    return "";
            }
        case ClientType.Web:
            return ClientAppId.MicrosoftPlannerClient;
        case ClientType.Sharepoint:
            return ClientAppId.Sharepoint;
        default:
            return "";
    }
}
