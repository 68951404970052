/**
 * Enumeration of the possible statuses of a store entity.
 */ export var StoreEntityStatus;
(function(StoreEntityStatus) {
    /** Entity has not been fetched and its status is unknown */ StoreEntityStatus["Unfetched"] = "U";
    /** Entity is being fetched */ StoreEntityStatus["Fetching"] = "F";
    /** Entity is present in the store */ StoreEntityStatus["Present"] = "P";
    /** Entity is present in the store but is soft deleted */ StoreEntityStatus["SoftDeleted"] = "SD";
    /** Fetch for the entity failed */ StoreEntityStatus["UnableToFetch"] = "UF";
    /** Fetch for the entity failed due to it being inaccessible for the user */ StoreEntityStatus["Inaccessible"] = "I";
    /** Fetch for the entity failed due to it not being found */ StoreEntityStatus["NotFound"] = "NF";
    /** Entity is stale and expired */ StoreEntityStatus["Stale"] = "S";
})(StoreEntityStatus || (StoreEntityStatus = {}));
export const failedStoreEntityStatuses = [
    "I",
    "NF",
    "SD",
    "UF"
];
