import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for ContainerStore, for use in the registry */ export const ContainerStoreActionTypes = [
    ActionType.DeleteGroupAction,
    ActionType.DeleteGroupFailedAction,
    ActionType.DeleteGroupSucceededAction,
    ActionType.FetchContainerAction,
    ActionType.FetchContainerFailedAction,
    ActionType.FetchContainerSucceededAction,
    ActionType.FetchUserJoinedGroupsSucceededAction,
    ActionType.UpdateContainerSucceededAction,
    ActionType.CreateGroupAction,
    ActionType.CreateGroupFailedAction,
    ActionType.CreateGroupSucceededAction
];
