// Constants
import { SubRouteKeys, SubRouteType, ViewType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { DefaultMyPlansPagePivotType, MyTasksViewPivotType } from "@ms/uno-constants/lib/local/ViewPivots";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utils
import { stringToEnum } from "@ms/uno-utilities/lib/local/EnumUtilities";
import { createRouteFromDetails } from "./DeepLinkUtilsHelper";
import { extractBasicPlanRoutingDetailsFromSubRoute, extractMyPlansRoutingDetailsFromSubRoute, extractPremiumPlanRoutingDetailsFromSubRoute, extractTodoListRoutingDetailsFromSubRoute, generateRouteDescriptorFromRoutingDetailsForPlan, generateSubRouteFromRoutingDetailsForMyPlans, getValueForKey } from "./RouteUtilities";
var DeepLinkPageType;
/**
 * DeepLink PageTypes
 * Note: All lowercase to be more case-insensitive friendly
 */ (function(DeepLinkPageType) {
    /** Basic Plan PageType */ DeepLinkPageType["BasicPlan"] = "plan";
    /** Todo List PageType */ DeepLinkPageType["TodoList"] = "list";
    /** Premium Plan PageType */ DeepLinkPageType["PremiumPlan"] = "premiumplan";
    /** All Tasks PageType */ DeepLinkPageType["AllTasks"] = "alltasks";
    /** Assigned To Me PageType */ DeepLinkPageType["AssignedToMe"] = "assignedtome";
    /** Flagged Emails PageType */ DeepLinkPageType["FlaggedEmails"] = "flaggedemails";
    /** Private Tasks PageType */ DeepLinkPageType["PrivateTasks"] = "privatetasks";
    /** My Plans PageType */ DeepLinkPageType["MyPlans"] = "myplans";
    /** My Day PageType */ DeepLinkPageType["MyDay"] = "myday";
})(DeepLinkPageType || (DeepLinkPageType = {}));
export var DeepLinkSubRouteKeys;
(function(DeepLinkSubRouteKeys) {
    /** DeepLink Schema Version of v1*/ DeepLinkSubRouteKeys["V1"] = "v1";
    /** SubRoute Task - Value after is TaskId */ DeepLinkSubRouteKeys["Task"] = "task";
    /** SubRoute Plan - Value after is PlanId */ DeepLinkSubRouteKeys["Plan"] = "plan";
    /** SubRoute Org - Value after is OrgId */ DeepLinkSubRouteKeys["Org"] = "org";
    /** SubRoute List - Value after is ListId */ DeepLinkSubRouteKeys["List"] = "list";
})(DeepLinkSubRouteKeys || (DeepLinkSubRouteKeys = {}));
var DeepLinkNotificationChannel;
/**
 * Notification Channel represents the source of the Notification DeepLink URL
 * Note: All lowercase to be more case-insensitive friendly
 */ (function(DeepLinkNotificationChannel) {
    /** Email Notification */ DeepLinkNotificationChannel["Email"] = "email";
    /** Teams Notification */ DeepLinkNotificationChannel["Teams"] = "teams";
})(DeepLinkNotificationChannel || (DeepLinkNotificationChannel = {}));
var DeepLinkNotificationType;
/**
 * Notification Type represents the type of the Notification DeepLink URL
 * Note: All lowercase to be more case-insensitive friendly
 */ (function(DeepLinkNotificationType) {
    /** Due Date Notification */ DeepLinkNotificationType["DueDate"] = "duedate";
    /** Task Assigned Notification */ DeepLinkNotificationType["TaskAssigned"] = "taskassigned";
    /** Trial Welcome Notification */ DeepLinkNotificationType["TrialWelcome"] = "trialwelcome";
    /** Task Re-Assigned Notification */ DeepLinkNotificationType["TaskAssignedReassigned"] = "taskassignedreassigned";
    /** Task Assigned Updated Notification */ DeepLinkNotificationType["TaskAssignedUpdated"] = "taskassignedupdated";
    /** Task Assigned By App Notification */ DeepLinkNotificationType["TaskAssignedByApp"] = "taskassignedbyapp";
    /** Task Re-Assigned By App Notification */ DeepLinkNotificationType["TaskAssignedReassignedByApp"] = "taskassignedreassignedbyapp";
    /** Task Assigned Updated By App Notification */ DeepLinkNotificationType["TaskAssignedUpdatedByApp"] = "taskassignedupdatedbyapp";
})(DeepLinkNotificationType || (DeepLinkNotificationType = {}));
var DeepLinkQueryParameterKeys;
/**
 * DeepLink query parameter keys
 * Note: All lowercase to be more case-insensitive friendly
 */ (function(DeepLinkQueryParameterKeys) {
    /** Notification Channel */ DeepLinkQueryParameterKeys["NotificationChannel"] = "nc";
    /** Notification Type */ DeepLinkQueryParameterKeys["NotificationType"] = "nt";
    /** Plan Type */ DeepLinkQueryParameterKeys["PlanType"] = "plantype";
})(DeepLinkQueryParameterKeys || (DeepLinkQueryParameterKeys = {}));
/**
 * Parses the specified deepLink and returns its corresponding Route (or null, if no valid route parsed)
 * Note: This will handle both legacy Teams App DeepLinks and the new UNO DeepLink format of
 * /<SchemaVersion>/<PageType>/<PageTypeSpecificSubRoute>?<OptionalParams>
 * e.g., /v1/plan/<PlanId>/task/<TaskId>
 * @param deepLink The deepLink to parse
 * @param loggers Loggers to use for logging
 * @param legacyParser Parser to use for parsing legacy deepLinks
 */ export function generateRouteFromDeepLink(deepLink, loggers, legacyParser) {
    if (!deepLink) {
        return null;
    }
    if (!deepLink.startsWith(`/${"v1"}/`) && legacyParser) {
        loggers?.traceLogger.logTrace(0x1e2de719 /* tag_4l42z */ , TraceLevel.Info, "generateRouteFromDeepLink - Trying to parse with Legacy format");
        const routeFromLegacyLink = legacyParser(deepLink, loggers);
        if (routeFromLegacyLink == null) {
            loggers?.traceLogger.logTrace(0x1e2de718 /* tag_4l42y */ , TraceLevel.Info, "generateRouteFromDeepLink - No route generated from legacy deepLink parsing");
        } else {
            loggers?.traceLogger.logTrace(0x1e2de717 /* tag_4l42x */ , TraceLevel.Info, "generateRouteFromDeepLink - Route generated from legacy deepLink parsing");
        }
        return routeFromLegacyLink;
    }
    loggers?.traceLogger.logTrace(0x1e2de716 /* tag_4l42w */ , TraceLevel.Info, "generateRouteFromDeepLink - Trying to parse with new UNO DeepLink format");
    // Let's strip off the Hash part
    deepLink = deepLink.split("#")[0];
    // Let's grab the DeepLink before Params and the DeepLink Params
    const deepLinkParamSplit = deepLink.split("?");
    const deepLinkWithoutParams = deepLinkParamSplit[0];
    const deepLinkParams = deepLinkParamSplit.length > 1 ? deepLinkParamSplit[1] : null;
    // Let's skip that first element (since it's empty)
    const [, ...deepLinkParts] = deepLinkWithoutParams.split("/");
    // Let's grab PageType and use that to determine parsing logic
    if (deepLinkParts.length < 2) {
        loggers?.traceLogger.logTrace(0x1e2de715 /* tag_4l42v */ , TraceLevel.Warning, "generateRouteFromDeepLink - Missing PageType");
        return null;
    }
    const page = deepLinkParts[1].toLowerCase();
    let route = null;
    switch(page){
        case "myplans":
            route = generateRouteFromMyPlansDeepLink(deepLinkParts, loggers);
            break;
        case "plan":
            route = generateRouteFromBasicPlanDeepLink(deepLinkParts, loggers);
            break;
        case "list":
            route = generateRouteFromTodoListDeepLink(deepLinkParts, loggers);
            break;
        case "premiumplan":
            route = generateRouteFromPremiumPlanDeepLink(deepLinkParts, loggers);
            break;
        case "alltasks":
            route = generateRouteFromMyTasksDeepLink(MyTasksViewPivotType.All, deepLinkParts, loggers);
            break;
        case "assignedtome":
            route = generateRouteFromMyTasksDeepLink(MyTasksViewPivotType.AssignedToMe, deepLinkParts, loggers);
            break;
        case "flaggedemails":
            route = generateRouteFromMyTasksDeepLink(MyTasksViewPivotType.FlaggedEmails, deepLinkParts, loggers);
            break;
        case "privatetasks":
            route = generateRouteFromMyTasksDeepLink(MyTasksViewPivotType.PrivateTasks, deepLinkParts, loggers);
            break;
        case "myday":
            route = generateRouteFromMyDayDeepLink(deepLinkParts, loggers);
            break;
        default:
            loggers?.traceLogger.logTrace(0x1e2de714 /* tag_4l42u */ , TraceLevel.Warning, "generateRouteFromDeepLink - Unexpected PageType");
            break;
    }
    if (route == null) {
        loggers?.traceLogger.logTrace(0x1e2de713 /* tag_4l42t */ , TraceLevel.Warning, "generateRouteFromDeepLink - No route generated");
    } else {
        loggers?.traceLogger.logTrace(0x1e2de712 /* tag_4l42s */ , TraceLevel.Info, "generateRouteFromDeepLink - Route generated");
        if (deepLinkParams != null) {
            const queryParams = new URLSearchParams(deepLinkParams);
            const record = Object.fromEntries(queryParams.entries());
            route.queryParameters = record;
        }
    }
    return route;
}
/**
 * Logs the Notification Info from the routeDescriptor (if present)
 */ export function logNotificationInfo(routeDescriptor, loggers) {
    const queryParamRecord = routeDescriptor.queryParameters;
    if (queryParamRecord == null) {
        return; // Nothing to log
    }
    const notificationChannelParam = queryParamRecord["nc"];
    const notificationTypeParam = queryParamRecord["nt"];
    if (notificationChannelParam != null && notificationTypeParam != null) {
        const notificationChannel = stringToEnum(notificationChannelParam.toLowerCase(), DeepLinkNotificationChannel);
        const notificationType = stringToEnum(notificationTypeParam.toLowerCase(), DeepLinkNotificationType);
        if (notificationChannel != null && notificationType != null) {
            loggers.engagementLogger.logEngagement("DeepLinkUtils", "RouteNotificationInfo", {
                notificationChannel: notificationChannel,
                notificationType: notificationType,
                notificationSubRouteType: routeDescriptor.subRouteType
            });
        } else {
            loggers.traceLogger.logTrace(0x1e2de711 /* tag_4l42r */ , TraceLevel.Warning, "logNotificationInfo - We got unexpected DeepLinkNotificationChannel/DeepLinkNotificationType value");
        }
    }
}
/**
 * Generates route from a My Plans DeepLink
 * e.g., ["v1", "myplans", "dialog", "createplan"]
 */ function generateRouteFromMyPlansDeepLink(deepLinkParts, loggers) {
    // There needs to be at least ["v1", "myplans", ...]
    if (deepLinkParts.length < 2) {
        loggers?.traceLogger.logTrace(0x1e2de710 /* tag_4l42q */ , TraceLevel.Warning, "generateRouteFromMyPlansDeepLink - Invalid deepLinkParts length");
        return null;
    }
    // We want to map a DeepLink like ["v1", "myplans", "dialog", "createplan"] to Route like [<DefaultMyPlansPagePivotType>, "dialog", "createplan"]
    const subRouteParts = deepLinkParts.slice(2); // Slice 2 to remove "v1", "myplans" from the beginning
    subRouteParts.unshift(DefaultMyPlansPagePivotType); // Add DefaultMyPlansPagePivotType to the beginning
    const myPlansRoutingDetails = extractMyPlansRoutingDetailsFromSubRoute(subRouteParts);
    if (myPlansRoutingDetails == null) {
        loggers?.traceLogger.logTrace(0x1e2de70f /* tag_4l42p */ , TraceLevel.Warning, "generateRouteFromMyPlansDeepLink - extractMyPlansRoutingDetailsFromSubRoute returned null");
        return null;
    }
    const subRoute = generateSubRouteFromRoutingDetailsForMyPlans(myPlansRoutingDetails);
    return {
        rootUrl: "",
        subRouteType: SubRouteType.MyPlans,
        subRoute: subRoute,
        queryParameters: {}
    };
}
/**
 * Generates route from a Basic Plan DeepLink
 * e.g., ["v1", "plan", "PlanId", "task", "TaskId"]
 */ function generateRouteFromBasicPlanDeepLink(deepLinkParts, loggers) {
    // We need to have enough length for PlanId
    if (deepLinkParts.length < 3) {
        loggers?.traceLogger.logTrace(0x1e2de70e /* tag_4l42o */ , TraceLevel.Warning, "generateRouteFromPlanDeepLink - Missing PlanId");
        return null;
    }
    // Slice 2 to remove "v1", "plan" from the beginning
    const planRouteDetails = extractBasicPlanRoutingDetailsFromSubRoute(deepLinkParts.slice(2));
    if (planRouteDetails == null) {
        loggers?.traceLogger.logTrace(0x1e2de70d /* tag_4l42n */ , TraceLevel.Warning, "generateRouteFromBasicPlanDeepLink - extractBasicPlanRoutingDetailsFromSubRoute returned null");
        return null;
    }
    const routeChangeDescriptor = generateRouteDescriptorFromRoutingDetailsForPlan(planRouteDetails, {});
    return {
        rootUrl: "",
        subRouteType: routeChangeDescriptor.subRouteType,
        subRoute: routeChangeDescriptor.subRoute,
        queryParameters: {}
    };
}
/**
 * Generates route from a Todo List DeepLink
 * e.g., ["v1", "plan", "PlanId", "task", "TaskId"]
 */ function generateRouteFromTodoListDeepLink(deepLinkParts, loggers) {
    // We need to have enough length for PlanId
    if (deepLinkParts.length < 3) {
        loggers?.traceLogger.logTrace(0x1e2de70c /* tag_4l42m */ , TraceLevel.Warning, "generateRouteFromTodoListDeepLink - Missing PlanId");
        return null;
    }
    // Slice 2 to remove "v1", "list" from the beginning
    const planRouteDetails = extractTodoListRoutingDetailsFromSubRoute(deepLinkParts.slice(2));
    if (planRouteDetails == null) {
        loggers?.traceLogger.logTrace(0x1e2de70b /* tag_4l42l */ , TraceLevel.Warning, "generateRouteFromTodoListDeepLink - extractBasicPlanRoutingDetailsFromSubRoute returned null");
        return null;
    }
    return createRouteFromDetails({
        subRouteType: SubRouteType.TodoList,
        personalListId: planRouteDetails.planId,
        taskId: planRouteDetails.taskId
    });
}
/**
 * Generates route from a Premium Plan DeepLink
 * e.g., ["v1", "premiumplan", "PlanId", "org", "OrgId", "task", "TaskId"]
 */ function generateRouteFromPremiumPlanDeepLink(deepLinkParts, loggers) {
    // We need to have enough length for PlanId and OrgId
    if (deepLinkParts.length < 5) {
        loggers?.traceLogger.logTrace(0x1e2de70a /* tag_4l42k */ , TraceLevel.Warning, "generateRouteFromPremiumPlanDeepLink - Missing PlanId or OrgId");
        return null;
    }
    const orgId = getValueForKey(deepLinkParts, SubRouteKeys.Org);
    if (!orgId || deepLinkParts[3].toLowerCase() !== "org") {
        loggers?.traceLogger.logTrace(0x1e2de709 /* tag_4l42j */ , TraceLevel.Warning, "generateRouteFromPremiumPlanDeepLink - org was not part of path");
        return null;
    }
    // Slice 2 to remove "v1", "premiumplan" from the beginning
    const planRouteDetails = extractPremiumPlanRoutingDetailsFromSubRoute(deepLinkParts.slice(2));
    if (planRouteDetails == null) {
        loggers?.traceLogger.logTrace(0x1e2de708 /* tag_4l42i */ , TraceLevel.Warning, "generateRouteFromPremiumPlanDeepLink - extractPremiumPlanRoutingDetailsFromSubRoute returned null");
        return null;
    }
    const routeChangeDescriptor = generateRouteDescriptorFromRoutingDetailsForPlan(planRouteDetails, {});
    return {
        rootUrl: "",
        subRouteType: routeChangeDescriptor.subRouteType,
        subRoute: routeChangeDescriptor.subRoute,
        queryParameters: {}
    };
}
/**
 * Generates route from a MyTasks pivot (e.g., AssignedToMe) DeepLink
 *
 * Note: Format = ["v1", "assignedtome", "task", "TaskId"]
 */ function generateRouteFromMyTasksDeepLink(myTasksPivot, deepLinkParts, loggers) {
    const subRouteType = SubRouteType.MyTasks;
    const routeCreateDetails = {
        ...parseBaseMyRouteCreateDetailsFromDeepLink(deepLinkParts, subRouteType, loggers),
        subRouteType: subRouteType,
        myTasksPivot: myTasksPivot
    };
    return createRouteFromDetails(routeCreateDetails);
}
/**
 * Generates route from MyDay DeepLink
 *
 * Note: Format = ["v1", myday, "task", "TaskId"]
 */ function generateRouteFromMyDayDeepLink(deepLinkParts, loggers) {
    const subRouteType = SubRouteType.MyDay;
    const routeCreateDetails = {
        ...parseBaseMyRouteCreateDetailsFromDeepLink(deepLinkParts, subRouteType, loggers),
        subRouteType: subRouteType
    };
    return createRouteFromDetails(routeCreateDetails);
}
/**
 * Helper method for parsing the Route metadata for MyDay and MyTasks DeepLink
 *
 * Note: Format = ["v1", <PageType>, "task", "TaskId"]
 */ function parseBaseMyRouteCreateDetailsFromDeepLink(deepLinkParts, subRouteType, loggers) {
    const routeCreateDetails = {
        subRouteType: subRouteType
    };
    const view = getValueForKey(deepLinkParts, SubRouteKeys.View);
    const viewType = view != null ? stringToEnum(view, ViewType) : null;
    if (viewType != null) {
        routeCreateDetails.view = viewType;
    }
    const taskId = getValueForKey(deepLinkParts, SubRouteKeys.Task);
    if (taskId != null) {
        routeCreateDetails.taskId = taskId;
    }
    return routeCreateDetails;
}
/**
 * Returns the DeepLink URL for the specified Route (or null if currently not supported)
 */ export function generateDeepLinkFromRoute(route, loggers) {
    const subRouteType = route.subRouteType;
    switch(subRouteType){
        case SubRouteType.BasicPlan:
            return generateDeepLinkFromBasicPlanRoute(route, loggers);
        case SubRouteType.PremiumPlan:
            return generateDeepLinkFromPremiumPlanRoute(route, loggers);
        case SubRouteType.MyTasks:
            return generateDeepLinkFromMyTasksRoute(route, loggers);
        case SubRouteType.MyDay:
            return generateDeepLinkFromMyDayRoute(route, loggers);
        case SubRouteType.TodoList:
            return generateDeepLinkFromTodoListRoute(route, loggers);
        default:
            loggers?.traceLogger.logTrace(0x1e2de707 /* tag_4l42h */ , TraceLevel.Warning, `generateDeepLinkFromRoute - Unsupported SubRouteType: ${subRouteType}`);
            return null;
    }
}
/**
 * Returns the DeepLink URL for the specified BasicPlan Route
 * Example SubRoute: ["PlanId", "task", "TaskId"]
 */ function generateDeepLinkFromBasicPlanRoute(route, loggers) {
    const subRoute = route.subRoute;
    if (route.subRouteType !== SubRouteType.BasicPlan || subRoute.length === 0) {
        loggers?.traceLogger.logTrace(0x1e2de706 /* tag_4l42g */ , TraceLevel.Warning, `generateDeepLinkFromBasicPlanRoute - Invalid SubRouteType/SubRoute`);
        return null;
    }
    const basicPlanRouteDetails = extractBasicPlanRoutingDetailsFromSubRoute(subRoute);
    if (!basicPlanRouteDetails?.planId) {
        loggers?.traceLogger.logTrace(0x1e2de705 /* tag_4l42f */ , TraceLevel.Warning, `generateDeepLinkFromBasicPlanRoute - No Plan Details found in SubRoute`);
        return null;
    }
    let deepLink = `/${"v1"}/${"plan"}/${basicPlanRouteDetails.planId}`;
    deepLink += generateCommonDeepLinkSuffixFromRoute(route);
    return deepLink;
}
/**
 * Returns the DeepLink URL for the specified MyTasks Route
 * Example SubRoute: ["assignedtome", "view", "grid", "task", "TaskId"]
 */ function generateDeepLinkFromMyTasksRoute(route, loggers) {
    const subRoute = route.subRoute;
    if (route.subRouteType !== SubRouteType.MyTasks || subRoute.length === 0) {
        loggers?.traceLogger.logTrace(0x1e2de704 /* tag_4l42e */ , TraceLevel.Warning, `generateDeepLinkFromMyTasksRoute - Invalid SubRouteType/SubRoute`);
        return null;
    }
    const myTasksPivotString = subRoute[0];
    const myTasksPivot = stringToEnum(myTasksPivotString, MyTasksViewPivotType);
    if (myTasksPivot == null) {
        loggers?.traceLogger.logTrace(0x1e2de703 /* tag_4l42d */ , TraceLevel.Warning, `generateDeepLinkFromMyTasksRoute - Invalid MyTasksViewPivotType`);
        return null;
    }
    let deepLinkPageType = null;
    switch(myTasksPivot){
        case MyTasksViewPivotType.All:
            deepLinkPageType = "alltasks";
            break;
        case MyTasksViewPivotType.AssignedToMe:
            deepLinkPageType = "assignedtome";
            break;
        case MyTasksViewPivotType.FlaggedEmails:
            deepLinkPageType = "flaggedemails";
            break;
        case MyTasksViewPivotType.PrivateTasks:
            deepLinkPageType = "privatetasks";
            break;
        default:
            loggers?.traceLogger.logTrace(0x1e2de702 /* tag_4l42c */ , TraceLevel.Warning, `generateDeepLinkFromMyTasksRoute - Unexpected MyTasksViewPivotType`);
            return null;
    }
    let deepLink = `/${"v1"}/${deepLinkPageType}`;
    deepLink += generateCommonDeepLinkSuffixFromRoute(route);
    return deepLink;
}
/**
 * Returns the DeepLink URL for the specified MyDay Route
 * Example SubRoute: ["myday", "view", "grid", "task", "TaskId"]
 */ function generateDeepLinkFromMyDayRoute(route, loggers) {
    if (route.subRouteType !== SubRouteType.MyDay) {
        loggers?.traceLogger.logTrace(0x1e2de701 /* tag_4l42b */ , TraceLevel.Warning, `generateDeepLinkFromMyDayRoute - Invalid SubRouteType`);
        return null;
    }
    let deepLink = `/${"v1"}/${"myday"}`;
    deepLink += generateCommonDeepLinkSuffixFromRoute(route);
    return deepLink;
}
/**
 * Returns the DeepLink URL for the specified ToDo List Route
 */ function generateDeepLinkFromTodoListRoute(route, loggers) {
    const subRoute = route.subRoute;
    if (route.subRouteType !== SubRouteType.TodoList) {
        loggers?.traceLogger.logTrace(0x1e2de700 /* tag_4l42a */ , TraceLevel.Warning, `generateDeepLinkFromTodoListRoute - Invalid SubRouteType`);
        return null;
    }
    const todoRoutingDetails = extractTodoListRoutingDetailsFromSubRoute(subRoute);
    if (!todoRoutingDetails?.planId) {
        loggers?.traceLogger.logTrace(0x1e2de6e3 /* tag_4l419 */ , TraceLevel.Warning, `generateDeepLinkFromTodoListRoute - No PlanId found in SubRoute`);
        return null;
    }
    let deepLink = `/${"v1"}/${"list"}/${todoRoutingDetails.planId}`;
    deepLink += generateCommonDeepLinkSuffixFromRoute(route);
    return deepLink;
}
/**
 * Returns DeepLink URL suffix for common route parts
 * (This also helps enforce some consistency across the DeepLink URL format)
 */ function generateCommonDeepLinkSuffixFromRoute(route) {
    let deepLinkSuffix = "";
    const view = getValueForKey(route.subRoute, SubRouteKeys.View);
    if (view) {
        deepLinkSuffix += `/view/${view}`;
    }
    const taskId = getValueForKey(route.subRoute, SubRouteKeys.Task);
    if (taskId) {
        deepLinkSuffix += `/task/${taskId}`;
    }
    return deepLinkSuffix;
}
/**
 * Returns the DeepLink URL for the specified PremiumPlan Route
 * Example SubRoute: ["PlanId", "org", "OrgId", "task", "TaskId"]
 */ function generateDeepLinkFromPremiumPlanRoute(route, loggers) {
    const subRoute = route.subRoute;
    if (route.subRouteType !== SubRouteType.PremiumPlan || subRoute.length < 3 || subRoute[1] !== SubRouteKeys.Org) {
        loggers?.traceLogger.logTrace(0x1e2de6e2 /* tag_4l418 */ , TraceLevel.Warning, `generateDeepLinkFromPremiumPlanRoute - Invalid SubRouteType/SubRoute`);
        return null;
    }
    const premiumPlanRouteDetails = extractPremiumPlanRoutingDetailsFromSubRoute(subRoute);
    if (!premiumPlanRouteDetails?.planId) {
        loggers?.traceLogger.logTrace(0x1e2de6e1 /* tag_4l417 */ , TraceLevel.Warning, `generateDeepLinkFromPremiumPlanRoute - No Plan Id found in SubRoute`);
        return null;
    }
    let deepLink = `/${"v1"}/${"premiumplan"}/${premiumPlanRouteDetails.planId}/${"org"}/${premiumPlanRouteDetails.orgId}`;
    deepLink += generateCommonDeepLinkSuffixFromRoute(route);
    return deepLink;
}
