// Constants
import { ClientFlavor, MsaTenantId } from "@ms/uno-constants/lib/local/AppConstants";
import { EngagementTelemetryConstants } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { SubRouteKeys, SubRouteType, ViewType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { DefaultMyTasksViewPivotType } from "@ms/uno-constants/lib/local/ViewPivots";
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { SettingsObjectName } from "@ms/uno-constants/lib/local/configuration/ISettings";
// Flux
import { AsyncDispatcher } from "@ms/uno-fluxcore/lib/local/dispatcher";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
// Host Adaptor
import { FailedReason } from "@ms/uno-hostadaptors/lib/local/IUnoHostAdaptor";
// Models
import { AssetLoadingError } from "@ms/uno-errors/lib/local/errors/AssetLoadingError";
// Services
import { AuthenticatedAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/AuthenticatedAjaxClient";
import { BasicAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/BasicAjaxClient";
import { GatedGetAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/GatedGetAjaxClient";
import { RequestPrioritizer } from "@ms/uno-services/lib/local/priorityRequestQueue/RequestPrioritizer";
import { Log } from "@ms/uno-telemetry/lib/local/events/Action.event";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { setIsOnlineStatusForLogs } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
// Utilities
import { initializeCultureData } from "@ms/uno-shell/lib/local/utilities/CultureUtilities";
import { HtmlSettingsReader } from "@ms/uno-utilities/lib/local/HtmlSettingsReader";
/**
 * Sets up the AppContext
 * @throws {Error} If there is an error initializing the AppContext
 */
export function setupAppContext() {
    const reader = new HtmlSettingsReader(SettingsObjectName);
    AppContext.initializeFromSettings(AppContext.BuildContextSettingsFromSettingsGetter(reader.getSetting.bind(reader)));
}
/**
 * Gets the context from hostAdaptor and also initializes culture data
 */
export async function getContextAndInitializeCultureData(hostAdaptor) {
    let context;
    try {
        context = await hostAdaptor.getContext();
        await initializeCultureData(context.locale);
    }
    catch (e) {
        const err = new AssetLoadingError(e.name, e.message);
        hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message: e.message });
        // TODO - 7545465, 8108486 need to implement errors and error logging
        // Task 8108486: Uno LazyLoading and asset loading error management
        throw err;
    }
    return context;
}
/**
 * Gets the TelemetryContext from the Context
 */
export function getTelemetryContext(context) {
    return {
        locale: context.locale,
        userId: context.userId,
        tenantId: context.tenantId,
        ringId: context.ringId, // Teams ring according to Teams client SDK
        isMSA: context.tenantId === MsaTenantId,
        isGuestUser: context.isGuestUser,
        platform: context.platform,
    };
}
/**
 * Provides the initial route based on the app flavor and configuration
 */
export function getInitialRoute(clientFlavor, configProvider) {
    return {
        rootUrl: "", // TODO [ADO#8706197] - Should be the teams root url (but setting this currently breaks RouteStore - Need to investigate/fix)
        subRouteType: getInitialSubRouteType(clientFlavor),
        subRoute: getInitialSubRoute(clientFlavor, configProvider),
        queryParameters: {},
    };
}
/**
 * Provide the initial subRouteType based on the app flavor
 */
export function getInitialSubRouteType(clientFlavor) {
    switch (clientFlavor) {
        case ClientFlavor.ConfigTab:
            return SubRouteType.ConfigTeamsTab;
        case ClientFlavor.RemoveTab:
            return SubRouteType.RemoveTeamsTab;
        case ClientFlavor.TaskFromMessage:
            return SubRouteType.MessageTasks;
        default:
            return SubRouteType.MyTasks;
    }
}
/**
 * Provide the initial subRoute based on the app flavor and configuration
 */
export function getInitialSubRoute(clientFlavor, configProvider) {
    if (clientFlavor === ClientFlavor.TaskFromMessage) {
        return [];
    }
    else {
        return [DefaultMyTasksViewPivotType, SubRouteKeys.View, ViewType.Grid];
    }
}
/**
 * Gets the StoreProviders from the StoreFactory
 */
export function getStoreProviders(storeFactory) {
    return {
        load: storeFactory.load.bind(storeFactory),
        isLoaded: storeFactory.isLoaded.bind(storeFactory),
        aggregates: storeFactory.getAggregatesStoreProvider(),
        activity: storeFactory.getActivityStoreProvider(),
        auth: storeFactory.getAuthStoreProvider(),
        appContext: storeFactory.getAppContextStoreProvider(),
        basicBucket: storeFactory.getBasicBucketStoreProvider(),
        basicPlan: storeFactory.getBasicPlanStoreProvider(),
        basicPlanConversionStatus: storeFactory.getBasicPlanConversionStatusStoreProvider(),
        basicPermissions: storeFactory.getBasicPermissionsStoreProvider(),
        basicTask: storeFactory.getBasicTaskStoreProvider(),
        campaign: storeFactory.getCampaignStoreProvider(),
        capabilities: storeFactory.getCapabilitiesStoreProvider(),
        container: storeFactory.getContainerStoreProvider(),
        conversations: storeFactory.getConversationsStoreProvider(),
        createTaskFromMessage: storeFactory.getCreateTaskFromMessageStoreProvider(),
        deltaSync: storeFactory.getDeltaSyncStoreProvider(),
        driveItem: storeFactory.getDriveItemStoreProvider(),
        groups: storeFactory.getGroupsStoreProvider(),
        intelligence: storeFactory.getIntelligenceStoreProvider(),
        modal: storeFactory.getModalStoreProvider(),
        mru: storeFactory.getMruStoreProvider(),
        navigation: storeFactory.getNavigationStoreProvider(),
        notification: storeFactory.getNotificationStoreProvider(),
        operationTracking: storeFactory.getOperationTrackingStoreProvider(),
        org: storeFactory.getOrgStoreProvider(),
        performanceCollection: storeFactory.getPerformanceCollectionStoreProvider(),
        planConversion: storeFactory.getPlanConversionStoreProvider(),
        portfolio: storeFactory.getPortfolioStoreProvider(),
        premiumPlan: storeFactory.getPremiumPlanStoreProvider(),
        premiumPlanPermissions: storeFactory.getPremiumPlanPermissionsStoreProvider(),
        projectHomeResource: storeFactory.getProjectHomeResourceStoreProvider(),
        publishing: storeFactory.getPublishingStoreProvider(),
        route: storeFactory.getRouteStoreProvider(),
        statusReport: storeFactory.getStatusReportStoreProvider(),
        suiteNav: storeFactory.getSuiteNavStoreProvider(),
        teams: storeFactory.getTeamsStoreProvider(),
        templates: storeFactory.getTemplatesStoreProvider(),
        tenant: storeFactory.getTenantStoreProvider(),
        upsell: storeFactory.getUpsellStoreProvider(),
        unoShell: storeFactory.getUnoShellStoreProvider(),
        user: storeFactory.getUserStoreProvider(),
        userMembership: storeFactory.getUserMembershipStoreProvider(),
        view: storeFactory.getViewStoreProvider(),
        recommendedPlans: storeFactory.getRecommendedPlansStoreProvider(),
    };
}
/**
 * Gets the AsyncDispatcher
 */
export function getAsyncDispatcher(registry, storeFactory, loggers) {
    return new AsyncDispatcher(registry, (storeType) => () => storeFactory.loadForDispatch(storeType), (event) => Log(event, loggers.logHandler), (tag, traceLevel, message) => loggers.traceLogger.logTrace(tag, traceLevel, message));
}
/**
 * Gets the RequestPrioritizer
 */
export function getRequestPrioritizer(storeProviders) {
    return new RequestPrioritizer((request) => {
        // Cancel request if it is not for the current view
        const currentViewId = storeProviders.load(Store.Route).getCurrentViewId();
        return currentViewId != null && request.viewIds != null && request.viewIds.indexOf(currentViewId) === -1;
    });
}
/**
 * Gets the AuthService
 */
export function getAuthServiceProvider(factory, dispatcher, hostAdaptor, loggers, configProvider) {
    const { auth } = configProvider().settings.serviceConfigurations;
    const { globalAjaxTimeout } = configProvider().settings.retrySettings;
    const apiClient = new AuthenticatedAjaxClient(new BasicAjaxClient(globalAjaxTimeout, loggers), auth.authResourceURI, loggers, hostAdaptor.getAccessToken);
    const gatedApiClient = new GatedGetAjaxClient(apiClient);
    return factory.getAuthServiceProvider(gatedApiClient, hostAdaptor, configProvider);
}
/**
 * Gets the ServiceProviders
 */
export function getServiceProviders(serviceOrchestrator, authService) {
    return {
        augloop: serviceOrchestrator.getAugloopServiceProvider(),
        auth: () => authService,
        centro: serviceOrchestrator.getCentroServiceProvider(),
        chatSvcAgg: serviceOrchestrator.getChatSvcAggServiceProvider(),
        conversations: serviceOrchestrator.getConversationsServiceProvider(),
        driveItem: serviceOrchestrator.getDriveItemServiceProvider(),
        flow: serviceOrchestrator.getFlowServiceProvider(),
        gateway: serviceOrchestrator.getGatewayServiceProvider(),
        iris: serviceOrchestrator.getIrisServiceProvider(),
        groups: serviceOrchestrator.getGroupsServiceProvider(),
        loop: serviceOrchestrator.getLoopServiceProvider(),
        loopMts: serviceOrchestrator.getLoopMtsServiceProvider(),
        mars: serviceOrchestrator.getMarsServiceProvider(),
        mru: serviceOrchestrator.getMruServiceProvider(),
        ocps: serviceOrchestrator.getOcpsServiceProvider(),
        oneshell: serviceOrchestrator.getOneShellServiceProvider(),
        planner: serviceOrchestrator.getPlannerServiceProvider(),
        plp: serviceOrchestrator.getProjectLandingPageServiceProvider(),
        pss: serviceOrchestrator.getProjectSchedulingServiceProvider(),
        tcms: serviceOrchestrator.getTcmsServiceProvider(),
        teams: serviceOrchestrator.getTeamsServiceProvider(),
        tenant: serviceOrchestrator.getTenantServiceProvider(),
        retail: serviceOrchestrator.getRetailServiceProvider(),
        roaming: serviceOrchestrator.getRoamingServiceProvider(),
        siteURL: serviceOrchestrator.getSiteURLServiceProvider(),
        todo: serviceOrchestrator.getTodoServiceProvider(),
        user: serviceOrchestrator.getUserServiceProvider(),
    };
}
/**
 * Gets the LogicModuleProviders
 */
export function getLogicModuleProviders(lmFactory, dispatcher, serviceProviders, storeProviders, configProvider, hostAdaptor, authService, authStore, fetchAccessToken) {
    return {
        adaptiveCard: lmFactory.getAdaptiveCardModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        appContext: lmFactory.getAppContextModuleProvider(dispatcher, serviceProviders, storeProviders, hostAdaptor, configProvider),
        auth: lmFactory.getAuthModuleProvider(dispatcher, authService, authStore, configProvider),
        basicBucket: lmFactory.getBasicBucketModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        basicPlan: lmFactory.getBasicPlanModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        basicTask: lmFactory.getBasicTaskModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        campaign: lmFactory.getCampaignModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        capabilities: lmFactory.getCapabilitiesModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        centro: lmFactory.getCentroModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        chatSvcAgg: lmFactory.getChatSvcAggModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        conversations: lmFactory.getConversationsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        deltaSync: lmFactory.getDeltaSyncModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        excelExport: lmFactory.getExcelExportModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        driveItem: lmFactory.getDriveItemModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        groups: lmFactory.getGroupsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        intelligence: lmFactory.getIntelligenceModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        links: lmFactory.getLinksModuleProvider(dispatcher, serviceProviders, storeProviders, hostAdaptor, configProvider),
        loop: lmFactory.getLoopModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider, fetchAccessToken),
        mru: lmFactory.getMruModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        notification: lmFactory.getNotificationModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        org: lmFactory.getOrgModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        planai: lmFactory.getPlanAiModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        planConversion: lmFactory.getPlanConversionModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        portfolio: lmFactory.getPortfolioModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        premiumPlan: lmFactory.getPremiumPlanModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        premiumTask: lmFactory.getPremiumTaskModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        publishing: lmFactory.getPublishingModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        roaming: lmFactory.getRoamingModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        roster: lmFactory.getRosterModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        suiteNav: lmFactory.getSuiteNavModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        teams: lmFactory.getTeamsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        templates: lmFactory.getTemplateModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        tenant: lmFactory.getTenantModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        todo: lmFactory.getTodoModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider, fetchAccessToken),
        ttTabs: lmFactory.getTeamsTasksTabsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        upsell: lmFactory.getUpsellModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        user: lmFactory.getUserModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
    };
}
/**
 * Gets the ActionCreatorProviders
 */
export function getActionCreatorProviders(acFactory, dispatcher, lmProviders, storeProviders, configProvider, hostAdaptor) {
    return {
        activity: acFactory.getActivityAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        appContext: acFactory.getAppContextAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        basicBucket: acFactory.getBasicBucketAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        basicPlan: acFactory.getBasicPlanAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        basicTask: acFactory.getPlannerTaskAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        campaign: acFactory.getCampaignAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        capabilities: acFactory.getCapabilitiesAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        container: acFactory.getContainerAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        createTaskFromTeamsMessage: acFactory.getCreateTaskFromTeamsMessageAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        deltaSync: acFactory.getDeltaSyncAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        driveItem: acFactory.getDriveItemAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        export: acFactory.getExportAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        groups: acFactory.getGroupsAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        intelligence: acFactory.getIntelligenceAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        links: acFactory.getLinksAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        modal: acFactory.getModalAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        mru: acFactory.getMruAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        notification: acFactory.getNotificationAcProvider(dispatcher, lmProviders, configProvider),
        operationTracking: acFactory.getOperationTrackingAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        org: acFactory.getOrgAcProvider(lmProviders, configProvider),
        performanceCollection: acFactory.getPerformanceCollectionAcProvider(dispatcher, storeProviders, configProvider),
        portfolio: acFactory.getPortfolioAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        planConversion: acFactory.getPlanConversionAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        premiumPlan: acFactory.getPremiumPlanAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        publishing: acFactory.getPublishingAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        routing: acFactory.getRoutingAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        suiteNavView: acFactory.getSuiteNavViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        teams: acFactory.getTeamsAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        teamsTab: acFactory.getTeamsTabAcProvider(dispatcher, lmProviders, storeProviders, configProvider, hostAdaptor),
        templates: acFactory.getTemplatesAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        tenant: acFactory.getTenantAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        unoShell: acFactory.getUnoShellAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        upsell: acFactory.getUpsellAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        user: acFactory.getUserAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        ocv: acFactory.getOCVAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        recommendedPlans: acFactory.getRecommendedPlansAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        projectHomeResource: acFactory.getResourceAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        // View AC providers
        basicPlanView: acFactory.getBasicPlanViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        createPlanView: acFactory.getCreatePlanViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        copyPlanView: acFactory.getCopyPlanViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        createPortfolioView: acFactory.getCreatePortfolioViewAcProvider(dispatcher, lmProviders, storeProviders),
        createTaskView: acFactory.getCreateTaskViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        leftNavView: acFactory.getLeftNavViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        myDayView: acFactory.getMyDayViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        myPlansView: acFactory.getMyPlansViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        myPortfoliosView: acFactory.getMyPortfoliosViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        myTasksView: acFactory.getMyTasksViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        unoShellView: acFactory.getUnoShellViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        convertPlanView: acFactory.getConvertPlanViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        createTeamsTabView: acFactory.getCreateTeamsTabViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider, hostAdaptor),
        sharePlanView: acFactory.getSharePlanViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        campaignCalloutView: acFactory.getCampaignCalloutViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        publishingLeftNavView: acFactory.getPublishingLeftNavViewAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
        viewTransforms: acFactory.getViewTransformsAcProvider(dispatcher, lmProviders, storeProviders, configProvider),
    };
}
/**
 * Gets the WorkerProviders
 */
export function getWorkerProviders(workerFactory, acProviders, storeProviders, configProvider, telemetryLoggers, routePathPrefix) {
    return {
        basicPlanConversionTrackerWorkerProvider: workerFactory.getBasicPlanConversionTrackerWorkerProvider(acProviders, storeProviders, configProvider),
        cdsProvisioningStatusWorker: workerFactory.getCdsProvisioningStatusWorkerProvider(acProviders, storeProviders, configProvider),
        deltaSyncWorker: workerFactory.getDeltaSyncWorkerProvider(acProviders, storeProviders, configProvider),
        deltaSyncFeedProcessorWorker: workerFactory.getDeltaSyncFeedProcessorWorkerProvider(acProviders, storeProviders),
        historyObserverWorker: workerFactory.getHistoryObserverWorkerProvider(acProviders, storeProviders, routePathPrefix, () => document.location.pathname),
        navigationWorker: workerFactory.getNavigationWorkerProvider(acProviders, storeProviders),
        networkConnectivityWorker: workerFactory.getNetworkConnectivityWorkerProvider(),
        planConversionStatusTrackerWorker: workerFactory.getPlanConversionStatusTrackerWorkerProvider(acProviders, storeProviders, configProvider),
        premiumPlanCreationStatusWorker: workerFactory.getPremiumPlanCreationStatusWorkerProvider(acProviders, storeProviders, configProvider),
        projectTrialOperationTrackerWorker: workerFactory.getProjectTrialOperationTrackerWorkerProvider(acProviders, storeProviders),
        recommendedPlanUpdateWorker: workerFactory.getRecommendedPlanUpdateWorkerProvider(acProviders, storeProviders, configProvider),
        augloopTileSyncWorker: workerFactory.getAugloopTileSyncWorkerProvider(acProviders, storeProviders, configProvider),
        operationTrackingWorker: workerFactory.getOperationTrackingWorkerProvider(acProviders, storeProviders, configProvider),
        performanceObserverWorker: workerFactory.getPerformanceObserverWorkerProvider(telemetryLoggers, configProvider),
    };
}
/**
 * Load chunks and initialize workers
 */
export async function loadAndInitializeWorkers(workerProviders, requestPrioritizer, loggers, configProvider) {
    try {
        const [networkConnectivityWorker, ...workers] = await Promise.all([
            workerProviders.networkConnectivityWorker(),
            workerProviders.deltaSyncWorker(),
            workerProviders.deltaSyncFeedProcessorWorker(),
            workerProviders.navigationWorker(),
            workerProviders.premiumPlanCreationStatusWorker(),
            workerProviders.projectTrialOperationTrackerWorker(),
            workerProviders.augloopTileSyncWorker(),
            workerProviders.operationTrackingWorker(),
            workerProviders.recommendedPlanUpdateWorker(),
        ]);
        workers.forEach((worker) => worker.init());
        networkConnectivityWorker.init();
        networkConnectivityWorker.addNetworkConnectivityChangeCallback(requestPrioritizer.onNetworkConnectivityChanged.bind(requestPrioritizer));
        networkConnectivityWorker.addNetworkConnectivityChangeCallback(setIsOnlineStatusForLogs);
        //Initialize the performance observer worker
        if (configProvider().flights.EnableModernPerformanceMetrics) {
            const performanceObserverWorker = await workerProviders.performanceObserverWorker();
            performanceObserverWorker.init();
        }
        if (configProvider().flights.EnableUnoLevelProjectProvisioning) {
            const cdsProvisioningStatusWorker = await workerProviders.cdsProvisioningStatusWorker();
            cdsProvisioningStatusWorker.init();
        }
    }
    catch (e) {
        loggers.traceLogger.logTrace(0x1e354195 /* tag_4nugv */, TraceLevel.Warning, `Failed to load and initialize workers. [ErrorType=${e.name}][Error=${e.message}]`);
    }
}
/**
 * Log initial plan view sub route type info for experimentation
 * @param componentName The component name
 * @param subRouteType The current sub route type
 * @param loggers The loggers
 */
export function logPlanViewSubRouteType(componentName, subRouteType, loggers) {
    // If initial route is for a plan view, track in engagement telemetry
    if (subRouteType === SubRouteType.BasicPlan || subRouteType === SubRouteType.PremiumPlan || subRouteType === SubRouteType.TodoList) {
        loggers.engagementLogger.logEngagement(componentName, EngagementTelemetryConstants.OpenPlanFromPersistedRoute, {
            subRouteType: subRouteType,
        });
    }
}
