// Utilities
import { retry } from "@ms/uno-utilities/lib/local/RetryUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
export class ComponentLoader {
    /**
     * Creates an instance of ComponentLoader
     * @param loggers Loggers
     * @param onFailure Callback to be called when a component fails to load
     */
    constructor(loggers, onFailure) {
        this.loggers = loggers;
        this.onFailure = onFailure;
        this.componentCache = {};
        this.bundleFetchPromiseCache = {};
        this.instantiatePromiseCache = {};
    }
    async load(name, importCall, instantiate, retries) {
        // Return the cached component if it exists
        if (this.componentCache[name]) {
            return this.componentCache[name];
        }
        let bundle;
        // Fetch the bundle
        try {
            // Reuse the promise if it exists
            if (!this.bundleFetchPromiseCache[name]) {
                this.bundleFetchPromiseCache[name] = retry(importCall, retries);
            }
            bundle = await this.bundleFetchPromiseCache[name];
        }
        catch (e) {
            this.loggers.traceLogger.logTrace(0x1e2d38d7 /* tag_4lt9x */, TraceLevel.Error, `[ImportFailure] [Name=${name}] [Error=${ErrorUtilities.getMessage(e)}]`);
            this.onFailure?.(name);
            throw e;
        }
        finally {
            // Clear the promise cache as we no longer need to hold it
            delete this.bundleFetchPromiseCache[name];
        }
        // Instantiate the component
        try {
            // Reuse the promise if it exists
            if (!this.instantiatePromiseCache[name]) {
                this.instantiatePromiseCache[name] = instantiate(bundle);
            }
            this.componentCache[name] = await this.instantiatePromiseCache[name];
        }
        catch (e) {
            this.loggers.traceLogger.logTrace(0x1e2d3897 /* tag_4lt8x */, TraceLevel.Error, `[InstantiationFailure] [Name=${name}] [Error=${ErrorUtilities.getMessage(e)}]`);
            this.onFailure?.(name);
            throw e;
        }
        finally {
            // Clear the promise cache as we no longer need to hold it
            delete this.instantiatePromiseCache[name];
        }
        return this.componentCache[name];
    }
    isLoaded(name) {
        return !!this.componentCache[name];
    }
    setOnFailure(onFailure) {
        this.onFailure = onFailure;
    }
}
