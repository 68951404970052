// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../ActionType";
export class PushSubRouteAction extends TypedAction(ActionType.PushSubRoute) {
    loggingData() {
        return {
            subRoute: this.subRoute.join("/")
        };
    }
    constructor(subRoute, timestamp = Date.now()){
        super(), this.subRoute = subRoute, this.timestamp = timestamp;
    }
}
