// Logging
import { ResultTypeEnum } from "@ms/uno-telemetry/lib/local/events/ResultTypeEnum";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import { Async, EventGroup } from "@fluentui/utilities";
import { generateLogStringFromError } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
/**
 * Helper class for base components. Provides usage logging functionality, eventing, and memoized element refs.
 */ export class BaseComponentHelper {
    /**
     * Disposes all stored disposables and clears the data structure
     */ cleanupDisposables() {
        if (this._disposables) {
            this._disposables.forEach((disposable)=>{
                if (disposable.dispose) {
                    disposable.dispose();
                }
            });
        }
        this._disposables = null;
    }
    /**
     * Log an error
     * @param message Custom message to log
     * @param error Error object caught
     * @param extraData (optional) Any extra data relevant to this error
     */ logError(message, error, extraData) {
        this.traceLogger.logTrace(0x1e44e804 /* tag_4ro6e */ , TraceLevel.Error, `${message}: ${generateLogStringFromError(error)}`, extraData);
    }
    /**
     * Log usage/engagement data
     * @param action Name of the action taken by the user
     * @param extraData (optional) Any extra data relevant to this event
     * @param exportName Name to export via ODIN, or undefined for no export
     */ logUsage(action, extraData, exportName) {
        this.engagementLogger.logEngagement(this.getComponentName(), action, extraData, exportName);
    }
    /**
     * Start a Qos logging event
     * @param name Name of the qos event to log
     * @return Identifier string for the qos event
     */ startQos(name) {
        const qos = this.engagementLogger.startQos(this.getComponentName(), name);
        this._activeQos[qos.data.name] = qos;
        return qos.data.name;
    }
    /**
     * End a Qos logging event with success
     * @param identifier Identifier string for the qos event
     * @param extraData Optional extra info to log
     */ endQosSuccess(identifier, extraData) {
        const qos = this._activeQos[identifier];
        if (qos) {
            qos.end({
                resultType: ResultTypeEnum.Success,
                extraData: extraData || undefined
            });
            this._activeQos[identifier] = undefined;
        }
    }
    /**
     * Get an async instance
     * Lazy created on demand
     */ get async() {
        if (!this._async) {
            this._async = new Async(this);
            this.disposables.push(this._async);
        }
        return this._async;
    }
    /**
     * Get the disposables
     * If you have objects of IDisposable that you want to automatically clean up when consumer calls cleanDisposables
     * add them here
     *
     * Lazy created on demand
     */ get disposables() {
        if (!this._disposables) {
            this._disposables = [];
        }
        return this._disposables;
    }
    /**
     * Get the stored EventGroup
     * Lazy created on demand
     */ get events() {
        if (!this._events) {
            this._events = new EventGroup(this);
            this.disposables.push(this._events);
        }
        return this._events;
    }
    constructor(getComponentNameCallback, traceLogger, engagementLogger){
        this.getComponentName = getComponentNameCallback;
        this._activeQos = {};
        this.traceLogger = traceLogger;
        this.engagementLogger = engagementLogger;
    }
}
