import find from "lodash/find";
/** Extracts header - key value pairs based on header keys */ export function extractHeaders(headers) {
    const requestHeaders = {};
    for (const pair of headers.entries()){
        const headerKey = pair[0];
        const headerValue = pair[1];
        requestHeaders[headerKey] = headerValue;
    }
    return requestHeaders;
}
/** Extracts loggable header - key value pairs based on a white-list of header keys */ export function extractLoggableHeaders(headers, loggableHeaders) {
    const loggableHeadersContainer = {};
    for (const pair of headers.entries()){
        // Make the headerKey lowercase so we have consistent casing for the keys in the return record
        const headerKey = pair[0].toLowerCase();
        const headerValue = pair[1];
        if (find(loggableHeaders, (loggableHeader)=>loggableHeader.toLowerCase() === headerKey)) {
            // Some of our headers have invalid '-' chars, replace with '_'
            const updatedHeaderKey = headerKey.replace(/-/g, "_");
            loggableHeadersContainer[updatedHeaderKey] = headerValue;
        }
    }
    return loggableHeadersContainer;
}
