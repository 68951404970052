import stringsJson from "../../shell-strings.json";
import culturesJson from "../../cultures.json";
// Constants
import { defaultMomentCulture, momentCultures, rtlCultures } from "@ms/uno-constants/lib/local/CultureConstants";
// Utilities
import { retry } from "@ms/uno-utilities/lib/local/RetryUtilities";
import { setRTL } from "@fluentui/utilities";
import moment from "moment";
export let Strings = stringsJson;
/**
 * A function called at app start which sets Moment date formatting and loads translations for a given culture
 * @param culture The culture to set
 */ export const initializeCultureData = (culture)=>{
    setMomentCulture(culture);
    return loadTranslations(culture);
};
export async function loadTranslations(culture) {
    if (culture.length === 0 || culture === "en-us") {
        return;
    }
    const parsedStringCulture = parseStringCulture(culture);
    if (parsedStringCulture === culturesJson.defaultCulture) {
        return;
    }
    const cultureJson = await retry(()=>import(/* webpackChunkName: "uno-[request]" */ "../../locales/" + parsedStringCulture + "/shell-strings.json"));
    Strings = cultureJson;
}
/**
 * Sets the Moment date formatting
 * @param stringCulture The culture to set
 */ export const setMomentCulture = (stringCulture)=>{
    // Make sure we only load one of the listed moment locales
    const validatedMomentCulture = parseMomentCulture(stringCulture);
    moment.locale(validatedMomentCulture);
};
/**
 * Parse a culture and return supported Moment culture
 * @param culture The culture to set a moment to
 */ export const parseMomentCulture = (culture)=>{
    if (culture.length === 0) {
        return defaultMomentCulture;
    }
    if (momentCultures.indexOf(culture.toLowerCase()) > -1) {
        return culture.toLowerCase();
    }
    if (momentCultures.indexOf(culture.split("-", 1)[0].toLowerCase()) > -1) {
        return culture.split("-", 1)[0].toLowerCase();
    }
    return defaultMomentCulture;
};
/**
 * Parse the culture for loading string files
 * @param culture Culture to use
 */ export const parseStringCulture = (culture)=>{
    if (culture != null) {
        if (culturesJson.cultures.indexOf(culture.toLowerCase()) > -1) {
            return culture.toLowerCase();
        }
        // Retrieve ll piece of ll-cc
        // For example "es-es" => "es", "de-de" => "de"
        const llPrefix = culture.split("-", 1)[0].toLowerCase();
        if (culturesJson.cultures.indexOf(llPrefix) > -1) {
            return llPrefix;
        }
        // Handle cases where the llPrefix maps to a ll-cc string culture
        // For example "en" => "en-us", "zh" => "zh-cn", "pt" => "pt-pt"
        if (culturesJson.fallbacks[llPrefix] != null) {
            return culturesJson.fallbacks[llPrefix];
        }
    }
    return culturesJson.defaultCulture;
};
/**
 * Sets the dir attribute on the html element for rtl and ltr. If the html dir attribute is already rtl it is assumed to be correct
 * NOTE: Should only be called when Planner is the main app (e.g. not in Teams or SPO)
 * @param culture The culture to parse to determine ltr or rtl
 */ export const setHtmlDirFromCulture = (culture)=>{
    const htmlElement = document.getElementsByTagName("html")?.[0];
    let rtl = false;
    // If we're already loading in rtl let's assume that it's correct from the service
    if (htmlElement?.getAttribute("dir") === "rtl") {
        setRTL(true);
        return;
    }
    if (culture.length === 0) {
        culture = culture.toLowerCase();
        const ll = culture.split("-")[0];
        if (rtlCultures.indexOf(culture) > -1 || rtlCultures.indexOf(ll) > -1) {
            rtl = true;
        }
    }
    htmlElement?.setAttribute("dir", rtl ? "rtl" : "ltr");
    setRTL(rtl);
};
