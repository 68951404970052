import { ArgumentOutOfRangeError } from "@ms/uno-errors/lib/local/errors";
/**
 * Generates a random number of given length.
 * @param length Length of a random number to be generated
 */ export function generateNumberWithLength(length) {
    /**
     * This generates the random numbers of given length
     * - Generate the lowest number for a given length
     * - Generate the highest number for a given length
     * - Add the lowest number to the difference and multiply it by the random number
     *   Note: The Math.random() function returns a floating-point, pseudo-random number in the range [0, 1]; that is, from 0 (inclusive) up to but not including 1 (exclusive).
     * - Math.floor() will finally round a number downward to its nearest integer resulting into the given length of random number.
     */ // Length should be at least 1
    if (length < 1) {
        throw new ArgumentOutOfRangeError("length must be a value greater than 1");
    }
    // Length shouldn't go any higher than 15 to stay away from MAX_SAFE_INTEGER value
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
    if (length > 15) {
        throw new ArgumentOutOfRangeError("length must be a value less than 16");
    }
    const lowestNumber = Math.pow(10, length - 1);
    const highestNumber = Math.pow(10, length);
    return Math.floor(Math.random() * (highestNumber - lowestNumber) + lowestNumber);
}
