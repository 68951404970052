// Utilities
import { retry } from "@ms/uno-utilities/lib/local/RetryUtilities";
/**
 * Lazy loader for basic plan view instantiator
 */ export const basicPlanInstantiateLoader = ()=>retry(()=>import(/* webpackChunkName: "uno-basicplan-instantiate" */ "./BasicPlanViewInstantiator")).then((bundle)=>{
        return bundle;
    });
/**
 * Lazy loader for premium plan view renderer
 */ export const basicPlanRenderLoader = ()=>retry(()=>import(/* webpackChunkName: "uno-basicplan-render" */ "./BasicPlanViewRenderer")).then((bundle)=>{
        return bundle.basicPlanViewRenderer;
    });
