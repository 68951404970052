/** Constants for Project Subscription related information */ /** Project Trial type: P1/P3/P5; Admin/Departmental */ export var ProjectTrialType;
(function(ProjectTrialType) {
    ProjectTrialType["P1_Admin"] = "P1_Admin";
    ProjectTrialType["P1_Departmental"] = "P1_Departmental";
    ProjectTrialType["P3_Admin"] = "P3_Admin";
    ProjectTrialType["P3_Departmental"] = "P3_Departmental";
    ProjectTrialType["P5_Admin"] = "P5_Admin";
    ProjectTrialType["Unknown"] = "Unknown";
})(ProjectTrialType || (ProjectTrialType = {}));
export var ProjectSkuId;
(function(ProjectSkuId) {
    ProjectSkuId["P1_Admin"] = "beb6439c-caad-48d3-bf46-0c82871e12be";
    ProjectSkuId["P1_Departmental"] = "84cd610f-a3f8-4beb-84ab-d9d2c902c6c9";
    ProjectSkuId["P3_Admin"] = "53818b1b-4a27-454b-8896-0dba576410e6";
    ProjectSkuId["P3_Departmental"] = "46102f44-d912-47e7-b0ca-1bd7b70ada3b";
    ProjectSkuId["P5_Admin"] = "09015f9f-377f-4538-bbb5-f75ceb09358a";
})(ProjectSkuId || (ProjectSkuId = {}));
export var ProjectSubscriptionType;
(function(ProjectSubscriptionType) {
    ProjectSubscriptionType["Trial"] = "Trial";
    ProjectSubscriptionType["Paid"] = "Paid";
    ProjectSubscriptionType["Unknown"] = "Unknown";
})(ProjectSubscriptionType || (ProjectSubscriptionType = {}));
export var ProjectSubscriptionState;
(function(ProjectSubscriptionState) {
    ProjectSubscriptionState["Pending"] = "Pending";
    ProjectSubscriptionState["Active"] = "Active";
    ProjectSubscriptionState["InGracePeriod"] = "InGracePeriod";
    ProjectSubscriptionState["Disabled"] = "Disabled";
    ProjectSubscriptionState["Deprovisioned"] = "Deprovisioned";
    ProjectSubscriptionState["Unknown"] = "Unknown";
})(ProjectSubscriptionState || (ProjectSubscriptionState = {}));
export var ProjectTrialEligibilityStatus;
(function(ProjectTrialEligibilityStatus) {
    ProjectTrialEligibilityStatus["Ineligible"] = "Ineligible";
    ProjectTrialEligibilityStatus["EligibleForSelfService"] = "EligibleForSelfService";
    ProjectTrialEligibilityStatus["EligibleToRequestOnly"] = "EligibleToRequestOnly";
})(ProjectTrialEligibilityStatus || (ProjectTrialEligibilityStatus = {}));
export var ProjectTrialOperationStatus;
(function(ProjectTrialOperationStatus) {
    ProjectTrialOperationStatus["Initialized"] = "Initialized";
    ProjectTrialOperationStatus["Pending"] = "Pending";
    ProjectTrialOperationStatus["Completed"] = "Completed";
    ProjectTrialOperationStatus["Failed"] = "Failed";
    ProjectTrialOperationStatus["Unknown"] = "Unknown";
})(ProjectTrialOperationStatus || (ProjectTrialOperationStatus = {}));
export var TryProjectDialogStatus;
(function(TryProjectDialogStatus) {
    TryProjectDialogStatus["Active"] = "Active";
    TryProjectDialogStatus["Pending"] = "Pending";
    TryProjectDialogStatus["Error"] = "Error";
})(TryProjectDialogStatus || (TryProjectDialogStatus = {}));
export var ProjectTrialOperationTimerStatus;
(function(ProjectTrialOperationTimerStatus) {
    ProjectTrialOperationTimerStatus["InProgress"] = "InProgress";
    ProjectTrialOperationTimerStatus["Stopped"] = "Stopped";
})(ProjectTrialOperationTimerStatus || (ProjectTrialOperationTimerStatus = {}));
export var ProjectSubscriptionCacheInvalidationScenarios;
(function(ProjectSubscriptionCacheInvalidationScenarios) {
    ProjectSubscriptionCacheInvalidationScenarios["SelfServiceSession"] = "SelfServiceSession";
    ProjectSubscriptionCacheInvalidationScenarios["CacheExpired"] = "CacheExpired";
    ProjectSubscriptionCacheInvalidationScenarios["AcquireTrial"] = "AcquireTrial";
    ProjectSubscriptionCacheInvalidationScenarios["GetProjectLinkClicked"] = "GetProjectLinkClicked";
    ProjectSubscriptionCacheInvalidationScenarios["RequestLicenseClicked"] = "RequestLicenseClicked";
})(ProjectSubscriptionCacheInvalidationScenarios || (ProjectSubscriptionCacheInvalidationScenarios = {}));
export var ProjectPlan;
(function(ProjectPlan) {
    ProjectPlan["ProjectPlan1"] = "Planner Plan 1";
    ProjectPlan["ProjectPlan3"] = "Planner and Project Plan 3";
    ProjectPlan["ProjectPlan5"] = "Planner and Project Plan 5";
})(ProjectPlan || (ProjectPlan = {}));
/** Default length in days for project trial */ export const DefaultTrialLength = 30;
/** Terms Of Service link */ export const TermsOfServiceUrl = "https://go.microsoft.com/fwlink/?linkid=2204238";
/** Project License comparison link */ export const ProjectLicenseComparisonUrl = "https://go.microsoft.com/fwlink/?linkid=2261043";
/** Basic and Premium Plan comparison link */ export const BasicPremiumPlanComparisonUrl = "https://support.microsoft.com/en-us/office/comparing-basic-vs-premium-plans-5e351170-4ed5-43dc-bf30-d6762f5a6968";
/** the local storage key for subscription status */ export const ProjectSubscriptionStatusLocalStorageKey = "SubscriptionStatus";
/** the local storage key for converted premium plan location*/ export const ConvertedPremiumPlanLocationsLocalStorageKey = "ConvertedPremiumPlanLocations";
/** expiry time for subscription status stored in local storage. unit is milliseconds */ export const ProjectSubscriptionStatusLocalStorageExpiryTime = 3600000;
/** expiry time for subscription status stored with paid license in local storage. unit is milliseconds */ export const ProjectSubscriptionStatusLocalStorageExpiryTimeForPaidLicense = 86400000;
export var ProjectUpsellExperienceMode;
(function(ProjectUpsellExperienceMode) {
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["Disabled"] = 0] = "Disabled";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["OfficeSubscriptionNeeded"] = 1] = "OfficeSubscriptionNeeded";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["OfferTrial_P1"] = 2] = "OfferTrial_P1";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["OfferTrial_P3"] = 3] = "OfferTrial_P3";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["RequestSubscription_P1"] = 4] = "RequestSubscription_P1";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["RequestSubscription_P3"] = 5] = "RequestSubscription_P3";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["ProvisioningTrial"] = 6] = "ProvisioningTrial";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["ProvisioningFailed"] = 7] = "ProvisioningFailed";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["TrialConversion"] = 8] = "TrialConversion";
    ProjectUpsellExperienceMode[ProjectUpsellExperienceMode["TrialExpired"] = 9] = "TrialExpired";
})(ProjectUpsellExperienceMode || (ProjectUpsellExperienceMode = {}));
export var TryPremiumEntryPoint;
(function(TryPremiumEntryPoint) {
    /** Create new blank premium plan */ TryPremiumEntryPoint["CreateNewBlank"] = "CreateNewBlank";
    /** Create new premium plan with template */ TryPremiumEntryPoint["CreateNewTemplate"] = "CreateNewTemplate";
    /** Try premium button in view header */ TryPremiumEntryPoint["Header"] = "Header";
    /** Try premium button in the premium plan view header */ TryPremiumEntryPoint["PremiumPlanHeader"] = "PremiumPlanHeader";
    /** Try premium timeline view hook in header overflow menu */ TryPremiumEntryPoint["PremiumPlanOverflowTimeline"] = "PremiumPlanOverflowTimeline";
    /** Try premium people view hook in header overflow menu */ TryPremiumEntryPoint["PremiumPlanOverflowPeople"] = "PremiumPlanOverflowPeople";
    /** Try premium goals view hook in header overflow menu */ TryPremiumEntryPoint["PremiumPlanOverflowGoals"] = "PremiumPlanOverflowGoals";
    /** Try premium assignment view hook in header overflow menu */ TryPremiumEntryPoint["PremiumPlanOverflowAssignments"] = "PremiumPlanOverflowAssignments";
    /** Try premium entry point in basic plan */ TryPremiumEntryPoint["BasicPlanConvertAndStartTrial"] = "BasicPlanConvertAndStartTrial";
    /** Try premium entry point in suite nav */ TryPremiumEntryPoint["SuiteNav"] = "SuiteNav";
    /** Iris campaign callout */ TryPremiumEntryPoint["CampaignCallout"] = "CampaignCallout";
})(TryPremiumEntryPoint || (TryPremiumEntryPoint = {}));
export var CopilotUpsellEntryPoint;
(function(CopilotUpsellEntryPoint) {
    /** Basic plan */ CopilotUpsellEntryPoint["BasicPlan"] = "BasicPlan";
    /** Premium plan */ CopilotUpsellEntryPoint["PremiumPlan"] = "PremiumPlan";
})(CopilotUpsellEntryPoint || (CopilotUpsellEntryPoint = {}));
export var FakeProjectSubscriptionStatus;
(function(FakeProjectSubscriptionStatus) {
    /** M365 license, eligible for P1 self-service trial */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["M365_EligibleForSelfService_P1"] = 0] = "M365_EligibleForSelfService_P1";
    /** M365 license, eligible for P3 self-service trial */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["M365_EligibleForSelfService_P3"] = 1] = "M365_EligibleForSelfService_P3";
    /** No trial, eligible for request P1 license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["M365_EligibleForRequestOnly_P1"] = 2] = "M365_EligibleForRequestOnly_P1";
    /** No trial, eligible for request P3 license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["M365_EligibleForRequestOnly_P3"] = 3] = "M365_EligibleForRequestOnly_P3";
    /** M365 license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["P1_Paid"] = 4] = "P1_Paid";
    /** P1 active trial */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["P1Trial_Active"] = 5] = "P1Trial_Active";
    /** P1 in-grace trial */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["P1Trial_InGrace"] = 6] = "P1Trial_InGrace";
    /** P1 Disabled trial */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["P1Trial_Disabled"] = 7] = "P1Trial_Disabled";
    /** P1 Deprovisioned trial */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["P1Trial_Deprovisioned"] = 8] = "P1Trial_Deprovisioned";
    /** P3 active trial */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["P3Trial_Active"] = 9] = "P3Trial_Active";
    /** M365 license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["P3_Paid"] = 10] = "P3_Paid";
    /** Stacked Subscriptions - P3 active trial & P1 paid license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["StackedSubscriptions_P3Active_P1Paid"] = 11] = "StackedSubscriptions_P3Active_P1Paid";
    /** Stacked Subscriptions - P3 disabled trial  & P1 paid license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["StackedSubscriptions_P3Disabled_P1Paid"] = 12] = "StackedSubscriptions_P3Disabled_P1Paid";
    /** Stacked Subscriptions - P3 deprovisioned trial  & P1 paid license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["StackedSubscriptions_P3Deprovisioned_P1Paid"] = 13] = "StackedSubscriptions_P3Deprovisioned_P1Paid";
    /** No M365 license */ FakeProjectSubscriptionStatus[FakeProjectSubscriptionStatus["No_M365"] = 14] = "No_M365";
})(FakeProjectSubscriptionStatus || (FakeProjectSubscriptionStatus = {}));
/**
 * Element target ids for showing trial notification callout
 */ export const CreatePremiumTileId = "CreatePremiumTile";
export const UseTemplateButtonId = "UseTemplateButton";
// the id of the trial button in Uno headers
export const TrialButtonIdInUno = "trialButton";
export var PlanConversionStatus;
(function(PlanConversionStatus) {
    PlanConversionStatus["None"] = "None";
    PlanConversionStatus["Started"] = "Started";
    PlanConversionStatus["PlanArchived"] = "PlanArchived";
    PlanConversionStatus["ProjectCreatedInCds"] = "ProjectCreatedInCds";
    PlanConversionStatus["ReadyToPublish"] = "ReadyToPublish";
    PlanConversionStatus["Succeeded"] = "Succeeded";
    PlanConversionStatus["Failed"] = "Failed";
})(PlanConversionStatus || (PlanConversionStatus = {}));
export var ConvertPlanMessage;
(function(ConvertPlanMessage) {
    ConvertPlanMessage["None"] = "None";
    ConvertPlanMessage["PlannerTaskStartDateChanged"] = "PlannerTaskStartDateChanged";
    ConvertPlanMessage["PlannerTaskEndDateChanged"] = "PlannerTaskEndDateChanged";
    ConvertPlanMessage["PlannerTitleTrimmed"] = "PlannerTitleTrimmed";
    ConvertPlanMessage["TaskCountOutOfRange"] = "TaskCountOutOfRange";
    ConvertPlanMessage["ProjectStartDateOutOfRange"] = "ProjectStartDateOutOfRange";
    ConvertPlanMessage["ProjectDurationOutOfRange"] = "ProjectDurationOutOfRange";
    ConvertPlanMessage["TaskDurationOutOfRange"] = "TaskDurationOutOfRange";
    ConvertPlanMessage["TaskStartDateOutOfRange"] = "TaskStartDateOutOfRange";
    ConvertPlanMessage["TaskEndDateOutOfRange"] = "TaskEndDateOutOfRange";
    ConvertPlanMessage["ResourceCountOutOfRange"] = "ResourceCountOutOfRange";
    ConvertPlanMessage["PlanContainerIsNotGroup"] = "PlanContainerIsNotGroup";
    ConvertPlanMessage["UnexpectedAttachmentType"] = "UnexpectedAttachmentType";
    ConvertPlanMessage["HasRecurrencePlannerTask"] = "HasRecurrencePlannerTask";
    ConvertPlanMessage["TaskHasCompletionRequirements"] = "TaskHasCompletionRequirements";
    ConvertPlanMessage["TaskHasCreationSourceSet"] = "TaskHasCreationSourceSet";
    ConvertPlanMessage["PlanHasCreationSourceSet"] = "PlanHasCreationSourceSet";
    ConvertPlanMessage["BucketHasCreationSourceSet"] = "BucketHasCreationSourceSet";
    ConvertPlanMessage["MaximumContextOnPlanLimitReached"] = "MaximumContextOnPlanLimitReached";
    ConvertPlanMessage["TaskDurationLimitHitDueToCompletedTask"] = "TaskDurationLimitHitDueToCompletedTask";
    ConvertPlanMessage["ProjectDurationLimitHitDueToCompletedTask"] = "ProjectDurationLimitHitDueToCompletedTask";
    ConvertPlanMessage["AssignmentError"] = "AssignmentError";
    ConvertPlanMessage["ShareProjectFailed"] = "ShareProjectFailed";
})(ConvertPlanMessage || (ConvertPlanMessage = {}));
export var UpsellTrialButtonDisplayMode;
(function(UpsellTrialButtonDisplayMode) {
    UpsellTrialButtonDisplayMode["Hidden"] = "Hidden";
    UpsellTrialButtonDisplayMode["OfferTrial"] = "OfferTrial";
    UpsellTrialButtonDisplayMode["RequestSubscription"] = "RequestSubscription";
    UpsellTrialButtonDisplayMode["ActiveTrial"] = "ActiveTrial";
    UpsellTrialButtonDisplayMode["InactiveTrial"] = "InactiveTrial";
})(UpsellTrialButtonDisplayMode || (UpsellTrialButtonDisplayMode = {}));
export var UpsellLicenseRequestTheme;
(function(UpsellLicenseRequestTheme) {
    UpsellLicenseRequestTheme["TeamsLight"] = "TeamsLight";
    UpsellLicenseRequestTheme["TeamsDark"] = "TeamsDark";
    UpsellLicenseRequestTheme["M365Light"] = "M365Light";
    UpsellLicenseRequestTheme["M365Dark"] = "M365Dark";
})(UpsellLicenseRequestTheme || (UpsellLicenseRequestTheme = {}));
