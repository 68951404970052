// Constants
import { HoverShadow, ScrollBarWidth, ScrollBarHeight, ScrollBarBorderRadius, ButtonShadow, ButtonHeight } from "../constants/StyleConstants";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { HighContrastGrayText, HighContrastHighlight, HighContrastWindowText } from "@ms/uno-constants/lib/local/HighContrastModeConstants";
// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { highContrast, highContrastBackground, highContrastBorders, highContrastColor, highContrastHighlightStyle, highContrastPrimaryButton } from "./HighContrast.styles";
// Utilities
import { FontSizes, FontWeights, NeutralColors } from "@fluentui/react";
import { memoizeFunction, getPlaceholderStyles, concatStyleSets } from "@fluentui/react";
// Mixin constants
export const buttonHorizontalPadding = "24px";
// Mixin for parents.
// $direction - row | row-reverse | column | column-reverse
// $wrap - nowrap | wrap | wrap-reverse
export const flexParent = memoizeFunction((direction = "row", wrap = "nowrap")=>{
    return {
        display: "flex",
        "-webkit-flex-direction": direction,
        "-moz-flex-direction": direction,
        "-ms-flex-direction": direction,
        flexDirection: direction,
        "-webkit-flex-wrap": wrap,
        "-moz-flex-wrap": wrap,
        "-ms-flex-wrap": wrap,
        flexWrap: wrap
    };
});
// Flex - Used for flexible views only
// - Applies only to elements using .flex-parent(...)
export const flexChildSizeFlexible = memoizeFunction((width = "0px")=>{
    return {
        "-webkit-flex": `1 1 ${width}`,
        "-moz-flex": `1 1 ${width}`,
        "-ms-flex": `1 1 ${width}`,
        flex: `1 1 ${width}`,
        minHeight: 0,
        /* Chrome fix - Chrome incorrectly sets a min-height it will not go lower than */ minWidth: 0
    };
});
// Flex - Used for fixed sized elements
// - Applies only to elements using .flex-parent(...)
export const flexChildSizeFixed = memoizeFunction((width = "auto")=>{
    return {
        "-webkit-flex": `0 0 ${width}`,
        "-moz-flex": `0 0 ${width}`,
        "-ms-flex": `0 0 ${width}`,
        flex: `0 0 ${width}`,
        minHeight: 0,
        /* Chrome fix - Chrome incorrectly sets a min-height it will not go lower than */ minWidth: 0
    };
});
/**
 * For centering all children in an element.
 * Requires calling flex-parent before use.
 */ export const flexParentCenterAll = {
    justifyContent: "center",
    alignItems: "center"
};
/**
 * For centering all children along cross axis in an element.
 * Requires calling flex-parent before use.
 */ export const flexParentCenterCrossAxis = {
    alignItems: "center"
};
/**
 * Truncation for text that commonly overflows.
 * @param overflow clip | ellipsis | <string>
 */ export const textClip = memoizeFunction((overflow = "clip")=>{
    return {
        textOverflow: overflow,
        whiteSpace: "nowrap",
        overflow: "hidden"
    };
});
/**
 * Truncation for long text that overflows
 * @param lineNumber max number of lines to display before truncation
 */ export const previewOverflowEllipsis = memoizeFunction((lineNumber = "5")=>{
    return {
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": lineNumber,
        whiteSpace: "pre-line",
        overflow: "hidden",
        textOverflow: "ellipsis"
    };
});
/**
 * Using an anchor element causes colors to change and an underlined text
 * In some cases, this isn't wanted
 */ export const styleAnchorIgnore = {
    textDecoration: "none",
    color: "inherit"
};
/**
 * Styles for Spinner
 */ export const appSpinnerStyles = {
    root: {
        marginTop: "48px"
    }
};
/** Uno Z Layers used for layering elements */ const zLayers = {
    devTools: 1000,
    copilotSidecar: 1001
};
/**
 * Get uno z index layer
 * @param layer
 */ export function getZIndex(layer) {
    return zLayers[layer] ?? 0;
}
/**
 * Styles for scrollbar
 */ export const styleScrollbar = memoizeFunction((theme)=>{
    return {
        "::-webkit-scrollbar": {
            width: ScrollBarWidth,
            height: ScrollBarHeight
        },
        "::-webkit-scrollbar-track": {
            borderRadius: ScrollBarBorderRadius
        },
        "::-webkit-scrollbar-track-piece": {
            borderRadius: ScrollBarBorderRadius
        },
        "::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.neutralTertiaryAlt,
            borderRadius: ScrollBarBorderRadius
        }
    };
});
/** Style for pointer event */ export const PointerEvent = {
    pointerEvents: "none"
};
/** z-index for grid toggle check label */ export const gridCheckLabelzIndex = {
    zIndex: 1
};
/**
 * Style for themed background hover color
 * @param theme The current theme
 */ export const backgroundColorHover = memoizeFunction((theme)=>{
    let style = backgroundColorNeutralSecondary(theme);
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            style = {
                backgroundColor: Colors.TeamsContrastHyperlinkColor,
                color: Colors.Black
            };
            break;
        case UnoTheme.TeamsLight:
            style = {
                backgroundColor: theme.palette.neutralLighter
            };
            break;
        default:
            break;
    }
    return style;
});
/**
 * Style for selected background color
 */ export const backgroundColorSelected = memoizeFunction((theme)=>{
    let backgroundColor = Colors.NeutralLighter;
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            backgroundColor = NeutralColors.gray160;
            break;
        case UnoTheme.TeamsContrast:
            backgroundColor = Colors.TeamsContrastSelectedColor;
            break;
        case UnoTheme.SharePoint:
            backgroundColor = Colors.SharepointAddTaskButtonBackgroundColor();
            break;
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.LoopLight:
            backgroundColor = Colors.NeutralLightGrey96;
            break;
        case UnoTheme.LoopDark:
            backgroundColor = Colors.NeutralDarkGrey24;
            break;
        default:
            break;
    }
    return {
        backgroundColor
    };
});
/**
 * Style for neutral primary background color
 * @param theme The current theme
 */ export const backgroundColorNeutralPrimary = memoizeFunction((theme)=>{
    let backgroundColor = theme.semanticColors.bodyBackground;
    switch(theme.name){
        case UnoTheme.Light:
            backgroundColor = Colors.White;
            break;
        case UnoTheme.Dark:
            backgroundColor = NeutralColors.gray180;
            break;
        case UnoTheme.SharePoint:
            backgroundColor = Colors.SharepointBackgroundColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            backgroundColor = Colors.White;
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            backgroundColor = Colors.Grey16;
            break;
        case UnoTheme.TeamsLight:
        case UnoTheme.TeamsDark:
        case UnoTheme.TeamsContrast:
        default:
            break;
    }
    return {
        backgroundColor
    };
});
/**
 * Style for neutral secondary background color
 * @param theme The current theme
 */ export const backgroundColorNeutralSecondary = memoizeFunction((theme)=>{
    let backgroundColor = theme.palette.neutralLight;
    switch(theme.name){
        case UnoTheme.Light:
            backgroundColor = Colors.NeutralLight;
            break;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            backgroundColor = NeutralColors.gray150;
            break;
        case UnoTheme.SharePoint:
            backgroundColor = Colors.SharepointAddTaskButtonBackgroundColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            backgroundColor = Colors.Grey96;
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            backgroundColor = Colors.DarkGrey24;
            break;
        case UnoTheme.TeamsContrast:
        case UnoTheme.TeamsLight:
        default:
            break;
    }
    return {
        backgroundColor
    };
});
/**
 * Style for neutral secondary background alt color
 */ export const backgroundColorNeutralSecondaryAlt = memoizeFunction((theme)=>{
    let backgroundColor = theme.palette.neutralLighter;
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            backgroundColor = NeutralColors.gray190;
            break;
        case UnoTheme.TeamsContrast:
            backgroundColor = Colors.Black;
            break;
        case UnoTheme.SharePoint:
            backgroundColor = Colors.SharepointAddTaskButtonBackgroundColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            backgroundColor = Colors.NeutralLightGrey92;
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            backgroundColor = Colors.NeutralDarkGrey12;
            break;
        default:
            break;
    }
    return {
        backgroundColor
    };
});
/**
 * Style for neutral primary font color
 * @param theme The current theme
 */ export const fontColorNeutralPrimary = memoizeFunction((theme)=>{
    let color = theme.palette.neutralPrimary;
    switch(theme.name){
        case UnoTheme.Light:
            color = Colors.NeutralPrimary;
            break;
        case UnoTheme.Dark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            color = Colors.White;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointTitleFontColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.Grey14;
            break;
        case UnoTheme.TeamsContrast:
        case UnoTheme.TeamsLight:
        case UnoTheme.TeamsDark:
        default:
            break;
    }
    return {
        color
    };
});
/**
 * Style for themed tertiary font color
 * @param theme The current theme
 */ export const fontColorNeutralTertiary = memoizeFunction((theme)=>{
    let color = theme.palette.neutralTertiary;
    switch(theme.name){
        case UnoTheme.Light:
            color = Colors.NeutralTertiary;
            break;
        case UnoTheme.Dark:
            color = NeutralColors.gray90;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointTitleFontColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.Grey38;
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            color = Colors.DarkGrey68;
            break;
        case UnoTheme.TeamsContrast:
        case UnoTheme.TeamsLight:
        case UnoTheme.TeamsDark:
        default:
            break;
    }
    return {
        color
    };
});
/**
 * Style for disabled font color
 */ export const fontColorDisabled = memoizeFunction((theme)=>{
    let style = fontColorNeutralTertiary(theme);
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            style = {
                color: Colors.TeamsContrastPlaceHolderOrDisabledColor
            };
            break;
        case UnoTheme.SharePoint:
            style = {
                color: Colors.SharepointPlaceholderFontColor()
            };
            break;
        case UnoTheme.Light:
        case UnoTheme.Dark:
        case UnoTheme.TeamsLight:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceLight:
        case UnoTheme.LoopWorkspaceDark:
        default:
            break;
    }
    return style;
});
/**
 * Style for themed secondary font color
 */ export const fontColorNeutralSecondary = memoizeFunction((theme)=>{
    let color = Colors.NeutralSecondary;
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
            color = Colors.NeutralSecondary;
            break;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            color = NeutralColors.gray60;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.White;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointNeutralTextFontColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.NeutralLightGrey26;
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            color = Colors.NeutralDarkGrey84;
            break;
        default:
            break;
    }
    return {
        color
    };
});
/**
 * Style for themed highlight style for partial highlight text
 */ export const partialHighlightStyle = memoizeFunction((theme)=>{
    const color = theme.name === UnoTheme.TeamsContrast ? Colors.TeamsContrastHyperlinkColor : themePrimaryColor(theme);
    return {
        color,
        textDecoration: "underline"
    };
});
/**
 * Style for dialog control
 */ export const dialogControlStyle = memoizeFunction((theme)=>{
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            return {
                backgroundColor: theme.palette.neutralLighter,
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "3px",
                borderColor: theme.palette.neutralDark
            };
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            return {
                backgroundColor: theme.palette.neutralLighterAlt,
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "3px",
                borderColor: theme.palette.neutralLighterAlt
            };
        default:
            return {
                backgroundColor: theme.palette.neutralLighter,
                borderStyle: "solid",
                borderWidth: "0px 0px 2px",
                borderRadius: "3px",
                borderColor: theme.palette.neutralLighter
            };
    }
});
/**
 * Style for dialog control secondary
 */ export const dialogControlSecondaryStyle = memoizeFunction((theme)=>{
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            return {
                backgroundColor: theme.palette.neutralLight,
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "3px",
                borderColor: theme.palette.neutralDark
            };
        default:
            return {
                backgroundColor: theme.palette.neutralLight,
                borderStyle: "solid",
                borderWidth: "0px 0px 2px",
                borderRadius: "3px",
                borderColor: theme.palette.neutralLight
            };
    }
});
/**
 * Style for dialog control placeholder
 */ export const dialogControlPlaceholderStyle = memoizeFunction((theme)=>{
    return getPlaceholderStyles([
        {
            color: theme.semanticColors.inputPlaceholderText
        },
        highContrastColor
    ]);
});
/**
 * Style for dialog control secondary placeholder
 */ export const dialogControlSecondaryPlaceholderStyle = memoizeFunction((theme)=>{
    return getPlaceholderStyles([
        {
            color: theme.palette.neutralSecondary
        },
        highContrastColor
    ]);
});
/**
 * Style for selected controls
 */ export const selectedControlStyle = memoizeFunction((theme)=>{
    let color = theme.palette.themePrimary;
    if (theme.name === UnoTheme.TeamsContrast) {
        color = Colors.TeamsContrastHyperlinkColor;
    }
    return {
        borderBottomColor: color,
        borderBottomLeftRadius: "2px",
        borderBottomRightRadius: "2px",
        borderBottomWidth: "2px"
    };
});
/**
 * Theme primary color by theme
 */ export const themePrimaryColor = memoizeFunction((theme)=>{
    let color = Colors.ThemePrimary;
    switch(theme.name){
        case UnoTheme.Light:
            color = Colors.ThemePrimary;
            break;
        case UnoTheme.Dark:
            color = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.Black;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointButtonHoverColor();
            break;
        case UnoTheme.LoopLight:
            color = Colors.PlannerPrimary;
            break;
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.LoopBrand80;
            break;
        case UnoTheme.LoopDark:
            color = Colors.PlannerDarkPrimary;
            break;
        case UnoTheme.LoopWorkspaceDark:
            color = Colors.LoopBrand100;
            break;
        default:
            break;
    }
    return color;
});
/**
 * Large font styles
 */ export const fontLarge = {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.semilight
};
export const styleButtonAsDiv = {
    display: "block",
    backgroundColor: "transparent",
    border: "none",
    alignItems: "inherit",
    textAlign: "inherit",
    color: "inherit",
    boxSizing: "inherit",
    padding: "0px",
    margin: "0px",
    font: "inherit"
};
/**
 * Style for buttons
 */ export const buttonStyle = memoizeFunction((theme)=>{
    const buttonBaseStyle = {
        backgroundColor: theme.semanticColors.buttonBackground,
        border: `1px solid ${theme.semanticColors.buttonBorder}`,
        color: theme.semanticColors.buttonText,
        boxShadow: theme.semanticColors.cardShadow,
        ":focus,:hover": {
            backgroundColor: theme.semanticColors.buttonBackgroundHovered,
            border: `1px solid ${theme.semanticColors.buttonBorder}`,
            color: theme.semanticColors.buttonTextHovered,
            boxShadow: theme.semanticColors.cardShadowHovered
        },
        ":disabled,:disabled:hover": {
            cursor: "default",
            backgroundColor: theme.semanticColors.buttonBackgroundDisabled,
            border: `1px solid ${theme.semanticColors.buttonBorderDisabled}`,
            color: theme.semanticColors.buttonTextDisabled,
            boxShadow: "none"
        }
    };
    let themeStyle;
    switch(theme.name){
        case UnoTheme.SharePoint:
            themeStyle = {
                color: Colors.SharepointTitleFontColor(),
                backgroundColor: Colors.SharepointLightBackgroundColor(),
                border: "1px solid " + Colors.SharepointLightBackgroundColor(),
                boxShadow: "0px 0px 3px " + Colors.SharepointAddTaskButtonBackgroundColor(),
                ":focus,:hover": {
                    backgroundColor: Colors.SharepointBackgroundColor(),
                    boxShadow: "0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.18), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11)"
                },
                ":disabled": {
                    backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor(),
                    color: Colors.SharepointTitleFontColor()
                }
            };
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            themeStyle = {
                border: "1px solid " + Colors.NeutralLightGrey82
            };
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            themeStyle = {
                border: "1px solid " + Colors.NeutralDarkGrey40
            };
            break;
        case UnoTheme.Light:
        case UnoTheme.Dark:
        case UnoTheme.TeamsLight:
        case UnoTheme.TeamsDark:
        case UnoTheme.TeamsContrast:
        default:
            break;
    }
    return concatStyleSets(buttonBaseStyle, themeStyle);
});
/**
 * Ghost text style
 */ export const ghostTextStyle = memoizeFunction((theme)=>{
    const style = [
        fontColorNeutralSecondary(theme),
        {
            fontSize: FontSizes.medium
        }
    ];
    return style;
});
/**
 * Style for neutral primary choice group option button
 */ export const choiceGroupOptionNeutralPrimary = memoizeFunction((checked, disabled, theme)=>{
    let buttonColor = Colors.NeutralPrimaryAlt;
    let checkedButtonColor = themePrimaryColor(theme);
    let disabledColor = Colors.NeutralTertiary;
    switch(theme.name){
        case UnoTheme.Light:
            buttonColor = Colors.NeutralPrimaryAlt;
            break;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            buttonColor = Colors.NeutralLighter;
            disabledColor = NeutralColors.gray90;
            break;
        case UnoTheme.TeamsLight:
            buttonColor = Colors.NeutralPrimaryAlt;
            break;
        case UnoTheme.TeamsContrast:
            buttonColor = Colors.White;
            checkedButtonColor = Colors.TeamsContrastSelectedColor;
            disabledColor = Colors.TeamsContrastPlaceHolderOrDisabledColor;
            break;
        case UnoTheme.SharePoint:
            buttonColor = Colors.SharepointCardSeparator();
            disabledColor = Colors.SharepointPlaceholderFontColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceLight:
        case UnoTheme.LoopWorkspaceDark:
        default:
            break;
    }
    return [
        {
            "::before, ::after": [
                {
                    borderColor: buttonColor
                },
                backgroundColorNeutralPrimary(theme),
                highContrastBackground()
            ],
            ":hover::before, hover::after": [
                {
                    borderColor: buttonColor
                },
                highContrast({
                    borderColor: HighContrastWindowText
                })
            ],
            ":hover::after": [
                {
                    backgroundColor: buttonColor
                },
                highContrast({
                    backgroundColor: HighContrastWindowText
                })
            ]
        },
        checked && [
            {
                "::before, :hover::before": [
                    {
                        borderColor: checkedButtonColor
                    },
                    highContrast({
                        borderColor: HighContrastHighlight
                    })
                ],
                "::after, :hover::after": [
                    {
                        borderColor: checkedButtonColor,
                        backgroundColor: checkedButtonColor
                    },
                    highContrast({
                        backgroundColor: HighContrastHighlight,
                        borderColor: HighContrastHighlight
                    })
                ]
            }
        ],
        disabled && {
            "::before, :hover::before, ::after, :hover::after": [
                {
                    borderColor: disabledColor
                },
                highContrast({
                    borderColor: HighContrastGrayText
                })
            ],
            "::after, :hover::after": [
                {
                    backgroundColor: disabledColor
                },
                highContrast({
                    backgroundColor: HighContrastGrayText
                })
            ]
        }
    ];
});
/**
 * Style for themed primary dialog background color
 */ export const dialogBackgroundPrimary = memoizeFunction((theme)=>{
    let backgroundColor = Colors.White;
    switch(theme.name){
        case UnoTheme.Dark:
            backgroundColor = NeutralColors.gray170;
            break;
        case UnoTheme.TeamsDark:
            backgroundColor = NeutralColors.gray170;
            break;
        case UnoTheme.TeamsContrast:
            backgroundColor = Colors.Black;
            break;
        case UnoTheme.SharePoint:
            backgroundColor = Colors.SharepointBackgroundColor();
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            backgroundColor = Colors.NeutralDarkGrey16;
            break;
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
        default:
            break;
    }
    return {
        backgroundColor
    };
});
/**
 * Color for error messages/bars
 */ export const errorColor = memoizeFunction((theme)=>{
    let color = Colors.DarkRed;
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.TeamsContrast:
            color = Colors.LightRed;
            break;
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceLight:
        case UnoTheme.LoopWorkspaceDark:
        default:
            break;
    }
    return color;
});
/**
 * Neutral secondary alt color by theme
 * Applied to: taskEditor description textarea, recurrenceDialog input
 */ export const neutralSecondaryAltColor = memoizeFunction((theme)=>{
    let color = Colors.NeutralLighter;
    switch(theme.name){
        case UnoTheme.Light:
            color = Colors.NeutralLighter;
            break;
        case UnoTheme.Dark:
            color = NeutralColors.gray190;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.NeutralLighter;
            break;
        case UnoTheme.TeamsDark:
            color = NeutralColors.gray190;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.Black;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointAddTaskButtonBackgroundColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.White;
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
        default:
            break;
    }
    return color;
});
/**
 * Style for primary border color
 */ export const themeBorderColorPrimary = memoizeFunction((theme)=>{
    let borderColor = theme.palette.themePrimary;
    switch(theme.name){
        case UnoTheme.Light:
            borderColor = Colors.ThemePrimary;
            break;
        case UnoTheme.Dark:
            borderColor = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            borderColor = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            borderColor = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            borderColor = Colors.White;
            break;
        case UnoTheme.SharePoint:
            borderColor = Colors.SharepointButtonHoverColor();
            break;
        case UnoTheme.LoopWorkspaceLight:
            borderColor = Colors.LoopLightP0;
            break;
        case UnoTheme.LoopLight:
            borderColor = Colors.PlannerPrimary;
            break;
        case UnoTheme.LoopDark:
            borderColor = Colors.PlannerDarkPrimary;
            break;
        case UnoTheme.LoopWorkspaceDark:
        default:
            break;
    }
    return {
        borderColor
    };
});
/**
 * Neutral lighter border color by theme
 * Only handling TeamsContrast right now, if needed for other themes, add them here
 */ export const borderColorNeutralLighter = memoizeFunction((theme)=>{
    let borderColor = theme.palette.neutralLighter;
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            borderColor = Colors.White;
            break;
        // Add cases if other themes need to be handled
        default:
            break;
    }
    return {
        borderColor
    };
});
/**
 * Style for themed button
 */ export const themedButtonStyle = memoizeFunction((theme)=>{
    const buttonBaseStyle = {
        backgroundColor: theme.semanticColors.primaryButtonBackground,
        borderColor: theme.semanticColors.primaryButtonBorder,
        color: theme.semanticColors.primaryButtonText,
        boxShadow: theme.semanticColors.cardShadow,
        ":focus,:hover": {
            backgroundColor: theme.semanticColors.primaryButtonBackgroundHovered,
            borderColor: theme.semanticColors.primaryButtonBorder,
            color: theme.semanticColors.primaryButtonTextHovered,
            boxShadow: theme.semanticColors.cardShadowHovered
        },
        ":disabled,:disabled:hover": {
            cursor: "default",
            backgroundColor: theme.semanticColors.primaryButtonBackgroundDisabled,
            borderColor: theme.semanticColors.buttonBorderDisabled,
            color: theme.semanticColors.buttonTextDisabled
        }
    };
    let themeStyle;
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.Dark:
            themeStyle = [
                highContrastPrimaryButton(),
                {
                    ":focus,:hover": highContrastHighlightStyle()
                }
            ];
            break;
        case UnoTheme.TeamsContrast:
            themeStyle = {
                backgroundColor: Colors.Black,
                borderColor: Colors.White,
                ":focus,:hover": {
                    color: Colors.Black,
                    borderColor: Colors.TeamsContrastHyperlinkColor,
                    backgroundColor: Colors.TeamsContrastHyperlinkColor
                },
                ":disabled,:disabled:hover": {
                    backgroundColor: Colors.TeamsContrastPlaceHolderOrDisabledColor,
                    borderColor: Colors.TeamsContrastPlaceHolderOrDisabledColor
                }
            };
            break;
        case UnoTheme.SharePoint:
            themeStyle = {
                backgroundColor: Colors.SharepointCompleteColor(),
                ":disabled,:disabled:hover": {
                    backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor(),
                    color: Colors.SharepointTitleFontColor()
                }
            };
            break;
        case UnoTheme.LoopLight:
            themeStyle = {
                backgroundColor: Colors.PlannerSecondary,
                ":hover": {
                    backgroundColor: Colors.PlannerDarkAlt
                },
                ":disabled,:disabled:hover": {
                    backgroundColor: Colors.NeutralLightGrey94,
                    color: Colors.NeutralLightGrey74
                }
            };
            break;
        case UnoTheme.LoopDark:
            themeStyle = {
                backgroundColor: Colors.PlannerDarkPrimary,
                ":hover": {
                    backgroundColor: Colors.PlannerDarkSecondary
                },
                ":disabled,:disabled:hover": {
                    backgroundColor: Colors.NeutralLightGrey94,
                    color: Colors.NeutralLightGrey74
                }
            };
            break;
        case UnoTheme.LoopWorkspaceLight:
            themeStyle = {
                backgroundColor: Colors.LoopBrand80,
                ":hover": {
                    backgroundColor: Colors.LoopBrand70
                },
                ":disabled,:disabled:hover": {
                    backgroundColor: Colors.NeutralLightGrey94,
                    color: Colors.NeutralLightGrey74
                }
            };
            break;
        case UnoTheme.LoopWorkspaceDark:
            themeStyle = {
                backgroundColor: Colors.LoopBrand70,
                ":hover": {
                    backgroundColor: Colors.LoopBrand80
                },
                ":disabled,:disabled:hover": {
                    backgroundColor: Colors.NeutralDarkGrey8,
                    color: Colors.NeutralDarkGrey36
                }
            };
            break;
        case UnoTheme.TeamsLight:
        case UnoTheme.TeamsDark:
        default:
            break;
    }
    const highContrastBorderStyle = highContrastBorders();
    return concatStyleSets(buttonBaseStyle, themeStyle, highContrastBorderStyle);
});
/**
 * To hide content while still making it readable by screen readers (Accessibility)
 */ export const screenReaderOnly = {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: "0",
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0,0,0,0)",
    border: "0"
};
/**
 * Style for fluent ui buttons
 */ export const buttonFluentUIStyle = memoizeFunction((theme)=>{
    const buttonRootBaseStyle = [
        fontColorNeutralPrimary(theme),
        backgroundColorNeutralPrimary(theme)
    ];
    const buttonBaseStyle = {
        root: buttonRootBaseStyle,
        rootFocused: buttonRootBaseStyle,
        rootHovered: buttonRootBaseStyle,
        rootPressed: buttonRootBaseStyle,
        rootDisabled: {
            cursor: "default"
        }
    };
    let themeStyle = {};
    let rootActiveStyles = {};
    switch(theme.name){
        case UnoTheme.Light:
            rootActiveStyles = {
                backgroundColor: Colors.NeutralLighter,
                color: Colors.NeutralPrimary,
                border: "1px solid " + Colors.NeutralLight
            };
            themeStyle = {
                root: {
                    border: "1px solid " + Colors.NeutralLight,
                    boxShadow: ButtonShadow
                },
                rootHovered: rootActiveStyles,
                rootFocused: rootActiveStyles,
                rootPressed: rootActiveStyles,
                rootDisabled: {
                    backgroundColor: Colors.NeutralLight,
                    color: Colors.NeutralPrimary
                }
            };
            break;
        case UnoTheme.Dark:
            rootActiveStyles = {
                backgroundColor: NeutralColors.gray150,
                color: Colors.White,
                border: "1px solid " + NeutralColors.gray120
            };
            themeStyle = {
                root: {
                    border: "1px solid " + NeutralColors.gray120
                },
                rootHovered: rootActiveStyles,
                rootFocused: rootActiveStyles,
                rootPressed: rootActiveStyles,
                rootDisabled: {
                    backgroundColor: NeutralColors.gray180,
                    color: Colors.White
                }
            };
            break;
        case UnoTheme.TeamsLight:
            rootActiveStyles = {
                backgroundColor: Colors.NeutralLighter,
                color: Colors.NeutralPrimary,
                border: "1px solid " + Colors.NeutralLight
            };
            themeStyle = {
                root: {
                    border: "1px solid " + Colors.NeutralLight,
                    borderWidth: "1px",
                    boxShadow: ButtonShadow
                },
                rootHovered: rootActiveStyles,
                rootFocused: rootActiveStyles,
                rootPressed: rootActiveStyles,
                rootDisabled: {
                    backgroundColor: Colors.NeutralLight,
                    color: Colors.NeutralPrimary
                }
            };
            break;
        case UnoTheme.TeamsDark:
            rootActiveStyles = {
                backgroundColor: NeutralColors.gray150,
                color: Colors.White,
                border: "1px solid " + NeutralColors.gray120
            };
            themeStyle = {
                root: {
                    border: "1px solid " + NeutralColors.gray120
                },
                rootHovered: rootActiveStyles,
                rootFocused: rootActiveStyles,
                rootPressed: rootActiveStyles,
                rootDisabled: {
                    backgroundColor: NeutralColors.gray180,
                    color: Colors.White
                }
            };
            break;
        case UnoTheme.TeamsContrast:
            rootActiveStyles = {
                color: Colors.Black,
                border: "1px solid " + Colors.TeamsContrastHyperlinkColor,
                backgroundColor: Colors.TeamsContrastHyperlinkColor
            };
            themeStyle = {
                root: {
                    border: "1px solid " + Colors.White
                },
                rootHovered: rootActiveStyles,
                rootFocused: rootActiveStyles,
                rootPressed: rootActiveStyles,
                rootDisabled: {
                    backgroundColor: Colors.Black,
                    border: "1px solid " + Colors.TeamsContrastPlaceHolderOrDisabledColor,
                    color: Colors.TeamsContrastPlaceHolderOrDisabledColor
                }
            };
            break;
        case UnoTheme.SharePoint:
            rootActiveStyles = {
                color: Colors.SharepointTitleFontColor(),
                backgroundColor: Colors.SharepointBackgroundColor(),
                boxShadow: "0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.18), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11)",
                border: "1px solid " + Colors.SharepointLightBackgroundColor()
            };
            themeStyle = {
                root: {
                    color: Colors.SharepointTitleFontColor(),
                    backgroundColor: Colors.SharepointLightBackgroundColor(),
                    border: "1px solid " + Colors.SharepointLightBackgroundColor(),
                    boxShadow: "0px 0px 3px " + Colors.SharepointAddTaskButtonBackgroundColor()
                },
                rootHovered: rootActiveStyles,
                rootFocused: rootActiveStyles,
                rootPressed: rootActiveStyles,
                rootDisabled: {
                    backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor(),
                    color: Colors.SharepointTitleFontColor()
                }
            };
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            themeStyle = {
                root: {
                    borderRadius: "8px",
                    border: "1px solid " + Colors.NeutralLightGrey82
                }
            };
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            themeStyle = {
                root: {
                    borderRadius: "8px",
                    border: "1px solid " + Colors.NeutralDarkGrey40
                }
            };
            break;
        default:
            break;
    }
    return concatStyleSets(buttonBaseStyle, themeStyle);
});
/**
 * Medium font styles
 */ export const fontMedium = {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular
};
/**
 * Small font styles
 */ export const fontSmall = {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular
};
/**
 * Style for primary themed font color
 */ export const fontColorThemePrimary = memoizeFunction((theme)=>{
    let color = Colors.ThemePrimary;
    switch(theme.name){
        case UnoTheme.Light:
            color = Colors.ThemePrimary;
            break;
        case UnoTheme.Dark:
            color = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.White;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointButtonHoverColor();
            break;
        case UnoTheme.LoopLight:
            color = Colors.PlannerPrimary;
            break;
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.LoopBrand80;
            break;
        case UnoTheme.LoopDark:
            color = Colors.PlannerDarkDarker;
            break;
        case UnoTheme.LoopWorkspaceDark:
            color = Colors.LoopBrand100;
            break;
        default:
            break;
    }
    return {
        color
    };
});
/**
 * Style for checkmark icon color
 * Applied to: selected item in dropdown/filter
 */ export const checkmarkIconColor = memoizeFunction((theme)=>{
    let color = Colors.NeutralPrimary;
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.TeamsContrast:
            color = Colors.White;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointTitleFontColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.NeutralLightGrey26;
            break;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            color = Colors.NeutralDarkGrey84;
            break;
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        default:
            break;
    }
    return {
        color
    };
});
/**
 * Label style
 */ export const labelStyle = memoizeFunction((theme)=>{
    let fontWeight = FontWeights.semibold;
    switch(theme.name){
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
            fontWeight = FontWeights.regular;
            break;
        default:
            break;
    }
    const style = [
        fontColorNeutralSecondary(theme),
        {
            fontSize: FontSizes.medium,
            fontWeight: fontWeight
        }
    ];
    return style;
});
/**
 * Style for active button color
 */ export const activeButtonColor = memoizeFunction((theme)=>{
    let color = Colors.Black;
    let borderColor = Colors.ThemePrimary;
    switch(theme?.name){
        case UnoTheme.Dark:
            color = Colors.White;
            borderColor = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.Black;
            borderColor = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.White;
            borderColor = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.TeamsContrastSelectedColor;
            borderColor = Colors.TeamsContrastSelectedColor;
            break;
        default:
            break;
    }
    return {
        color: color,
        borderBottomColor: borderColor
    };
});
/**
 * Style for neutral primary link color
 */ export const linkColorNeutralPrimary = memoizeFunction((theme)=>{
    let color = Colors.ThemePrimary;
    switch(theme?.name){
        case UnoTheme.Light:
        case UnoTheme.SharePoint:
            color = Colors.ThemePrimary;
            break;
        case UnoTheme.Dark:
            color = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.TeamsContrastHyperlinkColor;
            break;
        default:
            break;
    }
    return {
        color
    };
});
/**
 * Link secondary color by theme
 */ export const themeLinkColorSecondary = memoizeFunction((theme)=>{
    let color;
    let hoverColor;
    switch(theme.name){
        case UnoTheme.Light:
            color = Colors.ThemeSecondary;
            hoverColor = Colors.ThemePrimary;
            break;
        case UnoTheme.Dark:
            color = Colors.ThemeSecondaryAlt;
            hoverColor = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.TeamsButtonHoverColor;
            hoverColor = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.TeamsDarkPurple;
            hoverColor = Colors.TeamsLightPivotDownColor;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.TeamsContrastHyperlinkColor;
            break;
        case UnoTheme.SharePoint:
            color = Colors.SharepointButtonHoverColor();
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceLight:
        case UnoTheme.LoopWorkspaceDark:
        default:
            break;
    }
    return hoverColor ? {
        color: color,
        ":hover": {
            color: hoverColor
        }
    } : {
        color: color
    };
});
/**
 * Style for toggle background color
 */ export const toggleBackgroundColor = memoizeFunction((theme)=>{
    let color = Colors.ThemePrimary;
    switch(theme?.name){
        case UnoTheme.Dark:
            color = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.TeamsContrastSelectedColor;
            break;
        default:
            break;
    }
    return {
        backgroundColor: color
    };
});
/**
 * Style for toggle border color
 */ export const toggleBorderColor = memoizeFunction((theme)=>{
    let color = Colors.ThemePrimary;
    switch(theme?.name){
        case UnoTheme.Dark:
            color = Colors.ThemeTertiary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.TeamsContrastSelectedColor;
            break;
        default:
            break;
    }
    return {
        borderColor: color
    };
});
/**
 * Style for a button which starts out disabled and is enabled upon user input.
 */ export const submitFormButton = memoizeFunction((theme)=>{
    const submitButtonStyles = {
        fontSize: FontSizes.medium,
        height: ButtonHeight,
        pointerEvents: "auto",
        padding: `0px ${buttonHorizontalPadding}`,
        cursor: "pointer"
    };
    return concatStyleSets(buttonStyle(theme), submitButtonStyles);
});
/**
 * Styles for Late color
 * @param theme Planner theme
 */ export const lateColorStyles = memoizeFunction((theme)=>{
    let color = Colors.White;
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            color = Colors.Black;
            break;
        default:
            break;
    }
    return {
        color,
        ":hover": {
            color
        }
    };
});
/**
 * Styles for Late background color
 * @param theme Planner theme
 */ export const lateBackgroundColorStyles = memoizeFunction((theme)=>{
    let backgroundColor = Colors.Late;
    let backgroundColorHover = Colors.LateHover;
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            backgroundColor = Colors.White;
            backgroundColorHover = Colors.TeamsContrastHyperlinkColor;
            break;
        default:
            break;
    }
    return {
        backgroundColor,
        ":hover": {
            backgroundColor: backgroundColorHover
        }
    };
});
/**
 * Theme based color for delink icon
 */ export const delinkIconColors = memoizeFunction((theme)=>{
    let color = Colors.DelinkIconLight;
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
            color = Colors.DelinkIconLight;
            break;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            color = Colors.DelinkIconDark;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.DelinkIconHighContrast;
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        default:
            break;
    }
    return {
        color
    };
});
/** Box without shadow */ export const nonBoxShadow = {
    boxShadow: "none",
    ":hover": {
        boxShadow: "none"
    }
};
/**
 * style for border box shadow
 */ const borderBoxShadowWithColor = memoizeFunction((borderColor)=>{
    return `0px 0px 0.1px 1px ${borderColor}, ${HoverShadow}`;
});
/**
 * Theme based card shadow with border
 * @param theme app theme
 */ export const planContentCardShadowWithBorder = memoizeFunction((theme)=>{
    let boxShadow = "none";
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
            boxShadow = borderBoxShadowWithColor(Colors.NeutralLight);
            break;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            boxShadow = borderBoxShadowWithColor(NeutralColors.gray160);
            break;
        case UnoTheme.SharePoint:
        case UnoTheme.TeamsContrast:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        default:
            boxShadow = "none";
            break;
    }
    return {
        boxShadow
    };
});
/**
 * Styles to apply on charts colors
 * @param color colors to apply on the charts
 */ export const chartsColors = memoizeFunction((color0, color1, color2, color3)=>{
    const chartColorsStyles = {
        ".charts-color-0": {
            fill: color0
        },
        ".charts-color-1": {
            fill: color1
        },
        ".charts-color-2": {
            fill: color2
        },
        ".charts-color-3": {
            fill: color3
        }
    };
    return {
        chartColorsStyles
    };
});
