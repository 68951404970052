/** Returns an anonymous class that passes in the provided ActionType to Action's constructor. Removes the need for re-declaring the ActionType in both `extends` and `super(...)`*/ export const TypedAction = (type)=>{
    class _class extends Action {
        constructor(){
            super(type);
        }
    }
    return _class;
};
/**
 * Object that encapsulates an Action in our Flux system
 * TODO: #8769408 - remove default generic value when all actions have been updated to use specific types
 */ export class Action {
    /**
     * Return any extra information to be logged. Action type is always logged. Make sure to not include any PII.
     * Return null if you do not want any extra information to be logged.
     */ loggingData() {
        return undefined;
    }
    /**
     * Overridable method to allow actions to enable/disable logging
     */ isLoggingEnabled() {
        return true;
    }
    /**
     * Overridable method to allow actions to specify an export name for ODIN. Returning undefined implies non-exportable.
     */ getExportName() {
        return undefined;
    }
    constructor(newType){
        this.type = newType;
    }
}
