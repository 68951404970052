import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for GroupsStore, for use in the registry */ export const GroupsStoreActionTypes = [
    ActionType.DeleteGroupAction,
    ActionType.DeleteGroupFailedAction,
    ActionType.DeleteGroupSucceededAction,
    ActionType.FetchGroupAction,
    ActionType.FetchGroupDetailsAction,
    ActionType.FetchGroupDetailsFailedAction,
    ActionType.FetchGroupDetailsSucceededAction,
    ActionType.FetchGroupFailedAction,
    ActionType.FetchGroupPhotosSucceededAction,
    ActionType.FetchGroupSucceededAction,
    ActionType.FetchUserJoinedGroupsSucceededAction,
    ActionType.CreateGroupAction,
    ActionType.CreateGroupFailedAction,
    ActionType.CreateGroupSucceededAction
];
