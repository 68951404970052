// Constants
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { PlanType } from "@ms/uno-constants/lib/local/AppConstants";
// Utils
import { createRouteFromDetails, generateRouteFromDeepLink, getPlanIdFromEntityId, getPlanRouteDetailsForTab, getRouteFromTabContentUrl, isV1UnoEntityId } from "@ms/uno-routing";
import { generateRouteFromLegacyDeepLink, getTaskIdFromSubEntityId, isChannelTab } from "./LegacyTeamsDeepLinkUtils";
// teams-js
import { FrameContexts } from "@microsoft/teams-js-uno";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
export var AppPageIdFlavor;
(function(AppPageIdFlavor) {
    AppPageIdFlavor["Dev"] = "teamstasksdev.personalApp.mytasks";
    AppPageIdFlavor["PPE"] = "teamstasksppe.personalApp.mytasks";
    AppPageIdFlavor["Prod"] = "mytasks";
})(AppPageIdFlavor || (AppPageIdFlavor = {}));
/**
 * This is the entityId in the URL for the message tasks view
 * ex: https://tasks.teams.microsoft.com/teamsui/messageTasks?parentMessageId=...
 */ const messageTasksEntityId = "messagetasks";
/**
 * Tries to parse the message route from an entityId (aka pageId)
 * @param entityId The entityId (aka pageId)
 */ export function tryParseMessageRoute(entityId) {
    if (entityId !== messageTasksEntityId) {
        return null;
    }
    return {
        rootUrl: "",
        subRouteType: SubRouteType.MessageTasks,
        subRoute: [],
        queryParameters: {}
    };
}
/**
 * Tries to parse the tab route from the entityId (aka pageId) and subEntityId (aka subPageId)
 * @param entityId The Teams entityId (aka pageId)
 * @param subEntityId The Teams subEntityId (aka subPageId)
 * @param frameContext The frame context used to determine the current frame context (create, remove, content, etc)
 * @param loggers Loggers to use for logging
 */ export function tryParseTabRoute(entityId, subEntityId, frameContext, loggers) {
    // Determine if this is the config / remove teams tab scenario
    if (frameContext === FrameContexts.settings) {
        return createRouteFromDetails({
            subRouteType: SubRouteType.ConfigTeamsTab
        });
    } else if (frameContext === FrameContexts.remove) {
        const routeDetails = getPlanRouteDetailsForTab(entityId, window.location.href);
        if (!routeDetails) {
            // invalid route or on a new Tab page
            return null;
        }
        // planID / planType / orgId are possibly null when it is on Tab Creation landing page
        return createRouteFromDetails({
            subRouteType: SubRouteType.RemoveTeamsTab,
            planId: routeDetails.planId,
            orgId: routeDetails.planType === PlanType.Premium ? routeDetails.orgId : null,
            planType: routeDetails.planType
        });
    }
    // If it's not a legacy or modern tab, return null
    const isUnoTab = isV1UnoEntityId(entityId);
    const isLegacyTab = isChannelTab(entityId);
    if (!isLegacyTab && !isUnoTab) {
        return null;
    }
    // First let's check if we have new UNO DeepLink Schema (e.g., Copy Link to Task from UNO ChannelTab Task)
    const parsedRoute = generateRouteFromDeepLink(subEntityId, loggers, generateRouteFromLegacyDeepLink);
    if (parsedRoute) {
        loggers?.traceLogger.logTrace(0x1e3d20c2 /* tag_4psdc */ , TraceLevel.Info, `tryParseTabRoute - Parsed route with UNO DeepLink schema`);
        return parsedRoute;
    }
    // If we're using the new Uno tab format, parse the route using content URL's planRoute parameter
    if (isUnoTab) {
        loggers?.traceLogger.logTrace(0x1e301816 /* tag_4mb6w */ , TraceLevel.Info, `tryParseTabRoute - Parsed route from contentUrl`);
        return getRouteFromTabContentUrl(window.location.href, loggers);
    }
    // Then we try legacy parsing
    const planId = getPlanIdFromEntityId(entityId);
    const taskId = getTaskIdFromSubEntityId(loggers, subEntityId);
    if (!planId) {
        loggers?.traceLogger.logTrace(0x1e3d20c1 /* tag_4psdb */ , TraceLevel.Error, `tryParseTabRoute - Unable to legacy parse planId from entityId`);
        return null;
    }
    loggers?.traceLogger.logTrace(0x1e3d20c0 /* tag_4psda */ , TraceLevel.Info, `tryParseTabRoute - Legacy parsed info from entityId/subEntityId`);
    return createRouteFromDetails({
        subRouteType: SubRouteType.BasicPlan,
        planId: planId,
        taskId: taskId || undefined
    });
}
/**
 * Tries to parse the app route from the entityId (aka pageId) and subEntityId (aka subPageId)
 * Note: It's a valid scenario for App to not have deepLink (subEntityId) and so return null route
 * @param entityId The Teams entityId (aka pageId)
 * @param subEntityId The Teams subEntityId (aka subPageId)
 * @param loggers Loggers to use for logging
 */ export function tryParseAppRoute(entityId, subEntityId, loggers) {
    if (isTeamsAppEntityId(entityId)) {
        return generateRouteFromDeepLink(subEntityId, loggers, generateRouteFromLegacyDeepLink);
    }
    return null;
}
/**
 * Returns whether or not the specified entityId (aka pageId) is our Teams App entityId
 * Note: This denotes that we're in the Teams App experience (vs Tab, Message, etc.)
 * @param entityId The Teams entityId (aka pageId)
 */ export function isTeamsAppEntityId(entityId) {
    return Object.values(AppPageIdFlavor).find((value)=>value === entityId) != null;
}
