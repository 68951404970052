// Constants
import { WebUrlSegments } from "@ms/uno-constants/lib/local/WebConstants";
// Factories
import { BaseStoreFactory } from "../../base/factories/BaseStoreFactory";
// Stores
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
/**
 * Factory class for store providers in Web app
 */
export class WebStoreFactory extends BaseStoreFactory {
    constructor() {
        super(...arguments);
        this.loadSuiteNavStore = () => this.loader.load(this.getStoreBundleKey(Store.SuiteNav), () => import(/* webpackChunkName: "uno-suitenavstore" */ "@ms/uno-stores/lib/local/stores/suiteNav/SuiteNavStore"), (bundle) => new bundle.SuiteNavStore(this.loggers));
    }
    // TODO[UnoWeb] ADO#9153353 - We only need to do this while we don't have AFD mapping
    getRouteStorePathPrefix() {
        // This is the UNO Web endpoint on MTRS
        return WebUrlSegments.WebUI;
    }
    getSuiteNavStoreProvider() {
        return this.loadSuiteNavStore;
    }
}
