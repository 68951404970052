// Interface
import { ResourceType } from "../../service/project/xrm/IXrmResource";
import { AadUserType, UserType } from "./UserType";
// Utilities
import * as StringHelper from "@fluentui/utilities";
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
import isEmpty from "lodash/isEmpty";
/**
 * IUser implementation
 */ export class User {
    get formattedDisplayName() {
        if (this.isGuest()) {
            return StringHelper.format(Strings.User_GuestUserFormat, this.displayName);
        }
        return this.displayName;
    }
    isGuest() {
        if (!isEmpty(this.userType)) {
            return this.userType === UserType.Guest;
        }
        return !isEmpty(this.userPrincipalName) && this.userPrincipalName.indexOf("#EXT#") !== -1;
    }
    /**
     * Convert from Xrm Resource type to User type
     * @param resource The resource to convert from
     */ static fromXrmResource(resource) {
        /**
         * If the user is an AAD user then their AAD ID will be their id and aadId will possibly be null or all zeros.
         * If the user's type is not set then check their aadId first otherwise use id.
         */ return new User({
            id: resource.resourceType === ResourceType.AadUser ? resource.id : resource.aadId || resource.id,
            displayName: resource.name,
            jobTitle: resource.jobTitle,
            userPrincipalName: resource.userPrincipalName || "",
            userType: resource.aadUserType === AadUserType.Guest ? UserType.Guest : UserType.Member,
            mail: resource.email || "",
            givenName: "",
            preferredLanguage: "",
            proxyAddresses: [],
            surname: "",
            accountEnabled: null,
            usageLocation: null
        });
    }
    /**
     * Build from a Graph user resource entity as initialization data.
     * @param userResource Graph user resource entity to use for initializing the user builder
     */ static fromGraphUserResource(userResource) {
        return new User({
            displayName: userResource.displayName || userResource.userPrincipalName || "",
            givenName: userResource.givenName || "Guest",
            id: userResource.id,
            mail: userResource.mail,
            proxyAddresses: userResource.proxyAddresses || [],
            surname: userResource.surname,
            userPrincipalName: userResource.userPrincipalName,
            jobTitle: userResource.jobTitle,
            userType: User.userTypeStringToEnum(userResource.userType),
            preferredLanguage: userResource.preferredLanguage || null,
            accountEnabled: null,
            usageLocation: userResource.usageLocation || null
        });
    }
    /**
     * Build from a LEGACY web user resource entity as initialization data.
     * @param userResource User resource entity to use for initializing the user builder
     */ static fromLegacyWebUserResource(userResource) {
        return new User({
            displayName: userResource.DisplayName || userResource.UserPrincipalName || "",
            givenName: userResource.GivenName || "Guest",
            id: userResource.Id,
            mail: userResource.Mail || null,
            proxyAddresses: userResource.ProxyAddresses || [],
            surname: userResource.Surname || null,
            userPrincipalName: userResource.UserPrincipalName,
            jobTitle: userResource.JobTitle || null,
            userType: User.userTypeStringToEnum(userResource.UserType),
            preferredLanguage: userResource.PreferredLanguage || null,
            accountEnabled: null,
            usageLocation: null
        });
    }
    /**
     * Build from a user resource entity as initialization data.
     * @param userResource SP user resource entity to use for initializing the user builder
     */ static fromSPUserResource(userResource) {
        return new User({
            displayName: userResource.displayName || userResource.principalName || "",
            givenName: userResource.displayName || "Guest",
            id: userResource.id,
            mail: userResource.mail ?? null,
            proxyAddresses: [],
            userPrincipalName: userResource.principalName ?? "upn",
            jobTitle: null,
            preferredLanguage: "en-US",
            surname: "",
            userType: UserType.Member,
            accountEnabled: null,
            usageLocation: null
        });
    }
    /**
     * Build from UserContext as initialization data.
     * @param context UserContext to use for initializing the user builder
     */ static fromUserContext(context) {
        return new User({
            displayName: context.displayName || "",
            givenName: context.displayName || "",
            id: context.userId || "",
            jobTitle: null,
            mail: null,
            preferredLanguage: null,
            proxyAddresses: [],
            surname: null,
            userPrincipalName: context.userUpn || "",
            userType: null,
            accountEnabled: null,
            usageLocation: null
        });
    }
    /**
     * Build from a chat member resource entity as initialization data.
     * @param chatMemberResource Chat member resource entity to use for initializing the user builder
     */ static fromChatMemberResource(chatMemberResource) {
        return new User({
            displayName: chatMemberResource.displayName || "",
            givenName: chatMemberResource.givenName || "",
            id: chatMemberResource.objectId,
            jobTitle: chatMemberResource.jobTitle || null,
            mail: null,
            preferredLanguage: null,
            proxyAddresses: [],
            surname: chatMemberResource.surname || "",
            userPrincipalName: chatMemberResource.userPrincipalName || "",
            userType: null,
            accountEnabled: null,
            usageLocation: null
        });
    }
    /**
     * Convert the user type from string to enum
     * @param stringType The string user type to convert to enum
     */ static userTypeStringToEnum(stringType) {
        let enumType = null;
        if (stringType === "Guest") {
            enumType = UserType.Guest;
        } else if (stringType === "Member") {
            enumType = UserType.Member;
        }
        return enumType;
    }
    constructor(propertyBag){
        this.displayName = propertyBag.displayName;
        this.givenName = propertyBag.givenName;
        this.id = propertyBag.id;
        this.jobTitle = propertyBag.jobTitle;
        this.mail = propertyBag.mail;
        this.proxyAddresses = propertyBag.proxyAddresses;
        this.surname = propertyBag.surname;
        this.userPrincipalName = propertyBag.userPrincipalName;
        this.userType = propertyBag.userType;
        this.preferredLanguage = propertyBag.preferredLanguage;
        this.accountEnabled = propertyBag.accountEnabled;
        this.usageLocation = propertyBag.usageLocation;
    }
}
