// Models
import { ArgumentError, ArgumentNullError } from "@ms/uno-errors/lib/local/errors";
import { ExistenceStatus } from "./EntityStatus";
// Utilities
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import remove from "lodash/remove";
import merge from "lodash/merge";
/**
 * Implmentation of entity level logic for a crud supporting entity
 */ export class Entity {
    get value() {
        if (this.deltaCount === 0) {
            return this.baseline;
        }
        return this.applyDeltas();
    }
    get baseline() {
        return this._baseline;
    }
    get status() {
        return this._status;
    }
    get deltaCount() {
        return this.deltas.length;
    }
    getDelta(deltaKey) {
        return find(this.deltas, (delta)=>delta.key === deltaKey);
    }
    pushDelta(changes, deltaKey) {
        if (isEmpty(deltaKey)) {
            throw new ArgumentNullError("deltaKey", "DeltaKey cannot be null or empty");
        }
        if (this.getDelta(deltaKey)) {
            // Delta already exits -> No-op
            return;
        }
        this.deltas.push({
            key: deltaKey,
            changes: changes,
            committed: false
        });
    }
    revertDelta(deltaKey) {
        this.revertDeltaInternal(deltaKey);
    }
    commitDelta(deltaKey, additionalChanges) {
        const delta = this.getDelta(deltaKey);
        if (!delta) {
            throw new ArgumentError("deltaKey", `Could not find a delta with key ${deltaKey}`);
        }
        // Apply the delta and remove from the list
        delta.committed = true;
        // Merge additional changes into the delta to preserve order
        if (additionalChanges) {
            merge(delta.changes, additionalChanges);
        }
        this.performCompaction();
    }
    updateBaseline(changes) {
        this._baseline = this._baseline.applyDiffs(changes);
    }
    softDelete() {
        if (this._status !== ExistenceStatus.SoftDeleted) {
            this._status = ExistenceStatus.SoftDeleted;
        }
    }
    undelete() {
        if (this._status === ExistenceStatus.SoftDeleted) {
            this._status = ExistenceStatus.Created;
        }
    }
    performCompaction() {
        const commitkeys = [];
        // Start at the beginning, remove all consecutive 'committed' deltas
        for (const delta of this.deltas){
            if (delta.committed) {
                commitkeys.push(delta.key);
            } else {
                break;
            }
        }
        // For each delta key to commit, fold the associated changes into the singular patch, then remove it from the list of deltas
        const changes = {};
        let mergeNeeded = false;
        for (const key of commitkeys){
            const delta = this.getDelta(key);
            if (delta) {
                mergeNeeded = true;
                merge(changes, delta.changes);
                this.revertDeltaInternal(key);
            }
        }
        // Merge the folded patch if needed
        if (mergeNeeded) {
            this._baseline = this._baseline.applyDiffs(changes);
        }
    }
    /**
     * Accessor to get the value directly
     * @description Marking this deprecated to note this as a temporary fix until SharePoint supports ES2017 and discontinues support for IE. Also calling this.value is not allowed strangely.
     * @deprecated
     */ getValue() {
        if (this.deltaCount === 0) {
            return this.baseline;
        }
        return this.applyDeltas();
    }
    /**
     * Applies all the deltas to a copy of the baseline and returns that value.
     * If there are no deltas, this function just returns the baseline.
     */ applyDeltas() {
        const changeList = this.deltas.map((delta)=>delta.changes);
        let value = this._baseline;
        changeList.forEach((change)=>{
            value = value.applyDiffs(change);
        });
        return value;
    }
    /**
     * Internal reuse logic to revert or remove a delta from the list
     * @param deltaKey Key to uniquely identify the delta
     */ revertDeltaInternal(deltaKey) {
        remove(this.deltas, (update)=>update.key === deltaKey);
    }
    constructor(baseline){
        this._baseline = baseline;
        this.deltas = [];
        this._status = ExistenceStatus.Created;
    }
}
