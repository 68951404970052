// Styles
import { mergeStyles } from "@fluentui/react";
let _globalStylesApplied = false;
/**
 * Generate and apply global stylesheet that affects all elements of certain types like body,
 * scrollbar types, or certain statically named global classes or styles. Idempotent using a semaphore.
 * *Caution!* Applying styles here could unexpectedly affect certain scenarios unexpectedly.
 * Treat this is a stable/basic global cross-browser normalization mechanism for things like
 * html document, body element which cannot be classed/defined via standard mergeStyles classes.
 * See mergeStyles Global selectors docs https://github.com/microsoft/fluentui/blob/master/packages/merge-styles/README.md#global-selectors
 * @returns the global stylesheet
 */ export function applyUnoGlobalStyleOverrides() {
    if (_globalStylesApplied) return "";
    _globalStylesApplied = true;
    return mergeStyles({
        ":global(body)": {
            margin: 0,
            padding: 0,
            fontFamily: "sans-serif"
        }
    });
}
