import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../ActionType";
export class AppStartAction extends TypedAction(ActionType.AppStartAction) {
    loggingData() {
        return {
            pageName: this.pageName
        };
    }
    constructor(pageName, appStartTime){
        super(), this.pageName = pageName, this.appStartTime = appStartTime;
    }
}
export class PageReadyAction extends TypedAction(ActionType.PageReadyAction) {
    getExportName() {
        return this.exportName;
    }
    constructor(dataFetchStartTime, dataFetchEndTime, exportName, metaTag, extraData){
        super(), this.dataFetchStartTime = dataFetchStartTime, this.dataFetchEndTime = dataFetchEndTime, this.exportName = exportName, this.metaTag = metaTag, this.extraData = extraData;
    }
}
