// Telemetry
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { TraceLogger } from "@ms/uno-telemetry/lib/local/loggers/TraceLogger";
/**
 * This class can be used for retrieving settings and other contextual information from the html document
 * The Front end service needs to serialize a selection of settings into a nested object of the window object using a script tag
 * Pass the name of the nested object into the constructor of this class and you can consume these settings
 */ export class HtmlSettingsReader {
    /**
     * Get a setting
     * @param key The name of the setting. You should use one of the static properties on this class. If you need to add a new type of setting, add a static property first
     */ getSetting(key) {
        if (key in this.settings) {
            return this.settings[key];
        } else {
            return undefined;
        }
    }
    /**
     * Read the settings from the window
     * @param objectName The name of the object containing the settings
     */ readSettings(objectName) {
        const settingsObject = window[objectName];
        if (!settingsObject) {
            const message = `No ${objectName} object was found in the window. Settings will not be loaded`;
            TraceLogger.logTrace(0x1e447010 /* tag_4rhaq */ , TraceLevel.Error, message);
        } else {
            try {
                this.settings = settingsObject ?? {};
            } catch (e) {
                this.settings = {};
                const message = `Settings found in window.${objectName} were malformed, not loading settings. [Error=${e.message}]`;
                TraceLogger.logTrace(0x1e44700f /* tag_4rhap */ , TraceLevel.Error, message);
            }
        }
    }
    /**
     * Initialize a new instance of the HtmlSettingsReader class
     * @param objectName The name of the object containing the settings
     */ constructor(objectName){
        this.settings = {};
        this.readSettings(objectName);
    }
}
