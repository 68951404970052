import forEach from "lodash/forEach";
import { EventType } from "../UTelLogHandler";
import { createDataField, createExtraDataField, logEvent } from "../LoggingHelper";
import { AddExportPropertiesForUTel } from "./Exportable.event";
/**
 * Logs the data using UTelJs
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ function LogUTel(event, logHandler) {
    if (event == null) {
        return;
    }
    const dataField = [];
    dataField.push(createDataField(EventType.Action, event.name));
    AddExportPropertiesForUTel(dataField, event);
    forEach(event.payload, (value, key)=>{
        dataField.push(createExtraDataField(EventType.Action, key, value));
    });
    logHandler.log({
        type: EventType.Action,
        payload: dataField
    });
}
/**
 * Logs the event with all available loggers
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ export function Log(event, logHandler) {
    logEvent(event, LogUTel, logHandler);
}
