import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for UserStore, for use in the registry */ export const UserStoreActionTypes = [
    ActionType.AddGroupMembersAction,
    ActionType.AddGroupMembersFailedAction,
    ActionType.AddGroupMembersSucceededAction,
    ActionType.FetchContainerMembersAction,
    ActionType.FetchContainerMembersFailedAction,
    ActionType.FetchGroupMembersSucceededAction,
    ActionType.FetchRoamingUserDataFailedAction,
    ActionType.FetchRoamingUserDataSucceededAction,
    ActionType.FetchRosterMembersSucceededAction,
    ActionType.FetchTeamsChatMembersSucceeded,
    ActionType.FetchTodoMembersSucceededAction,
    ActionType.FetchUserAction,
    ActionType.FetchUserFailedAction,
    ActionType.FetchUserPhotoFailedAction,
    ActionType.FetchUserPhotoSucceededAction,
    ActionType.FetchUsersByKeywordAction,
    ActionType.FetchUsersByKeywordFailedAction,
    ActionType.FetchUsersByKeywordSucceededAction,
    ActionType.FetchUserSucceededAction,
    ActionType.UpdateRoamingUserDataFailedAction,
    ActionType.UpdateRoamingUserDataSucceededAction,
    ActionType.RemoveGroupMemberAction,
    ActionType.RemoveGroupMemberFailedAction,
    ActionType.RemoveGroupMemberSucceededAction,
    ActionType.FetchUserSettingsSucceededAction,
    ActionType.UpdateUserSettingsAction,
    ActionType.UpdateUserSettingsSucceededAction,
    ActionType.UpdateUserSettingsFailedAction,
    ActionType.DeltaSyncUpdateUserSettingsAction,
    ActionType.FetchLandingPageNotificationSettingsSucceededAction,
    ActionType.UpdateLandingPageNotificationSettingsSucceededAction
];
