// Action Creators
// Actions
import { AppStartAction, PageReadyAction } from "@ms/uno-actions/lib/local/performanceCollection/PerformanceCollectionAction";
// Constants
import { FetchedDataState, MarkerTag } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
// Utilities
import { checkIfNonNativeViewFromSubRouteType, extractPlanRoutingDetailsFromSubRoute } from "@ms/uno-routing/lib/local/utilities/RouteUtilities";
/**
 * ActionCreator class for anything pertaining to performance data collection like EUPL and PLT
 */ export class PerformanceCollectionActionCreator {
    appStart(pageName, appStartTime) {
        this.dispatcher.dispatchActionAsync(new AppStartAction(pageName, appStartTime));
    }
    pageReady(dataFetchStart, dataFetchEnd, exportName, metaTag, extraData) {
        this.dispatcher.dispatchActionAsync(new PageReadyAction(dataFetchStart, dataFetchEnd, exportName, metaTag, extraData));
        this.logViewRenderPerfMarkerForUnoNonNativeViews();
    }
    /**
     * Logs view render performance marker for Uno non-native views  on page ready.
     */ logViewRenderPerfMarkerForUnoNonNativeViews() {
        const routeStore = this.stores.route();
        const currentSubRouteType = routeStore.getCurrentSubRouteType();
        // Don't log ViewRender marker on pageReady for native views
        if (!checkIfNonNativeViewFromSubRouteType(currentSubRouteType, this.configProvider)) {
            return;
        }
        const viewType = extractPlanRoutingDetailsFromSubRoute(routeStore.getCurrentSubRoute(), currentSubRouteType)?.viewType;
        const markViewName = viewType ? `${currentSubRouteType}/${viewType}` : currentSubRouteType;
        const markExtraData = {
            viewName: markViewName,
            viewData: FetchedDataState[FetchedDataState.Critical]
        };
        this.loggers.performanceMarker.mark(MarkerTag.ViewRender, markExtraData);
    }
    /**
     * Initializes a new instance of the performance collector action creator
     * @param dispatcher The dispatcher the SPA uses to dispatch actions
     * @param stores The store providers the SPA uses to get stores
     * @param loggers The loggers for telemetry
     * @param configProvider The configuration provider for the app
     */ constructor(dispatcher, stores, loggers, configProvider){
        this.dispatcher = dispatcher;
        this.stores = stores;
        this.loggers = loggers;
        this.configProvider = configProvider;
    }
}
