import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for BasicBucketStore, for use in the registry */ export const BasicBucketStoreActionTypes = [
    ActionType.CreateBasicBucketAction,
    ActionType.CreateBasicBucketFailedAction,
    ActionType.CreateBasicBucketSucceededAction,
    ActionType.DeleteBasicBucketAction,
    ActionType.DeleteBasicBucketFailedAction,
    ActionType.DeleteBasicBucketSucceededAction,
    ActionType.DeltaSyncCreateBasicBucketAction,
    ActionType.DeltaSyncDeleteBasicBucketAction,
    ActionType.DeltaSyncUpdateBasicBucketAction,
    ActionType.FetchBasicBucketAction,
    ActionType.FetchBasicBucketFailedAction,
    ActionType.FetchBasicBucketsForPlanAction,
    ActionType.FetchBasicBucketsForPlanFailedAction,
    ActionType.FetchBasicBucketsForPlanSucceededAction,
    ActionType.FetchBasicBucketSucceededAction,
    ActionType.UpdateBasicBucketAction,
    ActionType.UpdateBasicBucketFailedAction,
    ActionType.UpdateBasicBucketSucceededAction
];
