/**
 * Contains the constants related to task creationContext externalSource
 */ export var ContextScenarioConstants;
(function(ContextScenarioConstants) {
    /** The contextScenarioId for the fluid meetingNotes app */ ContextScenarioConstants.meetingNotes = "com.microsoft.fluid.meetingNotes";
    /** The contextScenarioId for the fluid loop (quivia) app */ ContextScenarioConstants.loop = "com.microsoft.fluid.loop";
    /** The contextScenarioId for create task from teams message */ ContextScenarioConstants.teamsMessage = "com.microsoft.teams.message";
    /** The contextScenarioId for create task from project app */ ContextScenarioConstants.project = "com.microsoft.project.plannerIntegration";
    /** The contextScenarioId for app powered task */ ContextScenarioConstants.businessExtensibility = "com.microsoft.businessExtensibility";
})(ContextScenarioConstants || (ContextScenarioConstants = {}));
