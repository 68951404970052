/**
 * Constant values used in styles
 */ import { Depths } from "@fluentui/react";
import { numberToPx, pxToNumber } from "@ms/uno-utilities/lib/local/StyleUtilities";
// Border radius to use for all dialogs, dropdowns, cards, etc
export const BorderRadius = "3px";
// Scroll bar constants
export const ScrollBarWidth = "10px";
export const ScrollBarHeight = "7px";
export const ScrollBarBorderRadius = "5px";
// Widths used for primary dialogs
export const MainDialogMaxWidth = "800px";
export const MainDialogMinWidth = "300px";
export const MainDialogWidth = "70%";
// Widths used in secondary dialogs
export const MaxSecondaryDialogWidth = "560px";
export const SecondaryDialogWidth = "35%";
// Box shadow constants
export const HoverShadow = "0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.18), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11)";
export const Shadow = "0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.18), 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11)";
/** Template Constants */ export const TemplateTitleFontSize = "20px";
export const TemplateSubTitleFontSize = "16px";
export const TemplateNormalFontSize = "14px";
export const TemplateBoxShadow = "0px 0px 3.9px 0px rgba(0, 0, 0, 0.242)";
export const TemplateBoxHoverShadow = "1px 1px 7.9px 0px rgba(0, 0, 0, 0.242)";
export const TemplateBoxClickedHoverShadow = "1px 1px 4px 0px rgba(0, 0, 0, 0.242)";
export const TemplateDetailsImageBoxShadow = "0px 0px 5.2px rgba(0, 0, 0, 0.132)";
export const TemplateModalShadow = "0px 0px 23px rgba(0, 0, 0, 0.25)";
export const TemplateDetailsFooterBackground = "linear-gradient(179.26deg, rgba(181, 181, 181, 0) 0%, rgba(173, 173, 173, 0.2) 104.89%, rgba(173, 173, 173, 0.2) 104.89%, rgba(190, 190, 190, 0.2) 104.89%)"; //Not a shadow, but a gradient pseudo element used as a shadow
export const TemplateDetailsFooterHeight = "72px";
/** Menu item styles */ export const MenuItemWidth = "244px";
/** Loop constants */ export const LoopBorderRadius = "12px";
export const LoopButtonBorderRadius = "8px";
export const LoopLightHoverShadow = "0px 4px 8px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)";
export const LoopDarkHoverShadow = "0px 8px 16px rgba(0, 0, 0, 0.28), 0px 0px 2px rgba(0, 0, 0, 0.24)";
export const LoopMenuItemHeight = "36px";
export const LoopMenuItemPadding = "6px 12px";
export const LoopSubItemBorderRadius = "8px";
export const LoopMenuItemIconMarginRight = "12px";
/** Input height in Planner, 32px for content, 2px for border */ export const PlannerInputHeight = "34px";
/** Shadow constants */ export const ButtonShadow = "0px 2px 4px -0.75px rgba(0, 0, 0, 0.1)";
export const LoopLightRestShadow = "0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)";
export const LoopDarkRestShadow = "0px 2px 4px rgba(0, 0, 0, 0.28), 0px 0px 2px rgba(0, 0, 0, 0.24)";
export const ShadowMargin = "13px";
/** User profile icon Dimension in Persona view */ export const UserPersonaBubbleDiameter = "28px";
/** Border radius to use for all dialogs, dropdowns, cards, etc */ export const PlannerBorderRadius = "3px";
/** Width used for tooltips */ export const TooltipMinWidth = 300;
/** Group Picker/List constants */ export const SearchBarPadding = "10px";
export const CopyPlanPickerHeight = "360px";
export const GroupPickerTotalHorizontalPadding = "53px";
export const GroupListHeight = "300px";
export const GroupListIconPrimarySize = "40px";
export const GroupListPaddingBetweenElements = "5px";
export const GroupInfoLineHeight = "30px";
export const GroupInfoNewGroupIconFontSize = "24px";
export const FabricHorizontalPadding = "28px";
/** Button constants */ export const ButtonHeight = "32px";
/** Media queries */ export const HighContrastMediaQuery = "(forced-colors: active), (-ms-high-contrast: active)";
export const HighContrastWhiteOnBlackMediaQuery = "(-ms-high-contrast: white-on-black)";
/** Style to add shadow to a card */ export const CardShadow = {
    boxShadow: Depths.depth8,
    "&:hover": {
        boxShadow: Depths.depth16
    }
};
/** Board View */ export const ScrollbarSpaceAround = "10px";
export const TopHeaderIconHorizontalOffset = "24px";
export const HeaderDropdownItemSpacing = "16px"; // The spacing between dropdown elements
/** Charts View */ export const BarOrColumnChartContentPadding = "16px 32px";
export const ChartMarginBetweenElements = "10px";
// every width has to include the width of the left nav in uno, which is 360px.
export const SmallScreenWidth = "1060px";
export const LargeScreenWidth = "1300px";
// TODO: PBI 9025004 - align SPO styles with Uno teams and web
/** Sharepoint constants */ export const SharepointHorizontalPadding = "32px";
export const SharepointVerticalPadding = "0px";
export const SharepointContentTopPadding = "30px";
export const SharepointContentBottomPadding = "0px";
/** SuiteNav Header */ export const SuiteNavHeight = "48px";
/** Uno Header */ export const UnoHeaderHeight = "58px";
export const UnoHeaderPadding = "32px";
export const TaskCardContentWidth = "288px";
export const TaskLabelsSectionWidth = "6px";
export const MaxKnownScrollbarWidth = "20px";
export const ContentWidth = numberToPx(pxToNumber(TaskCardContentWidth) + pxToNumber(TaskLabelsSectionWidth) + pxToNumber(MaxKnownScrollbarWidth));
