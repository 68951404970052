import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for UpsellStore, for use in the registry */ export const UpsellStoreActionTypes = [
    ActionType.AcquireProjectTrialAction,
    ActionType.AcquireProjectTrialAndConvertPlanAction,
    ActionType.AcquireProjectTrialFailedAction,
    ActionType.AcquireProjectTrialSucceededAction,
    ActionType.ConvertBasicPlanToPremiumAction,
    ActionType.ConvertBasicPlanToPremiumFailedAction,
    ActionType.ConvertBasicPlanToPremiumSucceededAction,
    ActionType.FetchConvertPlanStatusSucceededAction,
    ActionType.FetchProjectSubscriptionStatusAction,
    ActionType.FetchProjectSubscriptionStatusSucceededAction,
    ActionType.FetchProjectSubscriptionStatusFailedAction,
    ActionType.FetchUserFeatureEntitlementsSucceededAction,
    ActionType.ResetPlanConversionStatusAction,
    ActionType.ResetProjectUpsellExperienceModeAction,
    ActionType.TrialProvisioningSucceededAction,
    ActionType.UpdateProjectSubscriptionStatusAction,
    ActionType.UpdateUserFeatureEntitlementsAction,
    ActionType.UpdateSelfServiceAction
];
