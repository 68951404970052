import forEach from "lodash/forEach";
import { EventType } from "../UTelLogHandler";
import { createDataField, createExtraDataField, logEvent } from "../LoggingHelper";
import { AddExportPropertiesForUTel } from "./Exportable.event";
/**
 * Logs the event using UTelJs
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ function LogUTel(event, logHandler) {
    if (event == null) {
        return;
    }
    const payload = [];
    event.name && payload.push(createDataField(EventType.Engagement, event.name));
    AddExportPropertiesForUTel(payload, event);
    event.extraData && forEach(event.extraData, (value, key)=>{
        payload.push(createExtraDataField(EventType.Engagement, key, value));
    });
    logHandler.log({
        type: EventType.Engagement,
        payload: payload
    });
}
/**
 * Logs the event with all available loggers
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ export function Log(event, logHandler) {
    logEvent(event, LogUTel, logHandler);
}
