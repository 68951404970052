// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Utilities
import { retry } from "@ms/uno-utilities/lib/local/RetryUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
/**
 * Gets the Fluent v8 theme for the given Uno theme.
 * @param unoTheme The Uno theme.
 */ export async function getThemeV8(unoTheme, traceLogger) {
    try {
        switch(unoTheme){
            case UnoTheme.TeamsDark:
                return (await retry(()=>import(/* webpackChunkName: "uno-teamsdarkv8" */ "../themes/v8/TeamsDark"))).getTeamsDarkV8Theme();
            case UnoTheme.TeamsContrast:
                return (await retry(()=>import(/* webpackChunkName: "uno-teamshighconstrastv8" */ "../themes/v8/TeamsContrast"))).getTeamsConstrastV8Theme();
            case UnoTheme.TeamsLight:
                return (await retry(()=>import(/* webpackChunkName: "uno-teamslightv8" */ "../themes/v8/TeamsLight"))).getTeamsLightV8Theme();
            case UnoTheme.Dark:
            case UnoTheme.Light:
            case UnoTheme.SharePoint:
            case UnoTheme.LoopLight:
            case UnoTheme.LoopDark:
            case UnoTheme.LoopWorkspaceLight:
            case UnoTheme.LoopWorkspaceDark:
            default:
                // TODO PBI #8054676: Add non-Teams themes to Uno ThemeProvider / FluentProvider
                return (await retry(()=>import(/* webpackChunkName: "uno-teamslightv8" */ "../themes/v8/TeamsLight"))).getTeamsLightV8Theme();
        }
    } catch (e) {
        traceLogger.logTrace(0x1e44a0e1 /* tag_4rkd7 */ , TraceLevel.Error, `Failed to dynamically import v8 theme:  [Error=${ErrorUtilities.getMessage(e)}]`);
        throw e;
    }
}
