import { Log } from "../events/Engagement.event";
import { QosEvent } from "../events/Qos.event";
import { ArgumentNullError, ArgumentError } from "@ms/uno-errors/lib/local/errors";
import { EngagementTelemetryLogProperties } from "../events/EngagementTelemetryEvent";
// Utilities
import { TraceLevel } from "../events/Trace.event";
// constant strings
const EMPTY_TEXT = "(empty)";
/**
 * This class is responsible for engagement Telemetry Logging. You can subscribe to engagement telemetry logs by implementing IEngagementTelemetryEventSubscriber and passing an instance in to
 * the initialize method. See the class EngagementTelemetryLogProperties for more info on the log format.
 */ export class EngagementLogger {
    initializeEngagementLoggerData(product, client, getCurrentPageMethod, coreActions, subscribers) {
        if (product === "") {
            throw new ArgumentError("product");
        }
        if (client === "") {
            throw new ArgumentError("client");
        }
        if (getCurrentPageMethod == null) {
            throw new ArgumentNullError("getCurrentPageMethod");
        }
        // simply save them in private fields for use later
        this.product = product;
        this.client = client;
        this.getCurrentPageMethod = getCurrentPageMethod;
        this.coreActionsSet = new Set(coreActions);
        this.subscribers = (subscribers || []).concat(this.globalSubscribers);
        this.initialized = true;
    }
    /**
     * Log engagement telemetry data
     * @param component Name of the component
     * @param action Name of the action taken
     * @param extraData (optional) Any extra data relevant to this event
     * @param exportName Name to export via ODIN, or undefined for no export
     */ logEngagement(component, action, extraData, exportName) {
        if (!this.initialized) {
            this.traceLogger.logTrace(0x1e44125e /* tag_4rbj4 */ , TraceLevel.Error, `EngagementLogger.logEngagement() was called without EngagementLogger being initialized first.`);
        }
        const engagementTelemetryProperties = this.getEngagementTelemetryProperties(component, action);
        const data = extraData || {};
        if (this.coreActionsSet.has(action)) {
            data["IsCoreAction"] = "true";
        }
        if (this.subscribers.length > 0) {
            this.subscribers.forEach((subscriber)=>{
                subscriber.log({
                    name: engagementTelemetryProperties.toQualifiedName(),
                    exportName: exportName,
                    extraData: data,
                    engagementTelemetryLogProperties: engagementTelemetryProperties
                }, this.logHandler);
            });
        }
    }
    /**
     * Start a Qos logging event using the EngagementLogger naming convention
     * @param component Name of the component/module
     * @param name Name of the qos event to log
     */ startQos(component, name) {
        if (!this.initialized) {
            this.traceLogger.logTrace(0x1e44125d /* tag_4rbj3 */ , TraceLevel.Error, `EngagementLogger.startQos() was called without EngagementLogger being initialized first.`);
        }
        return new QosEvent({
            name: this.getEngagementTelemetryProperties(component, name).toQualifiedName()
        }, this.logHandler);
    }
    /**
     * Creates a full event name from the component and name portions
     */ getEngagementTelemetryProperties(component, name) {
        // try to get the current route
        let currentRoute;
        if (this.getCurrentPageMethod) {
            try {
                currentRoute = this.getCurrentPageMethod();
            } catch (exception) {
                throw new Error("Failed to get current page via getCurrentPageMethod.");
            }
        }
        // we dont want empty strings
        if (currentRoute == null || currentRoute === "") {
            currentRoute = EMPTY_TEXT;
        }
        return new EngagementTelemetryLogProperties(this.product, this.client, currentRoute, component, name);
    }
    /**
     * Create a new instance of UsageLogger
     * @param logHandler log handler to use for logging
     * @param traceLogger trace logger
     */ constructor(logHandler, traceLogger){
        this.logHandler = logHandler;
        this.traceLogger = traceLogger;
        this.initialized = false;
        this.globalSubscribers = [
            {
                log: Log
            }
        ];
    }
}
