/**
 * Enumeration of the response headers we care about from task service
 * NOTE: Headers are officially case-insensitive. But its common practice to captialize first letter of each word.
 */ export var TaskServiceResponseHeaders;
(function(TaskServiceResponseHeaders) {
    /** The client side correlation id */ TaskServiceResponseHeaders["XCorrelationId"] = "X-CorrelationId";
    /** Tasks Platform Service generated correlation id per request */ TaskServiceResponseHeaders["TCorrelationId"] = "X-Tasks-CorrelationId";
    TaskServiceResponseHeaders["ProxyFE"] = "X-ProxyFE";
    TaskServiceResponseHeaders["ProxyCluster"] = "X-ProxyCluster";
    TaskServiceResponseHeaders["ProxyVersion"] = "X-ProxyVersion";
    TaskServiceResponseHeaders["OfficeFE"] = "X-OfficeFE";
    TaskServiceResponseHeaders["OfficeVersion"] = "X-OfficeVersion";
    TaskServiceResponseHeaders["OfficeCluster"] = "X-OfficeCluster";
    /** This is the AFD header that tells us which AFD node you were routed to as well as having their equivalent of a correlation id */ TaskServiceResponseHeaders["EdgeRef"] = "X-MSEdge-Ref";
    /** User Session id */ TaskServiceResponseHeaders["UserSession"] = "X-UserSessionId";
    /** Response header for the authentication failure challenge */ TaskServiceResponseHeaders["AuthenticationChallenge"] = "WWW-Authenticate";
    /** The reason phrase for authentication failure */ TaskServiceResponseHeaders["ReasonPhrase"] = "ReasonPhrase";
})(TaskServiceResponseHeaders || (TaskServiceResponseHeaders = {}));
/**
 * Constants used by the service and locally for object diffs
 */ export class ObjectDiffConstants {
}
/** Value used when a collection property is cleared of values */ ObjectDiffConstants.CollectionCleanupValue = "RemoveAll";
export var GraphResponseHeaders;
(function(GraphResponseHeaders) {
    /** The client side correlation id tracking a request */ GraphResponseHeaders["ClientRequestId"] = "client-request-id";
    /** The graph generated correlation id*/ GraphResponseHeaders["RequestId"] = "request-id";
    /** Tasks Platform Service generated correlation id per request */ GraphResponseHeaders["TCorrelationId"] = "X-Tasks-CorrelationId";
})(GraphResponseHeaders || (GraphResponseHeaders = {}));
export var LoopResponseHeaders;
(function(LoopResponseHeaders) {
    /** Loop generated correlation vector per request */ LoopResponseHeaders["CorrelationVector"] = "MS-CV";
})(LoopResponseHeaders || (LoopResponseHeaders = {}));
export var OcpsResponseHeaders;
(function(OcpsResponseHeaders) {
    /** OCPS generated correlation id per request */ OcpsResponseHeaders["CorrelationId"] = "x-cid";
})(OcpsResponseHeaders || (OcpsResponseHeaders = {}));
export var RequestHeaders;
(function(RequestHeaders) {
    /** The client side correlation id tracking a request (graph) */ RequestHeaders["ClientRequestId"] = "client-request-id";
    /** Similar to ClientRequestId, specifies the correlation id (web api) */ RequestHeaders["XCorrelationId"] = "X-CorrelationId";
    /** Header that indicates what page Planner is on */ RequestHeaders["PlannerUsageViewName"] = "X-Planner-Usage";
    /** Header that indicates the Planner client type */ RequestHeaders["PlannerUsageClientType"] = "X-Planner-ClientType";
    /** Header for a user session */ RequestHeaders["UserSessionIdHeaderName"] = "X-UserSessionId";
    /** This header will set routing on ajax calls */ RequestHeaders["PlannerRouteHintHeaderName"] = "X-PlannerRouteHint";
})(RequestHeaders || (RequestHeaders = {}));
export var TaskPlatformRequestHeaders;
(function(TaskPlatformRequestHeaders) {
    /** The header that indicates the client type to the tasks platform */ TaskPlatformRequestHeaders["TasksClientType"] = "X-Tasks-ClientType";
})(TaskPlatformRequestHeaders || (TaskPlatformRequestHeaders = {}));
/**
 * Project telemetry headers
 */ export const ProjectClientType = "x-project-client-type";
export const ProjectClientFlavor = "x-project-client-flavor";
export var PSSResponseHeaders;
(function(PSSResponseHeaders) {
    /** The client side correlation id tracking a request */ PSSResponseHeaders["ClientRequestId"] = "client-request-id";
    /** PSS generated correlation id per request */ PSSResponseHeaders["XCorrelationId"] = "x-correlationid";
    /** PSS header capturing the project cloudproj service (PCS) session identifier */ PSSResponseHeaders["ProjectSessionId"] = "x-project-session-id";
})(PSSResponseHeaders || (PSSResponseHeaders = {}));
export var PLPResponseHeaders;
(function(PLPResponseHeaders) {
    /** The client side correlation id tracking a request */ PLPResponseHeaders["ClientRequestId"] = "client-request-id";
    /** PLP generated correlation id per request */ PLPResponseHeaders["XCorrelationId"] = "x-correlationid";
})(PLPResponseHeaders || (PLPResponseHeaders = {}));
export var AuthenticationReasonPhrase;
(function(AuthenticationReasonPhrase) {
    /** Value indicates an authentication challenge error and we require to re-sign the current user */ AuthenticationReasonPhrase["InteractionRequired"] = "interaction_required";
})(AuthenticationReasonPhrase || (AuthenticationReasonPhrase = {}));
export var AuthenticationChallengeResponseErrors;
(function(AuthenticationChallengeResponseErrors) {
    /** Value for insufficient claims */ AuthenticationChallengeResponseErrors["InsufficientClaims"] = "insufficient_claims";
})(AuthenticationChallengeResponseErrors || (AuthenticationChallengeResponseErrors = {}));
export var GatewayServiceResponseHeaders;
(function(GatewayServiceResponseHeaders) {
    /** The client side correlation id tracking a request */ GatewayServiceResponseHeaders["ClientRequestId"] = "client-request-id";
    /** Gateway Service generated correlation id per request */ GatewayServiceResponseHeaders["XCorrelationId"] = "x-correlationid";
    /** Gateway service header capturing the session identifier */ GatewayServiceResponseHeaders["ProjectSessionId"] = "x-project-session-id";
})(GatewayServiceResponseHeaders || (GatewayServiceResponseHeaders = {}));
export var RetailServicesRequestHeaders;
(function(RetailServicesRequestHeaders) {
    /** The Teams client ring override currently in effect in session */ RetailServicesRequestHeaders["XRingOverride"] = "X-RingOverride";
})(RetailServicesRequestHeaders || (RetailServicesRequestHeaders = {}));
export var LoopServiceRequestHeaders;
(function(LoopServiceRequestHeaders) {
    /** The correlation vector */ LoopServiceRequestHeaders["MsCv"] = "MS-CV";
})(LoopServiceRequestHeaders || (LoopServiceRequestHeaders = {}));
export var MarsServiceRequestHeaders;
(function(MarsServiceRequestHeaders) {
    /** Configures whether task execution can respond with broken down tasks. */ MarsServiceRequestHeaders["TaskBreakdownPreference"] = "X-Task-Breakdown-Preference";
    /** The client side correlation id tracking a request */ MarsServiceRequestHeaders["CorrelationId"] = "X-Client-Correlation-Id";
})(MarsServiceRequestHeaders || (MarsServiceRequestHeaders = {}));
