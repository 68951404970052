/**
 * Guid utility functions.
 * Copied from https://onedrive.visualstudio.com/OneDriveWeb/_git/odsp-common?path=%2Futilities%2Fguid%2Fsrc%2FGuid.ts
 */ function generateUuidPart(length) {
    let str = "";
    while(str.length < length){
        let num = Math.random() * 16;
        num = num | 0; // clear decimal
        str += num.toString(16);
    }
    return str;
}
export const Empty = "00000000-0000-0000-0000-000000000000";
/**
 * Return a v4 UUID as specified by RFC 4122 http://tools.ietf.org/html/rfc4122
 */ export function generateGuid() {
    // Prefer native crypto api if available
    if (window.isSecureContext && crypto?.randomUUID != null) {
        return crypto.randomUUID();
    } else {
        const parts = [];
        parts.push(generateUuidPart(8));
        parts.push(generateUuidPart(4));
        // first digit must be 4 since we are using pseudorandom numbers
        const timeHighAndVersion = "4" + generateUuidPart(3);
        parts.push(timeHighAndVersion);
        // first digit must have first two bits set to 10
        let clockSeqHiAndReserved = generateUuidPart(4);
        // ClockSeqHiAndReserved is a base-16 string.
        let intVal = parseInt(clockSeqHiAndReserved[0], 16);
        intVal = intVal & 3; // clear out first two bits
        intVal = intVal | 8; // set first bit to 1
        clockSeqHiAndReserved = intVal.toString(16) + clockSeqHiAndReserved.substr(1);
        parts.push(clockSeqHiAndReserved);
        parts.push(generateUuidPart(12));
        return parts.join("-");
    }
}
/**
 * Utility method to determine if a string is a Guid
 * @param str the string to be tested
 */ export function isGuid(str) {
    let isGuid = false;
    const guidRegex = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/;
    if (str) {
        isGuid = guidRegex.test(str);
    }
    return isGuid;
}
