/**
 * Valid Teams RingIds. Retrieved from Teams SDK.
 *
 * Descriptions: https://aka.ms/teamsrings
 *
 * ringId property ref: https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/app.apphostinfo?view=msteams-client-js-latest#@microsoft-teams-js-app-apphostinfo-ringid
 */ export var TeamsRingId;
(function(TeamsRingId) {
    /** Teams canary, or engineering self-host */ TeamsRingId["ring0"] = "ring0";
    /** Teams slimcore in ring0 */ TeamsRingId["ring0_s"] = "ring0_s";
    /** Teams internal folks, Teams org, selected test tenants */ TeamsRingId["ring1"] = "ring1";
    /** Teams Beta/TAP tenant admins, IT Admins Feature Preview, IT Pro early adopters */ TeamsRingId["ring1_5"] = "ring1_5";
    /** Teams Early Developer Partners Preview */ TeamsRingId["ring1_6"] = "ring1_6";
    /** Teams Microsoft Inner Ring, Microsoft Internal, Microsoft Dogfood */ TeamsRingId["ring2"] = "ring2";
    /** Teams External TAP, Beta Users, External users via Beta/TAP Program, Insiders */ TeamsRingId["ring3"] = "ring3";
    /** Teams Public Preview, Targeted Release and Developer Preview.
     * External early adopters evaluating pre-release features */ TeamsRingId["ring3_6"] = "ring3_6";
    /** Teams Microsoft Outer Ring. All Microsoft */ TeamsRingId["ring3_9"] = "ring3_9";
    /** Teams Public. Worldwide, General Ring, General Audience, GA */ TeamsRingId["general"] = "general";
})(TeamsRingId || (TeamsRingId = {}));
/**
 * Used in TeamsTasksService API to determine the partner scenario.
 */ export const teamsTasksApiPartnerName = "planner";
export var TeamsTabQueryParams;
(function(TeamsTabQueryParams) {
    /** Tab route details. Should be a properly-formatted deep link path. */ TeamsTabQueryParams["PlanRoute"] = "planRoute";
})(TeamsTabQueryParams || (TeamsTabQueryParams = {}));
/** Planner teams tab Id */ export const TeamsPlannerTabId = "com.microsoft.teamspace.tab.planner";
