import { Log } from "../events/Performance.event";
/**
 * This class is responsible for performance logging.
 */ export class PerformanceLogger {
    /**
     * Log the performance entry
     * @param performanceEntry The performance entry to log
     */ log(performanceEntry) {
        Log({
            entry: performanceEntry,
            version: "v2"
        }, this.logHandler);
    }
    /**
     * Create a new instance of PerformanceLogger
     * @param logHandler log handler to use for logging
     */ constructor(logHandler){
        this.logHandler = logHandler;
    }
}
