/**
 * In-memory storage that simply stores data in a dictionary
 */ export class InMemoryStorage {
    /**
     * Get an item by key
     * @param {string} key Item's key
     * @returns {string} Value stored at the specified key, or null if it does not exist.
     */ getItem(key) {
        const value = this.storage[key];
        // should never return undefined
        if (value == null) {
            return null;
        }
        return value;
    }
    /**
     * Set an item by key
     * @param {string} key Item's key
     * @param {string} value Item's value
     */ setItem(key, value) {
        this.storage[key] = value;
    }
    /**
     * Remove an item by key
     * @param {string} key Item's key
     */ removeItem(key) {
        delete this.storage[key];
    }
    /**
     * Clear the store
     */ clear() {
        this.storage = {};
    }
    constructor(){
        this.clear();
    }
}
