// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../ActionType";
// Utilities
import moment from "moment";
export class ClearAccessTokenAction extends TypedAction(ActionType.ClearAccessToken) {
    loggingData() {
        return {
            resource: this.resource
        };
    }
    constructor(resource){
        super(), this.resource = resource;
    }
}
export class FetchAccessTokenAction extends TypedAction(ActionType.FetchAccessToken) {
    loggingData() {
        return {
            resource: this.resource
        };
    }
    constructor(resource, claims){
        super(), this.resource = resource, this.claims = claims;
    }
}
export class FetchAccessTokenFailedAction extends TypedAction(ActionType.FetchAccessTokenFailed) {
    loggingData() {
        return {
            resource: this.resource
        };
    }
    constructor(resource, claims, error){
        super(), this.resource = resource, this.claims = claims, this.error = error;
    }
}
export class FetchAccessTokenSucceededAction extends TypedAction(ActionType.FetchAccessTokenSucceeded) {
    loggingData() {
        return {
            resource: this.resource,
            expiry: this.expiry ? moment(this.expiry).toISOString() : "null"
        };
    }
    constructor(resource, accessToken, expiry, claims){
        super(), this.resource = resource, this.accessToken = accessToken, this.expiry = expiry, this.claims = claims;
    }
}
