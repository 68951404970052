// OTEL
import { EnumObjects, makeBooleanDataField, makeStringDataField } from "@microsoft/oteljs";
import { OneDSEndpoint } from "@microsoft/oteljs-1ds";
import { createOneDSSink } from "./UTelUtilities";
// Constants
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { OneDSEnvironment, LoggingStrings } from "@ms/uno-constants/lib/local/LoggingConstants";
import { UsageCoreActions } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { getClientAppId, setPersistentDataField } from "../LoggingHelper";
import { TenantRegion } from "@ms/uno-constants/lib/local/AppConstants";
// Utilities
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
/** Initialize the engagement logger
 * @param getCurrentPageMethod Method that returns the current page
 */ export function initializeEngagementLogger(getCurrentPageMethod, engagementLogger) {
    engagementLogger.initializeEngagementLoggerData(LoggingStrings.ProductName, AppContext.appConfiguration.sessionMetaData.appMetadata.clientType, getCurrentPageMethod, UsageCoreActions);
}
/**
 * Reset log handlers
 * @param telemetryContext Telemetry Context
 * @param logHandler Log handler to be reset
 * @param configProvider Config provider
 */ export function resetLogHandler(telemetryContext, logHandler, configProvider) {
    const oneDSInitOptions = getOneDSInitOptions(telemetryContext, configProvider);
    if (oneDSInitOptions) {
        const telemetrySink = createOneDSSink(oneDSInitOptions);
        if (telemetrySink) {
            const utelInitOption = getUTelLoggingOptions(telemetryContext);
            logHandler.initialize(telemetrySink, utelInitOption.initOptions, utelInitOption.enableConsoleLogging, ()=>{
                telemetrySink.flush(true);
            });
        }
    }
}
/**
 * Get OneDS init options
 * @param telemetryContext Telemetry Context
 */ function getOneDSInitOptions(telemetryContext, configProvider) {
    const audienceGroup = configProvider().settings.audienceGroup;
    // don't log without audience group
    if (audienceGroup == null) {
        return null;
    }
    const initOptions = {
        appLanguage: telemetryContext.locale,
        appName: LoggingStrings.ProductName,
        appVersion: AppContext.appConfiguration.sessionMetaData.clientBuild,
        appPlatform: telemetryContext.platform,
        sessionId: AppContext.appConfiguration.sessionMetaData.sessionId,
        user: getUserInfoForTelemetry(telemetryContext, configProvider),
        release: {
            audienceGroup: audienceGroup
        }
    };
    return initOptions;
}
/**
 * Get UTel logging options
 * @param telemetryContext Telemetry Context
 */ function getUTelLoggingOptions(telemetryContext) {
    const enableConsoleLogging = AppContext.isPreProductionEnvironment();
    const initOptions = {
        persistentDataFields: getDefaultPersistentDataFields(telemetryContext)
    };
    return {
        initOptions,
        enableConsoleLogging
    };
}
/**
 * Get user info (Office.System.User contract) for telemetry
 * If isAnonymous is false, then PrimaryIdentityHash and PrimaryIdentitySpace must be set
 * If PrimaryIdentitySpace is one of OrgIdPUID, OrgIdCID, or UserObjectId, then TenantId must be set
 * If isAnonymous is true, then either PrimaryIdentityHash must be unset or
 * PrimaryIdentityHash must be an anonymously constructed value and PrimaryIdentitySpace must be 'Anonymous'
 * @param telemetryContext Telemetry Context
 * @param configProvider Config provider
 */ function getUserInfoForTelemetry(telemetryContext, configProvider) {
    const oneDSEndpoint = getOneDSEndpoint(configProvider);
    const isAuthenticated = telemetryContext.userId && telemetryContext.userId !== "";
    const idType = telemetryContext.isMSA ? EnumObjects.PrimaryIdentitySpace.MSAPUID : EnumObjects.PrimaryIdentitySpace.UserObjectId;
    // StrictContractAnonymousUser
    let user = {
        isAnonymous: true,
        environment: oneDSEndpoint
    };
    if (isAuthenticated) {
        if (telemetryContext.tenantId) {
            // StrictContractKnownUser
            user = {
                isAnonymous: false,
                primaryIdentityHash: telemetryContext.userId,
                primaryIdentitySpace: idType,
                tenantId: telemetryContext.tenantId,
                environment: oneDSEndpoint
            };
        } else if (idType === EnumObjects.PrimaryIdentitySpace.MSAPUID) {
            // StrictContractKnownUserOther
            user = {
                isAnonymous: false,
                primaryIdentityHash: telemetryContext.userId,
                primaryIdentitySpace: idType,
                environment: oneDSEndpoint
            };
        }
    }
    return user;
}
/**
 * Get default persistent data fields for UTel
 * @param telemetryContext Telemetry context for logging
 */ function getDefaultPersistentDataFields(telemetryContext) {
    const { settings, sessionMetaData } = AppContext.appConfiguration;
    const persistentDataFields = [
        makeStringDataField("AppInfo.ExperimentIds", settings.ConfigIDs),
        makeStringDataField("BuildIdentifier", sessionMetaData.clientBuild),
        makeStringDataField("ClientAppId", getClientAppId(sessionMetaData.appMetadata.clientType, telemetryContext.platform)),
        makeStringDataField("RolloutStage", settings.rolloutStage),
        makeBooleanDataField("IsGuestUser", telemetryContext.isGuestUser),
        makeBooleanDataField("IsMsa", telemetryContext.isMSA),
        makeStringDataField("ClientFlavor", sessionMetaData.appMetadata.clientFlavor),
        makeStringDataField("ClientType", sessionMetaData.appMetadata.clientType),
        makeBooleanDataField("IsOnline", navigator.onLine),
        makeStringDataField("Environment", sessionMetaData.environment)
    ];
    telemetryContext.ringId && persistentDataFields.push(makeStringDataField("RingId", telemetryContext.ringId));
    return persistentDataFields;
}
/**
 * Get 1DS telemetry endpoint (e.g PUBLIC, EUDB)
 * @param configProvider Config provider
 */ export function getOneDSEndpoint(configProvider) {
    const oneDSEnvironment = configProvider().settings.oneDSSinkEnvironment;
    switch(oneDSEnvironment){
        case OneDSEnvironment.DoD:
            return OneDSEndpoint.USGOV_DOD;
        case OneDSEnvironment.DoJ:
            return OneDSEndpoint.USGOV_DOJ;
        default:
            return shouldLogToEU(configProvider) ? OneDSEndpoint.EUDB : OneDSEndpoint.PUBLIC;
    }
}
/**
 * Check if logs should be sent to EU
 * @param configProvider Config provider
 * */ function shouldLogToEU(configProvider) {
    const tenantRegion = configProvider().sessionMetaData.tenantRegion;
    const forceLogsToEU = configProvider().settings.forceLogsToEU;
    return forceLogsToEU || tenantRegion === TenantRegion.Eu;
}
/**
 * Create name for logging purposes that includes the api name and the call
 * @param apiName Name of the service api
 * @param methodName Name of the method on the service api
 * */ export function createQosEventName(apiName, methodName) {
    return `api.${apiName}.${methodName}`;
}
/**
 * Updates the current subRouteType and subRoute on the logging context
 * @param subRouteType Current subRouteType to log
 * @param subRoute Current subRoute to log
 * @param logHandler Log handler to set persistent data field
 */ export function setCurrentRouteInfoForLogs(subRouteType, subRoute, logHandler) {
    setPersistentDataField("SubRouteType", subRouteType, makeStringDataField, logHandler);
    setPersistentDataField("SubRoute", subRoute.join("/"), makeStringDataField, logHandler);
}
/**
 * Updates the online status
 * @param isOnline Online status
 * @param logHandler Log handler to set persistent data field
 */ export function setIsOnlineStatusForLogs(isOnline, logHandler) {
    setPersistentDataField("IsOnline", isOnline, makeBooleanDataField, logHandler);
}
/**
 * Get the log string from the error object
 * @param error Error object
 */ export function generateLogStringFromError(error) {
    // check if error is an Error object
    if (error instanceof Error) {
        return `[ErrorType=${error.name}][Exception=${ErrorUtilities.getMessage(error)}]`;
    }
    // check if error is an error object from the ajax request
    if (error?.response || error?.error) {
        let extraTelemetryDataString = "";
        if (error?.extraTelemetryData) {
            Object.entries(error?.extraTelemetryData).forEach(([key, value])=>{
                extraTelemetryDataString += `[${key}=${value}]`;
            });
        }
        return `[StatusCode=${error?.response?.status}][StatusText=${error?.response?.statusText}][ErrorType=${error?.error?.name}]${extraTelemetryDataString}`;
    }
    // get error message for other error types
    return `[Exception=${ErrorUtilities.getMessage(error)}]`;
}
/**
 * Create a new OneDSSink
 * @param telemetryContext Telemetry Context
 * @param configProvider Config provider
 */ export function createNewOneDSSink(telemetryContext, configProvider) {
    const oneDSInitOptions = getOneDSInitOptions(telemetryContext, configProvider);
    return oneDSInitOptions ? createOneDSSink(oneDSInitOptions) : null;
}
