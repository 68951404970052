// Constants
import { HttpMethods, HttpHeaders, HttpContentType } from "@ms/uno-constants/lib/local/HttpConstants";
// Utilities
import chunk from "lodash/chunk";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import merge from "lodash/merge";
import { extractHeaders, extractLoggableHeaders } from "../utilities/HeadersUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { isGetRequest } from "../utilities/ServiceUtilities";
import { b64toBlob } from "@ms/uno-utilities/lib/local/BlobUtilities";
import { ResultTypeEnum } from "@ms/uno-telemetry/lib/local/events/ResultTypeEnum";
import { ApiEvent } from "@ms/uno-telemetry/lib/local/events/Api.event";
import { createQosEventName } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
/**
 * Ajax client that will batch graph requests with a small period of time.
 *
 * Batches request queue a for specific version (Beta or V10).
 */ export class GraphBatchQueueAjaxClient {
    executeRequest(url, options) {
        const batchRequestUrl = this.tryParseRequestBatchUrl(url);
        if (isGetRequest(options) && batchRequestUrl) {
            // batch only v1.0 or beta endpoint requests
            return this.queueNewRequest(batchRequestUrl, options);
        } else {
            // no batch
            return this.innerAjaxClient.executeRequest(url, options);
        }
    }
    queueNewRequest(batchRequestUrl, options) {
        return new Promise((resolve, reject)=>{
            const newRequest = {
                url: batchRequestUrl,
                options: options ? cloneDeep(options) : {},
                successCallback: resolve,
                failureCallback: reject,
                processed: false
            };
            this.requestsQueue.push(newRequest);
            this.startProcessingTimer();
        });
    }
    startProcessingTimer() {
        const { maxBatchSize, batchInterval } = this.configProvider().settings.serviceConfigurations.graph;
        // Starts timer to process the batches
        if (!this.timerRunning && this.requestsQueue.length > 0) {
            this.timerRunning = true;
            setTimeout(()=>{
                this.timerRunning = false;
                if (this.requestsQueue.length > 0) {
                    const batches = chunk(this.requestsQueue, maxBatchSize);
                    this.requestsQueue = []; // resets the queue, new requests will re-start the timer
                    for (const batch of batches){
                        this.executeBatch(batch);
                    } // trigger the batch request(s)
                }
            }, batchInterval);
        }
    }
    executeBatch(batch) {
        const url = `${this.configProvider().settings.serviceConfigurations.graph.hostname}/${this.endpointBaseUrl()}$batch`;
        const batchRequestData = {
            requests: batch.map((value, index)=>{
                const requestHeaders = new Headers(value.options.headers);
                const telemetryConfig = value.options.telemetryConfig;
                if (telemetryConfig) {
                    const apiEventExtraData = extractLoggableHeaders(requestHeaders, telemetryConfig?.requestHeadersToLog ?? []);
                    const apiEvent = new ApiEvent({
                        name: createQosEventName(telemetryConfig.apiName, telemetryConfig.methodName),
                        extraData: apiEventExtraData
                    }, this.loggers.logHandler);
                    value.qosEvent = apiEvent;
                }
                const headersRecord = extractHeaders(requestHeaders);
                return {
                    id: `${index + 1}`,
                    method: value.options.method ?? HttpMethods.Get,
                    url: value.url,
                    body: value.options.body,
                    headers: headersRecord
                };
            })
        };
        const batchName = "batchRequest";
        const batchOptions = {
            method: "POST",
            telemetryConfig: {
                apiName: this.endpointVersion,
                methodName: batchName
            },
            body: JSON.stringify(batchRequestData),
            headers: {
                [HttpHeaders.ContentType]: HttpContentType.Json
            }
        };
        this.innerAjaxClient.executeRequest(url, batchOptions).then((response)=>this.tryParseResponse(response)).then((result)=>{
            this.processBatchResultChain(batchName, batch, batchRequestData, result);
        }).catch((e)=>{
            this.failUnprocessedBatchRequests(batch, e);
        });
    }
    failUnprocessedBatchRequests(batch, error) {
        // reject all requests if main batch fails
        for (const request of batch){
            if (!request.processed) {
                request.processed = true;
                request.qosEvent?.end({
                    resultType: ResultTypeEnum.Failure,
                    resultCode: error.response ? String(error.response.status) : String(-1),
                    extraData: {
                        batchError: true
                    }
                });
                request.failureCallback(error);
            }
        }
    }
    /** Helper generic processor for batch responses that follows batch response chain */ async processBatchResultChain(name, batch, batchRequestData, result) {
        const errorResponse = result.data;
        const batchResponse = result.data;
        if (result.data == null) {
            const error = {
                response: result.response,
                error: new Error("undefined data")
            };
            this.failUnprocessedBatchRequests(batch, error);
            return;
        } else if (errorResponse.error) {
            // in case it results in graph error
            const error = {
                response: result.response,
                error: new Error("response error")
            };
            this.failUnprocessedBatchRequests(batch, error);
            return;
        } else {
            // First process responses
            for (const singleResponse of batchResponse.responses){
                if (singleResponse == null) {
                    // corrupt response
                    const error = {
                        response: result.response,
                        error: new Error("missing response")
                    };
                    this.failUnprocessedBatchRequests(batch, error);
                    return;
                }
                const batchIndex = findIndex(batchRequestData.requests, (value)=>value.id === singleResponse.id);
                if (batchIndex === -1) {
                    // cannot find response in request ids, must be a corrupt response, fail all remaining requests
                    const error = {
                        response: result.response,
                        error: new Error("invalid response")
                    };
                    this.failUnprocessedBatchRequests(batch, error);
                    return;
                } else {
                    const batchRequest = batch[batchIndex];
                    if (!batchRequest.processed) {
                        batchRequest.processed = true;
                        const extraData = extractLoggableHeaders(new Headers(singleResponse.headers), batchRequest.options.telemetryConfig?.responseHeadersToLog ?? []);
                        const apiEnd = {
                            resultType: ResultTypeEnum.Failure,
                            resultCode: String(singleResponse.status),
                            extraData: extraData
                        };
                        try {
                            const resultResponse = await this.createResponseAdapter(singleResponse, result.response);
                            if (singleResponse.status < 200 || singleResponse.status >= 300) {
                                const error = {
                                    response: resultResponse,
                                    error: this.generateErrorFromResponseWithError(singleResponse, "failed request")
                                };
                                batchRequest.failureCallback(error);
                            } else if (singleResponse.body?.error) {
                                const error = {
                                    response: resultResponse,
                                    error: this.generateErrorFromResponseWithError(singleResponse, "error in response")
                                };
                                batchRequest.failureCallback(error);
                            } else {
                                apiEnd.resultType = ResultTypeEnum.Success;
                                batchRequest.successCallback(resultResponse);
                            }
                        } catch (error) {
                            const failedRequest = {
                                response: result.response,
                                error
                            };
                            batchRequest.failureCallback(failedRequest);
                        }
                        batchRequest.qosEvent?.end(apiEnd);
                    }
                }
            }
            // Then follow up any next data
            const nextLink = batchResponse["@odata.nextLink"];
            const batchOptions = {
                method: "GET",
                telemetryConfig: {
                    apiName: this.endpointVersion,
                    methodName: name
                }
            };
            if (nextLink) {
                // fetch next Link and process its data
                this.innerAjaxClient.executeRequest(nextLink, batchOptions).then((response)=>this.tryParseResponse(response)).then((result)=>{
                    this.processBatchResultChain(name, batch, batchRequestData, result);
                }).catch((e)=>{
                    this.failUnprocessedBatchRequests(batch, e);
                });
            } else if (find(batch, (request)=>!request.processed)) {
                // No more data to fetch but there are still missing responses, fail any request without response
                const error = {
                    response: result.response,
                    error: new Error("missing responses")
                };
                this.failUnprocessedBatchRequests(batch, error);
            }
        }
    }
    /** Create new adapter for a graph batch response and merges some headers from appendHeaders list found in main $batch request */ async createResponseAdapter(response, batchResponse) {
        const responseHeaders = response.headers || {};
        const logHeaders = {};
        if (batchResponse?.headers) {
            for (const name of this.appendHeaders){
                const value = batchResponse.headers.get(name);
                if (value) {
                    logHeaders[name] = value;
                }
            }
        }
        const adapterHeaders = merge({}, logHeaders, responseHeaders);
        const resultHeaders = new Headers(adapterHeaders);
        const responseBody = await this.createResponseBody(response);
        const responseAdaptor = new Response(responseBody, {
            headers: resultHeaders,
            status: response.status
        });
        return responseAdaptor;
    }
    /** Parse the batch response */ async tryParseResponse(response) {
        try {
            const data = await response.json();
            const result = {
                response,
                data
            };
            return result;
        } catch (e) {
            this.loggers.traceLogger.logTrace(0x1e44a121 /* tag_4rke7 */ , TraceLevel.Warning, `Exception parsing response`);
            const result = {
                response,
                data: null
            };
            return result;
        }
    }
    /** Endpoint base url */ endpointBaseUrl() {
        return `${this.endpointVersion}/`;
    }
    /**
     * Tries to parse given request url in batch url (without endpoint).
     * Returns null if url is not supported for batch in this endpoint.
     */ tryParseRequestBatchUrl(url) {
        if (this.verifySupportedBatchUrl(url)) {
            const startIndex = url.indexOf(this.endpointBaseUrl()) + this.endpointBaseUrl().length;
            return url.substring(startIndex);
        } else {
            return null;
        }
    }
    /** returns TRUE if the given url is for same endpoint version as this queue batch handles and it is not already a batch request otherwise returns FALSE */ verifySupportedBatchUrl(url) {
        return url ? url.toLowerCase().indexOf(this.endpointBaseUrl()) > -1 && url.toLowerCase().indexOf(`${this.endpointBaseUrl()}$batch`) === -1 : false;
    }
    /**
     * Create a new response body for each response in the batch
     * @see http://docs.oasis-open.org/odata/odata-json-format/v4.01/odata-json-format-v4.01.html#sec_BatchRequest
     */ async createResponseBody(response) {
        const responseContentType = response.headers?.[HttpHeaders.ContentType];
        // The value of body can be null, which is equivalent to not specifying the body name/value pair
        if (response.body == null || responseContentType == null) {
            return response.body;
        }
        // For media type application/json or one of its subtypes, optionally with format parameters, the value of body is JSON.
        if (responseContentType.startsWith("application/json")) {
            return JSON.stringify(response.body);
        }
        // For media types of top-level type text, for example text/plain, the value of body is a string containing the value of the response body.
        if (responseContentType.startsWith("text/")) {
            return response.body;
        }
        // For all other media types the value of body is a string containing the base64url-encoded value of the request body
        // convert base64url to blob
        return await b64toBlob(response.body, responseContentType);
    }
    /**
     * Create an Error object from the given response
     * @param response IGraphResponseResource
     */ generateErrorFromResponseWithError(response, defaultMessage) {
        const error = new Error(response.body?.error?.message ?? defaultMessage);
        error.name = response.body?.error?.code;
        return error;
    }
    /**
     * Creates an instance of GraphBatchQueueAjaxClient.
     * @param ajaxClient Inner ajax helper to call
     * @param endpoint the MSGraph endpoint version
     * @param loggers Loggers for telemetry
     * @param [appendHeaders] Optional list of headers to be copied over from actual $batch call into the response headers
     * @param configProvider Provider for all configurations and settings
     */ constructor(ajaxClient, endpoint, loggers, appendHeaders = [], configProvider){
        this.loggers = loggers;
        this.configProvider = configProvider;
        this.requestsQueue = [];
        this.timerRunning = false;
        this.innerAjaxClient = ajaxClient;
        this.endpointVersion = endpoint;
        this.appendHeaders = clone(appendHeaders);
    }
}
