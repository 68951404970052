import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for ResourceStore, for use in the registry */ export const ProjectHomeResourceStoreActionTypes = [
    ActionType.CreateResourceItem,
    ActionType.CreateResourceItemFailed,
    ActionType.CreateResourceItemSucceeded,
    ActionType.DeleteResourceItem,
    ActionType.DeleteResourceItemFailed,
    ActionType.DeleteResourceItemSucceeded,
    ActionType.FetchAttachedResourceItemsForPlan,
    ActionType.FetchAttachedResourceItemsForPlanFailed,
    ActionType.FetchAttachedResourceItemsForPlanSucceeded,
    ActionType.FetchRecentResourceItemsForPlan,
    ActionType.FetchRecentResourceItemsForPlanFailed,
    ActionType.FetchRecentResourceItemsForPlanSucceeded,
    ActionType.InitializeEsdkForResourcesSucceeded
];
