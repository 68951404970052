import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for CapabilitiesStore, for use in the registry */ export const CapabilitiesStoreActionTypes = [
    ActionType.FetchIsPlannerPremiumEnabledFailedAction,
    ActionType.FetchIsPlannerPremiumEnabledSucceededAction,
    ActionType.FetchIsPortfolioEnabledFailedAction,
    ActionType.FetchIsPortfolioEnabledSucceededAction,
    ActionType.FetchTodoCapabilities,
    ActionType.FetchTodoCapabilitiesSucceeded,
    ActionType.FetchTodoCapabilitiesFailed,
    ActionType.FetchUserPublishingEligibilityFailedAction,
    ActionType.FetchUserPublishingEligibilitySucceededAction
];
