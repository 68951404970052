export const LocalTempIdPrefix = "LOCALTID";
export class LocalIdGenerator {
    /**
     * Generate a new locally unique Id for the client's temporary use
     */ static generate() {
        LocalIdGenerator.counter++;
        return LocalTempIdPrefix + LocalIdGenerator.counter.toString();
    }
    /**
     * Check if the given id is a temporary id generated in the client
     * @param id Id to be checked
     */ static isLocalTempId(id) {
        if (id == null) {
            return false;
        }
        return id.indexOf(LocalTempIdPrefix) > -1;
    }
    /**
     * This method exists for the unit tests so that they can test id generation
     * Don't call this unless it's for a test!
     */ static resetCounterOnlyForTest() {
        LocalIdGenerator.counter = 0;
    }
}
LocalIdGenerator.counter = 0;
