import { makeStringDataField } from "@microsoft/oteljs";
import { OneDSSink } from "@microsoft/oteljs-1ds";
/**
 * Create a OneDSSink
 * @param options Options object containing all relevant info needed
 */ export function createOneDSSink(options) {
    // Define core UTel values for the sink - user, app, session, and optionally release
    const app = {
        platform: options.appPlatform,
        name: options.appName,
        version: options.appVersion
    };
    const session = {
        id: options.sessionId
    };
    const user = options.user;
    const coreFields = {
        app: app,
        user: user,
        session: session,
        release: {
            audienceGroup: options.release?.audienceGroup
        }
    };
    // Any additional data fields that need to be sent with every event gets set here.
    // The names of these data fields don't automatically get prefixed with "Data."
    // Generally they should be Type A data fields as in http://aka.ms/officeeventschema.
    const persistentDataFields = options.persistentDataFields ?? [];
    persistentDataFields.push(makeStringDataField("Culture.UiLanguage", options.appLanguage));
    options.release?.audience && persistentDataFields.push(makeStringDataField("Release.Audience", options.release.audience));
    const oneDsSink = new OneDSSink(persistentDataFields, {
        endpointUrl: options.user.environment,
        coreFields: coreFields
    });
    // Init() validates the required core fields on the OneDSSink.
    // If the fields are invalid, the sink is disabled and should not be added.
    const success = oneDsSink.init();
    if (!success) {
        oneDsSink.shutdown();
        return null;
    }
    return oneDsSink;
}
