/**
 * Convert a base64 string to a blob
 * @see https://github.com/microsoftgraph/msgraph-sdk-javascript/blob/dev/docs/content/Batching.md
 * @param b64Data base64 string
 * @param contentType Content type (e.g image/jpeg)
 * @param sliceSize [optional] slice size
 */ export async function b64toBlob(b64Data, contentType, sliceSize) {
    sliceSize = sliceSize || 512;
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for(let offset = 0; offset < byteCharacters.length; offset += sliceSize){
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for(let i = 0; i < slice.length; i++){
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
        type: contentType
    });
    return blob;
}
