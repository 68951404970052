import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
export class BeforeUnload {
    static init() {
        if (!this._initialized) {
            this._initialized = true;
            // Wire up before unload
            this._originalBeforeUnload = window.onbeforeunload;
            window.onbeforeunload = window["__onbeforeunload"] = (ev)=>{
                return this.handleBeforeUnload(ev);
            };
        }
    }
    static registerHandler(callback) {
        this._handlers.push(callback);
    }
    static handleBeforeUnload(ev) {
        let response;
        for(let x = 0; x < this._handlers.length; x++){
            try {
                response = this._handlers[x](true);
                if (response) {
                    break;
                }
            } catch (e) {
                throw new Error(ErrorUtilities.getMessage(e));
            }
        }
        if (!response && this._originalBeforeUnload) {
            try {
                response = this._originalBeforeUnload(ev);
            } catch (e) {
                throw new Error(ErrorUtilities.getMessage(e));
            }
        }
        // Make sure the response is undefined so ie will not block
        return response ? response : undefined;
    }
}
BeforeUnload._handlers = [];
BeforeUnload._initialized = false;
