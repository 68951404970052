// Actions
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
// Constants
import { StoreEntityStatus } from "@ms/uno-constants/lib/local/StoreConstants";
// Stores
import { EventPublishingStore } from "@ms/uno-fluxcore/lib/local/stores/EventPublishingStore";
import { logUnreachableAction } from "../../utilities/LoggingUtilities";
/**
 * Store containing the access tokens and user id information
 */ export class AuthStore extends EventPublishingStore {
    get name() {
        return "AuthStore";
    }
    handleAction(action) {
        let storeChanged = false;
        switch(action.type){
            case ActionType.ClearAccessToken:
                storeChanged = this.handleClearAccessTokenAction(action);
                break;
            case ActionType.FetchAccessTokenSucceeded:
                storeChanged = this.handleFetchAccessTokenSucceededAction(action);
                break;
            case ActionType.FetchAccessToken:
                storeChanged = this.handleFetchAccessTokenAction(action);
                break;
            case ActionType.FetchAccessTokenFailed:
                storeChanged = this.handleFetchAccessTokenFailedAction(action);
                break;
            default:
                logUnreachableAction(this, this.loggers, action);
                return;
        }
        if (storeChanged) {
            this.publishStoreChangedEvent();
        }
    }
    getIdTokenDetails() {
        // TODO PBI 7728460 - implement this
        throw new Error("Method not implemented.");
    }
    /**
     * Get the accessToken currently stored for a resource and claims
     * @param resource
     * @param claims
     */ getAccessToken(resource, claims) {
        // TODO Append if we will have multiple tokens for a resource based on claims in future
        const resourceToken = this.resourceToken[resource];
        const resourceTokenExpiry = this.resourceTokenExpiry[resource];
        const status = this.tokenFetchingStatus[resource] ?? StoreEntityStatus.Unfetched;
        if (!resourceToken) {
            return {
                data: null,
                status: status
            };
        }
        return {
            data: {
                accessToken: resourceToken,
                expiry: resourceTokenExpiry
            },
            status: status
        };
    }
    handleFetchAccessTokenSucceededAction(action) {
        // Update the stored Access token for the resource.
        this.resourceToken[action.resource] = action.accessToken;
        this.resourceTokenExpiry[action.resource] = action.expiry;
        // Update status
        this.tokenFetchingStatus[action.resource] = StoreEntityStatus.Present;
        return true;
    }
    handleFetchAccessTokenAction(action) {
        if (this.tokenFetchingStatus[action.resource] === StoreEntityStatus.Fetching) {
            return false;
        }
        this.tokenFetchingStatus[action.resource] = StoreEntityStatus.Fetching;
        return true;
    }
    handleFetchAccessTokenFailedAction(action) {
        if (this.tokenFetchingStatus[action.resource] === StoreEntityStatus.UnableToFetch) {
            return false;
        }
        this.tokenFetchingStatus[action.resource] = StoreEntityStatus.UnableToFetch;
        return true;
    }
    handleClearAccessTokenAction(action) {
        const storeCopy = this.resourceToken[action.resource];
        const storeStatus = this.tokenFetchingStatus[action.resource];
        if (storeCopy == null || storeStatus === StoreEntityStatus.Unfetched) {
            return false;
        }
        // Clear cached token if any
        delete this.resourceToken[action.resource];
        delete this.resourceTokenExpiry[action.resource];
        this.tokenFetchingStatus[action.resource] = StoreEntityStatus.Unfetched;
        return true;
    }
    constructor(loggers){
        super(), this.loggers = loggers, this.resourceToken = {}, this.resourceTokenExpiry = {}, this.tokenFetchingStatus = {};
    }
}
