// Authentication
import { BrowserAuthError, BrowserAuthErrorCodes, BrowserCacheLocation, EventMessageUtils, EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType, PublicClientApplication } from "@azure/msal-browser-1p";
import { AzureCloudInstance, Logger, LogLevel, PromptValue } from "@azure/msal-common-uno";
// Constants
import { Environment, TenantRegion } from "@ms/uno-constants/lib/local/AppConstants";
import { AuthTokenClaims, GuestUserAcctClaimValue, TenantRegionSubScope } from "@ms/uno-constants/lib/local/AuthConstants";
// Errors
import { NoAccountAuthError } from "./NoAccountAuthError";
// Telemetry
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
import { retry } from "../../RetryUtilities";
export var MsalCacheLocation;
(function(MsalCacheLocation) {
    /**
     * Local storage on browser. Share login across browser tabs.
     */ MsalCacheLocation[MsalCacheLocation["LocalStorage"] = 0] = "LocalStorage";
    /**
     * Session storage on browser.
     */ MsalCacheLocation[MsalCacheLocation["SessionStorage"] = 1] = "SessionStorage";
    /**
     * Memomry storage. Not shared across browser tabs and sessions.
     */ MsalCacheLocation[MsalCacheLocation["MemoryStorage"] = 2] = "MemoryStorage";
})(MsalCacheLocation || (MsalCacheLocation = {}));
var PromptType;
/**
 * Helper for authentication using msal.js
 */ export class MsalHelper {
    get msalObject() {
        if (this.msalPca == null) {
            throw new Error("MsalHelper instance is not initialized.");
        }
        return this.msalPca;
    }
    /**
     * Initialize Msal Object
     */ static async initializeMsal(msalConfig, matsConfiguration, loggers, correlationId) {
        try {
            const msalClient = await PublicClientApplication.createPublicClientApplication(msalConfig, matsConfiguration, correlationId);
            return msalClient;
        } catch (error) {
            loggers.traceLogger.logTrace(0x1e318657 /* tag_4myzx */ , TraceLevel.Error, MsalHelper.constructErrorMessage({
                error,
                hasClaims: false
            }));
            throw error;
        }
    }
    /**
     * Initialize Msal Helper
     * @param msalConfigSettings The partial configuration settings to construct the MSAL object
     * @param msalHelperOptions Options for the MsalHelper
     * @param [loggers] logger to send logs
     */ static async initialize(msalConfigSettings, msalHelperOptions, loggers) {
        if (MsalHelper._instance != null) {
            throw new Error("MsalHelper instance is not null. Don't initialize more than once.");
        }
        const aadAuthorityHost = MsalHelper.getAuthorityHost(msalConfigSettings.environment, loggers);
        const msalConfig = MsalHelper.createMsalConfig(msalConfigSettings, loggers);
        const matsConfiguration = MsalHelper.createMatsConfiguration(msalConfigSettings, loggers);
        const msalObject = await MsalHelper.initializeMsal(msalConfig, matsConfiguration, loggers, msalConfigSettings.correlationId);
        const redirectUri = msalConfigSettings.redirectUrl.toString();
        const silentRedirectUri = msalConfigSettings.silentRedirectUrl.toString();
        // create a MSALHelper Instance
        MsalHelper._instance = new MsalHelper(aadAuthorityHost, msalConfigSettings.clientId, msalObject, redirectUri, silentRedirectUri, msalHelperOptions, loggers);
    }
    /**
     * Create the MATs configuration so we log to MATs telemetry
     * @param msalConfigSettings The partial configuration settings to construct the MSAL object
     * @param loggers The logger
     */ static createMatsConfiguration(msalConfigSettings, loggers) {
        const { clientName, clientVersion } = msalConfigSettings;
        if (clientName === "" || clientVersion === "") {
            const errorMessage = "MsalHelper: clientName and clientVersion must be provided in msalConfigSettings";
            loggers.traceLogger.logTrace(0x1e2df707 /* tag_4l52h */ , TraceLevel.Error, errorMessage);
            throw new Error(errorMessage);
        }
        return {
            appName: clientName,
            appVersion: clientVersion,
            enableMATS: true
        };
    }
    /**
     * Get AuthorityHost based on environment
     * @param environment Environment
     * @returns Authority host
     */ static getAuthorityHost(environment, loggers) {
        switch(environment){
            case Environment.Dod:
            case Environment.Gcch:
            case Environment.Agc:
                return AzureCloudInstance.AzureUsGovernment;
            case Environment.Ppe:
            case Environment.Prod:
            case Environment.Gcc:
                return AzureCloudInstance.AzurePublic;
            default:
                ErrorUtilities.unreachableCase(environment, (log)=>{
                    loggers.traceLogger.logTrace(0x1e315660 /* tag_4mvz6 */ , TraceLevel.Warning, log);
                });
                return AzureCloudInstance.None;
        }
    }
    /**
     * Creates the Msal configuration object
     * @param msalConfigSettings The partial configuration settings to construct the MSAL object
     * @param [loggers] logger to send logs
     * @returns Msal Configuration object for the app
     */ static createMsalConfig(msalConfigSettings, loggers) {
        const { clientId, msalCacheLocation, postLogoutRedirectUrl, correlationId } = msalConfigSettings;
        const postLogoutRedirectUri = postLogoutRedirectUrl.toString();
        const cacheLocation = MsalHelper.getCacheLocationName(msalCacheLocation, loggers);
        // cp1 is the capability for CAE
        const clientCapabilities = [
            "cp1"
        ];
        return {
            auth: {
                clientId,
                postLogoutRedirectUri,
                clientCapabilities
            },
            cache: {
                cacheLocation
            },
            system: {
                loggerOptions: MsalHelper.createLoggerOptions(loggers, correlationId)
            }
        };
    }
    /**
     * Returns the cacheLocation name for the MsalCacheLocation
     * @param cacheLocation The MsalCacheLocation
     * @param loggers The loggers object
     */ static getCacheLocationName(cacheLocation, loggers) {
        switch(cacheLocation){
            case 0:
                return BrowserCacheLocation.LocalStorage;
            case 1:
                return BrowserCacheLocation.SessionStorage;
            case 2:
                return BrowserCacheLocation.MemoryStorage;
            default:
                ErrorUtilities.unreachableCase(cacheLocation, (log)=>{
                    loggers.traceLogger.logTrace(0x1e311613 /* tag_4mryt */ , TraceLevel.Warning, log);
                });
                return BrowserCacheLocation.LocalStorage;
        }
    }
    /**
     * Create logger options for msal log callback
     * @param loggers The ILoggerContext
     * @param correlationId The correlationId for MATS
     * @returns LoggerOptions object
     */ static createLoggerOptions(loggers, correlationId) {
        return {
            logLevel: LogLevel.Verbose,
            loggerCallback: (...args)=>MsalHelper.msalLoggerCallback(...args, loggers),
            piiLoggingEnabled: false,
            correlationId
        };
    }
    /**
     * Get the instance or throw if it doesn't exist yet
     * @returns Instance of MsalHelper or throws if it hasn't been initialized with initializeMsal
     */ static get instance() {
        if (MsalHelper._instance == null) {
            throw new Error("Cannot retrieve instance as Msal helper is uninitialized");
        }
        return MsalHelper._instance;
    }
    /**
     * Handle redirect response and attempt login
     * @param response The authentication result from a redirect. Can be null if not called during a redirect.
     * @param params Parameters to login the user
     */ async handleRedirect(response, params) {
        const { forceLogin, userId, tenantId, loginHint, ensureActiveAccount, claimsChallenge } = params || {};
        const forceLoginOrClaimsChallenge = forceLogin || claimsChallenge;
        if (response) {
            const responseAccount = response.account;
            // Should not have forceLogin or claimsChallenge set when called back from handleRedirectPromise
            if (forceLoginOrClaimsChallenge) {
                this.loggers.traceLogger.logTrace(0x1e2e0097 /* tag_4l6cx */ , TraceLevel.Warning, `Force login or claims challenge should not be set when called back from handleRedirectPromise.[ForceLogin=${forceLogin}][HasClaimsChallenge=${claimsChallenge ? "true" : "false"}]`);
            }
            if (responseAccount) {
                this.msalObject.setActiveAccount(responseAccount);
                if (!forceLoginOrClaimsChallenge) {
                    return;
                }
            } else if (!forceLoginOrClaimsChallenge) {
                throw new NoAccountAuthError(response.requestId);
            }
        }
        // When forceLogin is true or there is a claims challenge, we skip using current cached user and force the user to login
        if (!forceLoginOrClaimsChallenge) {
            if (!ensureActiveAccount && this.hasActiveAccount()) {
                return;
            }
            if (ensureActiveAccount && userId && tenantId && this.validateActiveAccount(userId, tenantId)) {
                // If there is an cached active account then we know the user is already logged-in and no further action is needed.
                return;
            }
        }
        await this.attemptLogin(forceLogin, loginHint, tenantId, userId, claimsChallenge);
    }
    /**
     * Evaluates if there is an active account present
     * @returns True if there is an active account
     */ hasActiveAccount() {
        const account = this.msalObject.getActiveAccount();
        return account !== null;
    }
    /**
     * Validate that an active account exists and/or the specified userId/tenantId
     * @param userId Expected userId
     * @param tenantId Expected tenantId
     * @returns True if specified user is logged in
     */ validateActiveAccount(userId, tenantId) {
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            return false;
        }
        if (userId && tenantId && userId === account.localAccountId && tenantId === account.tenantId) {
            return true;
        }
        return false;
    }
    // #region ensure logged in utilities
    /**
     * Ensure user is logged with with extended properties.
     * 1. We check if we are able to log the user in using loginHint if it is specified.
     * 2. If the user has never logged into uno web, we try sso.
     * 3. If userId/tenantId is specified we filter using them. If an account exists, use it to login.
     * 4. If there is more than 1 account show the account selector, otherwise show the login username page.
     * @param forceLogin Skips using current cached user and forces the user to login
     * @param loginHint Param to be used as login_hint. It is usually the user's upn/email.
     * @param tenantId The tenantId to be used for login
     * @param userId The expected userId trying to login, used to validate account after login
     * @param claimsChallenge Claims for getting the token
     */ async attemptLogin(forceLogin, loginHint, tenantId, userId, claimsChallenge) {
        if (forceLogin || claimsChallenge) {
            await this.login({
                promptType: 0,
                tenantId,
                loginHint,
                claimsChallenge
            });
            return;
        }
        const accounts = this.msalObject.getAllAccounts();
        if (loginHint) {
            // Login hint is usually the user's upn. If we have loginHint, we use the loginRedirect
            // with loginHint specified. This ensures login succeeds on Chrome when SSO extension is not enabled.
            await this.login({
                promptType: forceLogin ? 0 : 2,
                tenantId,
                loginHint
            });
        } else if (!accounts || accounts.length === 0) {
            const ssoRequest = {
                scopes: this.getDefaultAppScopes(),
                authority: this.getAuthorityUrl(tenantId),
                redirectUri: this.silentRedirectUri
            };
            const ssoResponse = await this.msalObject.ssoSilent(ssoRequest);
            return this.handleSilentLoginSuccess(ssoResponse);
        } else {
            let account = this.msalObject.getActiveAccount();
            // If we are signed in to a different tenant, sign out and sign in to the correct tenant
            if (account && tenantId && account.tenantId !== tenantId) {
                await this.logout();
                return;
            }
            if (userId || tenantId || loginHint) {
                // Check if an account already exists for the tenantId or userId if it is provided
                account = this.msalObject.getAccount({
                    tenantId: tenantId,
                    localAccountId: userId,
                    loginHint
                });
            }
            if (account) {
                await this.attemptSilentLogin(this.getDefaultAppScopes(), account);
            } else {
                await this.login({
                    promptType: accounts.length > 1 ? 1 : forceLogin ? 0 : 2,
                    tenantId
                });
            }
        }
    }
    /**
     * Default authority with current tenantId
     * @param tenantId The tenantId to login to
     * @returns the authority url
     */ getAuthorityUrl(tenantId) {
        if (tenantId && this.aadAuthorityHost !== AzureCloudInstance.None) {
            return `${this.aadAuthorityHost}/${tenantId}`;
        }
        // Using undefined will use the default authority
        return undefined;
    }
    /**
     * Set the active account after a successful slient login
     * @param response The response from a silent login
     */ handleSilentLoginSuccess(response) {
        if (response) {
            this.msalObject.setActiveAccount(response.account);
        } else {
            this.loggers.traceLogger.logTrace(0x1e31761b /* tag_4mxy1 */ , TraceLevel.Warning, "Received null response for silent login.");
            const error = new InteractionRequiredAuthError("NoActiveAccountAfterSilentLogin", "No active account found after silent login.");
            throw error;
        }
    }
    // #region ensure logged in utilities
    /**
     * Function which ensures the user is either logged in or initiates the
     * login flow in other cases.
     * @param params ILoginParameters object
     */ async ensureLoggedIn(params) {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
        // Note: If you are calling loginRedirect or acquireTokenRedirect from a page that is not your redirectUri you will need to ensure
        // handleRedirectPromise is called and awaited on both the redirectUri page as well as the page that you initiated the redirect from.
        // This is because the redirectUri page will initiate a redirect back to the page that originally invoked loginRedirect and that page
        // will process the token response.
        try {
            // handleRedirectPromise should only be called once during bootstrap login otherwise there is a case that is gets stuck throwing error for "login_required"
            const isBootstrapLogin = !(params.forceLogin && this.options.handleInteractionRequiredOnSilentLogin);
            const response = isBootstrapLogin ? await this.msalObject.handleRedirectPromise() : null;
            await this.handleRedirect(response, params);
            if (!this.hasActiveAccount()) {
                throw new InteractionRequiredAuthError("NoActiveAccountAfterLogin", "No active account found after login.");
            }
            const msalActiveAccount = this.msalObject.getActiveAccount();
            const activeAccount = this.getActiveAccount();
            const { tenant_region_scope, tenant_region_sub_scope, xms_tdbr } = msalActiveAccount?.idTokenClaims ?? {};
            this.loggers.traceLogger.logTrace(0x1e2e204d /* tag_4l8bn */ , TraceLevel.Info, `ensureLoggedIn done [isGuest=${activeAccount?.isGuest}][telemetryRegion=${activeAccount?.telemetryRegion}][accountEnvironment=${activeAccount?.accountEnvironment}][tenantRegion=${tenant_region_scope}][tenantRegionSubScope=${tenant_region_sub_scope}][telemetryDataBoundary=${xms_tdbr}]`);
        } catch (error) {
            const hasClaims = params.claimsChallenge ? true : false;
            this.loggers.traceLogger.logTrace(0x1e2e004f /* tag_4l6bp */ , TraceLevel.Warning, MsalHelper.constructErrorMessage({
                error,
                hasClaims
            }));
            throw error;
        }
    }
    // #endregion
    /**
     * Gets the active account from MSAL Cache
     * @returns { IUserAccount | null } IUserAccount if there is an active account, null otherwise
     */ getActiveAccount() {
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            return null;
        }
        let isGuest = false;
        let telemetryRegion = TenantRegion.Row;
        let accountEnvironment = Environment.Prod;
        const { idTokenClaims } = account;
        if (idTokenClaims) {
            if (idTokenClaims[AuthTokenClaims.TelemetryDataBoundary] === TenantRegion.Eu) {
                telemetryRegion = TenantRegion.Eu;
            }
            // User is a guest if the homeAccountId does not contain the tenantId
            // and the idp claim is present in idTokenClaims
            // or claim 'acct' is set to 1.
            if (idTokenClaims.idp) {
                isGuest = !account.homeAccountId.includes(account.tenantId);
            }
            if (idTokenClaims[AuthTokenClaims.Acct] === GuestUserAcctClaimValue) {
                isGuest = true;
            }
            const tenant_region_sub_scope = idTokenClaims.tenant_region_sub_scope;
            if (tenant_region_sub_scope === TenantRegionSubScope.GCC) {
                accountEnvironment = Environment.Gcc;
            } else if (tenant_region_sub_scope === TenantRegionSubScope.DODCON) {
                accountEnvironment = Environment.Gcch;
            } else if (tenant_region_sub_scope === TenantRegionSubScope.DOD) {
                accountEnvironment = Environment.Dod;
            }
        }
        return {
            userId: account.localAccountId,
            tenantId: account.tenantId,
            loginHint: account.username,
            displayName: account.name,
            isGuest,
            telemetryRegion,
            accountEnvironment
        };
    }
    /**
     * Reset the logger after the user has been logged in
     * @param loggers The logger
     * @param appName App name
     * @param clientVersion client version
     * @param correlationId correlationId for MATS
     */ setLogger(loggers, appName, clientVersion, correlationId) {
        this.loggers = loggers;
        const logger = new Logger(MsalHelper.createLoggerOptions(loggers, correlationId), appName, clientVersion);
        this.msalObject.setLogger(logger);
    }
    /**
     * Stop the Msal logger
     */ stopLogger() {
        // Set an empty logger which will not log anything
        const emptyLogger = new Logger({});
        this.msalObject.setLogger(emptyLogger);
    }
    // #region Token Related Function
    /**
     * Try to login in silently. This function will throw an exception on failure.
     * @param scopes Scopes for the endpoint
     * @param [account] Account info object
     * @param [claimsChallenge] Claims for getting the token
     */ async attemptSilentLogin(scopes, account, claimsChallenge) {
        const claims = this.getClaimsParameter(claimsChallenge);
        const authority = this.getAuthorityUrl(account?.tenantId);
        const request = {
            account,
            scopes,
            claims,
            authority,
            redirectUri: this.silentRedirectUri
        };
        try {
            const response = await this.msalObject.acquireTokenSilent(request);
            return this.handleSilentLoginSuccess(response);
        } catch (acquireTokenSilentError) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e29a410 /* tag_4k0qq */ , TraceLevel.Warning, MsalHelper.constructErrorMessage({
                error: acquireTokenSilentError,
                hasClaims,
                scopes
            }));
            if (acquireTokenSilentError instanceof InteractionRequiredAuthError && this.options.handleInteractionRequiredOnSilentLogin) {
                // During bootstrap user login we retry with redirect as it is more reliable than popup
                await this.login({
                    promptType: 0,
                    account,
                    scopes,
                    claimsChallenge
                });
            } else {
                throw acquireTokenSilentError;
            }
        }
    }
    /**
     * Acquire the token for a set of scopes and return it via promise
     * @param scopes Scopes for the endpoint
     * @param [claimsChallenge] Claims for getting the token in base64 encoded string
     * @returns Promise with access token
     */ async getAccessToken(scopes, claimsChallenge) {
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            this.loggers.traceLogger.logTrace(0x1e2da284 /* tag_4l0ke */ , TraceLevel.Warning, "There is no account to get the token. Please ensure the user is logged in.");
        // Don't throw an error here as msal will throw the error below.
        // We have an event listened to handle the msal error.
        }
        const claims = this.getClaimsParameter(claimsChallenge);
        const authority = this.getAuthorityUrl(account?.tenantId);
        const request = {
            account: account ?? undefined,
            scopes,
            claims,
            authority,
            redirectUri: this.silentRedirectUri
        };
        try {
            const response = await this.msalObject.acquireTokenSilent(request);
            return {
                accessToken: response.accessToken,
                expiry: response.expiresOn
            };
        } catch (acquireTokenSilentError) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e2e004e /* tag_4l6bo */ , TraceLevel.Warning, MsalHelper.constructErrorMessage({
                error: acquireTokenSilentError,
                hasClaims,
                scopes
            }));
            if (acquireTokenSilentError instanceof InteractionRequiredAuthError && this.options.handleInteractionRequiredOnSilentLogin) {
                return await this.acquireTokenPopup(scopes, claims);
            }
            return Promise.reject(acquireTokenSilentError);
        }
    }
    /**
     * Acquire the token for the set of specified scopes and return it via promise
     * @param scopes scopes for the endpoint
     * @param [claims] Claims for getting the token
     * @returns Promise with access token
     */ async acquireTokenPopup(scopes, claims) {
        if (!this.options.useCachedPromises) {
            return this.acquireTokenPopupInternal(scopes, claims);
        }
        // Return promise if already cached
        const key = JSON.stringify({
            scopes,
            claims
        });
        const cachedTokenPromise = this.cachedTokenPromises[key];
        if (cachedTokenPromise != null) {
            return cachedTokenPromise;
        }
        // Make new call to acquireTokenPopup and cache the promise
        const tokenPromise = this.acquireTokenPopupInternal(scopes, claims);
        this.cachedTokenPromises[key] = tokenPromise;
        try {
            const token = await tokenPromise;
            delete this.cachedTokenPromises[key];
            return token;
        } catch (error) {
            delete this.cachedTokenPromises[key];
            return Promise.reject(error);
        }
    }
    /**
     * Acquire token using popup
     * @param scopes scopes for the endpoint
     * @param claimsChallenge Base64 encoded claims for getting the token
     */ async acquireTokenPopupInternal(scopes, claimsChallenge) {
        const account = this.msalObject.getActiveAccount();
        const claims = this.getClaimsParameter(claimsChallenge);
        const authority = this.getAuthorityUrl(account?.tenantId);
        const popupRequest = {
            account: account !== null ? account : undefined,
            scopes,
            claims,
            authority,
            redirectUri: this.redirectUri
        };
        try {
            const response = await this.msalObject.acquireTokenPopup(popupRequest);
            return {
                accessToken: response.accessToken,
                expiry: response.expiresOn
            };
        } catch (error) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e2e004d /* tag_4l6bn */ , TraceLevel.Warning, MsalHelper.constructErrorMessage({
                error,
                hasClaims,
                scopes
            }));
            throw error;
        }
    }
    // #endregion
    /**
     * Login redirect to the app
     * @param params Parameters to login
     */ async login(params) {
        const { promptType, tenantId, loginHint, claimsChallenge, scopes = this.getDefaultAppScopes(), account } = params;
        const prompt = this.getPromptNameFromType(promptType);
        const claims = this.getClaimsParameter(claimsChallenge);
        const loginRequest = {
            scopes,
            prompt,
            loginHint,
            authority: this.getAuthorityUrl(tenantId),
            claims,
            account,
            redirectUri: this.redirectUri
        };
        try {
            // For non-iframe scenarios redirect the user for login
            if (!this.isInIframe) {
                return await this.msalObject.loginRedirect(loginRequest);
            }
            // Otherwise, use a popup based login
            const popupResponse = await this.msalObject.loginPopup(loginRequest);
            const responseAccount = popupResponse?.account;
            if (responseAccount) {
                // Set the account as active
                this.msalObject.setActiveAccount(responseAccount);
            } else {
                throw new NoAccountAuthError(popupResponse.requestId);
            }
        } catch (error) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e2e004c /* tag_4l6bm */ , TraceLevel.Warning, MsalHelper.constructErrorMessage({
                error,
                hasClaims,
                scopes
            }));
            throw error;
        }
    }
    /**
     * Decode the claims challenge parameter if it is base64 encoded
     * The documentation for CAE says that the claims challenge parameter should be base64 encoded
     * We need to decode it before passing it to the acquireTokenSilent method
     * https://learn.microsoft.com/en-us/entra/identity-platform/app-resilience-continuous-access-evaluation?tabs=JavaScript
     * https://learn.microsoft.com/en-us/entra/identity-platform/claims-challenge?tabs=dotnet#claims-request
     * @param claimsChallenge Claims challenge parameter
     */ getClaimsParameter(claimsChallenge) {
        if (!claimsChallenge) {
            return undefined;
        }
        try {
            JSON.parse(claimsChallenge);
            this.loggers.traceLogger.logTrace(0x1e3075a0 /* tag_4mhw6 */ , TraceLevel.Info, "Claims challenge parameter is not base64 encoded.");
            return claimsChallenge;
        } catch  {
            this.loggers.traceLogger.logTrace(0x1e30759f /* tag_4mhw5 */ , TraceLevel.Info, "Claims challenge parameter is base64 encoded.");
            // The documentation for CAE says that the claims challenge parameter should be base64 encoded
            // We need to decode it before passing it to the acquireTokenSilent method
            return window.atob(claimsChallenge);
        }
    }
    getPromptNameFromType(prompt) {
        switch(prompt){
            case 0:
                return PromptValue.LOGIN;
            case 1:
                return PromptValue.SELECT_ACCOUNT;
            case 2:
                return PromptValue.NONE;
            default:
                ErrorUtilities.unreachableCase(prompt, (log)=>{
                    this.loggers.traceLogger.logTrace(0x1e311612 /* tag_4mrys */ , TraceLevel.Warning, log);
                });
                return PromptValue.NONE;
        }
    }
    /**
     * Helper function to logout the active account
     * @param postLogoutRedirectUrl Redirect URL after logout
     */ async logout(postLogoutRedirectUrl) {
        const account = this.msalObject.getActiveAccount();
        if (!account) {
            return;
        }
        const logoutRequest = {
            account,
            postLogoutRedirectUri: postLogoutRedirectUrl?.toString() ?? undefined
        };
        try {
            await this.msalObject.setActiveAccount(null);
            if (this.isInIframe) {
                await this.msalObject.logoutPopup(logoutRequest);
            } else {
                await this.msalObject.logoutRedirect(logoutRequest);
            }
        } catch (error) {
            this.loggers.traceLogger.logTrace(0x1e2e004b /* tag_4l6bl */ , TraceLevel.Warning, MsalHelper.constructErrorMessage({
                error,
                hasClaims: false
            }));
            throw error;
        }
    }
    /**
     * Register a function to be called when an interaction required error occurs
     * @param callback The function to be called when an interaction required error occurs
     */ registerInteractionRequiredErrorHandler(callback) {
        this.msalObject.addEventCallback((message)=>{
            const { eventType, error, interactionType } = message;
            const isInteractionRequiredTypeEvent = eventType === EventType.LOGIN_FAILURE || eventType === EventType.ACQUIRE_TOKEN_FAILURE || eventType === EventType.SSO_SILENT_FAILURE;
            if (!isInteractionRequiredTypeEvent) {
                return;
            }
            const isGetAccessTokenSilentFailure = eventType === EventType.ACQUIRE_TOKEN_FAILURE && interactionType === InteractionType.Silent;
            if (this.options.handleInteractionRequiredOnSilentLogin && isGetAccessTokenSilentFailure) {
                // if enableSilentSignInPopup is set then the acquire token failure is handled during getAccessToken
                return;
            }
            if (this.options.handleInteractionRequiredOnSilentLogin && eventType === EventType.ACQUIRE_TOKEN_FAILURE && interactionType === InteractionType.Popup && error instanceof BrowserAuthError && error.errorCode === BrowserAuthErrorCodes.popupWindowError) {
                const status = EventMessageUtils.getInteractionStatusFromEvent(message);
                if (status === null || status === InteractionStatus.None) {
                    this.loggers.traceLogger.logTrace(0x1e29a387 /* tag_4k0oh */ , TraceLevel.Warning, `[Msal] popup blocked. ${MsalHelper.constructErrorMessage({
                        error,
                        hasClaims: false
                    })}`);
                    callback(/* claims */ "", /* isPopupBlockedError*/ true);
                    return;
                }
            }
            if (error instanceof InteractionRequiredAuthError) {
                const status = EventMessageUtils.getInteractionStatusFromEvent(message);
                if (status === null || status === InteractionStatus.None) {
                    const claimsChallenge = error?.claims;
                    this.loggers.traceLogger.logTrace(0x1e307203 /* tag_4mhid */ , TraceLevel.Info, `[Msal] User interaction required to login. ${MsalHelper.constructErrorMessage({
                        error,
                        hasClaims: !!claimsChallenge
                    })}`);
                    callback(/* claims */ claimsChallenge, /* isPopupBlockedError*/ false);
                }
            }
        });
    }
    async prefetchAndEvaluateConditionalAccess(prefetchScopes) {
        // We will fetch main scopes and evaluate conditional access policies with login redirect
        // We can avoid acquire token with popup and use redirect since we are loading the app and popup might be blocked by browsers by default
        for (const scope of prefetchScopes){
            try {
                await this.attemptSilentTokenFetchWithRetry(scope);
            } catch (error) {
                // This is likely a NOOP since if actually fails it will attempt to login with redirect navigation
                this.loggers.traceLogger.logTrace(0x1e29820e /* tag_4kyio */ , TraceLevel.Warning, `Prefetch failure: ${MsalHelper.constructErrorMessage({
                    error,
                    hasClaims: !!error.claims,
                    scopes: [
                        scope
                    ]
                })}`);
            }
        }
    }
    /**
     * Attempts to silently fetch token for given scope and login with redirect.
     * This method retries on retriable msal errors
     * @param scope the scope to attempt login
     */ async attemptSilentTokenFetchWithRetry(scope) {
        const scopes = [
            scope
        ];
        const maxTokenFetchRetryCount = 3;
        await retry(async ()=>await this.attemptSilentLogin(scopes, this.msalObject.getActiveAccount() ?? undefined), maxTokenFetchRetryCount - 1, /* interval */ 0, this.canRetry);
    }
    /**
     * Checks if can retry given msal auth error
     * @param error the error to retry
     * @returns Returns TRUE if can retry
     */ canRetry(error) {
        const errorCode = error?.errorCode;
        return errorCode === BrowserAuthErrorCodes.monitorWindowTimeout || errorCode === "temporarily_unavailable" || errorCode === "endpoints_resolution_error";
    }
    /**
     * Login scopes for app
     * @returns Default app scopes for login
     */ getDefaultAppScopes() {
        return [
            "openid",
            "profile",
            `${this.clientId}/.default`
        ];
    }
    /**
     * Error mesage to log
     * @param error Auth error thrown by msal
     * @param hasClaims If the request has claims
     * @param scopes scopes set for the request (won't be set for login request)
     */ static constructErrorMessage(params) {
        const { error, scopes, hasClaims } = params;
        const errorHasClaims = !!error?.claims;
        return `Msal error [scopes=${scopes?.join(",") ?? ""}][errorCode=${error?.errorCode}][subError=${error?.subError}][errorName=${error?.name ?? ""}][correlationId=${error?.correlationId ?? ""}][hasClaims=${hasClaims}][errorHasClaims=${errorHasClaims}][message=${ErrorUtilities.getMessage(error)}] `;
    }
    constructor(aadAuthorityHost, clientId, msalPca, redirectUri, silentRedirectUri, options, loggers){
        this.aadAuthorityHost = aadAuthorityHost;
        this.clientId = clientId;
        this.msalPca = msalPca;
        this.redirectUri = redirectUri;
        this.silentRedirectUri = silentRedirectUri;
        this.options = options;
        this.loggers = loggers;
        this.cachedTokenPromises = {};
        this.isInIframe = self !== top;
    }
}
MsalHelper._instance = null;
/**
     *  Callback for MSAL logger
     * @param level MSAL log level
     * @param message Log message
     * @param containsPii True if contains pii
     * @param traceLogger The trace logger
     */ MsalHelper.msalLoggerCallback = (level, message, containsPii, loggers)=>{
    if (containsPii) {
        return;
    }
    // Popup errors are unavoidable because browsers block popups by default, so set them as warnings.
    const shouldConvertErrorToWarning = ()=>message.indexOf(BrowserAuthErrorCodes.emptyWindowError) >= 0;
    // Downlevel "Info" level logs from MSAL to Verbose in order to reduce log volume. These logs are not highly useful for general logging.
    // For debugging purposes, you can review these logs via lowering a specific tenant's trace log level threshold to verbose.
    let clientLogLevel;
    switch(level){
        case LogLevel.Trace:
        case LogLevel.Verbose:
        case LogLevel.Info:
            clientLogLevel = TraceLevel.Verbose;
            break;
        case LogLevel.Error:
            clientLogLevel = shouldConvertErrorToWarning() ? TraceLevel.Warning : TraceLevel.Error;
            break;
        case LogLevel.Warning:
            clientLogLevel = TraceLevel.Warning;
            break;
        default:
            ErrorUtilities.unreachableCase(level, (log)=>{
                loggers.traceLogger.logTrace(0x1e31565f /* tag_4mvz5 */ , TraceLevel.Warning, log);
            });
            // Set default level to "Warning" in order to let us know if we are missing a case
            clientLogLevel = TraceLevel.Warning;
    }
    loggers.traceLogger.logTrace(0x1e318656 /* tag_4myzw */ , clientLogLevel, `[MSAL]: ${message}`);
};
