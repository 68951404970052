import { User } from "./User";
// Constants
import { FormerMember } from "./FormerUser";
// Utilities
import { isGuid } from "@ms/uno-utilities/lib/local/Guid";
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
export var UnknownUser;
(function(UnknownUser) {
    function getUnknownUser(id) {
        if (id && isGuid(id)) {
            return getUnknownMember(id);
        } else {
            return getFormerMember(id);
        }
    }
    /**
     * Returns a placeholder User instance for given userId for an user that does not exist in the store.
     * This detects if it is a Former member when the user id is empty or it is not a Guid which means it does not exist in AAD.
     * The Unknown member placeholder is used for id not found in store but it is a Guid which indicates it might exist in AAD but we cannot enumerate the user information.
     * @param [id] Optional info for the unknown member.
     */ UnknownUser.getUnknownUser = getUnknownUser;
    function getFormerMember(id) {
        return new User({
            displayName: Strings.User_FormerUserName,
            id: id ? id : "",
            mail: FormerMember.Mail,
            userPrincipalName: FormerMember.Mail,
            givenName: "FormerUserName",
            jobTitle: null,
            preferredLanguage: null,
            proxyAddresses: [],
            surname: null,
            userType: null,
            accountEnabled: null,
            usageLocation: null
        });
    }
    /**
     * Creating a fallback user object with former member info.
     * @param [id] Optional info for the former member.
     */ UnknownUser.getFormerMember = getFormerMember;
    function getUnknownMember(id) {
        return new User({
            displayName: Strings.User_UnknownUserName,
            id: id ? id : "",
            mail: null,
            userPrincipalName: "UnknownUserName",
            givenName: "FormerUserName",
            jobTitle: null,
            preferredLanguage: null,
            proxyAddresses: [],
            surname: null,
            userType: null,
            accountEnabled: null,
            usageLocation: null
        });
    }
    /**
     * Creating a fallback user object with unknown user info.
     * @param [id] Optional info for the unknown member.
     */ UnknownUser.getUnknownMember = getUnknownMember;
})(UnknownUser || (UnknownUser = {}));
