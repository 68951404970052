import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for PortfolioStore, for use in the registry */ export const PortfolioStoreActionTypes = [
    ActionType.CreatePortfolioAction,
    ActionType.CreatePortfolioSucceededAction,
    ActionType.CreatePortfolioFailedAction,
    ActionType.FetchPortfolioItemsAction,
    ActionType.FetchPortfolioItemsSucceededAction,
    ActionType.FetchPortfolioItemsFailedAction,
    ActionType.DeletePortfolioAction,
    ActionType.DeletePortfolioSucceededAction,
    ActionType.DeletePortfolioFailedAction,
    ActionType.UpdatePortfolioAction,
    ActionType.UpdatePortfolioSucceededAction,
    ActionType.UpdatePortfolioFailedAction
];
