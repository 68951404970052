// Supported cultures for different libraries the app uses e.g. Moment
/**
 * List of ll-cc or just ll cultures that are rtl. Entries will be in the ll-cc format or ll format
 * https://lingohub.com/academy/best-practices/rtl-language-list
 */ export const rtlCultures = [
    "ar",
    "he",
    "ur",
    "ku",
    "fa"
];
/**
 * A list of the currently supported cultures by the moment library
 */ export const momentCultures = [
    "ar",
    "ar-ly",
    "ar-ma",
    "ar-sa",
    "ar-tn",
    "bg",
    "ca",
    "cs",
    "cy",
    "da",
    "de",
    "de-at",
    "el",
    "en",
    "en-au",
    "en-ca",
    "en-gb",
    "en-ie",
    "en-in",
    "en-nz",
    "es",
    "es-do",
    "et",
    "eu",
    "fi",
    "fil",
    "fr",
    "fr-ca",
    "fr-ch",
    "gl",
    "he",
    "hi",
    "hr",
    "hu",
    "id",
    "is",
    "it",
    "ja",
    "ka",
    "kk",
    "ko",
    "lt",
    "lv",
    "ml",
    "ms-my",
    "nb",
    "nl",
    "nn",
    "no",
    "pl",
    "pt",
    "pt-br",
    "ro",
    "ru",
    "sr-cyrl",
    "sr",
    "sk",
    "sl",
    "sv",
    "th",
    "tr",
    "uk",
    "vi",
    "zh-cn",
    "zh-hk",
    "zh-tw"
];
// The default moment culture
export const defaultMomentCulture = "en";
