/**
 * Enumeration of the various themes
 */ export var UnoTheme;
(function(UnoTheme) {
    /** Dark theme with darker colors and backgrounds */ UnoTheme["Dark"] = "dark";
    /** Light theme with lighter colors and backgrounds */ UnoTheme["Light"] = "light";
    /** Teams dark theme with darker colors and backgrounds */ UnoTheme["TeamsDark"] = "teamsdark";
    /** Teams light theme with lighter colors and backgrounds */ UnoTheme["TeamsLight"] = "teamslight";
    /** Teams high contrast theme with high contrast colors and backgrounds */ UnoTheme["TeamsContrast"] = "teamscontrast";
    /** SharePoint theme */ UnoTheme["SharePoint"] = "sharepoint";
    /** Loop light theme */ UnoTheme["LoopLight"] = "looplight";
    /** Loop dark theme */ UnoTheme["LoopDark"] = "loopdark";
    /** Loop Workspace light theme */ UnoTheme["LoopWorkspaceLight"] = "loopworkspacelight";
    /** Loop Workspace dark theme */ UnoTheme["LoopWorkspaceDark"] = "loopworkspacedark";
})(UnoTheme || (UnoTheme = {}));
