import { PerformanceCollectionActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/performanceCollection/PerformanceCollectionActionCreator";
import { RoutingActionCreator } from "@ms/uno-actioncreators/lib/local/actioncreators/routing/RoutingActionCreator";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/** Factory class for action creator providers in Base app */
export class BaseActionCreatorFactory {
    /**
     * Initialize the action creator factory
     * @param loader Component loader
     * @param loggers Loggers for telemetry
     */
    constructor(loader, loggers) {
        this.loader = loader;
        this.loggers = loggers;
    }
    getActivityAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("activity"), () => import(
        /* webpackChunkName: "uno-activityac" */ "@ms/uno-actioncreators/lib/local/actioncreators/activity/ActivityActionCreator"), (bundle) => new bundle.ActivityActionCreator(dispatcher));
    }
    getAppContextAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("appContext"), () => import(
        /* webpackChunkName: "uno-appcontextac" */ "@ms/uno-actioncreators/lib/local/actioncreators/appContext/AppContextActionCreator"), (bundle) => new bundle.AppContextActionCreator(dispatcher, modules, this.loggers));
    }
    getBasicBucketAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("basicBucket"), () => import(
        /* webpackChunkName: "uno-basicbucketac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/bucket/BasicBucketActionCreator"), (bundle) => new bundle.BasicBucketActionCreator(stores, modules, this.loggers));
    }
    getBasicPlanAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("basicPlan"), () => import(
        /* webpackChunkName: "uno-basicplanac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/plan/BasicPlanActionCreator"), (bundle) => new bundle.BasicPlanActionCreator(modules, stores, dispatcher, this.loggers, configProvider));
    }
    getCampaignAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("campaign"), () => import(
        /* webpackChunkName: "uno-campaignac" */ "@ms/uno-actioncreators/lib/local/actioncreators/campaign/CampaignActionCreator"), (bundle) => new bundle.CampaignActionCreator(dispatcher, modules, stores, this.loggers));
    }
    getCapabilitiesAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("capabilities"), () => import(
        /* webpackChunkName: "uno-capabilitiesac" */ "@ms/uno-actioncreators/lib/local/actioncreators/capabilities/CapabilitiesActionCreator"), (bundle) => new bundle.CapabilitiesActionCreator(modules, this.loggers));
    }
    getContainerAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("container"), () => import(
        /* webpackChunkName: "uno-containerac" */ "@ms/uno-actioncreators/lib/local/actioncreators/container/ContainerActionCreator"), (bundle) => new bundle.ContainerActionCreator(modules, this.loggers));
    }
    getCreateTaskFromTeamsMessageAcProvider(dispatcher, modules, stores, configProvider) {
        return () => {
            this.loggers.traceLogger.logTrace(0x1e35b48e /* tag_4n1so */, TraceLevel.Error, `Shouldn't import adaptive card action creator for base (non-Teams) endpoint.`);
            throw new Error("NotSupported");
        };
    }
    getCreateTaskViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("createTaskView"), () => import(
        /* webpackChunkName: "uno-createtaskviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreateTaskViewActionCreator"), (bundle) => new bundle.CreateTaskViewActionCreator(modules, stores, this.loggers));
    }
    getDeltaSyncAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("deltaSync"), () => import(
        /* webpackChunkName: "uno-deltasyncac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/deltaSync/DeltaSyncActionCreator"), (bundle) => new bundle.DeltaSyncActionCreator(modules, stores, dispatcher, this.loggers, configProvider));
    }
    getDriveItemAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("driveItem"), () => import(
        /* webpackChunkName: "uno-driveitemac" */ "@ms/uno-actioncreators/lib/local/actioncreators/driveItem/DriveItemActionCreator"), (bundle) => new bundle.DriveItemActionCreator(modules, this.loggers));
    }
    getExportAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("entityExport"), () => import(
        /* webpackChunkName: "uno-excelexportac" */ "@ms/uno-actioncreators/lib/local/actioncreators/export/ExportActionCreator"), (bundle) => new bundle.ExportActionCreator(dispatcher, stores, modules, this.loggers));
    }
    getGroupsAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("groups"), () => import(
        /* webpackChunkName: "uno-groupsac" */ "@ms/uno-actioncreators/lib/local/actioncreators/groups/GroupsActionCreator"), (bundle) => new bundle.GroupsActionCreator(dispatcher, stores, modules, this.loggers, configProvider));
    }
    getIntelligenceAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("intelligence"), () => import(
        /* webpackChunkName: "uno-intelligenceac" */ "@ms/uno-actioncreators/lib/local/actioncreators/intelligence/IntelligenceActionCreator"), (bundle) => new bundle.IntelligenceActionCreator(modules, dispatcher, stores, configProvider, this.loggers));
    }
    getLinksAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("links"), () => import(
        /* webpackChunkName: "uno-linksac" */ "@ms/uno-actioncreators/lib/local/actioncreators/links/LinksActionCreator"), (bundle) => new bundle.LinksActionCreator(modules, dispatcher));
    }
    getModalAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("modal"), () => import(
        /* webpackChunkName: "uno-modalac" */ "@ms/uno-actioncreators/lib/local/actioncreators/modal/ModalActionCreator"), (bundle) => new bundle.ModalActionCreator(dispatcher, stores, this.loggers, configProvider));
    }
    getMruAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("mru"), () => import(/* webpackChunkName: "uno-mruac" */ "@ms/uno-actioncreators/lib/local/actioncreators/mru/MruActionCreator"), (bundle) => new bundle.MruActionCreator(modules, stores, this.loggers));
    }
    getNotificationAcProvider(dispatcher, modules, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("notification"), () => import(
        /* webpackChunkName: "uno-notificationac" */ "@ms/uno-actioncreators/lib/local/actioncreators/notification/NotificationActionCreator"), (bundle) => new bundle.NotificationActionCreator(dispatcher, modules));
    }
    getOperationTrackingAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("operationTracking"), () => import(
        /* webpackChunkName: "uno-optrackac" */ "@ms/uno-actioncreators/lib/local/actioncreators/operationTracking/OperationTrackingActionCreator"), (bundle) => new bundle.OperationTrackingActionCreator(dispatcher, modules, stores, this.loggers, configProvider));
    }
    getOrgAcProvider(modules, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("org"), () => import(/* webpackChunkName: "uno-orgac" */ "@ms/uno-actioncreators/lib/local/actioncreators/org/OrgActionCreator"), (bundle) => new bundle.OrgActionCreator(modules, this.loggers, configProvider));
    }
    getPerformanceCollectionAcProvider(dispatcher, stores, configProvider) {
        return () => {
            if (this.performanceCollection) {
                return this.performanceCollection;
            }
            this.performanceCollection = new PerformanceCollectionActionCreator(dispatcher, stores, this.loggers, configProvider);
            return this.performanceCollection;
        };
    }
    getPortfolioAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("portfolio"), () => import(
        /* webpackChunkName: "uno-portfolioac" */ "@ms/uno-actioncreators/lib/local/actioncreators/portfolio/PortfolioActionCreator"), (bundle) => new bundle.PortfolioActionCreator(dispatcher, modules, stores, this.loggers));
    }
    getPlanConversionAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("planConversion"), () => import(
        /* webpackChunkName: "uno-planconversionac" */ "@ms/uno-actioncreators/lib/local/actioncreators/upsell/PlanConversionActionCreator"), (bundle) => new bundle.PlanConversionActionCreator(configProvider, modules, stores, this.loggers));
    }
    getPublishingAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("publishing"), () => import(
        /* webpackChunkName: "uno-publishingac" */ "@ms/uno-actioncreators/lib/local/actioncreators/publishing/PublishingActionCreator"), (bundle) => new bundle.PublishingActionCreator());
    }
    getPremiumPlanAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("premiumPlan"), () => import(
        /* webpackChunkName: "uno-premiumplanac" */ "@ms/uno-actioncreators/lib/local/actioncreators/project/premiumPlan/PremiumPlanActionCreator"), (bundle) => new bundle.PremiumPlanActionCreator(modules, stores, dispatcher, this.loggers, configProvider));
    }
    getUnoShellAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("unoShell"), () => import(
        /* webpackChunkName: "uno-unoshellac" */ "@ms/uno-actioncreators/lib/local/actioncreators/unoShell/UnoShellActionCreator"), (bundle) => new bundle.UnoShellActionCreator(dispatcher));
    }
    getRecommendedPlansAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("recommendedPlan"), () => import(
        /* webpackChunkName: "uno-recommendedplansac" */ "@ms/uno-actioncreators/lib/local/actioncreators/recommendedPlan/RecommendedPlansActionCreator"), (bundle) => new bundle.RecommendedPlansActionCreator(dispatcher, modules, stores, this.loggers));
    }
    getResourceAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("projectHomeResource"), () => import(
        /* webpackChunkName: "uno-projecthomeresourceac" */ "@ms/uno-actioncreators/lib/local/actioncreators/projectHome/ProjectHomeResourceActionCreator"), (bundle) => new bundle.ProjectHomeResourceActionCreator(dispatcher, modules, stores, configProvider, this.loggers));
    }
    getRoutingAcProvider(dispatcher, modules, stores, configProvider) {
        return () => {
            if (this.routing) {
                return this.routing;
            }
            this.routing = new RoutingActionCreator(dispatcher, modules, stores, this.loggers);
            return this.routing;
        };
    }
    getTeamsAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("teams"), () => import(
        /* webpackChunkName: "uno-teamsac" */ "@ms/uno-actioncreators/lib/local/actioncreators/teams/TeamsActionCreator"), (bundle) => new bundle.TeamsActionCreator(modules, this.loggers));
    }
    getTemplatesAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("templates"), () => import(
        /* webpackChunkName: "uno-templateac" */ "@ms/uno-actioncreators/lib/local/actioncreators/templates/TemplateActionCreator"), (bundle) => new bundle.TemplateActionCreator(modules, stores, dispatcher, this.loggers, configProvider));
    }
    getTenantAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("tenant"), () => import(
        /* webpackChunkName: "uno-tenantac" */ "@ms/uno-actioncreators/lib/local/actioncreators/tenant/TenantActionCreator"), (bundle) => new bundle.TenantActionCreator(modules, this.loggers));
    }
    getUpsellAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("upsell"), () => import(
        /* webpackChunkName: "uno-upsellac" */ "@ms/uno-actioncreators/lib/local/actioncreators/upsell/UpsellActionCreator"), (bundle) => new bundle.UpsellActionCreator(dispatcher, configProvider, modules, stores, this.loggers));
    }
    getUserAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("user"), () => import(/* webpackChunkName: "uno-userac" */ "@ms/uno-actioncreators/lib/local/actioncreators/user/UserActionCreator"), (bundle) => new bundle.UserActionCreator(dispatcher, modules, stores, this.loggers));
    }
    getOCVAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("ocv"), () => import(/* webpackChunkName: "uno-OCVac" */ "@ms/uno-actioncreators/lib/local/actioncreators/OCV/OCVActionCreator"), (bundle) => new bundle.OCVActionCreator(modules, stores, this.loggers, configProvider));
    }
    getBasicPlanViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("basicPlanView"), () => import(
        /* webpackChunkName: "uno-basicplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/BasicPlanViewActionCreator"), (bundle) => new bundle.BasicPlanViewActionCreator(modules, stores, this.loggers, configProvider));
    }
    getCreatePortfolioViewAcProvider(dispatcher, modules, stores) {
        return () => this.loader.load(this.getAcBundleKey("createPortfolioView"), () => import(
        /* webpackChunkName: "uno-createportfolioviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreatePortfolioViewActionCreator"), (bundle) => new bundle.CreatePortfolioViewActionCreator(modules, this.loggers));
    }
    getPlannerTaskAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("basicTask"), () => import(
        /* webpackChunkName: "uno-basictaskac" */ "@ms/uno-actioncreators/lib/local/actioncreators/planner/task/BasicTaskActionCreator"), (bundle) => new bundle.BasicTaskActionCreator(dispatcher, stores, modules, this.loggers, configProvider));
    }
    getTeamsTabAcProvider(dispatcher, modules, stores, configProvider, teamsHostAdaptor) {
        return () => {
            this.loggers.traceLogger.logTrace(0x1e35b462 /* tag_4n1r8 */, TraceLevel.Error, `Invalid import teams tab action creator`);
            throw new Error("NotSupported");
        };
    }
    getCreatePlanViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("createPlanView"), () => import(
        /* webpackChunkName: "uno-createplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreatePlanViewActionCreator"), (bundle) => new bundle.CreatePlanViewActionCreator(modules, this.loggers, configProvider));
    }
    getCopyPlanViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("copyPlanView"), () => import(
        /* webpackChunkName: "uno-copyplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CopyPlanViewActionCreator"), (bundle) => new bundle.CopyPlanViewActionCreator(modules, this.loggers, configProvider));
    }
    getLeftNavViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("leftNavView"), () => import(
        /* webpackChunkName: "uno-leftnavviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/LeftNavViewActionCreator"), (bundle) => new bundle.LeftNavViewActionCreator(modules, stores, this.loggers, configProvider));
    }
    getMyDayViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("myDayView"), () => import(
        /* webpackChunkName: "uno-mydayviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyDayViewActionCreator"), (bundle) => new bundle.MyDayViewActionCreator(modules, this.loggers));
    }
    getMyPlansViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("myPlansView"), () => import(
        /* webpackChunkName: "uno-myplansviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyPlansViewActionCreator"), (bundle) => new bundle.MyPlansViewActionCreator(modules, this.loggers));
    }
    getMyTasksViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("myTasksView"), () => import(
        /* webpackChunkName: "uno-mytasksviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyTasksViewActionCreator"), (bundle) => new bundle.MyTasksViewActionCreator(modules, stores, this.loggers));
    }
    getMyPortfoliosViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("myPortfoliosView"), () => import(
        /* webpackChunkName: "uno-myportfoliosviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/MyPortfoliosViewActionCreator"), (bundle) => new bundle.MyPortfoliosViewActionCreator(modules, this.loggers));
    }
    getSuiteNavViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => {
            this.loggers.traceLogger.logTrace(0x1e3196c5 /* tag_4mz1f */, TraceLevel.Error, `Shouldn't import suite nav action creator for base (non-Web) endpoint.`);
            throw new Error("NotSupported");
        };
    }
    getUnoShellViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("unoShellView"), () => import(
        /* webpackChunkName: "uno-unoshellviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/UnoShellViewActionCreator"), (bundle) => new bundle.UnoShellViewActionCreator(dispatcher, modules, stores, this.loggers, configProvider));
    }
    getCreateTeamsTabViewAcProvider(dispatcher, modules, stores, configProvider, hostAdaptor) {
        return () => {
            this.loggers.traceLogger.logTrace(0x1e31365a /* tag_4mtz0 */, TraceLevel.Error, `Invalid import create teams tab view action creator`);
            throw new Error("NotSupported");
        };
    }
    getViewTransformsAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("viewTransforms"), () => import(
        /* webpackChunkName: "uno-viewtransformsac" */ "@ms/uno-actioncreators/lib/local/actioncreators/viewTransforms/ViewTransformsActionCreator"), (bundle) => new bundle.ViewTransformsActionCreator(dispatcher, modules));
    }
    getConvertPlanViewAcProvider(dispatcher, modules, stores) {
        return () => this.loader.load(this.getAcBundleKey("convertPlanView"), () => import(
        /* webpackChunkName: "uno-convertplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/ConvertPlanViewActionCreator"), (bundle) => new bundle.ConvertPlanViewActionCreator(modules, this.loggers));
    }
    getSharePlanViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("sharePlanView"), () => import(
        /* webpackChunkName: "shareplanviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/SharePlanViewActionCreator"), (bundle) => new bundle.SharePlanViewActionCreator(dispatcher, modules, stores, this.loggers, configProvider));
    }
    getPublishingLeftNavViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("publishingLeftNavView"), () => import(
        /* webpackChunkName: "publishingleftnavviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/PublishingLeftNavViewActionCreator"), (bundle) => new bundle.PublishingLeftNavViewActionCreator());
    }
    getCampaignCalloutViewAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("campaignCalloutView"), () => import(
        /* webpackChunkName: "uno-campaigncalloutview" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CampaignCalloutViewActionCreator"), (bundle) => new bundle.CampaignCalloutViewActionCreator(modules, stores, this.loggers));
    }
    /**
     * Get key for ac bundle name
     * @param acName Name of the action creator
     */
    getAcBundleKey(acName) {
        return `uno-${acName}ac`;
    }
}
