import { TraceLevel, Log } from "../events/Trace.event";
/**
 * This class is responsible for trace logging. It is equivalent to the existing TraceLogger but without static methods.
 * Each app should be responsible for creating its own trace logger and passing it down to all consumers.
 * When all consumers move to using this trace logger, the existing TraceLogger will be deprecated.
 */ export class TraceEventLogger {
    setLogThreshold(threshold) {
        this.logThreshold = threshold;
    }
    setCommonExtraData(data) {
        this.extraData = data;
    }
    logTrace(locationTag, level, message, extraData) {
        if (level <= this.logThreshold) {
            if (typeof locationTag === "number") {
                // If the locationTag is a number, assume it's an eventId from the Office Git Tagger and convert it to a string
                // https://www.owiki.ms/wiki/Git/Tagger
                locationTag = this.tagIdToString(locationTag);
            }
            const extraDataToLog = {
                ...this.extraData,
                ...extraData
            };
            Log({
                level: level,
                locationTag: locationTag,
                message: message,
                timestamp: new Date(),
                preciseMarker: window.performance.now(),
                extraData: extraDataToLog
            }, this.logHandler);
        }
    }
    /**
     * Converts a tagId (number) to string format
     * @param tagId The tag id (number) to convert
     */ tagIdToString(tagId) {
        // The symbol space is 34 characters which means we need at least 6 bits to utilize the space (2^5 is only 32)
        // We'll take each 6 bit chunk of the tagId (bitwise AND to clear excess bits) and index into our space to get the corresponding character
        // Then we concat the result from each chunk to create the tagId as a string
        return this.symbolSpace[tagId >> 24 & 0x3f] + this.symbolSpace[tagId >> 18 & 0x3f] + this.symbolSpace[tagId >> 12 & 0x3f] + this.symbolSpace[tagId >> 6 & 0x3f] + this.symbolSpace[tagId >> 0 & 0x3f];
    }
    /**
     * Create a new instance of TraceLogger
     * @param logThreshold log threshold
     * @param logHandler log handler to use for logging
     */ constructor(logThreshold = TraceLevel.Info, logHandler){
        this.logThreshold = logThreshold;
        this.logHandler = logHandler;
        this.symbolSpace = "abcdefghijklmnopqrstuvwxyz0123456789";
    }
}
