/**
 * Supported pivots for My Tasks view
 */ export var MyTasksViewPivotType;
(function(MyTasksViewPivotType) {
    MyTasksViewPivotType["All"] = "all";
    /** User's task captures */ MyTasksViewPivotType["PrivateTasks"] = "privatetasks";
    /** Tasks assigned to user */ MyTasksViewPivotType["AssignedToMe"] = "assignedtome";
    /** User's flagged emails */ MyTasksViewPivotType["FlaggedEmails"] = "flaggedemails";
})(MyTasksViewPivotType || (MyTasksViewPivotType = {}));
/**
 * The default pivot type for My Tasks page
 */ export const DefaultMyTasksViewPivotType = "assignedtome";
export var MyPlansPagePivotType;
(function(MyPlansPagePivotType) {
    /** User's recent plans */ MyPlansPagePivotType["Recent"] = "recent";
    /** Shared plans accessible to user */ MyPlansPagePivotType["Shared"] = "shared";
    /** User's personal plans */ MyPlansPagePivotType["Personal"] = "personal";
    /** User's pinned plans */ MyPlansPagePivotType["Pinned"] = "pinned";
    /** Plans in teams accessible to user */ MyPlansPagePivotType["MyTeams"] = "myTeams";
    /** Recommended plans */ MyPlansPagePivotType["Recommended"] = "recommended";
})(MyPlansPagePivotType || (MyPlansPagePivotType = {}));
/**
 * The default pivot type for My Plans page
 */ export const DefaultMyPlansPagePivotType = "recent";
