export var ResourceTag;
(function(ResourceTag) {
    /** Resources that are assigned to a given task */ ResourceTag[ResourceTag["Assigned"] = 0] = "Assigned";
    /** Resources that are part of the project team but not assigned to a given task */ ResourceTag[ResourceTag["Unassigned"] = 1] = "Unassigned";
    /** Resources that are not part of the project team, not assigned to a given task */ ResourceTag[ResourceTag["Results"] = 2] = "Results";
})(ResourceTag || (ResourceTag = {}));
export var ResourceType;
(function(ResourceType) {
    ResourceType["XrmUser"] = "XrmUser";
    ResourceType["XrmContact"] = "XrmContact";
    ResourceType["XrmAccount"] = "XrmAccount";
    ResourceType["XrmEquipment"] = "XrmEquipment";
    ResourceType["XrmGeneric"] = "XrmGeneric";
    ResourceType["AadUser"] = "AadUser";
})(ResourceType || (ResourceType = {}));
/** Non-user types for the purposes of group and team assignment */ export const NonUserResourceTypes = [
    "XrmAccount",
    "XrmContact",
    "XrmEquipment",
    "XrmGeneric"
];
