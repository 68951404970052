// Helpers
import { BaseComponentHelper } from "./BaseComponentHelper";
// Utilities
import * as React from "react";
import { LoggerContext } from "../contexts/LoggerContext";
/**
 * Base class for all components. Provides usage logging functionality
 */ export class BaseComponent extends React.Component {
    /**
     * React's render() method with error logging. Deriving components should define the renderComponent() method instead of
     * overriding this so that they benefit from the error handling and logging.
     */ render() {
        let result;
        try {
            result = this.renderComponent();
        } catch (error) {
            this.logError(`Error in render method of ${this.name()} component`, error);
            result = null;
        }
        return result;
    }
    /**
     * Catches exceptions generated in descendant components. Unhandled exceptions will cause
     * the entire component tree to unmount.
     *
     * Is overridable by components, but by default the error will be logged and considered handled.
     * We don't want the entire component tree to unmount
     */ componentDidCatch(error, errorInfo) {
        this.logError(`Component ${this.name()} caught error`, error);
    }
    /**
     * Dispose of any disposables before we unmount
     */ componentWillUnmount() {
        this.helperInstance.cleanupDisposables();
    }
    /**
     * Log an error
     * @param message Custom message to log
     * @param error Error object caught
     * @param extraData (optional) Any extra data relevant to this error
     */ logError(message, error, extraData) {
        return this.helperInstance.logError(message, error, extraData);
    }
    /**
     * Log usage/engagement data
     * @param action Name of the action taken by the user
     * @param extraData (optional) Any extra data relevant to this event
     * @param exportName Name to export via ODIN, or undefined for no export
     */ logUsage(action, extraData, exportName) {
        return this.helperInstance.logUsage(action, extraData, exportName);
    }
    /**
     * Start a Qos logging event
     * @param name Name of the qos event to log
     * @return Identifier string for the qos event
     */ startQos(name) {
        return this.helperInstance.startQos(name);
    }
    /**
     * End a Qos logging event with success
     * @param identifier Identifier string for the qos event
     * @param extraData Optional extra info to log
     */ endQosSuccess(identifier, extraData) {
        return this.helperInstance.endQosSuccess(identifier, extraData);
    }
    /**
     * Get the Async manager for this component
     * Lazy created on demand
     */ get async() {
        return this.helperInstance.async;
    }
    /**
     * Get the disposables for this component
     * If you have objects of IDisposable that you want to automatically clean up when this component unmounts
     * add them here
     *
     * Lazy created on demand
     */ get disposables() {
        return this.helperInstance.disposables;
    }
    /**
     * Get the EventGroup for this component
     * Lazy created on demand
     */ get events() {
        return this.helperInstance.events;
    }
    constructor(props, context){
        super(props, context);
        this.helperInstance = new BaseComponentHelper(()=>this.name(), context.traceLogger, context.engagementLogger);
    }
}
// Class component only supports one context type, any extending class must provide logHandler via context if overriding
BaseComponent.contextType = LoggerContext;
