import { memoizeFunction, mergeStyles } from "@fluentui/react";
// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { SuiteNavHeight } from "../../../constants/StyleConstants";
// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Utilities
import { flexChildSizeFixed } from "../../../utilities/Mixin.styles";
/**
 * Helper method to get styles for the Suite Nav container
 */ export const getContainerStyles = memoizeFunction((theme)=>{
    let borderColor;
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            borderColor = theme.palette.black;
            break;
        case UnoTheme.Light:
        case UnoTheme.Dark:
        default:
            borderColor = theme.palette.neutralQuaternary;
            break;
    }
    const containerStyle = [
        flexChildSizeFixed(SuiteNavHeight),
        {
            backgroundColor: theme.palette.neutralLight,
            borderBottom: `1px solid ${borderColor}`
        }
    ];
    return containerStyle;
});
/**
 * Get styles for the Suite nav component
 * @param theme Fluent UI theme
 */ export const getStyles = memoizeFunction((theme)=>{
    const containerStyle = getContainerStyles(theme);
    const suiteNav = containerStyle;
    const suiteNavPlaceHolder = containerStyle;
    return {
        suiteNavPlaceHolder,
        suiteNav
    };
});
/**
 * Get the classnames associated with the computed styles for the suite nav component
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        suiteNavPlaceHolder: mergeStyles("suiteNavPlaceHolder", styles.suiteNavPlaceHolder),
        suiteNav: mergeStyles("suiteNav", styles.suiteNav)
    };
});
/**
 * Helper method to get the base theme for the Suite Nav
 * @param theme Fluent UI theme
 */ export const getAppBaseTheme = memoizeFunction((theme)=>{
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            return {
                Primary: theme.palette.neutralLight,
                Accent: theme.palette.themePrimary,
                AppName: theme.palette.themePrimary,
                DefaultText: theme.palette.neutralPrimary,
                HoverText: Colors.HighContrastBlack,
                HoverBackground: Colors.HighContrastYellow,
                PressedText: Colors.HighContrastBlack,
                PressedBackground: Colors.HighContrastBlackSelectedTextBackground,
                SelectedText: Colors.HighContrastBlack,
                SelectedBackground: Colors.HighContrastBlackSelectedTextBackground
            };
        case UnoTheme.TeamsDark:
            return {
                Primary: theme.palette.neutralLight,
                Accent: theme.palette.themePrimary,
                AppName: Colors.White,
                DefaultText: theme.palette.neutralPrimary,
                HoverText: theme.palette.themePrimary,
                HoverBackground: theme.palette.neutralLighter,
                PressedText: theme.palette.themePrimary,
                PressedBackground: theme.palette.white,
                SelectedText: theme.palette.themePrimary,
                SelectedBackground: theme.palette.white
            };
        case UnoTheme.TeamsLight:
        default:
            return {
                Primary: theme.palette.neutralLight,
                Accent: theme.palette.themePrimary,
                AppName: theme.palette.themePrimary,
                DefaultText: theme.palette.neutralPrimary,
                HoverText: theme.palette.themePrimary,
                HoverBackground: theme.palette.neutralLighter,
                PressedText: theme.palette.themePrimary,
                PressedBackground: theme.palette.white,
                SelectedText: theme.palette.themePrimary,
                SelectedBackground: theme.palette.white
            };
    }
});
/**
 * Styles for the diamond icon in Suite Nav
 */ export const diamondIconStyles = {
    root: {
        alignSelf: "center"
    }
};
