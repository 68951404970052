// Constants
import { HttpHeaders } from "@ms/uno-constants/lib/local/HttpConstants";
import { ResultTypeEnum } from "@ms/uno-telemetry/lib/local/events/ResultTypeEnum";
// Utilities
import { ApiEvent } from "@ms/uno-telemetry/lib/local/events/Api.event";
import { extractLoggableHeaders } from "../utilities/HeadersUtilities";
import { createQosEventName } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
/**
 * Core network layer logic for executing AJAX requests
 * --- Provides api qos logging
 */ export class CoreAjaxClient {
    async executeRequest(url, options) {
        this.setRequestAcceptHeader(options);
        const { telemetryConfig } = options;
        const request = new Request(url, options);
        // Qos logging
        const apiEventExtraData = extractLoggableHeaders(request.headers, telemetryConfig?.requestHeadersToLog ?? []);
        const apiEvent = telemetryConfig == null ? null : new ApiEvent({
            name: createQosEventName(telemetryConfig.apiName, telemetryConfig.methodName),
            extraData: apiEventExtraData
        }, this.loggers.logHandler);
        const apiEnd = {
            resultType: ResultTypeEnum.Failure,
            resultCode: String(-1),
            extraData: {}
        };
        try {
            const response = await this.requestExecutor(url, options);
            const extraData = extractLoggableHeaders(response.headers, telemetryConfig?.responseHeadersToLog ?? []);
            if (response.ok) {
                // Success
                if (apiEvent != null) {
                    apiEnd.resultType = ResultTypeEnum.Success;
                    apiEnd.resultCode = String(response.status);
                    apiEnd.extraData = extraData;
                    apiEvent.end(apiEnd);
                }
                return response;
            }
            // Failure
            const failureResult = {
                response: response,
                error: null,
                extraTelemetryData: extraData
            };
            if (apiEvent != null) {
                apiEnd.resultCode = String(response.status);
                apiEnd.extraData = extraData;
                apiEvent.end(apiEnd);
            }
            throw failureResult;
        } catch (e) {
            if (e.response != null) {
                // This was already thrown above as an IFailedRequestResult. No need to wrap again
                throw e;
            }
            // If no response returned, log the request headers for telemetry
            const failureResult = {
                response: null,
                error: e.name === "AbortError" ? new Error("Timeout") : e,
                extraTelemetryData: apiEventExtraData
            };
            if (apiEvent != null) {
                apiEnd.resultCode = String(-1);
                apiEvent.end(apiEnd);
            }
            throw failureResult;
        }
    }
    /** Set the request accept header */ setRequestAcceptHeader(options) {
        const headers = new Headers(options.headers);
        if (headers.get(HttpHeaders.Accept) == null) {
            headers.set(HttpHeaders.Accept, "application/json, */*;q=0.01");
        }
        options.headers = headers;
    }
    /**
     * Create an instance of CoreAjaxClient
     * @param requestExecutor Request executor
     * @param loggers : Loggers for telemetry
     */ constructor(requestExecutor, loggers){
        this.requestExecutor = requestExecutor;
        this.loggers = loggers;
    }
}
