// Constants
import { PlanConversionStatus, ProjectSkuId, ProjectSubscriptionState, ProjectSubscriptionType, ProjectTrialEligibilityStatus, ProjectTrialOperationStatus, ProjectTrialType, ProjectPlan, ConvertPlanMessage } from "@ms/uno-constants/lib/local/UpsellConstants";
import { Environment, LearnMoreLinks } from "@ms/uno-constants/lib/local/AppConstants";
/**
 * Get the project trial type, given the skuId
 * @param skuId fixed sku id that can be mapped to corresponding trial type
 */ export const getProjectTrialType = (skuId)=>{
    switch(skuId){
        case ProjectSkuId.P1_Admin:
            return ProjectTrialType.P1_Admin;
        case ProjectSkuId.P1_Departmental:
            return ProjectTrialType.P1_Departmental;
        case ProjectSkuId.P3_Admin:
            return ProjectTrialType.P3_Admin;
        case ProjectSkuId.P3_Departmental:
            return ProjectTrialType.P3_Departmental;
        case ProjectSkuId.P5_Admin:
            return ProjectTrialType.P5_Admin;
        default:
            return ProjectTrialType.Unknown;
    }
};
/**
 * Get the project trial SkuId, given the trial type
 * @param trialType trial type that can be mapped to corresponding fixed sku id
 */ export const getProjectSkuId = (trialType)=>{
    switch(trialType){
        case ProjectTrialType.P1_Admin:
            return ProjectSkuId.P1_Admin;
        case ProjectTrialType.P1_Departmental:
            return ProjectSkuId.P1_Departmental;
        case ProjectTrialType.P3_Admin:
            return ProjectSkuId.P3_Admin;
        case ProjectTrialType.P3_Departmental:
            return ProjectSkuId.P3_Departmental;
        case ProjectTrialType.P5_Admin:
            return ProjectSkuId.P5_Admin;
        default:
            return null;
    }
};
/**
 * Get the subscription type
 * @param subscriptionType string that can be mapped to corresponding subscription type
 */ export const getProjectSubscriptionType = (subscriptionType)=>{
    switch(subscriptionType.toLowerCase()){
        case ProjectSubscriptionType.Trial.toLowerCase():
            return ProjectSubscriptionType.Trial;
        case ProjectSubscriptionType.Paid.toLowerCase():
            return ProjectSubscriptionType.Paid;
        default:
            return ProjectSubscriptionType.Unknown;
    }
};
/**
 * Get the project subscription state
 * @param subscriptionStatus string that can be mapped to corresponding subscription status
 */ export const getProjectSubscriptionState = (subscriptionStatus)=>{
    switch(subscriptionStatus.toLowerCase()){
        case ProjectSubscriptionState.Pending.toLowerCase():
            return ProjectSubscriptionState.Pending;
        case ProjectSubscriptionState.Active.toLowerCase():
            return ProjectSubscriptionState.Active;
        case ProjectSubscriptionState.InGracePeriod.toLowerCase():
            return ProjectSubscriptionState.InGracePeriod;
        case ProjectSubscriptionState.Disabled.toLowerCase():
            return ProjectSubscriptionState.Disabled;
        case ProjectSubscriptionState.Deprovisioned.toLowerCase():
            return ProjectSubscriptionState.Deprovisioned;
        default:
            return ProjectSubscriptionState.Unknown;
    }
};
/**
 * Get the project trial operation status
 * @param operationStatus string that can be mapped to corresponding operation status
 */ export const getProjectTrialOperationStatus = (operationStatus)=>{
    switch(operationStatus.toLowerCase()){
        case ProjectTrialOperationStatus.Pending.toLowerCase():
            return ProjectTrialOperationStatus.Pending;
        case ProjectTrialOperationStatus.Completed.toLowerCase():
            return ProjectTrialOperationStatus.Completed;
        case ProjectTrialOperationStatus.Failed.toLowerCase():
            return ProjectTrialOperationStatus.Failed;
        default:
            return ProjectTrialOperationStatus.Unknown;
    }
};
/**
 * Get the project trial eligibility Status
 * @param trialEligibilityStatus string that can be mapped to corresponding trial eligibility status
 */ export const getProjectTrialEligibilityStatus = (trialEligibilityStatus)=>{
    switch(trialEligibilityStatus.toLowerCase()){
        case ProjectTrialEligibilityStatus.EligibleForSelfService.toLowerCase():
            return ProjectTrialEligibilityStatus.EligibleForSelfService;
        case ProjectTrialEligibilityStatus.EligibleToRequestOnly.toLowerCase():
            return ProjectTrialEligibilityStatus.EligibleToRequestOnly;
        default:
            return ProjectTrialEligibilityStatus.Ineligible;
    }
};
/**
 * Get project subscription plan learn more link given the trial type
 * @param trialType user trial type
 */ export const getProjectSubscriptionPlanLearnMoreUrl = (trialType)=>{
    switch(trialType){
        case ProjectTrialType.P1_Admin:
        case ProjectTrialType.P1_Departmental:
            return LearnMoreLinks.projectP1SubscriptionPlanLearnMoreUrl;
        case ProjectTrialType.P3_Admin:
        case ProjectTrialType.P3_Departmental:
            return LearnMoreLinks.projectP3SubscriptionPlanLearnMoreUrl;
        case ProjectTrialType.P5_Admin:
            return LearnMoreLinks.projectP5SubscriptionPlanLearnMoreUrl;
        default:
            return "";
    }
};
/**
 * Calculate remaining days in trial
 * expiry date (UTC) - current date (UTC)
 * @param expiryDate UTC string of expiry date of the trial
 */ export const calculateRemainingDaysInTrial = (expiryDate)=>{
    const MILLISECONDS_IN_ONE_DAY = 60 * 60 * 24 * 1000;
    // convert string to milliseconds
    const milliseconds = Date.parse(expiryDate);
    // calculate remaining milliseconds
    const remainingMilliseconds = milliseconds - Date.now();
    // convert remaining milliseconds to days
    const remainingDays = Math.ceil(remainingMilliseconds / MILLISECONDS_IN_ONE_DAY);
    if (remainingDays > 0) {
        return remainingDays;
    }
    return -1;
};
/**
 * Get Product Name From Trial Type
 * @param trialType user trial type
 */ export const getProductNameFromTrialType = (trialType)=>{
    switch(trialType){
        case ProjectTrialType.P1_Admin:
        case ProjectTrialType.P1_Departmental:
            return ProjectPlan.ProjectPlan1;
        case ProjectTrialType.P3_Admin:
        case ProjectTrialType.P3_Departmental:
            return ProjectPlan.ProjectPlan3;
        case ProjectTrialType.P5_Admin:
            return ProjectPlan.ProjectPlan5;
        default:
            return "";
    }
};
/**
 * Determine if the environment eligible for upsell
 * @param environment The environment
 */ export function isEnvironmentEligibleForUpsell(environment) {
    return !(environment === Environment.Dod || environment === Environment.Gcc || environment === Environment.Gcch || environment === Environment.Agc);
}
/**
 * Get the convert plan status
 * @param convertStatus string that can be mapped to corresponding ConvertPlanStatus
 */ export const getConvertPlanStatus = (convertStatus)=>{
    switch(convertStatus.toLowerCase()){
        case PlanConversionStatus.Started.toLowerCase():
            return PlanConversionStatus.Started;
        case PlanConversionStatus.PlanArchived.toLowerCase():
            return PlanConversionStatus.PlanArchived;
        case PlanConversionStatus.ProjectCreatedInCds.toLowerCase():
            return PlanConversionStatus.ProjectCreatedInCds;
        case PlanConversionStatus.ReadyToPublish.toLowerCase():
            return PlanConversionStatus.ReadyToPublish;
        case PlanConversionStatus.Succeeded.toLowerCase():
            return PlanConversionStatus.Succeeded;
        case PlanConversionStatus.Failed.toLowerCase():
            return PlanConversionStatus.Failed;
        default:
            return PlanConversionStatus.None;
    }
};
/**
 * Get the convert plan info message
 * @param info string that can be mapped to corresponding ConvertPlanMessage
 */ export const getConvertPlanMessage = (info)=>{
    switch(info.toLowerCase()){
        case ConvertPlanMessage.AssignmentError.toLowerCase():
            return ConvertPlanMessage.AssignmentError;
        case ConvertPlanMessage.HasRecurrencePlannerTask.toLowerCase():
            return ConvertPlanMessage.HasRecurrencePlannerTask;
        case ConvertPlanMessage.PlanContainerIsNotGroup.toLowerCase():
            return ConvertPlanMessage.PlanContainerIsNotGroup;
        case ConvertPlanMessage.PlannerTaskEndDateChanged.toLowerCase():
            return ConvertPlanMessage.PlannerTaskEndDateChanged;
        case ConvertPlanMessage.PlannerTaskStartDateChanged.toLowerCase():
            return ConvertPlanMessage.PlannerTaskStartDateChanged;
        case ConvertPlanMessage.PlannerTitleTrimmed.toLowerCase():
            return ConvertPlanMessage.PlannerTitleTrimmed;
        case ConvertPlanMessage.ProjectDurationOutOfRange.toLowerCase():
            return ConvertPlanMessage.ProjectDurationOutOfRange;
        case ConvertPlanMessage.ProjectStartDateOutOfRange.toLowerCase():
            return ConvertPlanMessage.ProjectStartDateOutOfRange;
        case ConvertPlanMessage.ResourceCountOutOfRange.toLowerCase():
            return ConvertPlanMessage.ResourceCountOutOfRange;
        case ConvertPlanMessage.ShareProjectFailed.toLowerCase():
            return ConvertPlanMessage.ShareProjectFailed;
        case ConvertPlanMessage.TaskCountOutOfRange.toLowerCase():
            return ConvertPlanMessage.TaskCountOutOfRange;
        case ConvertPlanMessage.TaskDurationOutOfRange.toLowerCase():
            return ConvertPlanMessage.TaskDurationOutOfRange;
        case ConvertPlanMessage.TaskEndDateOutOfRange.toLowerCase():
            return ConvertPlanMessage.TaskEndDateOutOfRange;
        case ConvertPlanMessage.TaskHasCompletionRequirements.toLowerCase():
            return ConvertPlanMessage.TaskHasCompletionRequirements;
        case ConvertPlanMessage.TaskHasCreationSourceSet.toLowerCase():
            return ConvertPlanMessage.TaskHasCreationSourceSet;
        case ConvertPlanMessage.TaskStartDateOutOfRange.toLowerCase():
            return ConvertPlanMessage.TaskStartDateOutOfRange;
        case ConvertPlanMessage.UnexpectedAttachmentType.toLowerCase():
            return ConvertPlanMessage.UnexpectedAttachmentType;
        default:
            return ConvertPlanMessage.None;
    }
};
