// Actions
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for CampaignStore, for use in the registry */ export const CampaignStoreActionTypes = [
    ActionType.CampaignTriggerAction,
    ActionType.DismissActiveCampaignCreative,
    ActionType.FetchCampaignCreatives,
    ActionType.FetchCampaignCreativesFailed,
    ActionType.FetchCampaignCreativesSucceeded,
    ActionType.NavigateToView,
    ActionType.PushSubRoute,
    ActionType.ReplaceSubRoute,
    ActionType.SendCampaignCreativeImpressionBeacon,
    ActionType.SendCampaignCreativeImpressionBeaconFailed,
    ActionType.SendCampaignCreativeImpressionBeaconSucceeded,
    ActionType.ShowNextActiveCampaignCreativeStep
];
