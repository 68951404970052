// Constants
// Utilities
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import isEmpty from "lodash/isEmpty";
/**
 * A class to hold a prioritized request
 */ export class PrioritizedRequest {
    /**
     * Apply all changes to updateIds tracked in this request
     * @param latestUpdatedRequestParams The latest request params set before executing the request
     * @returns The refreshed latestUpdatedRequestParams with all updateId changes applied
     */ applyPendingUpdateIdsToRequestParams(latestUpdatedRequestParams) {
        // First, apply latest updated request params
        this.primaryRequest.requestParams = latestUpdatedRequestParams;
        // Re-apply the update ids if any
        this.pendingUpdatedId.forEach((param)=>{
            this.primaryRequest.updateRequestParams(param.dependencyRequest.entityId, param.updatedId);
        });
        // return the updated requestParams
        return this.primaryRequest.requestParams;
    }
    /**
     * Checks whether this Request has dependency on given request
     * @param otherRequest The request to check if this request has dependency with
     * @returns Returns TRUE if there is a dependency between this and other request, otherwise returns FALSE
     */ hasDependencyWith(otherRequest) {
        if (!isEmpty(this.primaryRequest.dependentEntityIds) && this.primaryRequest.dependentEntityIds.indexOf(otherRequest.entityId) !== -1) {
            // 'otherRequest' is child dependent of this request
            return true;
        }
        if (!isEmpty(otherRequest.dependentEntityIds) && otherRequest.dependentEntityIds.indexOf(this.primaryRequest.entityId) !== -1) {
            // 'otherRequest' includes a parent dependency in dependentEntityIds of this request
            return true;
        }
        return false;
    }
    /**
     * Creates an instance of PrioritizedRequest
     * @param request Request
     * @param priority Request's priority
     * @param [makeRequestToStore] Callback to check the stores for the data requested
     * @param [viewIds] List of valid views for this request to execute on
     * @param [getLatestParams] Callback to get the latest parameters to send
     */ constructor(request, priority, makeRequestToStore, viewIds, getLatestParams){
        this.priority = priority;
        this.primaryRequest = request;
        this.duplicateRequests = [];
        this.loggingId = LocalIdGenerator.generate();
        this.makeRequestToStore = makeRequestToStore;
        this.viewIds = viewIds;
        this.getLatestParams = getLatestParams;
        this.numberOfBlockingRequests = 0;
        this.pendingUpdatedId = [];
    }
}
