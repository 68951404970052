// Constants
import { TaskPlatformRequestHeaders } from "@ms/uno-constants/lib/local/ServiceConstants";
import { generateTaskPlatformClientType } from "../utilities/TelemetryHeaderUtilities";
/**
 * Ajax client that sets planner headers for planner services
 */ export class TasksPlatformHeaderAjaxClient {
    async executeRequest(url, options) {
        this.setCustomHeader(options);
        return await this.innerAjaxClient.executeRequest(url, options);
    }
    /**
     * Set the tasks platform specific custom headers
     * @param options request options with the request details
     */ setCustomHeader(options) {
        const { clientType, clientFlavor } = this.configProvider().sessionMetaData.appMetadata;
        const headers = new Headers(options.headers);
        // This header will allow task platform service to determine which client made the request.
        // To obtain the value for this header, we concatenate the client type and client flavor fields from the app metadata.
        headers.set(TaskPlatformRequestHeaders.TasksClientType, generateTaskPlatformClientType(clientType, clientFlavor));
        // Add headers to options
        options.headers = headers;
    }
    /**
     * Initializes a new instance of the PlannerHeaderAjaxClient
     * @param innerAjaxClient Ajax client
     * @param configProvider Provider for reading configuration at execution time.
     */ constructor(innerAjaxClient, configProvider){
        this.innerAjaxClient = innerAjaxClient;
        this.configProvider = configProvider;
    }
}
