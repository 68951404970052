// Telemetry
import { resetLogHandler } from "../local/utilities/LogUtilities";
import { TraceEventLogger } from "./loggers/TraceEventLogger";
import { TraceLevel } from "./events/Trace.event";
import { EngagementLogger } from "./loggers/EngagementLogger";
import { PerformanceLogger } from "./loggers/PerformanceLogger";
import { UnhandledErrorHandler } from "./UnhandledErrorHandler";
import { UTelLogHandler } from "./UTelLogHandler";
/** Logger manager to manage log handler for the app */ export class LoggerManager {
    /**
     * Get log handler to send events
     */ getLoggers() {
        return {
            logHandler: this.logHandler,
            traceLogger: this.traceEventLogger,
            engagementLogger: this.engagementLogger,
            performanceLogger: this.performanceLogger
        };
    }
    constructor(telemetryContext, configProvider){
        this.logHandler = new UTelLogHandler();
        this.configProvider = configProvider;
        resetLogHandler(telemetryContext, this.logHandler, this.configProvider);
        const tracelevel = configProvider().settings.traceLogThreshold || TraceLevel.Info;
        this.traceEventLogger = new TraceEventLogger(tracelevel, this.logHandler);
        this.engagementLogger = new EngagementLogger(this.logHandler, this.traceEventLogger);
        this.performanceLogger = new PerformanceLogger(this.logHandler);
        UnhandledErrorHandler.registerUnhandledErrorHandler(this.traceEventLogger);
    }
}
