const DELIMITER_TEXT = ".";
/**
 * This class represents the basic properties of a engagement telemetry log along with a helper method to generate the qualified name.
 * Engagement telemetry entries will be a 5 part delimited string. The first two parts should be
 * static for the web app and set once upon app initialization by calling the initializeEngagementLoggerData()  method of EngagementLogger. The next three parts are specific to the
 * current context - the current page, the name of the control, and the name of the action within the control.
 *
 * For Example: "PJO.PJNext.newproject.PJNextLeftNav.MruClick"
 * First segment: product name
 * Second segment: client name
 * Third segment: page name
 * Fourth segment: control name
 * Fifth segment: name of the usage/engagement action taken by the user
 */ export class EngagementTelemetryLogProperties {
    /** Returns a string formatted as the fully qualified name of the tracked telemetry log. See class description for more details. */ toQualifiedName() {
        return this.product + DELIMITER_TEXT + this.client + DELIMITER_TEXT + this.currentRoute + DELIMITER_TEXT + this.component + DELIMITER_TEXT + this.name;
    }
    constructor(product, client, currentRoute, component, name){
        this.product = product;
        this.client = client;
        this.currentRoute = currentRoute;
        this.component = component;
        this.name = name;
        if (this.product == null) {
            throw new Error("ArgumentNullException: product");
        }
        if (this.client == null) {
            throw new Error("ArgumentNullException: client");
        }
        if (this.currentRoute == null) {
            throw new Error("ArgumentNullException: currentRoute");
        }
        if (this.component == null) {
            throw new Error("ArgumentNullException: component");
        }
        if (this.name == null) {
            throw new Error("ArgumentNullException: name");
        }
    }
}
