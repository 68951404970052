// Constants
import { HttpHeaders } from "@ms/uno-constants/lib/local/HttpConstants";
import moment from "moment";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import merge from "lodash/merge";
/**
 * Ajax client that adds configurable retry capabilities on top of another ajax client
 */ export class RetryAjaxClient {
    executeRequest(url, options) {
        return this.retryRequest(0, Date.now(), url, options);
    }
    /**
     * Internal method to handle retry loop logic.
     */ async retryRequest(retryCount, startTime, url, options) {
        const { maxRetryCount, retryCheck, maxRetryTimeoutInMiliseconds } = this.getRetryConfig(options);
        try {
            return await this.innerAjaxClient.executeRequest(url, options);
        } catch (error) {
            const failureResult = error;
            if (retryCount >= maxRetryCount || // Max number of retries
            Date.now() - startTime > maxRetryTimeoutInMiliseconds || // Timeout
            !await retryCheck(options, error) // Non-retriable request
            ) {
                failureResult.retryCount = retryCount;
                // The retry end log is interesting only when retrying is possible
                if (maxRetryCount !== 0) {
                    this.loggers.traceLogger.logTrace(0x1e44a0e3 /* tag_4rkd9 */ , TraceLevel.Info, `Finished Retries [Api=${options.telemetryConfig?.apiName}][Method=${options.telemetryConfig?.methodName}][RetryCount=${retryCount}][Status=${failureResult.response?.status}]`);
                }
                return Promise.reject(failureResult);
            } else {
                // Keep retrying until success or any stop condition is met
                retryCount++;
                const retryDelay = this.getRetryAfterTimeMs(failureResult.response, options);
                return new Promise((resolve, reject)=>{
                    setTimeout(()=>{
                        this.loggers.traceLogger.logTrace(0x1e44a0e2 /* tag_4rkd8 */ , TraceLevel.Info, `Retrying request [Api=${options.telemetryConfig?.apiName}][Method=${options.telemetryConfig?.methodName}][RetryCount=${retryCount}][RetryDelay=${retryDelay}][Status=${failureResult.response?.status}]`);
                        this.retryRequest(retryCount, startTime, url, options).then(resolve).catch(reject);
                    }, retryDelay);
                });
            }
        }
    }
    /**
     * Method to retrieve the retry after delay time in milliseconds
     */ getRetryAfterTimeMs(response, options) {
        const { customRetryAfterHeaderName, delayOnRetryInMiliseconds } = this.getRetryConfig(options);
        const retryAfterHeaderValue = response?.headers.get(customRetryAfterHeaderName || HttpHeaders.RetryAfter) ?? null;
        if (!retryAfterHeaderValue) {
            return delayOnRetryInMiliseconds;
        }
        // Retry after header can be an HTTP-date or an integer number of seconds
        // See: https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html (under 14.37 Retry-After)
        if (!isNaN(Number(retryAfterHeaderValue))) {
            // Retry after is in seconds. If retryAfterHeaderValue is less than or equal to 0, use default delay
            const retryAfterSeconds = parseInt(retryAfterHeaderValue, 10);
            if (retryAfterSeconds <= 0) {
                return delayOnRetryInMiliseconds;
            }
            return retryAfterSeconds * 1000;
        }
        // Retry after is an HTTP-date
        if (!moment(retryAfterHeaderValue, moment.RFC_2822, true).isValid()) {
            // Invalid HTTP-date value. Use default delay
            return delayOnRetryInMiliseconds;
        }
        const retryAfterDateMs = Date.parse(retryAfterHeaderValue);
        const timeDifferenceMs = retryAfterDateMs - new Date().getTime();
        if (timeDifferenceMs <= 0) {
            // If the retry after date is in the past or the same time, use the default delay
            return delayOnRetryInMiliseconds;
        }
        return timeDifferenceMs;
    }
    /**
     * Gets the retry configuration from the request if it exists, otherwise uses the global configuration
     * @param options The request options
     */ getRetryConfig(options) {
        // Merge the default retry config with the request specific retry config
        return merge({
            ...this.defaultRetryConfig
        }, options.retryConfig);
    }
    constructor(ajaxClient, config, loggers){
        this.loggers = loggers;
        this.innerAjaxClient = ajaxClient;
        this.defaultRetryConfig = config;
        loggers.traceLogger.logTrace(0x1e44a100 /* tag_4rkea */ , TraceLevel.Verbose, `Initializing client request retry logic [MaxRetryCount=${config.maxRetryCount}][MaxRetryTimeoutMs=${config.maxRetryTimeoutInMiliseconds}][RetryDelay=${config.delayOnRetryInMiliseconds}]`);
    }
}
