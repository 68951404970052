/**
 * ArgumentOutOfRangeError
 */ export class ArgumentOutOfRangeError extends Error {
    constructor(name, value, message){
        super();
        this.message = `${name}`;
        if (value) {
            this.message += `: ${value}`;
        }
        if (message) {
            this.message += `. ${message}`;
        }
        this.name = "ArgumentOutOfRangeError";
    }
}
