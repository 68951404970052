// Models
// Utilities
import { convertPerformanceMarkerTagToString } from "../utilities/PerformanceUtilities";
/**
 * Web platform performance marker
 */ export class WebPlatformPerformanceMarker {
    mark(tag, extraData) {
        const markTag = convertPerformanceMarkerTagToString(tag, extraData);
        performance.mark(markTag);
    }
}
