/* eslint-disable security/detect-non-literal-regexp */ /* eslint-disable @typescript-eslint/naming-convention */ export var Paths;
(function(Paths) {
    Paths["TabConfig"] = "/teamTabConfig";
    Paths["RemoveTab"] = "/removeTeamTab";
    Paths["MessageTasks"] = "/messageTasks";
    Paths["Old_ChannelTabBase"] = "/channelTab/teamtasks";
    Paths["Old_ChannelTab"] = "/channelTab/teamtasks/team/:teamId/channel/:channelId";
    Paths["ChannelTabBase"] = "/channelTab";
    Paths["ChannelPlanTab"] = "/channelTab/team/:teamId/channel/:channelId/plan/:planId/:viewMode?";
    Paths["ChannelPlanTabDefaultView"] = "/channelTab/team/:teamId/channel/:channelId/plan/:planId";
    Paths["UnoAppBase"] = "/unoApp";
    Paths["PersonalAppBase"] = "/personalApp";
    Paths["AllTaskListsBase"] = "/personalApp/alltasklists";
    Paths["TeamChannelPlanTasks"] = "/taskListType/teamTaskList/team/:teamId/channel/:channelId/plan/:planId/:viewMode";
    Paths["TaskListTypeBase"] = "/taskListType/:taskListType";
    Paths["TaskListTasks"] = "/taskListType/:taskListType/:taskListId";
    Paths["SubListTasks"] = "/subListId/:subListId";
    Paths["SmartListPlanTaskBase"] = "/plan/:planId";
    Paths["SmartListPersonalTaskBase"] = "/personalList/:personalListId";
    Paths["PublishingBase"] = "/personalApp/publish";
    Paths["PublishingBaseNoPublishing"] = "/personalApp/publish/nopublications";
    Paths["PublicationTeam"] = "/team/:teamId";
    Paths["TaskDetails"] = "/task/:taskId";
    Paths["TaskCreate"] = "/newtask";
    Paths["Publication"] = "/publication/:publicationId";
    Paths["PublicationViewMode"] = "/:viewMode";
    Paths["PublicationDraft"] = "/draft";
    Paths["PublicationTasksReport"] = "/tasksReport";
    Paths["PublicationTeamHierarchyReport"] = "/teamHierarchyReport";
    Paths["PublicationTaskTeamHierarchyReport"] = "/task/:taskId/teamHierarchyReport";
    Paths["PublicationTeamTasksReport"] = "/targetTeam/:targetTeamId/targetNode/:targetNodeId/tasksReport";
    Paths["PublicationTaskReport"] = "/targetTeam/:targetTeamId/task/:taskId";
    Paths["PublishableTaskDetails"] = "/task/:taskId/edit";
    Paths["GroupThreadIdBase"] = "/group/:threadId";
    Paths["ChatPlanDetailBase"] = "/plan/:planId";
    Paths["ChatThreadIdBase"] = "/chat/:chatThreadId";
    Paths["NavigationOriginBase"] = "/navigationOrigin/:navigationOrigin";
    Paths["ChatPlanTemplateTypeToCreateBase"] = "/planTemplateToCreate/:planTemplateType";
    Paths["DeveloperPlayground"] = "/playground";
    Paths["PublishedPublishableTaskDetails"] = "/task/:taskId";
    Paths["RemoveConsumerChatTab"] = "/removeConsumerChatTab";
    Paths["ChatConsumerTabConfig"] = "/chatConsumerTabConfig";
    Paths["ConsumerChatTabBase"] = "/consumerChatTab";
    Paths["ConsumerChatTab"] = "/consumerChatTab/chat/:chatId/plan/:planId";
    Paths["NotificationActivityTypeBase"] = "/nt/:notificationActivityType";
    Paths["ViewModePath"] = "/:viewMode?";
})(Paths || (Paths = {}));
/**
 * Route for a task list
 * /personalApp/alltasklists/taskListType/:taskListType/:taskListId
 */ export const TaskListRoute = "/personalApp/alltasklists" + "/taskListType/:taskListType/:taskListId";
/**
 * Route for a task list
 * /personalApp/alltasklists/taskListType/:taskListType/:taskListId/subListId/:subListId
 */ export const TaskSubListRoute = TaskListRoute + "/subListId/:subListId";
/**
 * Sub-route for a task from a plan that's in a smart list
 * /plan/:planId/task/:taskId
 */ export const SmartListPlanTaskSubRoute = "/plan/:planId" + "/task/:taskId";
/**
 * Sub-route for a task from a personalList that's in a smart list
 * /personalList/:personalListId/task/:taskId
 */ export const SmartListPersonalTaskSubRoute = "/personalList/:personalListId" + "/task/:taskId";
/**
 * Route for a task from a plan that's in a smart list
 * /personalApp/alltasklists/taskListType/:taskListType/:taskListId/:viewMode?/plan/:planId/:viewMode?/task/:taskId
 */ export const SmartListPlanTaskRoute = TaskListRoute + "/:viewMode?" + SmartListPlanTaskSubRoute;
/**
 * Route for a task from a plan that's in a smart list
 * /personalApp/alltasklists/taskListType/:taskListType/:taskListId/subListId/:subListId/:viewMode?/plan/:planId/:viewMode?/task/:taskId
 */ export const SmartSubListPlanTaskRoute = TaskSubListRoute + "/:viewMode?" + SmartListPlanTaskSubRoute;
/**
 * Route for a task from a personalList that's in a smart list
 * /personalApp/alltasklists/taskListType/:taskListType/:taskListId/personalList/:personalListId/task/:taskId
 */ export const SmartListPersonalTaskRoute = TaskListRoute + SmartListPersonalTaskSubRoute;
/**
 * Route for a task from a personalList that's in a smart list
 * /personalApp/alltasklists/taskListType/:taskListType/:taskListId/subListId/:subListId/personalList/:personalListId/task/:taskId
 */ export const SmartSubListPersonalTaskRoute = TaskSubListRoute + SmartListPersonalTaskSubRoute;
/**
 * Router for a task in personal list
 */ export const PersonalListTask = TaskListRoute + "/task/:taskId";
export var PlanViewMode;
(function(PlanViewMode) {
    PlanViewMode["list"] = "list";
    PlanViewMode["board"] = "board";
    PlanViewMode["chart"] = "chart";
    PlanViewMode["schedule"] = "schedule";
})(PlanViewMode || (PlanViewMode = {}));
export var TaskListType;
(function(TaskListType) {
    TaskListType["smartList"] = "smartList";
    TaskListType["personalList"] = "personalList";
    TaskListType["teamTaskList"] = "teamTaskList";
})(TaskListType || (TaskListType = {}));
export var SmartListIdType;
(function(SmartListIdType) {
    SmartListIdType["AssignedToMe"] = "SL_AssignedToMe";
    SmartListIdType["Important"] = "SL_Important";
    SmartListIdType["Planned"] = "SL_Planned";
    SmartListIdType["MyDay"] = "SL_MyDay";
    SmartListIdType["FlaggedEmail"] = "SL_FlaggedEmail";
    SmartListIdType["MyTasks"] = "SL_MyTasks";
})(SmartListIdType || (SmartListIdType = {}));
export var SmartSubListIdType;
(function(SmartSubListIdType) {
    SmartSubListIdType["AllMyTasks"] = "SL_AllMyTasks";
    SmartSubListIdType["AssignedToMe"] = "SL_AssignedToMe";
    SmartSubListIdType["FlaggedEmail"] = "SL_FlaggedEmail";
    SmartSubListIdType["PrivateTasks"] = "SL_PrivateTasks";
})(SmartSubListIdType || (SmartSubListIdType = {}));
export var PlannerPageRouteNumber;
(function(PlannerPageRouteNumber) {
    // Create or edit Planner tab in a Teams channel
    PlannerPageRouteNumber["ChannelTabConfig"] = "12";
    // Planner as a Teams tab
    PlannerPageRouteNumber["ChannelTabContent"] = "7";
    // Remove Planner tab in a Teams channel
    PlannerPageRouteNumber["RemoveChannelTab"] = "13";
    // Planner as a Teams app
    PlannerPageRouteNumber["PersonalApp"] = "8";
})(PlannerPageRouteNumber || (PlannerPageRouteNumber = {}));
export var PublicationPageMode;
(function(PublicationPageMode) {
    PublicationPageMode["draft"] = "draft";
    PublicationPageMode["tasksReport"] = "tasksReport";
    PublicationPageMode["teamHierarchyReport"] = "teamHierarchyReport";
    PublicationPageMode["targetTeam"] = "targetTeam";
    PublicationPageMode["task"] = "task";
    PublicationPageMode["published"] = "published";
})(PublicationPageMode || (PublicationPageMode = {}));
const TeamChannelTasksRegex = new RegExp("/taskListType/teamTaskList/team/:teamId/channel/:channelId/plan/:planId/:viewMode".replace(":teamId", "([^/]+)").replace(":channelId", "([^/]+)").replace(":planId", "([^/]+)").replace(":viewMode", "([^/]+)"));
const ChannelPlanTabRegex = new RegExp("/channelTab/team/:teamId/channel/:channelId/plan/:planId/:viewMode?".replace(":teamId", "([^/]+)").replace(":channelId", "([^/]+)").replace(":planId", "([^/]+)").replace("/:viewMode?", "(?:/([^/]+))?"));
export const PlanViewModeRegex = new RegExp(`^/(${"list"}|${"board"}|${"chart"}|${"schedule"})`);
export const TaskIdRegex = new RegExp("/task/:taskId".replace(":taskId", "([^/]+)"));
const RawTaskIdRegex = /^[A-Za-z0-9_@-]{10,}$/;
const TaskListIdRegex = new RegExp("/taskListType/:taskListType/:taskListId".replace(":taskListType", "([^/]+)").replace(":taskListId", "([^/]+)"));
const PersonalListIdRegex = new RegExp("/personalList/:personalListId".replace(":personalListId", "([^/]+)"));
const SubListIdRegex = new RegExp("/subListId/:subListId".replace(":subListId", "([^/]+)"));
const SmartListPlanIdRegex = new RegExp("/plan/:planId".replace(":planId", "([^/]+)"));
const ViewModeRegex = new RegExp(`/(${"list"}|${"board"}|${"chart"}|${"schedule"})`);
/**
 * Checks if we have a (legacy) Planner iframe created task link which has only "taskId" as subEntityId
 * @param subEntityId The subentityId from Teams
 * @returns True if the subEntityId looks like a plain task ID
 */ export const isRawTaskId = (subEntityId)=>RawTaskIdRegex.test(subEntityId);
/**
 * Parses the subEntityId given by TeamsContext that contains deeplink information,
 * into route params so we can route to the appropriate UI for this deeplink.
 * @param subEntityId The value from TeamsContext to be parsed for deeplinking. Examples:
 * * Task Assigned Notification Deeplink (personal app) = `"/nt/TaskAssignedToYou/taskListType/smartList/SL_AssignedToMe/plan/somePlanId/task/someTaskId"`
 * * Task in a plan (personal app) = `"/taskListType/teamTaskList/team/someTeamId/channel/somechannelId/plan/somePlanId/list/task/someTaskId"`
 * * Task in a plan (channel tab) = `"/board/task/ZappiIyx1ki5EX3gd0R9o5UAKjJ1"`
 * * Legacy Planner task (channel tab) = just `"taskId"` like `"PxLTC0d5A0-RGhdk_b0q5WUAABcv"_`,
 * @returns object with parsed values form subEntityId, or null is unparseable
 */ export function parseSubEntityPath(subEntityId) {
    const parsed = {};
    let isChannelTabView = false;
    if (subEntityId) {
        if (subEntityId.startsWith("/channelTab")) {
            parsed.taskListType = "teamTaskList";
            isChannelTabView = true;
        } else if (subEntityId.startsWith("/personalApp/alltasklists")) {
            subEntityId = subEntityId.replace("/personalApp/alltasklists", "");
        }
        if (!parsed.taskListType) {
            const taskListTypeMatches = subEntityId.match(TaskListIdRegex);
            if (taskListTypeMatches?.length) {
                parsed.taskListType = TaskListType[taskListTypeMatches[1]] || null;
                parsed.taskListId = taskListTypeMatches[2] || null;
            }
            const optionalSubListTypeMatches = subEntityId.match(SubListIdRegex);
            if (optionalSubListTypeMatches?.length) {
                parsed.subListId = optionalSubListTypeMatches[1] || null;
            }
        }
        const teamAndChannelMatches = isChannelTabView ? subEntityId.match(ChannelPlanTabRegex) : subEntityId.match(TeamChannelTasksRegex);
        if (teamAndChannelMatches) {
            parsed.teamId = teamAndChannelMatches.length ? teamAndChannelMatches[1] : null;
            parsed.channelId = teamAndChannelMatches.length >= 1 ? teamAndChannelMatches[2] : null;
            parsed.planId = teamAndChannelMatches.length > 2 ? teamAndChannelMatches[3] : null;
            parsed.viewMode = teamAndChannelMatches.length > 3 ? teamAndChannelMatches[4] : null;
        } else if (parsed.taskListType === "smartList") {
            const planMatches = subEntityId.match(SmartListPlanIdRegex);
            if (planMatches) {
                parsed.planId = planMatches.length ? planMatches[1] : null;
            }
            // Smart list can possibly include viewmode at the end of the subEntityId
            const viewModeMatches = subEntityId.match(ViewModeRegex);
            if (viewModeMatches && viewModeMatches.length > 0) {
                parsed.viewMode = viewModeMatches[1];
            }
        }
        // If it's a simple plan view mode deeplink for channel tab
        // like "/board/task/ZappiIyx1ki5EX3gd0R9o5UAKjJ1", parse the viewMode out
        if (!parsed.viewMode) {
            const planViewModeMatches = subEntityId.match(PlanViewModeRegex);
            if (planViewModeMatches) {
                parsed.viewMode = planViewModeMatches.length >= 2 ? planViewModeMatches[1] : null;
            }
        }
        //If it's a task deeplink like ".../task/:taskId" parse the taskId out
        const taskMatches = subEntityId.match(TaskIdRegex);
        if (taskMatches?.length) {
            parsed.taskId = taskMatches[1];
        }
        // If it's a personal list deeplink like ".../personalList/:personalListId" parse the personalListId out
        const personalListMatches = subEntityId.match(PersonalListIdRegex);
        if (personalListMatches?.length) {
            parsed.personalListId = personalListMatches[1];
        }
        // If couldn't parse any params from subEntityId yet, it could be
        // a (legacy) Planner iframe created task link which has only "taskId" as subEntityId
        // (user copied from Planner iframe for Board/Chart/Schedule view before copylink planner-frame-api was shipped )
        if (Object.keys(parsed).length === 0 && isRawTaskId(subEntityId)) {
            //validate taskId characters
            parsed.taskId = subEntityId;
        }
        // Return parsed params only if any were parsed.
        // If no params were parsed, don't return empty object here. So that at the end we'll return null.
        if (Object.keys(parsed).length) {
            return parsed;
        }
    }
    return null;
}
/**
 * Path to a smart task list, like Important or Planned
 * @param routePath
 * @param params
 */ export function getSmartListPath(routePath, params) {
    params = {
        ...params,
        taskListType: "smartList"
    };
    if (routePath.startsWith("/personalApp/alltasklists")) {
        let smartListPath = "/personalApp/alltasklists" + "/taskListType/:taskListType/:taskListId";
        if (!!params.subListId) {
            smartListPath = smartListPath + "/subListId/:subListId";
        }
        if (!!params.viewMode) {
            smartListPath = smartListPath + "/:viewMode?";
        }
        smartListPath = smartListPath.replace(":taskListType", params.taskListType).replace(":taskListId", params.taskListId).replace(":subListId", params.subListId || "").replace(":viewMode?", params.viewMode || "");
        return smartListPath;
    } else {
        return null;
    }
}
