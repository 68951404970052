// Utilities
import { EventType } from "../UTelLogHandler";
import { createExtraDataField, logEvent } from "../LoggingHelper";
import { MarkerTag } from "../performance/constants/PerformanceMarkerConstants";
import { EntryType } from "../performance/constants/PerformanceEntryType";
/**
 * Logs the event using UTelJs
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ function LogUTel(event, logHandler) {
    if (event == null) {
        return;
    }
    const payload = [];
    const { entry, version } = event;
    payload.push(createExtraDataField(EventType.Performance, "version", version));
    if (entry.entryType === EntryType.MarkEntry) {
        payload.push(createExtraDataField(EventType.Performance, "entryType", entry.entryType));
        const entryTag = entry.tag;
        payload.push(createExtraDataField(EventType.Performance, "tag", MarkerTag[entryTag]));
        payload.push(createExtraDataField(EventType.Performance, "markTime", String(entry.markTime)));
        if (entry.extraData) {
            for (const [extraDataKey, extraDataValue] of Object.entries(entry.extraData)){
                payload.push(createExtraDataField(EventType.Performance, extraDataKey, String(extraDataValue)));
            }
        }
    } else {
        for (const [entryKey, entryValue] of Object.entries(entry)){
            const valueToLog = entryKey === "attribution" ? JSON.stringify(entryValue) : String(entryValue);
            payload.push(createExtraDataField(EventType.Performance, entryKey, valueToLog));
        }
    }
    logHandler.log({
        type: EventType.Performance,
        payload: payload
    });
}
/**
 * Logs the event with all available loggers
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ export function Log(event, logHandler) {
    logEvent(event, LogUTel, logHandler);
}
