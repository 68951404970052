import { EventType } from "../UTelLogHandler";
import { createExtraDataField, logEvent } from "../LoggingHelper";
/**
 * Logs the event using UTelJs
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ function LogUTel(event, logHandler) {
    if (event == null) {
        return;
    }
    const payload = [];
    for (const [key, value] of Object.entries(event)){
        if (key === "extraData" && value != null) {
            for (const [extraDataKey, extraDataValue] of Object.entries(value)){
                payload.push(createExtraDataField(EventType.Performance, extraDataKey, String(extraDataValue)));
            }
        } else {
            payload.push(createExtraDataField(EventType.Performance, key, String(value)));
        }
    }
    logHandler.log({
        type: EventType.Performance,
        payload: payload
    });
}
/**
 * Logs the event with all available loggers
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ export function Log(event, logHandler) {
    logEvent(event, LogUTel, logHandler);
}
