// Actions
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
// Stores
import { EventPublishingStore } from "@ms/uno-fluxcore/lib/local/stores/EventPublishingStore";
// Utilities
import { UnoViewStatus, UnoChildView } from "@ms/uno-models/lib/local/constants/UnoViewConstants";
import { logUnreachableAction } from "../../utilities/LoggingUtilities";
/**
 * Uno Shell store that keeps track of each views state
 */ export class UnoShellStore extends EventPublishingStore {
    get name() {
        return "UnoShellStore";
    }
    getChildViewInstantiationStatus(childView) {
        return this.childViewStatus[childView];
    }
    handleAction(action) {
        let storeChanged = false;
        switch(action.type){
            case ActionType.UpdateChildViewStatusAction:
                storeChanged = this.updateChildViewStatus(action.childView, action.state);
                break;
            default:
                logUnreachableAction(this, this.loggers, action);
                break;
        }
        if (storeChanged) {
            this.publishStoreChangedEvent();
        }
    }
    updateChildViewStatus(childView, status) {
        this.childViewStatus[childView] = status;
        return true;
    }
    constructor(loggers){
        super(), this.loggers = loggers, this.childViewStatus = {};
        this.childViewStatus[UnoChildView.PremiumPlan] = UnoViewStatus.NotLoaded;
        this.childViewStatus[UnoChildView.Portfolio] = UnoViewStatus.NotLoaded;
    }
}
