/**
 * Factory class for worker providers in Base app
 */
export class BaseWorkerFactory {
    /**
     * Initialize the worker factory
     * @param loader Component loader
     * @param loggers Loggers for telemetry
     */
    constructor(loader, loggers) {
        this.loader = loader;
        this.loggers = loggers;
    }
    getAugloopTileSyncWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("auglooptilesync"), () => import(
        /* webpackChunkName: "uno-auglooptilesyncworker" */ "@ms/uno-workers/lib/local/workers/augloop/AugloopTileSyncWorker"), (bundle) => new bundle.AugloopTileSyncWorker(acProviders, storeProviders, configProvider));
    }
    getBasicPlanConversionTrackerWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("basicplanconversiontracker"), () => import(
        /* webpackChunkName: "uno-basicplanconversiontrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/BasicPlanConversionTrackerWorker"), (bundle) => new bundle.BasicPlanConversionTrackerWorker(acProviders, storeProviders, this.loggers, configProvider));
    }
    getCdsProvisioningStatusWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("cdsProvisioningStatus"), () => import(
        /* webpackChunkName: "uno-cdsProvisioningStatusWorker" */ "@ms/uno-workers/lib/local/workers/plan/CdsProvisioningStatusWorker"), (bundle) => new bundle.CdsProvisioningStatusWorker(acProviders, storeProviders, this.loggers, configProvider));
    }
    getDeltaSyncWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("deltasync"), () => Promise.all([
            import(/* webpackChunkName: "uno-deltasyncworker" */ "@ms/uno-workers/lib/local/workers/deltaSync/DeltaSyncWorker"),
            import(
            /* webpackChunkName: "uno-exponentialbackoffutility" */ "@ms/uno-workers/lib/local/utilities/backoff/ExponentialBackoffUtility"),
            import(
            /* webpackChunkName: "uno-pagevisibilitystatusprovider" */ "@ms/uno-workers/lib/local/utilities/pageVisibility/PageVisibilityStatusProvider"),
        ]), ([worker, backoffUtility, pageVisibilityStatusProvider]) => {
            const deltaSyncConfiguration = configProvider().settings.deltaSyncConfiguration;
            return new worker.DeltaSyncWorker(acProviders, storeProviders, configProvider, new backoffUtility.ExponentialBackoffUtility(deltaSyncConfiguration.baseInterval, deltaSyncConfiguration.cutoff, deltaSyncConfiguration.backoffThreshold), new pageVisibilityStatusProvider.PageVisibilityStatusProvider(), this.loggers);
        });
    }
    getDeltaSyncFeedProcessorWorkerProvider(acProviders, storeProviders) {
        return () => this.loader.load(this.getWorkerBundleKey("deltasyncfeedprocessor"), () => import(
        /* webpackChunkName: "uno-deltasyncfeedprocessorworker" */ "@ms/uno-workers/lib/local/workers/deltaSync/DeltaSyncFeedProcessorWorker"), (bundle) => new bundle.DeltaSyncFeedProcessorWorker(acProviders, storeProviders));
    }
    getHistoryObserverWorkerProvider(acProviders, storeProviders, routePathPrefix, browserPathProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("historyobserver"), () => import(
        /* webpackChunkName: "uno-historyobserverWorker" */ "@ms/uno-workers/lib/local/workers/history/HistoryObserverWorker"), (bundle) => new bundle.HistoryObserverWorker(acProviders, storeProviders, routePathPrefix, browserPathProvider));
    }
    getNavigationWorkerProvider(acProviders, storeProviders) {
        return () => this.loader.load(this.getWorkerBundleKey("nav"), () => import(/* webpackChunkName: "uno-navworker" */ "@ms/uno-workers/lib/local/workers/plan/NavigationWorker"), (bundle) => new bundle.NavigationWorker(acProviders, storeProviders, this.loggers));
    }
    getNetworkConnectivityWorkerProvider() {
        return () => this.loader.load(this.getWorkerBundleKey("networkconnectivity"), () => import(
        /* webpackChunkName: "uno-networkconnectivityworker" */ "@ms/uno-workers/lib/local/workers/network/NetworkConnectivityWorker"), (bundle) => new bundle.NetworkConnectivityWorker(this.loggers.logHandler));
    }
    getOperationTrackingWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("operationtracking"), () => import(
        /* webpackChunkName: "uno-operationtrackingworker" */ "@ms/uno-workers/lib/local/workers/operation/OperationTrackingWorker"), (bundle) => new bundle.OperationTrackingWorker(acProviders, storeProviders, this.loggers, configProvider));
    }
    getPerformanceObserverWorkerProvider(telemetryLogger, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("performanceobserver"), () => import(
        /* webpackChunkName: "uno-performanceobserverworker" */ "@ms/uno-workers/lib/local/workers/performance/WebPlatformPerformanceObserverWorker"), (bundle) => new bundle.WebPlatformPerformanceObserverWorker(telemetryLogger, configProvider));
    }
    getPlanConversionStatusTrackerWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("planconversionstatustracker"), () => import(
        /* webpackChunkName: "uno-planconversionstatustrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/PlanConversionStatusTrackerWorker"), (bundle) => new bundle.PlanConversionStatusTrackerWorker(acProviders, storeProviders, this.loggers, configProvider));
    }
    getPremiumPlanCreationStatusWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("premiumplancreationstatus"), () => import(
        /* webpackChunkName: "uno-premiumplancreationstatusworker" */ "@ms/uno-workers/lib/local/workers/plan/PremiumPlanCreationStatusWorker"), (bundle) => new bundle.PremiumPlanCreationStatusWorker(acProviders, storeProviders, this.loggers, configProvider));
    }
    getProjectTrialOperationTrackerWorkerProvider(acProviders, storeProviders) {
        return () => this.loader.load(this.getWorkerBundleKey("projecttrialoperationtracker"), () => import(
        /* webpackChunkName: "uno-projecttrialoperationtrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/ProjectTrialOperationTrackerWorker"), (bundle) => new bundle.ProjectTrialOperationTrackerWorker(acProviders, storeProviders));
    }
    getRecommendedPlanUpdateWorkerProvider(acProviders, storeProviders, configProvider) {
        return () => this.loader.load(this.getWorkerBundleKey("recommendedplanupdate"), () => import(
        /* webpackChunkName: "uno-recommendedplanupdateworker" */ "@ms/uno-workers/lib/local/workers/plan/RecommendedPlansUpdateWorker"), (bundle) => new bundle.RecommendedPlansUpdateWorker(acProviders, storeProviders, this.loggers, configProvider));
    }
    /**
     * Get the worker bundle key for the given worker name
     * @param workerName
     */
    getWorkerBundleKey(workerName) {
        return `uno-${workerName}worker`;
    }
}
