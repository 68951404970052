// Models
// Utilities
import remove from "lodash/remove";
/**
 * Abstract class that handles event pub/sub for all Stores.
 * Stores need to be able to publish change events to all subscribers.
 */ export class EventPublishingStore {
    /**
     * Get the number of subscribers
     */ getSubscriberCount() {
        return this.listeners.length;
    }
    /**
     * Subscribe to this Store with a callback method
     * @param callback - Method to be called on Store changes
     */ subscribeToStore(callback) {
        this.listeners.push(callback);
    }
    /**
     * Unsubscribe from this Store
     * @param callback - Method to remove from list of callbacks
     */ unsubscribeFromStore(callback) {
        remove(this.listeners, (cb)=>{
            return cb === callback;
        });
    }
    /**
     * Publish a 'store changed' event, which will call all subscribers' callback methods.
     */ publishStoreChangedEvent() {
        for (const callback of this.listeners){
            callback?.();
        }
    }
    constructor(){
        this.listeners = [];
    }
}
