// Actions
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
// Constants
import { HttpStatus } from "@ms/uno-constants/lib/local/HttpStatusConstants";
import { StoreEntityStatus } from "@ms/uno-constants/lib/local/StoreConstants";
import { ProjectSubscriptionState } from "@ms/uno-constants/lib/local/UpsellConstants";
import { ClientType } from "@ms/uno-constants/lib/local/AppConstants";
import { FormerMember } from "@ms/uno-models/lib/local/client/user/FormerUser";
import { UnknownUser } from "@ms/uno-models/lib/local/client/user/UnknownUser";
import { Entity } from "@ms/uno-entitycore/lib/local/Entity";
// Stores
import { EventPublishingStore } from "@ms/uno-fluxcore/lib/local/stores/EventPublishingStore";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import { calculateRemainingDaysInTrial } from "@ms/uno-utilities/lib/local/UpsellUtilities";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { logUnreachableAction } from "../../utilities/LoggingUtilities";
import { getStatusBasedOnResponse } from "../../utilities/StatusUtilities";
/**
 * Store for user related data
 */ export class UserStore extends EventPublishingStore {
    get name() {
        return "UserStore";
    }
    getUserById(id) {
        const user = this.users[id];
        const status = this.userStatus[id] ?? StoreEntityStatus.Unfetched;
        return {
            data: user ?? null,
            status: status
        };
    }
    getUserSettings(readBaseline = false) {
        const status = this.userSettingsStatus;
        return {
            data: readBaseline ? this.userSettings?.baseline ?? null : this.userSettings?.value ?? null,
            status: status
        };
    }
    getLandingPageNotificationSettings() {
        return this.landingPageNotificationSettings;
    }
    getUserPhoto(userId) {
        const photo = this.userPhotos[userId] ?? null;
        const status = this.userPhotosStatus[userId] ?? StoreEntityStatus.Unfetched;
        const user = this.getUserById(userId).data;
        if (photo == null && (user == null || !user.displayName)) {
            // We don't have either user or user displayName for placeholder, fallback to FormerMember.Photo
            return {
                data: FormerMember.Photo,
                status: StoreEntityStatus.Present
            };
        }
        return {
            data: photo,
            status
        };
    }
    getUsersByKeyword(keyword) {
        const status = this.userIdsByKeywordStatus[keyword] ?? StoreEntityStatus.Unfetched;
        const usersByKeywordResult = this.usersByKeywordResults[keyword];
        if (usersByKeywordResult == null) {
            return {
                data: null,
                status
            };
        }
        if (this.isUsersByKeywordResultExpired(usersByKeywordResult)) {
            return {
                data: null,
                status: StoreEntityStatus.Unfetched
            };
        }
        const { userIds } = usersByKeywordResult;
        const users = compact(userIds.map((userId)=>this.users[userId]));
        return {
            data: users,
            status
        };
    }
    getContainerUsers(containerId) {
        const userIdList = this.containerUsers[containerId];
        const status = this.containerUsersStatus[containerId] ?? StoreEntityStatus.Unfetched;
        if (userIdList == null) {
            return {
                data: null,
                status: status
            };
        }
        const users = [];
        userIdList.forEach((userId)=>{
            users.push(this.users[userId]);
        });
        return {
            data: users,
            status: status
        };
    }
    getUserByEmail(email) {
        if (email === FormerMember.Mail) {
            return {
                data: UnknownUser.getFormerMember(),
                status: StoreEntityStatus.Present
            };
        }
        const matchedUsers = Object.values(this.users).filter((user)=>{
            let match = false;
            if (user.mail === email || user.userPrincipalName === email) {
                match = true;
            } else {
                user.proxyAddresses.forEach((otherMailAddress)=>{
                    if (otherMailAddress === email) {
                        match = true;
                    }
                });
            }
            return match;
        });
        if (matchedUsers.length > 0) {
            return {
                data: matchedUsers[0],
                status: StoreEntityStatus.Present
            };
        } else {
            return {
                data: null,
                status: StoreEntityStatus.Unfetched
            };
        }
    }
    getTeamsChatMembers(chatId) {
        const userIdList = this.chatMembers[chatId];
        const status = this.chatMembersStatus[chatId] ?? StoreEntityStatus.Unfetched;
        if (userIdList == null) {
            return {
                data: null,
                status: status
            };
        }
        const users = [];
        userIdList.forEach((userId)=>{
            users.push(this.users[userId]);
        });
        return {
            data: users,
            status: status
        };
    }
    getUserRoamingData() {
        return {
            data: this.userRoamingData,
            status: this.userRoamingDataStatus
        };
    }
    shouldUserSeeTrialReminder(highestTrialSubscription) {
        // Check user's subscription status
        const remainingDaysInTrial = calculateRemainingDaysInTrial(highestTrialSubscription.expiryDate);
        const eligibleStatus = highestTrialSubscription.subscriptionStatus === ProjectSubscriptionState.Active && remainingDaysInTrial <= 15 && remainingDaysInTrial > 0 || highestTrialSubscription.subscriptionStatus === ProjectSubscriptionState.Disabled;
        // Check user's roaming data
        const userRoamingData = this.getUserRoamingData();
        if (!userRoamingData?.data) {
            return false;
        }
        return userRoamingData.data.showTrialReminder && eligibleStatus;
    }
    shouldUserSeeFirstRunExperience() {
        // Check user's roaming data
        const userRoamingData = this.getUserRoamingData();
        if (userRoamingData.data == null) {
            return false;
        }
        const clientType = this.configProvider().sessionMetaData.appMetadata.clientType;
        let freKey;
        if (clientType === ClientType.Teams) {
            freKey = "lastFirstRunExperienceDate";
        } else if (clientType === ClientType.Web) {
            freKey = "lastFirstRunExperienceDateWeb";
        } else {
            return false;
        }
        if (userRoamingData.data[freKey] == null) {
            return true;
        }
        return moment(this.configProvider().settings.showFirstRunExperienceAfter).isAfter(userRoamingData.data[freKey]);
    }
    handleAction(action) {
        let storeChanged = false;
        switch(action.type){
            case ActionType.FetchUserAction:
                storeChanged = this.handleFetchUserAction(action);
                break;
            case ActionType.FetchUserSucceededAction:
                storeChanged = this.handleFetchUserSucceededAction(action);
                break;
            case ActionType.FetchUserFailedAction:
                storeChanged = this.handleFetchUserFailedAction(action);
                break;
            case ActionType.FetchUserPhotoSucceededAction:
                storeChanged = this.handleFetchUserPhotosSucceededAction(action);
                break;
            case ActionType.FetchUserPhotoFailedAction:
                storeChanged = this.handleFetchUserPhotoFailedAction(action);
                break;
            case ActionType.FetchUsersByKeywordAction:
                storeChanged = this.handleFetchUsersByKeywordAction(action);
                break;
            case ActionType.FetchUsersByKeywordSucceededAction:
                storeChanged = this.handleFetchUsersByKeywordSucceededAction(action);
                break;
            case ActionType.FetchUsersByKeywordFailedAction:
                storeChanged = this.handleFetchUsersByKeywordFailedAction(action);
                break;
            case ActionType.FetchContainerMembersAction:
                storeChanged = this.handleFetchContainerMembersAction(action);
                break;
            case ActionType.FetchGroupMembersSucceededAction:
                storeChanged = this.handleFetchGroupMembersSucceededAction(action);
                break;
            case ActionType.FetchRosterMembersSucceededAction:
                storeChanged = this.handleFetchRosterMembersSucceededAction(action);
                break;
            case ActionType.FetchContainerMembersFailedAction:
                storeChanged = this.handleFetchContainerMembersFailedAction(action);
                break;
            case ActionType.FetchTodoMembersSucceededAction:
                storeChanged = this.handleFetchTodoMembersSucceededAction(action);
                break;
            case ActionType.FetchTeamsChatMembersSucceeded:
                storeChanged = this.handleFetchTeamsChatMembersSucceededAction(action);
                break;
            case ActionType.FetchRoamingUserDataSucceededAction:
                storeChanged = this.handleFetchRoamingUserDataSucceededAction(action);
                break;
            case ActionType.FetchRoamingUserDataFailedAction:
                storeChanged = this.handleFetchRoamingUserDataFailed();
                break;
            case ActionType.UpdateRoamingUserDataSucceededAction:
                storeChanged = this.handleUpdateRoamingUserDataSucceededAction(action);
                break;
            case ActionType.UpdateRoamingUserDataFailedAction:
                storeChanged = this.handleUpdateRoamingUserDataFailed();
                break;
            case ActionType.AddGroupMembersAction:
                storeChanged = this.handleAddGroupMembersAction(action);
                break;
            case ActionType.AddGroupMembersSucceededAction:
                storeChanged = this.handleAddGroupMembersSucceededAction(action);
                break;
            case ActionType.AddGroupMembersFailedAction:
                storeChanged = this.handleAddGroupMembersFailedAction(action);
                break;
            case ActionType.RemoveGroupMemberAction:
                storeChanged = this.handleRemoveGroupMemberAction(action);
                break;
            case ActionType.RemoveGroupMemberSucceededAction:
                storeChanged = this.handleRemoveGroupMemberSucceededAction(action);
                break;
            case ActionType.RemoveGroupMemberFailedAction:
                storeChanged = this.handleRemoveGroupMemberFailedAction(action);
                break;
            case ActionType.FetchUserSettingsSucceededAction:
                storeChanged = this.handleFetchUserSettingsSucceededAction(action);
                break;
            case ActionType.UpdateUserSettingsAction:
                storeChanged = this.handleUpdateUserSettingsAction(action);
                break;
            case ActionType.UpdateUserSettingsSucceededAction:
                storeChanged = this.handleUpdateUserSettingsSucceededAction(action);
                break;
            case ActionType.UpdateUserSettingsFailedAction:
                storeChanged = this.handleUpdateUserSettingsFailedAction(action);
                break;
            case ActionType.DeltaSyncUpdateUserSettingsAction:
                storeChanged = this.handleDeltaSyncUpdateUserSettingsAction(action);
                break;
            case ActionType.FetchLandingPageNotificationSettingsSucceededAction:
                storeChanged = this.handleFetchLandingPageNotificationSettingsSucceededAction(action);
                break;
            case ActionType.UpdateLandingPageNotificationSettingsSucceededAction:
                storeChanged = this.handleUpdateLandingPageNotificationSettingsSucceededAction(action);
                break;
            default:
                logUnreachableAction(this, this.loggers, action);
                break;
        }
        if (storeChanged) {
            this.publishStoreChangedEvent();
        }
    }
    /**
     * Handler for FetchUserSettingsSucceededAction
     * @param action The fetch user settings succeeded action
     */ handleFetchUserSettingsSucceededAction(action) {
        const { userSettings } = action;
        let storeChanged = false;
        const storeCopy = this.userSettings ?? null;
        if (storeCopy == null) {
            storeChanged = true;
            this.addUserSettingsAndStatus(userSettings);
        } else if (storeCopy.baseline.itemVersion < userSettings.itemVersion) {
            storeCopy.updateBaseline(userSettings);
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for UpdateUserSettingsAction
     * @param action The update roaming user data action
     */ handleUpdateUserSettingsAction(action) {
        let storeChanged = false;
        const { delta, updateKey } = action;
        const storeCopy = this.userSettings;
        if (storeCopy == null) {
            const msg = `StoreException: UserSettings not found in store`;
            this.loggers.traceLogger.logTrace(0x1e2d8554 /* tag_4lyvu */ , TraceLevel.Error, msg);
            throw new Error(msg);
        }
        const updatedUserSettings = storeCopy.value.getClone().applyDiffs(delta);
        if (!isEqual(storeCopy.value, updatedUserSettings)) {
            this.loggers.traceLogger.logTrace(0x1e2d8553 /* tag_4lyvt */ , TraceLevel.Verbose, `Pushing delta for UserSettings [UpdateKey=${updateKey}][DeltaCount=${storeCopy.deltaCount}]`);
            storeCopy.pushDelta(delta, updateKey);
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for UpdateUserSettingsSucceededAction
     * @param action The update user settings succeeded action
     */ handleUpdateUserSettingsSucceededAction(action) {
        let storeChanged = false;
        const { userSettings, updateKey } = action;
        const storeCopy = this.userSettings;
        if (storeCopy == null) {
            // This can't happen since you can't delete UserSettings (diffsync)
            this.loggers.traceLogger.logTrace(0x1e2d848a /* tag_4lysk */ , TraceLevel.Warning, "StoreException: UserSettings not found in store");
            return false;
        }
        // Commit the pending update
        if (storeCopy.getDelta(updateKey) != null) {
            storeCopy.commitDelta(updateKey, userSettings);
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for UpdateUserSettingsFailedAction
     * @param action The update user settings failed action
     */ handleUpdateUserSettingsFailedAction(action) {
        let storeChanged = false;
        const { response, updateKey } = action;
        const storeCopy = this.userSettings;
        if (storeCopy == null) {
            // Since you can't delete UserSettings (diffsync) this should never happen
            const msg = `StoreException: UserSettings not found in store`;
            this.loggers.traceLogger.logTrace(0x1e2d8552 /* tag_4lyvs */ , TraceLevel.Error, msg);
            throw new Error(msg);
        }
        // Revert the delta if it exists
        if (storeCopy.getDelta(updateKey) != null) {
            storeCopy.revertDelta(updateKey);
            storeChanged = true;
        }
        // Update the status if necessary
        if (response != null) {
            storeChanged = this.updateUserSettingsStatusBasedOnResponse(response) || storeChanged;
        }
        return storeChanged;
    }
    /**
     * Handles the DeltaSyncUpdateUserSettingsAction
     * @param action The delta sync action
     */ handleDeltaSyncUpdateUserSettingsAction(action) {
        let storeChanged = false;
        const { updatedUserSettings } = action;
        const storeCopy = this.userSettings;
        if (storeCopy == null) {
            const msg = `StoreException: UserSettings not found in store`;
            this.loggers.traceLogger.logTrace(0x1e2d8551 /* tag_4lyvr */ , TraceLevel.Error, msg);
            throw new Error(msg);
        }
        // Only need to apply the update if the incoming bucket is higher versioned than the store baseline copy
        if (storeCopy.baseline.itemVersion < updatedUserSettings.itemVersion) {
            storeCopy.updateBaseline(updatedUserSettings);
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchLandingPageNotificationSettingsSucceededAction
     * @param action The fetch landing page notification settings succeeded action
     */ handleFetchLandingPageNotificationSettingsSucceededAction(action) {
        this.landingPageNotificationSettings = action.notificationSettings;
        return true;
    }
    /**
     * Handler for UpdateLandingPageNotificationSettingsSucceededAction
     * @param action The update landing page notification settings succeeded action
     */ handleUpdateLandingPageNotificationSettingsSucceededAction(action) {
        this.landingPageNotificationSettings = action.notificationSettings;
        return true;
    }
    /**
     * Handler for FetchUserAction
     * @param action Action fired when fetching a user
     */ handleFetchUserAction(action) {
        if (this.userStatus[action.userId] === StoreEntityStatus.Fetching) {
            return false;
        }
        this.userStatus[action.userId] = StoreEntityStatus.Fetching;
        return true;
    }
    /**
     * Handler for FetchUserSucceededAction
     * @param action Action fired when fetching a user succeeded
     */ handleFetchUserSucceededAction(action) {
        const { user } = action;
        let storeChanged = false;
        const storeCopy = this.users[user.id] ?? null;
        if (storeCopy == null || !isEqual(storeCopy, user)) {
            this.addUserAndStatus(user);
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchUserFailedAction
     * @param action Action fired when fetching a user failed
     */ handleFetchUserFailedAction(action) {
        let storeChanged = false;
        const { response, userId } = action;
        // Update the status if appropriate
        if (response?.status === HttpStatus.NotFound || response?.status === HttpStatus.Forbidden) {
            storeChanged = this.updateUserStatusBasedOnResponse(userId, response);
        } else {
            this.userStatus[userId] = StoreEntityStatus.UnableToFetch;
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchUserPhotoSucceededAction
     * @param action Action fired when fetching user photos succeeded
     */ handleFetchUserPhotosSucceededAction(action) {
        const { userId, userPhoto } = action;
        let storeChanged = false;
        const storeCopy = this.userPhotos[userId] ?? null;
        if (storeCopy !== userPhoto) {
            this.userPhotos[userId] = userPhoto;
            storeChanged = true;
        }
        const storeStatus = this.userPhotosStatus[userId] ?? null;
        if (storeStatus !== StoreEntityStatus.Present) {
            this.userPhotosStatus[userId] = StoreEntityStatus.Present;
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchUserPhotoFailedAction
     * @param action Action fired when fetching user photo failed
     */ handleFetchUserPhotoFailedAction(action) {
        // action.userId is null, undefined or empty for users who are commenters on a conversation but have since left the container
        const { userId } = action;
        if (!userId) {
            return false;
        }
        const storeCopy = this.userPhotos[userId];
        if (storeCopy == null) {
            // Set to fail so we don't fetch the photo again
            this.userPhotos[userId] = "";
            this.userPhotosStatus[userId] = StoreEntityStatus.UnableToFetch;
            return true;
        }
        return false;
    }
    /**
     * Handler for FetchUsersByKeywordAction
     */ handleFetchUsersByKeywordAction(action) {
        let storeChanged = false;
        const { keyword } = action;
        // Only update status to Fetching.
        // Keep existing results in the store to avoid making extra API calls
        if (this.userIdsByKeywordStatus[keyword] !== StoreEntityStatus.Fetching) {
            this.userIdsByKeywordStatus[keyword] = StoreEntityStatus.Fetching;
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchUsersByKeywordSucceededAction
     */ handleFetchUsersByKeywordSucceededAction(action) {
        let storeChanged = false;
        const { keyword, users } = action;
        // Update status
        if (this.userIdsByKeywordStatus[keyword] !== StoreEntityStatus.Present) {
            this.userIdsByKeywordStatus[keyword] = StoreEntityStatus.Present;
            storeChanged = true;
        }
        // Only update empty or expired results
        const usersByKeywordResult = this.usersByKeywordResults[keyword];
        if (usersByKeywordResult == null || this.isUsersByKeywordResultExpired(usersByKeywordResult)) {
            // Update users by keyword results and include current timestamp
            const userIds = users.map((user)=>user.id);
            this.usersByKeywordResults[keyword] = {
                userIds,
                fetchResultTimestamp: moment()
            };
            // Update users data
            users.forEach((user)=>{
                this.addUserAndStatus(user);
            });
            return true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchUsersByKeywordFailedAction
     */ handleFetchUsersByKeywordFailedAction(action) {
        let storeChanged = false;
        const { keyword } = action;
        if (this.userIdsByKeywordStatus[keyword] !== StoreEntityStatus.UnableToFetch) {
            this.userIdsByKeywordStatus[keyword] = StoreEntityStatus.UnableToFetch;
            storeChanged = true;
        }
        if (this.usersByKeywordResults[keyword] != null) {
            delete this.usersByKeywordResults[keyword];
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchContainerMembersAction
     * @param action Action fired when fetching users for container
     */ handleFetchContainerMembersAction(action) {
        if (this.containerUsersStatus[action.containerId] === StoreEntityStatus.Fetching) {
            return false;
        }
        this.containerUsersStatus[action.containerId] = StoreEntityStatus.Fetching;
        return true;
    }
    /**
     * Handle getting users for a group container
     * @param action Fired when users are successfully retrieved for the plan container
     */ handleFetchGroupMembersSucceededAction(action) {
        let storeChanged = false;
        const containerUserIdList = [];
        action.users.forEach((user)=>{
            const userStoreCopy = this.getUserById(user.id).data;
            if (userStoreCopy == null) {
                if (user.displayName == null) {
                    this.loggers.traceLogger.logTrace(0x1e45240e /* tag_4rsqo */ , TraceLevel.Warning, `User.displayName is null [UserId=${user.id}]`);
                }
                this.users[user.id] = user;
                storeChanged = true;
            }
            containerUserIdList.push(user.id);
        });
        if (action.isFirstPage) {
            // First page of results for the container, so refresh user list
            this.containerUsers[action.groupId] = containerUserIdList;
            storeChanged = true;
        } else {
            // Add result to end of user list
            const existingUserList = this.containerUsers[action.groupId] || [];
            // Add all items in containerUserIdList that haven't already been added to existingUserList
            containerUserIdList.forEach((userId)=>{
                if (existingUserList.indexOf(userId) === -1) {
                    existingUserList.push(userId);
                    storeChanged = true;
                }
            });
            this.containerUsers[action.groupId] = existingUserList;
            if (action.isLastPage && this.containerUsersStatus[action.groupId] !== StoreEntityStatus.Present) {
                this.containerUsersStatus[action.groupId] = StoreEntityStatus.Present;
                storeChanged = true;
            }
        }
        return storeChanged;
    }
    /**
     * Handle getting roster members
     * @param action Fired when members are successfully retrieved for the roster container
     */ handleFetchRosterMembersSucceededAction(action) {
        let storeChanged = false;
        // Add result to end of user list
        const existingUserList = this.containerUsers[action.rosterId] || [];
        // Add all items in containerUserIdList that haven't already been added to existingUserList
        action.rosterMembers.forEach((member)=>{
            const userId = member.userId;
            if (existingUserList.indexOf(userId) === -1) {
                existingUserList.push(userId);
                storeChanged = true;
            }
        });
        this.containerUsers[action.rosterId] = existingUserList;
        if (action.isLastPage && this.containerUsersStatus[action.rosterId] !== StoreEntityStatus.Present) {
            this.containerUsersStatus[action.rosterId] = StoreEntityStatus.Present;
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchContainerMembersFailedAction
     * @param action Action fired when fetching users for plan container failed
     */ handleFetchContainerMembersFailedAction(action) {
        let storeChanged = false;
        const { response, containerId } = action;
        // Update the status if appropriate
        if (response?.status === HttpStatus.NotFound || response?.status === HttpStatus.Forbidden) {
            storeChanged = this.updateContainerUsersStatusBasedOnResponse(containerId, response);
        } else {
            this.containerUsersStatus[containerId] = StoreEntityStatus.UnableToFetch;
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handle fetching todo members
     * @param action Fired when members are successfully retrieved for the todo container
     */ handleFetchTodoMembersSucceededAction(action) {
        let storeChanged = false;
        const existingUserList = this.containerUsers[action.planId] || [];
        action.members.forEach((member)=>{
            const userStoreCopy = this.getUserById(member.id).data;
            if (userStoreCopy == null) {
                if (member.displayName == null) {
                    this.loggers.traceLogger.logTrace(0x1e44085c /* tag_4ra72 */ , TraceLevel.Warning, `User.displayName is null [UserId=${member.id}]`);
                }
                this.addUserAndStatus(member);
                storeChanged = true;
            }
        });
        action.members.forEach((member)=>{
            const userId = member.id;
            if (existingUserList.indexOf(userId) === -1) {
                existingUserList.push(userId);
                storeChanged = true;
            }
        });
        existingUserList.forEach((existingUserId)=>{
            if (!action.members.find((member)=>member.id === existingUserId)) {
                const index = existingUserList.indexOf(existingUserId);
                existingUserList.splice(index, 1);
                storeChanged = true;
            }
        });
        this.containerUsers[action.planId] = existingUserList;
        this.containerUsersStatus[action.planId] = StoreEntityStatus.Present;
        return storeChanged;
    }
    /**
     * Update the stored chat members from the response.
     * @param action The action fired for successfully fetching chat members.
     */ handleFetchTeamsChatMembersSucceededAction(action) {
        let storeChanged = false;
        const existingUserList = this.chatMembers[action.chatId] || [];
        action.chatMembers.forEach((member)=>{
            const userStoreCopy = this.getUserById(member.id).data;
            if (userStoreCopy == null) {
                if (member.displayName == null) {
                    this.loggers.traceLogger.logTrace(0x1e41418f /* tag_4qugp */ , TraceLevel.Warning, `User.displayName is null. [UserId=${member.id}]`);
                }
                this.addUserAndStatus(member);
                storeChanged = true;
            }
        });
        action.chatMembers.forEach((member)=>{
            const userId = member.id;
            if (existingUserList.indexOf(userId) === -1) {
                existingUserList.push(userId);
                storeChanged = true;
            }
        });
        existingUserList.forEach((existingUserId)=>{
            if (!action.chatMembers.find((member)=>member.id === existingUserId)) {
                const index = existingUserList.indexOf(existingUserId);
                existingUserList.splice(index, 1);
                storeChanged = true;
            }
        });
        this.chatMembers[action.chatId] = existingUserList;
        this.chatMembersStatus[action.chatId] = StoreEntityStatus.Present;
        return storeChanged;
    }
    /**
     * Handler for AddGroupMembersAction
     * @param action Action fired when adding group members
     */ handleAddGroupMembersAction(action) {
        let storeChanged = false;
        const { groupId, userIds } = action;
        // Add users to container optimistically
        const existingUserList = this.containerUsers[groupId] || [];
        userIds.forEach((userId)=>{
            if (existingUserList.indexOf(userId) === -1) {
                existingUserList.push(userId);
                storeChanged = true;
            }
        });
        this.containerUsers[groupId] = existingUserList;
        return storeChanged;
    }
    /**
     * Handler for AddGroupMembersSucceededAction
     * @param action Action fired when adding group members succeeded
     */ handleAddGroupMembersSucceededAction(action) {
        const { groupId, userIds } = action;
        const existingUserList = this.containerUsers[groupId];
        if (existingUserList == null) {
            throw new Error("StoreException: Group container users not found in store");
        }
        userIds.forEach((userId)=>{
            if (existingUserList.indexOf(userId) === -1) {
                // Note: further consideration needed for handling scenarios such as multiple in flight requests, diff sync, etc.
                // Log for now so that we can monitor how often this occurs and improve if necessary.
                this.loggers.traceLogger.logTrace(0x1e3dd7d1 /* tag_4p35r */ , TraceLevel.Warning, `User not found in Group [GroupId=${groupId}][UserId=${userId}]`);
            }
        });
        return false;
    }
    /**
     * Handler for AddGroupMembersFailedAction
     * @param action Action fired when adding group members failed
     */ handleAddGroupMembersFailedAction(action) {
        let storeChanged = false;
        const { groupId, userIds } = action;
        const existingUserList = this.containerUsers[groupId];
        if (existingUserList == null) {
            throw new Error("StoreException: Group container users not found in store");
        }
        // Remove the users from the container
        userIds.forEach((userId)=>{
            const userIndex = existingUserList.indexOf(userId);
            if (userIndex !== -1) {
                existingUserList.splice(userIndex, 1);
                storeChanged = true;
            } else {
                this.loggers.traceLogger.logTrace(0x1e3dd7d0 /* tag_4p35q */ , TraceLevel.Warning, `User not found in Group [GroupId=${groupId}][UserId=${userId}]`);
            }
        });
        return storeChanged;
    }
    /**
     * Handler for RemoveGroupMemberAction
     * @param action Action fired when removing a group member
     */ handleRemoveGroupMemberAction(action) {
        let storeChanged = false;
        const { groupId, userId } = action;
        const existingUserList = this.containerUsers[groupId];
        if (existingUserList == null) {
            throw new Error("StoreException: Group container users not found in store");
        }
        const userIndex = existingUserList.indexOf(userId);
        if (userIndex !== -1) {
            existingUserList.splice(userIndex, 1);
            this.containerUsers[groupId] = existingUserList;
            storeChanged = true;
        } else {
            this.loggers.traceLogger.logTrace(0x1e2db5d4 /* tag_4l1xu */ , TraceLevel.Warning, `User not found in Group [GroupId=${groupId}][UserId=${userId}]`);
        }
        return storeChanged;
    }
    /**
     * Handler for RemoveGroupMemberSucceededAction
     * @param action Action fired when removing a group member succeeded
     */ handleRemoveGroupMemberSucceededAction(action) {
        const storeChanged = false;
        const { groupId, userId } = action;
        const existingUserList = this.containerUsers[groupId];
        if (existingUserList == null) {
            throw new Error("StoreException: Group container users not found in store");
        }
        const userIndex = existingUserList.indexOf(userId);
        if (userIndex !== -1) {
            this.loggers.traceLogger.logTrace(0x1e2db5d3 /* tag_4l1xt */ , TraceLevel.Warning, `User still in Group [GroupId=${groupId}][UserId=${userId}]`);
        }
        return storeChanged;
    }
    /**
     * Handler for RemoveGroupMemberFailedAction
     * @param action Action fired when removing a group member failed
     */ handleRemoveGroupMemberFailedAction(action) {
        let storeChanged = false;
        const { groupId, userId } = action;
        const existingUserList = this.containerUsers[groupId];
        if (existingUserList == null) {
            throw new Error("StoreException: Group container users not found in store");
        }
        // Add the user back to the container
        if (existingUserList.indexOf(userId) === -1) {
            existingUserList.push(userId);
            this.containerUsers[groupId] = existingUserList;
            storeChanged = true;
        } else {
            this.loggers.traceLogger.logTrace(0x1e2db5d2 /* tag_4l1xs */ , TraceLevel.Warning, `User already in Group [GroupId=${groupId}][UserId=${userId}]`);
        }
        return storeChanged;
    }
    /**
     * Add a user to the store tables
     * @param user User to add
     */ addUserAndStatus(user) {
        this.users[user.id] = user;
        this.userStatus[user.id] = StoreEntityStatus.Present;
    }
    /**
     * Update the status of a user based on the request response
     * @param userId user Id
     * @param response Response from the request
     */ updateUserStatusBasedOnResponse(userId, response) {
        let storeChanged = false;
        const entityStatus = this.userStatus[userId] ?? StoreEntityStatus.Unfetched;
        const status = getStatusBasedOnResponse(response);
        if (status != null && entityStatus !== status) {
            this.userStatus[userId] = status;
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Update the status of a container user mapping based on the request response
     * @param containerId container Id
     * @param response Response from the request
     */ updateContainerUsersStatusBasedOnResponse(containerId, response) {
        let storeChanged = false;
        const entityStatus = this.containerUsersStatus[containerId] ?? StoreEntityStatus.Unfetched;
        const status = getStatusBasedOnResponse(response);
        if (status != null && entityStatus !== status) {
            this.containerUsersStatus[containerId] = status;
            storeChanged = true;
        }
        return storeChanged;
    }
    /**
     * Handler for FetchRoamingUserDataSucceededAction and UpdateRoamingUserDataSucceededAction
     * @param action Action fired when user data are successfully retrieved from roaming service
     */ handleFetchRoamingUserDataSucceededAction(action) {
        this.userRoamingData = action.roamingUserData;
        this.userRoamingDataStatus = StoreEntityStatus.Present;
        return true;
    }
    /**
     * Handler for UpdateRoamingUserDataSucceededAction
     * @param action Action fired when user data has been updated successfully in roaming service
     */ handleUpdateRoamingUserDataSucceededAction(action) {
        this.userRoamingData = action.roamingUserData;
        this.userRoamingDataStatus = StoreEntityStatus.Present;
        return true;
    }
    /**
     * Handler for fetching user roaming data failed action
     */ handleFetchRoamingUserDataFailed() {
        this.userRoamingData = null;
        this.userRoamingDataStatus = StoreEntityStatus.UnableToFetch;
        return false;
    }
    /**
     * Handler for updating user roaming data failed action
     */ handleUpdateRoamingUserDataFailed() {
        this.userRoamingData = null;
        this.userRoamingDataStatus = StoreEntityStatus.Unfetched;
        return false;
    }
    isUsersByKeywordResultExpired(usersByKeywordResult) {
        // If the data is older than expiry time, treat it as expired
        const expiryTime = this.configProvider().settings.userSearchResultsExpiryTimeInSeconds;
        return usersByKeywordResult.fetchResultTimestamp.isBefore(moment().subtract(expiryTime, "seconds"));
    }
    /**
     * Add user settings to the store tables
     * @param userSettings UserSettings to add
     */ addUserSettingsAndStatus(userSettings) {
        this.userSettings = new Entity(userSettings);
        this.userSettingsStatus = StoreEntityStatus.Present;
    }
    /**
     * Update the status of usersettings based on the request response
     * @param response Response from the request
     */ updateUserSettingsStatusBasedOnResponse(response) {
        let storeChanged = false;
        const entityStatus = this.userSettings ?? StoreEntityStatus.Unfetched;
        const status = getStatusBasedOnResponse(response);
        if (status != null && entityStatus !== status) {
            this.userSettingsStatus = status;
            storeChanged = true;
        }
        return storeChanged;
    }
    constructor(user, loggers, configProvider){
        super(), this.loggers = loggers, this.configProvider = configProvider, this.users = {}, this.userPhotos = {}, this.containerUsers = {}, this.chatMembers = {}, this.usersByKeywordResults = {}, this.userSettings = null, this.landingPageNotificationSettings = null, this.userStatus = {}, this.userPhotosStatus = {}, this.containerUsersStatus = {}, this.chatMembersStatus = {}, this.userIdsByKeywordStatus = {}, this.userSettingsStatus = StoreEntityStatus.Unfetched, this.userRoamingData = null, this.userRoamingDataStatus = StoreEntityStatus.Unfetched;
        this.users[user.id] = user;
    }
}
