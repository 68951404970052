// Constants
import { RequestPriority } from "../constants/RequestPriority";
// Errors
import { RequestCancelError } from "@ms/uno-errors/lib/local/errors/RequestCancelError";
// Models
import { RequestType } from "../models/request/Request";
import { PrioritizedRequest } from "../models/request/PrioritizedRequest";
// Queue
import { PriorityRequestQueue } from "./PriorityRequestQueue";
// Utilities
import { generateAjaxClientError } from "../utilities/ServiceUtilities";
import { getIsOnline } from "@ms/uno-utilities/lib/local/NetworkUtilities";
import clone from "lodash/clone";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import remove from "lodash/remove";
/**
 * Singleton class that will execute requests in priority order
 */ export class RequestPrioritizer {
    /**
     * Handle a request - execute it immediately if there are available connections, otherwise queue it
     * @param request Request to handle
     * @param priority Priority of request
     * @param [findMatchCallback] Callback to find duplicates
     * @param [handleMatchCallback] Duplicate handler
     * @param [makeRequestToStore] Callback to check the stores for the data requested
     * @param [viewIds] List of valid views for this request to execute on
     * @param [getLatestParams] Callback to get the latest parameters to send
     */ handleRequest(request, priority, findMatchCallback, handleMatchCallback, makeRequestToStore, viewIds, getLatestParams) {
        const blockingCount = this.priorityRequestQueue.getNumberOfBlockingRequests(request);
        // If the blocking request count is 0, then check if there are any available connections at or below the request's priority
        // If the user is offline, enqueue the request
        // High priority requests can execute on any priority connection
        // Medium requests at medium & low connections
        // Low only on low connections
        if (blockingCount === 0 && getIsOnline()) {
            for(let i = priority; i <= RequestPriority.Priority10; i++){
                if (this.connectionsPerPriority[i] && this.connectionsPerPriority[i] > 0) {
                    this.connectionsPerPriority[i]--;
                    const requestAndDuplicates = new PrioritizedRequest(request, priority, makeRequestToStore, viewIds, getLatestParams);
                    this.executeRequest(requestAndDuplicates, i);
                    return;
                }
            }
        }
        // If blocking or not online, enqueue the request
        this.priorityRequestQueue.enqueueRequest(request, priority, findMatchCallback, handleMatchCallback, makeRequestToStore, viewIds, getLatestParams);
    }
    /**
     * On network connectivity change callback that is called when a user goes back online after being offline
     */ onNetworkConnectivityChanged(isOnline) {
        if (!isOnline) return;
        for(const priorityQueueKey in this.priorityRequestQueue.priorityQueues){
            if (this.priorityRequestQueue.priorityQueues.hasOwnProperty(priorityQueueKey) && this.priorityRequestQueue.priorityQueues[priorityQueueKey].length > 0) {
                // If items in queue, start dequeueing
                const request = this.priorityRequestQueue.dequeueAbovePriority(RequestPriority.Priority10);
                if (!request) {
                    continue;
                }
                this.executeRequest(request, request.priority);
                break;
            }
        }
    }
    /**
     * Execute the request, then get next available request
     * @param request Request to execute
     * @param connectionPriority Priority of the connection
     */ executeRequest(request, connectionPriority) {
        setTimeout(async ()=>{
            if (this.shouldCancelRequestCallback(request)) {
                // Check if the request should be cancelled, if so reject it & grab the next request
                this.rejectPriorityRequest(request, generateAjaxClientError(null, new RequestCancelError()));
                this.handleNextRequest(connectionPriority);
                return;
            }
            if (request.makeRequestToStore != null) {
                const data = request.makeRequestToStore(request.primaryRequest.requestParams);
                if (data != null) {
                    this.resolvePriorityRequest(request, data);
                    this.handleNextRequest(connectionPriority);
                    return;
                }
            }
            // Check if a request this one is dependent on is executing, or if a request with same id is executing
            // If so grab next request and put this one back in front of queue
            if (this.executingRequestIds.indexOf(request.primaryRequest.entityId) !== -1 || request.numberOfBlockingRequests > 0) {
                // Already executing a request with this entity id, grab next request and put this one back in front of queue
                const nextRequest = this.priorityRequestQueue.dequeueAbovePriority(connectionPriority, true);
                if (nextRequest) {
                    this.priorityRequestQueue.addRequestToFrontOfQueue(request);
                    this.executeRequest(nextRequest, connectionPriority);
                } else {
                    // Try and execute the request again, prevents the request from never getting executed
                    this.executeRequest(request, connectionPriority);
                }
                return;
            }
            // Ready to execute, set execution mode for the request and prepare callbacks
            this.executingRequestIds.push(request.primaryRequest.entityId);
            this.priorityRequestQueue.addToExecutingList(request);
            this.priorityRequestQueue.markDependentRequestsAsBlocked(request);
            let updatedRequestParams = request.primaryRequest.requestParams;
            if (request.getLatestParams != null) {
                // Get latest parameters will refresh request params based on store pending updates
                updatedRequestParams = request.getLatestParams(request.primaryRequest.requestParams);
                // If requested parameters are updated, it might have reverted dependency updateId changes, need to re-apply those
                updatedRequestParams = request.applyPendingUpdateIdsToRequestParams(updatedRequestParams);
            }
            const successCallback = (data)=>{
                this.resolvePriorityRequest(request, data);
                const newEntityId = request.primaryRequest.requestType === RequestType.Create ? request.primaryRequest.extractNewEntityId(data) : undefined;
                this.priorityRequestQueue.unMarkDependentRequestsAsBlocked(request, newEntityId);
            };
            const failCallback = (reason)=>{
                this.rejectPriorityRequest(request, reason);
                const removedRequests = this.priorityRequestQueue.removeDependentRequests(request);
                forEach(removedRequests, (removedRequest)=>{
                    this.rejectPriorityRequest(removedRequest, reason);
                });
            };
            const finallyCallback = ()=>{
                this.priorityRequestQueue.removeFromExecutingList(request);
                remove(this.executingRequestIds, (requestId)=>{
                    return requestId === request.primaryRequest.entityId;
                });
                this.handleNextRequest(connectionPriority);
            };
            // Actual execution of the request
            try {
                const data = await request.primaryRequest.requestMethod(updatedRequestParams);
                successCallback(data);
            } catch (error) {
                failCallback(error);
            } finally{
                finallyCallback();
            }
        }, 0);
    }
    /**
     * Reject a request and all its duplicates
     * @param request Request to reject
     * @param reason Reason to reject
     */ rejectPriorityRequest(request, reason) {
        if (request.primaryRequest.deferred) {
            request.primaryRequest.deferred.reject(reason);
        }
        if (!isEmpty(request.duplicateRequests)) {
            forEach(request.duplicateRequests, (request)=>{
                if (request.deferred) {
                    request.deferred.reject(reason);
                }
            });
        }
    }
    /**
     * Resolve a request and all its duplicates
     * @param request Request to resolve
     * @param data Resolved data
     */ resolvePriorityRequest(request, data) {
        if (request.primaryRequest.deferred) {
            request.primaryRequest.deferred.resolve(data);
        }
        if (!isEmpty(request.duplicateRequests)) {
            forEach(request.duplicateRequests, (request)=>{
                if (request.deferred) {
                    request.deferred.resolve(data);
                }
            });
        }
    }
    /**
     * Get the next request for the connection priority
     * If a request is found execute it, otherwise increment the available connections
     * @param connectionPriority Priority of connection
     */ handleNextRequest(connectionPriority) {
        const nextRequest = this.priorityRequestQueue.dequeueAbovePriority(connectionPriority, /* getNotBlocked */ true);
        if (nextRequest) {
            this.executeRequest(nextRequest, connectionPriority);
        } else {
            this.connectionsPerPriority[connectionPriority]++;
        }
    }
    /** Ctor */ constructor(shouldCancelRequestCallback, customConnectionPriority){
        this.priorityRequestQueue = new PriorityRequestQueue();
        this.shouldCancelRequestCallback = shouldCancelRequestCallback;
        this.executingRequestIds = [];
        this.connectionsPerPriority = customConnectionPriority ? clone(customConnectionPriority) : {};
        // These connections are reserved exclusively for high priority requests
        this.connectionsPerPriority[RequestPriority.Priority1] = this.connectionsPerPriority[RequestPriority.Priority1] || 1;
        // These connections are reserved for med & high priority requests
        this.connectionsPerPriority[RequestPriority.Priority5] = this.connectionsPerPriority[RequestPriority.Priority5] || 2;
        // These connections can be used by any request
        this.connectionsPerPriority[RequestPriority.Priority10] = this.connectionsPerPriority[RequestPriority.Priority10] || 3;
    }
}
