/**
 * Status states of an uno view during its lifecycle of getting loaded, instantiated and rendered
 */ export var UnoViewStatus;
(function(UnoViewStatus) {
    /** If the view is in error state and cannot be rendered, from failures in asset-loading or instantiation */ UnoViewStatus["Error"] = "Error";
    /** If the view's instatiator/render asset scripts have not been lazy-loaded yet */ UnoViewStatus["NotLoaded"] = "NotLoaded";
    /** The view's instatiator loading/instantiation process has begun */ UnoViewStatus["InstantiationStarted"] = "InstantiationStarted";
    /** The view's instatiator/render asset scripts have been lazy-loaded, instatiator can now be invoked */ UnoViewStatus["ScriptsLoaded"] = "ScriptsLoaded";
    /** The view's instatiator has been successfully called, it can now be rendered */ UnoViewStatus["Instantiated"] = "Instantiated";
    /** The view needs to be re-instantiated */ UnoViewStatus["ReInstantiate"] = "ReInstantiate";
})(UnoViewStatus || (UnoViewStatus = {}));
export var UnoChildView;
(function(UnoChildView) {
    /** Basic plan view */ UnoChildView["BasicPlan"] = "BasicPlan";
    /** Premium plan view */ UnoChildView["PremiumPlan"] = "PremiumPlan";
    /** Portfolio view */ UnoChildView["Portfolio"] = "Portfolio";
    /** Legacy TT */ UnoChildView["LegacyTT"] = "LegacyTT";
})(UnoChildView || (UnoChildView = {}));
