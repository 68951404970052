// Events
import { TraceLevel, Log } from "../events/Trace.event";
/**
 * This class is responsible for trace logging.
 * TODO: Bug 9045710: [Uno] Clean up static trace logger
 * @deprecated Use TraceEventLogger instead
 */ export class TraceLogger {
    /** Set the logging threshold */ static setLogThreshold(threshold) {
        this.logThreshold = threshold;
    }
    /**
     * Log a trace event
     * @param locationTag Location tag for the trace event. If a number is passed, we'll assume it's from Office Git Tagger and convert it
     * @param level Log level
     * @param message Log Message for the trace event
     * @param extraData (optional) Any extra data relevant to this trace event
     */ static logTrace(locationTag, level, message, extraData) {
        if (level <= this.logThreshold) {
            if (typeof locationTag === "number") {
                // If the locationTag is a number, assume it's an eventId from the Office Git Tagger and convert it to a string
                // https://www.owiki.ms/wiki/Git/Tagger
                locationTag = TraceLogger.tagIdToString(locationTag);
            }
            Log({
                level: level,
                locationTag: locationTag,
                message: message,
                timestamp: new Date(),
                preciseMarker: window.performance.now(),
                extraData
            });
        }
    }
    /**
     * Converts a tagId (number) to string format
     * @param tagId The tag id (number) to convert
     */ static tagIdToString(tagId) {
        // The symbol space is 34 characters which means we need at least 6 bits to utilize the space (2^5 is only 32)
        // We'll take each 6 bit chunk of the tagId (bitwise AND to clear excess bits) and index into our space to get the corresponding character
        // Then we concat the result from each chunk to create the tagId as a string
        return TraceLogger.symbolSpace[tagId >> 24 & 0x3f] + TraceLogger.symbolSpace[tagId >> 18 & 0x3f] + TraceLogger.symbolSpace[tagId >> 12 & 0x3f] + TraceLogger.symbolSpace[tagId >> 6 & 0x3f] + TraceLogger.symbolSpace[tagId >> 0 & 0x3f];
    }
}
TraceLogger.logThreshold = TraceLevel.Info;
TraceLogger.symbolSpace = "abcdefghijklmnopqrstuvwxyz0123456789";
