/**
 * Registry that manages the mapping between action types and store types for the application's flux infrastructure.
 */ export class FluxRegistry {
    getStoreTypesForAction(actionType) {
        return this.atosregistry[actionType] || [];
    }
    /**
     * Invert the registry matrix from a dictionary of actiontypes per storetype to a dictionary of storetypes per actiontype
     * This allows not traversing through the entire registry per action dispatch and instead do a quick lookup of storetypes for a given actiontype
     */ invertRegistry(stoaregistry) {
        const registryKeys = Object.keys(stoaregistry);
        for (const storeType of registryKeys){
            const actionTypes = stoaregistry[storeType];
            for (const actionType of actionTypes){
                if (!this.atosregistry[actionType]) {
                    this.atosregistry[actionType] = [];
                }
                this.atosregistry[actionType].push(storeType);
            }
        }
    }
    constructor(stoaregistry){
        this.atosregistry = {};
        // Perform the one time inversion of the registry matrix to improve ammortized lookup performance
        this.invertRegistry(stoaregistry);
    }
}
