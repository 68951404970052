// Constants
import { WebUrlSegments } from "@ms/uno-constants/lib/local/WebConstants";
/**
 * Removes a GCC subRoutePath from current browser without navigation.
 */
export function removeGccPathFromBrowserUrl() {
    const url = new URL(window.location.href);
    removeGccPathFromURL(url);
    history.replaceState({}, "", url);
}
/**
 * Removes a GCC from the url prefix that is used only for authentication routing purposes.
 * For instance, /webui/gcc/plan/<planId> becomes /webui/plan/<planId>
 * @param url The url to be updated
 */
export function removeGccPathFromURL(url) {
    const path = url.pathname;
    const gccPath = `/${WebUrlSegments.GccPath}`;
    const webuiPath = `/${WebUrlSegments.WebUI}`;
    const webuiGccPath = `${webuiPath}${gccPath}`;
    if (path.toLowerCase().startsWith(webuiGccPath)) {
        // removes the /gcc part if starts with /webui
        url.pathname = `${webuiPath}${path.substring(webuiGccPath.length)}`;
    }
}
/**
 * Append GCC to the url for authentication routing purposes on PROD if not already there
 * @param url the url to be updated
 */
export function addGccPathToURL(url) {
    const path = url.pathname;
    const gccPath = `/${WebUrlSegments.GccPath}`;
    const webuiPath = `/${WebUrlSegments.WebUI}`;
    const webuiGccPath = `${webuiPath}${gccPath}`;
    if (path.toLowerCase().startsWith(webuiPath) && !path.toLowerCase().startsWith(webuiGccPath)) {
        url.pathname = `${webuiGccPath}${path.substring(webuiPath.length)}`;
        return;
    }
}
