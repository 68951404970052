// Stores
// Models
import { UnoViewStatus } from "@ms/uno-models/lib/local/constants/UnoViewConstants";
// Telemetry
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
import * as EuplEvent from "@ms/uno-telemetry/lib/local/events/EUPL.event";
import * as PttEvent from "@ms/uno-telemetry/lib/local/events/PageTransitionTime.event";
// Utilities
import { PageTimingKey, setPageTiming } from "@ms/uno-utilities/lib/local/PasUtilities";
import forEach from "lodash/forEach";
import { logUnreachableAction } from "../../utilities/LoggingUtilities";
/**
 * Store for Performance data collection for PLT/EUPL measurements as well as Page Transition Time measurements
 * For reference see http://www.w3.org/TR/navigation-timing/
 * also, got tips at http://www.stevesouders.com/blog/2014/08/21/resource-timing-practical-tips/
 *
 * Navigation Timing data is used to calculate PLT data. After the last event on their timeline, we log a few of our own in order to
 * calculate EUPL. These extra events are tracked for every page transition in our SPA, as well as on first load. First load PLT+EUPL
 * data is logged to our 'EUPL' logging event, while page transition times are logged to our 'PTT' logging event. Here are our events:
 *  - appStart - when the app starts
 *  - dataFetchStart - when the current page starts requesting data that it needs
 *  - dataFetchEnd - when the current page has all the data that it needs
 *
 * Diagram of the post- main page load events and durations (events above the timeline, durations below)
 *  events->               appStart      dataFetchStart     dataFetchEnd
 *  time->     ----------------|----------------|----------------|
 *  durations->   appStart          preRender        dataFetch
 *
 * In order to properly log both PLT and EUPL data, two things must happen:
 * 1. the app must always dispatch the AppStartAction during SPA initialization. This is handled in one place.
 * 2. Every page-level control must dispatch the PageReadyAction when the page is ready for user interaction (EUPL).
 *
 * Note: In order for this store to correctly log EUPL and PTT perf data, it must see the route change actions for the initial page load
 * when the app loads for the first time.
 */ export class PerformanceCollectionStore {
    get name() {
        return "PerformanceCollectionStore";
    }
    handleAction(action) {
        switch(action.type){
            case ActionType.AppStartAction:
                this.setAppStart(action);
                break;
            case ActionType.PageReadyAction:
                this.pageReady(action);
                break;
            case ActionType.NavigateToView:
                this.handleRouteChange(this.getPageNameFromRoutingDetails(action.routeChangeDescriptor.subRouteType, action.routeChangeDescriptor.subRoute), action.timestamp);
                break;
            case ActionType.PushSubRoute:
                this.handleRouteChange(this.getPageNameFromRoutingDetails(this.getCurrentSubRouteType(), action.subRoute), action.timestamp);
                break;
            case ActionType.UpdateChildViewStatusAction:
                // If we are re-instantiating the page, then we need to consider it as a route change so as to track with valid PTT
                if (action.state === UnoViewStatus.ReInstantiate) {
                    this.handleRouteChange(this.pageName, action.timestamp);
                }
                break;
            default:
                logUnreachableAction(this, this.loggers, action);
                return;
        }
    }
    /**
     * When the app starts, we record some information for logging later
     * @param action Information about the app start
     */ setAppStart(action) {
        // This method should only execute once
        if (this.pageInitLogged) {
            throw new Error("setAppStart() method cannot be called more than once.");
        }
        // Save some state
        this.pageInitLogged = true;
        this.pageName = action.pageName;
        this.pageStartTime = action.appStartTime;
        this.appStartTime = action.appStartTime;
        this.initialHttpRequestCount = this.getHttpRequestsLength();
    }
    /**
     * When the page is ready, we collect some data and log PTT (and maybe EUPL - only on first page load)
     * @param action Information about the page ready event
     */ pageReady(action) {
        const { logHandler } = this.loggers;
        // Log EUPL, but only on the first page load (loading of our SPA)
        // If we have already logged EUPL, then we should log PTT instead
        if (this.shouldLogEupl()) {
            const euplData = this.calculateMainPageRequestData();
            const pttData = this.calculatePageTransitionTimeData(action, this.appStartTime);
            euplData.metaTag = action.metaTag || "";
            euplData.extraData = action.extraData;
            // Copy the post-appStart data from pttData
            euplData.pageName = pttData.pageName;
            euplData.preDataFetch = pttData.preDataFetch;
            euplData.dataFetch = pttData.dataFetch;
            // Get http requests
            euplData.httpRequestCount = this.initialHttpRequestCount + pttData.httpRequestCount;
            // Full eupl =  full plt + app start + full ptt
            euplData.euplFull = euplData.pltFull + euplData.appStart + pttData.pttFull;
            // Log the EUPL data for PAS
            this.logPageEUPLDataForPAS(euplData);
            // Log the EUPL data
            EuplEvent.Log(euplData, logHandler);
        } else {
            // Log the Page Transition Time event
            const pttData = this.calculatePageTransitionTimeData(action, this.pageStartTime);
            // Log the page transition time for PAS
            this.logPageTransitionTimeDataForPAS(pttData);
            PttEvent.Log(pttData, logHandler);
        }
        // Always set this flag to true
        this.euplLogged = true;
    }
    /**
     * When a route change happens, record some information about the change for logging later
     * @param pageName The name of the page that we are navigating to
     * @param timestamp The timestamp of the route change
     */ handleRouteChange(pageName, timestamp) {
        // Reset the startTime
        this.pageStartTime = timestamp;
        this.previousPageName = this.pageName;
        this.pageName = pageName;
        this.routeChangesSeen++;
    }
    /**
     * Returns true if we should log EUPL
     */ shouldLogEupl() {
        // If we have already logged it, dont need to
        if (this.euplLogged) {
            return false;
        }
        // If we haven't logged it, but we've seen more than one route change action come through, then we assume
        // that the first page forgot to log EUPL. In this case, we should not log it on any subsequent route change.
        if (this.routeChangesSeen > 1) {
            return false;
        }
        // Otherwise, yes, log it
        return true;
    }
    /**
     * Calculates metrics used in our PageTransitionTime event and returns them
     * @param action Information about the page ready event
     */ calculatePageTransitionTimeData(action, startTime) {
        return {
            pageName: this.pageName,
            previousPageName: this.previousPageName,
            // PTT Full - full page transition time from start until data fetch end
            pttFull: action.dataFetchEndTime - startTime,
            // Time it takes for our app to make the first data fetch
            preDataFetch: action.dataFetchStartTime - startTime,
            // Time it takes for our app to get data back from the server
            dataFetch: action.dataFetchEndTime - action.dataFetchStartTime,
            // Get http requests since the latest route change action
            httpRequestCount: this.getHttpRequestsLength(),
            // Extra data
            extraData: action.extraData
        };
    }
    /**
     * Log the page transition time data for PAS
     * @param pttData The PTT data
     */ logPageTransitionTimeDataForPAS(pttData) {
        setPageTiming(PageTimingKey.pttFull, pttData.pttFull);
    }
    /**
     * Log the EUPL data for PAS
     * @param pttData The PTT data
     */ logPageEUPLDataForPAS(euplData) {
        setPageTiming(PageTimingKey.euplFull, euplData.euplFull);
    }
    /**
     * Calculates metrics about our main page request and returns them
     */ calculateMainPageRequestData() {
        const euplData = {};
        // Save performance timing data from the browser
        if (window.performance?.timing != null) {
            const winPerf = window.performance.timing;
            // Put window.performance.timing data in our EUPL event data
            euplData.navigationStart = winPerf.navigationStart;
            euplData.unloadEventStart = winPerf.unloadEventStart;
            euplData.unloadEventEnd = winPerf.unloadEventEnd;
            euplData.redirectStart = winPerf.redirectStart;
            euplData.redirectEnd = winPerf.redirectEnd;
            euplData.fetchStart = winPerf.fetchStart;
            euplData.domainLookupStart = winPerf.domainLookupStart;
            euplData.domainLookupEnd = winPerf.domainLookupEnd;
            euplData.connectStart = winPerf.connectStart;
            // This property is not in lib.d.ts for some reason
            // also, we dont want to log it if it is zero. If the connection was not secure, then it will be zero
            if (winPerf["secureConnectionStart"] != null && winPerf["secureConnectionStart"] !== 0) {
                euplData.secureConnectionStart = winPerf["secureConnectionStart"];
            }
            euplData.connectEnd = winPerf.connectEnd;
            euplData.requestStart = winPerf.requestStart;
            euplData.responseStart = winPerf.responseStart;
            euplData.responseEnd = winPerf.responseEnd;
            euplData.domLoading = winPerf.domLoading;
            euplData.domInteractive = winPerf.domInteractive;
            euplData.domContentLoadedEventStart = winPerf.domContentLoadedEventStart;
            euplData.domContentLoadedEventEnd = winPerf.domContentLoadedEventEnd;
            euplData.domComplete = winPerf.domComplete;
            euplData.loadEventStart = winPerf.loadEventStart;
            euplData.loadEventEnd = winPerf.loadEventEnd;
            // save the end time - either loadEventEnd if available, or domComplete
            // if the loadEventEnd event did not occur yet, then it will be 0. In that case, we should use domComplete since we
            // always want to have a (non-zero) value. Under normal circumstances, by the time this code executes, we should
            // have a value for loadEventEnd.
            const endTime = euplData.loadEventEnd > 0 ? euplData.loadEventEnd : euplData.domComplete;
            // PLT Full - full Performance Timing timeline
            euplData.pltFull = endTime - euplData.navigationStart;
            // redirTime: this measure computes the time between navigation started until all last redirect finished.
            // using fetchStart here instead of redirectEnd would be same but IE does not set redirectEnd value.
            euplData.pltRedir = euplData.fetchStart - euplData.navigationStart;
            // PLT Network - measures time on the network
            euplData.pltNetwork = euplData.responseEnd - euplData.fetchStart;
            // PLT Dom - measures time after domLoading started until page loaded
            euplData.pltDom = endTime - euplData.domLoading;
            // Gap or overlap between the pltNetwork and pltDom timeframes. If the value is positive, there is a gap of that size. If it is negative, then there is an overlap of that size.
            euplData.pltNetworkDomGap = euplData.domLoading = euplData.responseEnd;
            // Time it takes for our app to start running
            euplData.appStart = this.appStartTime - endTime;
        }
        // Save performance navigation data from the browser
        if (window.performance?.navigation != null) {
            // Redirect count
            euplData.redirCount = window.performance.navigation.redirectCount || 0;
            // Navigation type - (0=nagivate, 1=reload, 2=browser back or forward)
            euplData.navType = window.performance.navigation.type || -1;
        }
        return euplData;
    }
    /**
     * Get the http requests whose responseEnd value is greater than the largest one we've seen so far. This method
     * is designed to be called multiple times and return distinct entries each time.
     */ getHttpRequestsLength() {
        let largestResponseEnd = this.lastLargestHttpRequestReponseEnd;
        let httpRequestCount = 0;
        if (window.performance?.getEntriesByType != null) {
            // Get all requests, loop through them all
            const perfEntries = performance.getEntriesByType("resource");
            forEach(perfEntries, (entry)=>{
                // We track the largest responseEnd seen during this method call, so that next time, we can use
                // that to filter out resources that we've already captured.
                if (entry.responseEnd != null) {
                    if (entry.responseEnd > this.lastLargestHttpRequestReponseEnd) {
                        // Track largest responseEnd seen
                        if (entry.responseEnd > largestResponseEnd) {
                            largestResponseEnd = entry.responseEnd;
                        }
                        // Add it to count
                        httpRequestCount++;
                    }
                }
            });
        }
        // Set value so next delta doesn't overlap with current
        this.lastLargestHttpRequestReponseEnd = largestResponseEnd;
        return httpRequestCount;
    }
    /**
     * Get the page name from the routing details
     * @param subRouteType The sub route type
     * @param subRoute The sub route
     */ getPageNameFromRoutingDetails(subRouteType, subRoute) {
        let pageName = `${subRouteType}`;
        if (subRoute.length > 0) {
            pageName += `/${subRoute.join("/")}`;
        }
        return pageName;
    }
    constructor(getCurrentSubRouteType, loggers){
        this.getCurrentSubRouteType = getCurrentSubRouteType;
        this.loggers = loggers;
        this.euplLogged = false;
        this.pageInitLogged = false;
        this.pageName = "";
        this.previousPageName = "";
        this.pageStartTime = 0;
        this.appStartTime = 0;
        this.initialHttpRequestCount = 0;
        this.lastLargestHttpRequestReponseEnd = -1;
        this.routeChangesSeen = 0;
    }
}
