/**
 * Rollout rings/categories supported in Uno
 */ export var RolloutStage;
(function(RolloutStage) {
    /** The dev or local users */ RolloutStage["Dev"] = "dev";
    /** The pre-production app users */ RolloutStage["PreProd"] = "ppe";
    /** The demo tenants users */ RolloutStage["Demo"] = "demo";
    /** The dogfood dev users - engineers and EMs */ RolloutStage["DogfoodDev"] = "dogfood-dev";
    /** Intended for select PathWM PMs, select PathWM LT, design, and research */ RolloutStage["DogfoodStaging"] = "dogfood-staging";
    /** Inclusive of all PathWM members and any early MS dogfooders before the wider Microsoft/MSIT audience */ RolloutStage["DogfoodAll"] = "dogfood-all";
    /** The MSIT users */ RolloutStage["ProdMSIT"] = "production-msit";
    /** The beta users belonging to TAP tenants */ RolloutStage["ProdBetaTAP"] = "production-beta-tap";
    /** The production or multi tenant (mt) users */ RolloutStage["ProdAll"] = "production-all";
    /** The gcc (gov-med) or L2 users */ RolloutStage["Gcc"] = "gcc";
    /** The gcc high (gov-high) or L4 users */ RolloutStage["Gcch"] = "gcch";
    /** The department of defense or L5 users */ RolloutStage["DoD"] = "dod";
    /**
     * Kern River Project (KRP) is a sovereign cloud variation for customers
     * who need an isolated cloud that is NOT Internet connected (i.e. air-gapped)
     * and has high level of security restrictions.
     */ RolloutStage["AirGap"] = "krp";
})(RolloutStage || (RolloutStage = {}));
