/**
 * Ajax client to gate all GET calls. While a GET request is in-flight, we will not send any others with the same url. This class is meant to be used
 * as a layer within our ajax request system.
 */ export class GatedGetAjaxClient {
    executeRequest(url, options) {
        if (options.method != null && options.method !== "GET") {
            return this.innerAjaxClient.executeRequest(url, options);
        }
        const cachedRequestPromise = this.cachedGetPromises[url];
        if (cachedRequestPromise != null) {
            return cachedRequestPromise;
        }
        const requestPromise = this.innerAjaxClient.executeRequest(url, options).then((requestResult)=>{
            delete this.cachedGetPromises[url];
            return Promise.resolve(requestResult);
        }).catch((failureResult)=>{
            delete this.cachedGetPromises[url];
            return Promise.reject(failureResult);
        });
        this.cachedGetPromises[url] = requestPromise;
        return requestPromise;
    }
    constructor(ajaxClient){
        this.innerAjaxClient = ajaxClient;
        this.cachedGetPromises = {};
    }
}
