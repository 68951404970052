/**
 * Enum encapsulating various type a user can be-member, guest, unknown
 */ export var UserType;
(function(UserType) {
    UserType["Member"] = "Member";
    UserType["Guest"] = "Guest";
})(UserType || (UserType = {}));
export var AadUserType;
(function(AadUserType) {
    AadUserType["Member"] = "Member";
    AadUserType["Guest"] = "Guest";
})(AadUserType || (AadUserType = {}));
