import { HttpStatus } from "@ms/uno-constants/lib/local/HttpStatusConstants";
import { StoreEntityStatus } from "@ms/uno-constants/lib/local/StoreConstants";
import moment from "moment";
/**
 * Get the status based on a request response
 * @param response Response from the request
 */ export function getStatusBasedOnResponse(response) {
    if (response.status === HttpStatus.NotFound) {
        return StoreEntityStatus.NotFound;
    }
    if (response.status === HttpStatus.Forbidden) {
        return StoreEntityStatus.Inaccessible;
    }
    return null;
}
/**
 * Get whether the status represents a failed state
 * @param status Status to check
 */ export function isStatusFailure(status) {
    return status === StoreEntityStatus.NotFound || status === StoreEntityStatus.Inaccessible || status === StoreEntityStatus.UnableToFetch;
}
/**
 * Get whether the status represents a fetching or unfetched state
 * @param status Status to check
 */ export function isStatusFetchingOrUnfetched(status) {
    return status === StoreEntityStatus.Fetching || status === StoreEntityStatus.Unfetched;
}
/**
 * Get status based on the fetched time and expiry time
 * @param timestamp Time the entity was fetched
 * @param expiryTime Time in seconds before the entity expires
 */ export function getStatusBasedOnTimestamp(timestamp, expiryTime) {
    return timestamp?.add(expiryTime, "s").isAfter(moment().utc()) ? StoreEntityStatus.Present : StoreEntityStatus.Stale;
}
