// Constants
import { persistentStorageSchemaVersion } from "@ms/uno-constants/lib/local/AppConstants";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import { InMemoryStorage } from "./InMemoryStorage";
import { StorageType } from "./IStorage";
import { PersistentStorage } from "./PersistentStorage";
export const storageAvailableCache = {};
export let inMemoryStorage;
/**
 * Try to get sessionStorage with in memory storage as a fallback
 * @param traceLogger The trace logger for logging diagnostic traces
 */ export function getSessionOrInMemoryStorage(traceLogger) {
    if (isStorageAvailable(StorageType.SessionStorage)) {
        return window.sessionStorage;
    } else {
        if (inMemoryStorage === null) {
            inMemoryStorage = new InMemoryStorage();
        }
        traceLogger.logTrace(0x1e4854c5 /* tag_4sftf */ , TraceLevel.Info, "Using in-memory storage as a fallback");
        return inMemoryStorage;
    }
}
/**
 * Try to get localStorage
 * @param customPrefix The custom prefix to use for storage keys
 * @param traceLogger The trace logger for logging diagnostic traces
 * @param configProvider The configuration provider for settings
 */ export function getLocalStorage(customPrefix, traceLogger, configProvider) {
    if (configProvider().settings.disableStoragePersistence || !isStorageAvailable(StorageType.LocalStorage)) {
        traceLogger.logTrace(0x1e3d4212 /* tag_4puis */ , TraceLevel.Warning, "Local storage is not available");
        return null;
    }
    return new PersistentStorage(customPrefix, window.localStorage, persistentStorageSchemaVersion, traceLogger);
}
/**
 * Test whether or not a specific type of storage is available.
 *
 * Reference:
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */ export function isStorageAvailable(storageType) {
    // Check in-memory cache first
    let isAvailable = storageAvailableCache[storageType];
    if (isAvailable != null) {
        return isAvailable;
    }
    let storage = null;
    try {
        // Accessing storage from the window may throw an exception if it is disabled
        // https://stackoverflow.com/questions/30481516/iframe-in-chrome-error-failed-to-read-localstorage-from-window-access-deni
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
        storage = window[storageType];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        isAvailable = true;
    } catch (e) {
        isAvailable = e instanceof DOMException && // everything except Firefox
        (e.code === 22 || // Firefox
        e.code === 1014 || // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" || // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") && storage != null && // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
    // cache the result in-memory
    storageAvailableCache[storageType] = isAvailable;
    return isAvailable;
}
