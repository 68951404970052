/**
 * Utilities for dealing with errors
 */ export class ErrorUtilities {
    /**
     * Set the redaction function to use for redacting error messages
     * @param redactionFunction Redact function to use
     */ static setRedactionFunction(redactionFunction) {
        this.redactionFunction = redactionFunction;
    }
    /**
     * Get the error message
     * @param error Error to extract message from
     * @param notRedact If true, do not redact the message
     */ static getMessage(error, notRedact) {
        let message = "";
        if (error) {
            if (error.message) {
                message = error.message;
            } else if (error.description) {
                message = error.description;
            } else if (typeof error === "object") {
                message = this.safeSerialize(error);
            } else if (error.toString) {
                message = error.toString();
            }
        }
        return notRedact ? message : this.redactMessage(message);
    }
    /**
     * Get the error stack trace
     * @param error Error to extract stack trace from
     * @param notRedact If true, do not redact the stack trace
     */ static getStackTrace(error, notRedact) {
        // get stack trace
        const stack = error?.stack ?? "";
        return notRedact ? stack : this.redactMessage(stack);
    }
    /**
     * Handle the unreachable case for switch statements by logging appropriately
     * @param value Value of the unreachable case
     * @param logPredicate Predicate to log the generated log
     */ static unreachableCase(value, logPredicate) {
        const logString = `Unreachable case found`;
        const error = new Error(logString);
        logPredicate(`${logString} [Case=${value}][StackTrace=${this.getStackTrace(error)}`);
    }
    /**
     * This is a function you can call to safely serialize anything to JSON.
     * The built-in JSON.stringify() throws an exception for circular references and can't handle
     * many built-ins such as HTMLElements and the global window object.
     */ static safeSerialize(obj) {
        let str;
        try {
            const seenObjects = [];
            str = JSON.stringify(obj, (key, value)=>{
                if (value === window) {
                    return "[window]";
                } else if (value instanceof HTMLElement) {
                    return "[HTMLElement]";
                } else if (typeof value === "function") {
                    return "[function]";
                } else if (value instanceof Error) {
                    // JSON.stringify Error will return an empty object string {}, so we need to handle Error object separately
                    return value.message;
                } else if (typeof value === "object") {
                    if (value === null) {
                        return value;
                    } else if (seenObjects.indexOf(value) === -1) {
                        seenObjects.push(value);
                        return value;
                    } else {
                        return "[circular]";
                    }
                } else {
                    return value;
                }
            });
        } catch (err) {
            // Note: we make this be a JSON string, so that consumers
            // can always call JSON.parse.
            str = JSON.stringify("[object]");
        }
        return str;
    }
    /**
     * Helper to redact messages
     * @param message Message to redact
     */ static redactMessage(message) {
        if (this.redactionFunction) {
            return this.redactionFunction(message);
        }
        return message;
    }
}
