// Constants
import { HttpStatus } from "@ms/uno-constants/lib/local/HttpStatusConstants";
import { AuthenticationChallengeResponseErrors, AuthenticationReasonPhrase } from "@ms/uno-constants/lib/local/ServiceConstants";
import { HttpHeaders } from "@ms/uno-constants/lib/local/HttpConstants";
import { TaskServiceResponseHeaders } from "@ms/uno-constants/lib/local/ServiceConstants";
// Telemetry
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/**
 * Returns boolean indicating if given response is 401 authentication that includes challenge for insufficient claims
 * @param response a Response object
 * @param traceLogger Trace logger
 */ export async function isAuthenticationInsuficientClaimsResponse(response, traceLogger) {
    return await extractClaimsFromResponse(traceLogger, response) !== "";
}
/**
 * Try parse IAuthenticationChallengeResponse from given request response data and return its value.
 * If not able to parse returns null
 * @param statusCode the status code
 * @param reasonPhrase the reasonPhrase response header
 * @param responseText the responseText to check for claims json
 * @param wwwAuthenticateHeader the www-authenticate header value
 * @param traceLogger Trace logger
 */ export function tryParseAuthenticationChallengeResponse(statusCode, reasonPhrase, responseText, wwwAuthenticateHeader, traceLogger) {
    if (statusCode === HttpStatus.Unauthorized && responseText && reasonPhrase === AuthenticationReasonPhrase.InteractionRequired) {
        // First try Planner response format
        try {
            // Ensures responseText is a valid parsable claims json string
            JSON.stringify(JSON.parse(responseText));
            return {
                reasonPhrase: AuthenticationReasonPhrase.InteractionRequired,
                claim: window.btoa(responseText),
                error: AuthenticationChallengeResponseErrors.InsufficientClaims
            };
        } catch (e) {
            // No-op
            traceLogger.logTrace(0x1e449095 /* tag_4rjcv */ , TraceLevel.Info, `Failed to parse json response`);
        }
    }
    if (statusCode === HttpStatus.Unauthorized && wwwAuthenticateHeader) {
        // Try parsing claims from WWW-Authenticate header
        const response = parseWwwAuthHeaderChallengeResponse(wwwAuthenticateHeader);
        if (response) {
            return response;
        }
    }
    return null;
}
/**
 * @param wwwAuthenticateHeader WWW-Authenticate header value from the response
 */ export function parseWwwAuthHeaderChallengeResponse(wwwAuthenticateHeader) {
    if (!wwwAuthenticateHeader) {
        return null;
    }
    const headerDict = parseWwwAuthenticateHeader(wwwAuthenticateHeader, HttpHeaders.Bearer);
    if (!headerDict) {
        return null;
    }
    const errorValue = headerDict["error"];
    const claims = headerDict["claims"];
    if (errorValue !== AuthenticationChallengeResponseErrors.InsufficientClaims || !claims) {
        return null;
    }
    try {
        return {
            error: AuthenticationChallengeResponseErrors.InsufficientClaims,
            reasonPhrase: AuthenticationReasonPhrase.InteractionRequired,
            claim: claims
        };
    } catch (e) {
    // No-op
    }
    return null;
}
/**
 * Parses given www-authenticate response header and returns the dictionary with its values.
 * This function can parse strings with multiple www-authenticate headers.
 * Notes: Assuming commas "," are used as delimiters. This function fails if commas are used in any of the key/value pairs.
 *
 * Example header value:
 * 'Bearer realm="", authorization_uri="https://login.windows-ppe.net/common/oauth2/authorize", client_id="00000003-0000-0000-c000-000000000000", error="insufficient_claims", claims="eyJhY2Nlc3NfdG9rZW4iOnsiYWNycyI6eyJlc3NlbnRpYWwiOnRydWUsInZhbHVlIjoidXJuOm1pY3Jvc29mdDpyZXExIn19fQ==", cc_type="authcontext""'
 *
 * @param authResponseHeader The content of WWW-Authenticate header.
 * @param scheme AuthResponseHeader may have multiple auth schemes. Use this value to target the desired scheme.
 * @returns returns dictionary if able to parse otherwise return null
 */ export function parseWwwAuthenticateHeader(authResponseHeader, scheme) {
    let hasScheme = false;
    const record = {};
    // Split by comma, trim whitespace
    const tokens = authResponseHeader.split(",").map((token)=>token.trim());
    // Parse each token
    for (let currentToken of tokens){
        if (!currentToken || currentToken.length === 0) {
            continue;
        }
        // Check if token starts with scheme
        if (!hasScheme && currentToken.toLowerCase().startsWith(scheme.toLowerCase())) {
            hasScheme = true;
            currentToken = currentToken.substring(scheme.length + 1);
        }
        // Stop parsing this token if we did not find the scheme yet
        if (!hasScheme) {
            continue;
        }
        const equalSignIndex = currentToken.indexOf("=");
        if (equalSignIndex === -1) {
            continue;
        }
        // Parse key value pairs
        const key = currentToken.substring(0, equalSignIndex);
        let value = currentToken.substring(equalSignIndex + 1);
        if (key && value) {
            if (record[key]) {
                break;
            }
            // Remove leading and trailing quotes
            value = value.replace(/^"(.*)"$/, "$1");
            record[key] = value;
        }
    }
    return hasScheme ? record : null;
}
/**
 * Returns claims string from the response
 * @param traceLogger the trace logger
 * @param response the Response object
 */ export async function extractClaimsFromResponse(traceLogger, response) {
    if (!response) {
        return "";
    }
    let responseText = "";
    try {
        responseText = await response.clone().text();
    } catch (e) {
    // do nothing
    }
    const statusCode = response.status;
    const reasonPhrase = response.headers.get(TaskServiceResponseHeaders.ReasonPhrase) ?? "";
    const authenticationResponseHeader = response.headers.get(TaskServiceResponseHeaders.AuthenticationChallenge) ?? "";
    const authChallengeResponse = tryParseAuthenticationChallengeResponse(statusCode, reasonPhrase, responseText, authenticationResponseHeader, traceLogger);
    return authChallengeResponse?.claim ?? "";
}
