import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for DriveItemStore, for use in the registry */ export const DriveItemStoreActionTypes = [
    ActionType.CancelUploadSessionAction,
    ActionType.CancelUploadSessionFailedAction,
    ActionType.CancelUploadSessionSucceededAction,
    ActionType.CreateUploadSessionAction,
    ActionType.CreateUploadSessionFailedAction,
    ActionType.CreateUploadSessionSucceededAction,
    ActionType.FetchDriveItemAction,
    ActionType.FetchDriveItemFailedAction,
    ActionType.FetchDriveItemsInFolderAction,
    ActionType.FetchDriveItemsInFolderFailedAction,
    ActionType.FetchDriveItemsInFolderSucceededAction,
    ActionType.FetchDriveItemSucceededAction,
    ActionType.FetchDriveItemThumbnailAction,
    ActionType.FetchDriveItemThumbnailFailedAction,
    ActionType.FetchDriveItemThumbnailSucceededAction,
    ActionType.FetchDriveItemPermissionsAction,
    ActionType.FetchDriveItemPermissionsFailedAction,
    ActionType.FetchDriveItemPermissionsSucceededAction,
    ActionType.FetchFormResponseAction,
    ActionType.FetchFormResponseFailedAction,
    ActionType.FetchFormResponseSucceededAction,
    ActionType.FetchFormsInGroupAction,
    ActionType.FetchFormsInGroupFailedAction,
    ActionType.FetchFormsInGroupSucceededAction,
    ActionType.UploadBytesToSessionAction,
    ActionType.UploadBytesToSessionFailedAction,
    ActionType.UploadBytesToSessionSucceededAction
];
