// Styles
import { flexChildSizeFlexible, flexParent, styleScrollbar } from "@ms/uno-controls/lib/local/utilities/Mixin.styles";
import { memoizeFunction, mergeStyles } from "@fluentui/react";
/**
 * Helper to get styles for the Uno shell
 * @param theme The theme
 */ export const getUnoShellStyles = memoizeFunction((theme)=>{
    const unoShellStyles = {
        root: [
            flexParent("column"),
            {
                backgroundColor: theme.semanticColors.bodyBackground,
                maxHeight: "100vh",
                maxWidth: "100vw",
                inset: "0px",
                position: "fixed",
                "*": styleScrollbar(theme),
                ul: {
                    margin: "0",
                    padding: "0"
                },
                li: {
                    listStyleType: "none"
                }
            }
        ],
        appContent: [
            flexParent("row"),
            flexChildSizeFlexible()
        ],
        mainContentContainer: {
            float: "none",
            overflow: "hidden",
            width: "100%",
            backgroundColor: theme.semanticColors.bodyStandoutBackground,
            position: "relative"
        },
        modalContainer: {
            height: "100vh",
            width: "100vw",
            zIndex: 1,
            position: "absolute",
            backgroundColor: theme.palette.neutralLight
        },
        emptyModal: {
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "450px",
            height: "300px",
            display: "flex",
            transform: "translate(-50%, -50%)",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            backgroundColor: theme.semanticColors.bodyBackground,
            boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px"
        },
        notificationBar: {
            width: "100%",
            [`& > .notificationBar.hidden`]: {
                display: "none"
            }
        }
    };
    return unoShellStyles;
});
/**
 * Get the classnames associated with the computed styles for the Uno shell
 * @param styles The styles for the component
 */ export const getUnoShellClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("root", styles.root),
        appContent: mergeStyles("appContent", styles.appContent),
        mainContentContainer: mergeStyles("mainContentContainer", styles.mainContentContainer),
        modalContainer: mergeStyles("modalContainer", styles.modalContainer),
        notificationBar: mergeStyles("notificationBar", styles.notificationBar),
        emptyModal: mergeStyles("emptyModal", styles.emptyModal)
    };
});
