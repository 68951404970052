// Constants
import { RequestPriority } from "../constants/RequestPriority";
import { PrioritizedRequest } from "../models/request/PrioritizedRequest";
// Utilities
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";
/**
 * Store requests in priority order
 */ export class PriorityRequestQueue {
    /**
     * Dequeue a request at or above a given priority
     * @param connectionPriority Priority of request to dequeue
     * @param [getNotBlocked] Whether to only return requests that aren't blocked
     */ dequeueAbovePriority(connectionPriority, getNotBlocked) {
        // Loop through request queues starting at highest priority
        for(let i = RequestPriority.Priority1; i <= connectionPriority; i++){
            if (this.priorityQueues[i] && this.priorityQueues[i].length > 0) {
                if (!getNotBlocked) {
                    return this.priorityQueues[i].shift();
                }
                for(let j = 0; j < this.priorityQueues[i].length; j++){
                    const peek = this.priorityQueues[i][j];
                    const executingIndex = findIndex(this.executingRequests, (execRequest)=>{
                        return execRequest.primaryRequest.entityId === peek.primaryRequest.entityId;
                    });
                    if (peek.numberOfBlockingRequests === 0 && executingIndex === -1) {
                        return this.priorityQueues[i].splice(j, 1)[0];
                    }
                }
            }
        }
        return null;
    }
    /**
     * Enqueue a new request
     * @param request Request to enqueue
     * @param priority Priority of request
     * @param [findMatchCallback] callback to find duplicate requests
     * @param [handleMatchCallback] callback to handle duplicate requests
     * @param [makeRequestToStore] Callback to check the stores for the data requested
     * @param [viewIds] List of valid views for this request to execute on
     * @param [getLatestParams] Callback to get the latest parameters to send
     */ enqueueRequest(request, priority, findMatchCallback, handleMatchCallback, makeRequestToStore, viewIds, getLatestParams) {
        const newRequest = new PrioritizedRequest(request, priority, makeRequestToStore, viewIds, getLatestParams);
        // Search queue for match & handle it
        if (findMatchCallback && handleMatchCallback) {
            for(let i = 0; i < this.priorityQueues[priority].length; i++){
                const checkRequest = this.priorityQueues[priority][i];
                if (findMatchCallback(checkRequest.primaryRequest, request)) {
                    const updatedRequest = handleMatchCallback(checkRequest, newRequest);
                    this.priorityQueues[priority][i] = updatedRequest;
                    return;
                }
            }
        }
        // Look through executing requests for the number of requests that are blocking it
        newRequest.numberOfBlockingRequests = this.getNumberOfBlockingRequests(request);
        // No match found just add to end
        this.priorityQueues[priority].push(newRequest);
    }
    /**
     * Get the number of executing requests that block this request
     * @param request Request to check
     */ getNumberOfBlockingRequests(request) {
        let blockingRequests = 0;
        for(let i = 0; i < this.executingRequests.length; i++){
            const executingRequest = this.executingRequests[i];
            if (executingRequest.hasDependencyWith(request)) {
                blockingRequests++;
            }
        }
        return blockingRequests;
    }
    /**
     * Add a prioritized request to the front of the queue
     * @param request Request to add to front of queue
     */ addRequestToFrontOfQueue(request) {
        this.priorityQueues[request.priority].unshift(request);
    }
    /**
     * Loop through queued requests and mark any dependent requests as blocked
     * @param request Request with dependent entities
     */ markDependentRequestsAsBlocked(request) {
        this.forEachRequestInQueues((queuedRequest)=>{
            if (request.hasDependencyWith(queuedRequest.primaryRequest)) {
                queuedRequest.numberOfBlockingRequests++;
            }
        });
    }
    /**
     * Loop through queued requests and unmark any dependent requests as blocked
     * If a new entity id is passed in update the requests as well
     * @param request Request with dependent entities
     * @param [newEntityId] New entity id
     */ unMarkDependentRequestsAsBlocked(request, newEntityId) {
        this.forEachRequestInQueues((queuedRequest)=>{
            if (request.hasDependencyWith(queuedRequest.primaryRequest)) {
                queuedRequest.numberOfBlockingRequests--;
                if (newEntityId != null) {
                    // These updates will be applied just before the request is sent
                    queuedRequest.pendingUpdatedId.push({
                        dependencyRequest: request.primaryRequest,
                        updatedId: newEntityId
                    });
                    queuedRequest.primaryRequest.updateRequestParams(request.primaryRequest.entityId, newEntityId);
                }
            }
        });
    }
    /**
     * Loop through queued requests and remove any dependent requests
     * @param request Request with dependent entities
     */ removeDependentRequests(request) {
        const removed = [];
        for(let priority = RequestPriority.Priority1; priority <= RequestPriority.Priority10; priority++){
            if (this.priorityQueues[priority] && this.priorityQueues[priority].length > 0) {
                const removedFromQueue = remove(this.priorityQueues[priority], (queuedRequest)=>{
                    return request.hasDependencyWith(queuedRequest.primaryRequest);
                });
                removed.push(...removedFromQueue);
            }
        }
        return removed;
    }
    /**
     * Add the request to the list of executing requests
     * @param request Request that is now executing
     */ addToExecutingList(request) {
        this.executingRequests.push(request);
    }
    /**
     * Remove a request from the list of executing requests
     * @param request Request that is no longer executing
     */ removeFromExecutingList(request) {
        remove(this.executingRequests, (execRequest)=>{
            return execRequest === request;
        });
    }
    /**
     * Helper method to loop through all requests in all queues and perform some action on them
     * @param callback Callback to perform on each request
     */ forEachRequestInQueues(callback) {
        for(let priority = RequestPriority.Priority1; priority <= RequestPriority.Priority10; priority++){
            if (this.priorityQueues[priority] && this.priorityQueues[priority].length > 0) {
                for(let i = 0; i < this.priorityQueues[priority].length; i++){
                    const queuedRequest = this.priorityQueues[priority][i];
                    callback(queuedRequest);
                }
            }
        }
    }
    /** Ctor */ constructor(){
        this.priorityQueues = {};
        this.priorityQueues[RequestPriority.Priority1] = [];
        this.priorityQueues[RequestPriority.Priority5] = [];
        this.priorityQueues[RequestPriority.Priority10] = [];
        this.executingRequests = [];
    }
}
