import { GraphResponseHeaders, RequestHeaders, MarsServiceRequestHeaders, LoopServiceRequestHeaders, } from "@ms/uno-constants/lib/local/ServiceConstants";
// Services
import { GraphODataApiVersion } from "@ms/uno-services/lib/local/services/graph/constants/GraphServiceConstants";
import { BasicAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/BasicAjaxClient";
import { GraphBatchQueueAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/GraphBatchQueueAjaxClient";
import { ProjectHeaderAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/ProjectHeaderAjaxClient";
import { TokenRefreshAndRetryAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/TokenRefreshAndRetryAjaxClient";
import { AuthenticatedAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/AuthenticatedAjaxClient";
import { RetryAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/RetryAjaxClient";
import { CorrelationSettingAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/CorrelationSettingAjaxClient";
import { TasksPlatformHeaderAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/TasksPlatformTelemetryAjaxClient";
import { GatedGetAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/GatedGetAjaxClient";
import { getAjaxRetryConfig } from "@ms/uno-services/lib/local/utilities/ServiceUtilities";
/**
 * Class for managing setup of services for the app
 */
export class ProductionServiceOrchestrator {
    /**
     * Initialize an instance of the ProductionServiceOrchestrator
     * @param serviceFactory The service factory
     * @param requestPrioritizer The request prioritizer for priority based queuing
     * @param configProvider The configuration provider
     * @param loggers The logger context
     * @param getAccessTokenDelegate The delegate for getting an access token
     * @param refreshAccessTokenDelegate The delegate for refreshing an access token
     */
    constructor(serviceFactory, requestPrioritizer, configProvider, loggers, getAccessTokenDelegate, refreshAccessTokenDelegate) {
        this.serviceFactory = serviceFactory;
        this.requestPrioritizer = requestPrioritizer;
        this.configProvider = configProvider;
        this.loggers = loggers;
        this.getAccessTokenDelegate = getAccessTokenDelegate;
        this.refreshAccessTokenDelegate = refreshAccessTokenDelegate;
        /** Batching ajax client for graph */
        this.graphAjaxClient = null;
        const { globalAjaxTimeout, maxRetryCount, maxRetryTimeout, defaultRetryDelay } = configProvider().settings.retrySettings;
        this.ajaxClient = new RetryAjaxClient(new BasicAjaxClient(globalAjaxTimeout, loggers), getAjaxRetryConfig({
            maxRetryCount,
            maxRetryTimeoutInMiliseconds: maxRetryTimeout,
            delayOnRetryInMiliseconds: defaultRetryDelay,
        }), loggers);
    }
    /**
     * Get the AugLoop service provider
     */
    getAugloopServiceProvider() {
        const { augloop } = this.configProvider().settings.serviceConfigurations;
        return this.serviceFactory.getAugloopServiceProvider(async () => {
            return (await this.getAccessTokenDelegate(augloop.authResourceURI)).accessToken;
        }, this.configProvider);
    }
    getCentroServiceProvider() {
        const { centro } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(new GatedGetAjaxClient(this.ajaxClient), centro.authResourceURI, this.loggers, this.getAccessTokenDelegate), centro.authResourceURI, this.refreshAccessTokenDelegate, this.loggers);
        return this.serviceFactory.getCentroServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the ChatSvcAgg service provider
     */
    getChatSvcAggServiceProvider() {
        const { chatsvcagg } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, chatsvcagg.authResourceURI, this.loggers, this.getAccessTokenDelegate));
        return this.serviceFactory.getChatSvcAggServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the Conversations service provider
     */
    getConversationsServiceProvider() {
        return this.serviceFactory.getConversationsServiceProvider(this.getGraphAjaxClient(), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the DriveItem service provider
     */
    getDriveItemServiceProvider() {
        return this.serviceFactory.getDriveItemServiceProvider(this.getGraphAjaxClient(), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Flow service provider
     */
    getFlowServiceProvider() {
        const { flow } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, flow.authResourceURI, this.loggers, this.getAccessTokenDelegate), flow.authResourceURI, this.refreshAccessTokenDelegate, this.loggers));
        return this.serviceFactory.getFlowServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the Gateway service provider
     */
    getGatewayServiceProvider() {
        const { project } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new ProjectHeaderAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, project.authResourceURI, this.loggers, this.getAccessTokenDelegate), project.authResourceURI, this.refreshAccessTokenDelegate, this.loggers), this.configProvider));
        return this.serviceFactory.getGatewayServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the Groups service provider
     */
    getGroupsServiceProvider() {
        return this.serviceFactory.getGroupsServiceProvider(this.getGraphAjaxClient(), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the iris service provider
     */
    getIrisServiceProvider() {
        const { iris } = this.configProvider().settings.serviceConfigurations;
        return this.serviceFactory.getIrisServiceProvider(new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, iris.authResourceURI, this.loggers, this.getAccessTokenDelegate), iris.authResourceURI, this.refreshAccessTokenDelegate, this.loggers)), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the loop service provider
     */
    getLoopServiceProvider() {
        const { settings } = this.configProvider();
        const { loop } = settings.serviceConfigurations;
        const apiClient = new CorrelationSettingAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, loop.authResourceURI, this.loggers, this.getAccessTokenDelegate), loop.authResourceURI, this.refreshAccessTokenDelegate, this.loggers), LoopServiceRequestHeaders.MsCv, this.loggers);
        return this.serviceFactory.getLoopServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the Loop MTS service provider
     */
    getLoopMtsServiceProvider() {
        const { loopMts } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, loopMts.authResourceURI, this.loggers, this.getAccessTokenDelegate), loopMts.authResourceURI, this.refreshAccessTokenDelegate, this.loggers));
        return this.serviceFactory.getLoopMtsServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the MARS service provider
     */
    getMarsServiceProvider() {
        const { mars } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new CorrelationSettingAjaxClient(new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, mars.authResourceURI, this.loggers, this.getAccessTokenDelegate), mars.authResourceURI, this.refreshAccessTokenDelegate, this.loggers)), MarsServiceRequestHeaders.CorrelationId, this.loggers);
        return this.serviceFactory.getMarsServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the Mru service provider
     */
    getMruServiceProvider() {
        const { mru } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, mru.authResourceURI, this.loggers, this.getAccessTokenDelegate), mru.authResourceURI, this.refreshAccessTokenDelegate, this.loggers));
        return this.serviceFactory.getMruServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the Ocps service provider
     */
    getOcpsServiceProvider() {
        const { ocps } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, ocps.authResourceURI, this.loggers, this.getAccessTokenDelegate), ocps.authResourceURI, this.refreshAccessTokenDelegate, this.loggers));
        return this.serviceFactory.getOcpsServiceProvider(apiClient, this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the oneShell service provider
     */
    getOneShellServiceProvider() {
        const { oneshell } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, oneshell.authResourceURI, this.loggers, this.getAccessTokenDelegate), oneshell.authResourceURI, this.refreshAccessTokenDelegate, this.loggers));
        return this.serviceFactory.getOneShellServiceProvider(apiClient, this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Planner service provider
     */
    getPlannerServiceProvider() {
        return this.serviceFactory.getPlannerServiceProvider(new TasksPlatformHeaderAjaxClient(this.getGraphAjaxClient(), this.configProvider), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the ProjectLandingPage service provider
     */
    getProjectLandingPageServiceProvider() {
        const { project } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new ProjectHeaderAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, project.authResourceURI, this.loggers, this.getAccessTokenDelegate), project.authResourceURI, this.refreshAccessTokenDelegate, this.loggers), this.configProvider));
        return this.serviceFactory.getProjectLandingPageServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the ProjectScheduling service provider
     */
    getProjectSchedulingServiceProvider() {
        const { project } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new ProjectHeaderAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, project.authResourceURI, this.loggers, this.getAccessTokenDelegate), project.authResourceURI, this.refreshAccessTokenDelegate, this.loggers), this.configProvider));
        return this.serviceFactory.getProjectSchedulingServiceProvider(apiClient, this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Retail service provider
     */
    getRetailServiceProvider() {
        const { retailservices } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new CorrelationSettingAjaxClient(new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, retailservices.authResourceURI, this.loggers, this.getAccessTokenDelegate), retailservices.authResourceURI, this.refreshAccessTokenDelegate, this.loggers)), RequestHeaders.ClientRequestId, this.loggers);
        return this.serviceFactory.getRetailServiceProvider(apiClient, this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Roaming service provider
     */
    getRoamingServiceProvider() {
        const { roaming } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, roaming.authResourceURI, this.loggers, this.getAccessTokenDelegate));
        return this.serviceFactory.getRoamingServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the SiteURL service provider
     */
    getSiteURLServiceProvider() {
        return this.serviceFactory.getSiteURLServiceProvider(this.getGraphAjaxClient(), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Tcms service provider
     */
    getTcmsServiceProvider() {
        const apiClient = new GatedGetAjaxClient(this.ajaxClient);
        return this.serviceFactory.getTcmsServiceProvider(apiClient, this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Teams service provider
     */
    getTeamsServiceProvider() {
        return this.serviceFactory.getTeamsServiceProvider(this.getGraphAjaxClient(), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Tenant service provider
     */
    getTenantServiceProvider() {
        return this.serviceFactory.getTenantServiceProvider(this.getGraphAjaxClient(), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Todo service provider
     */
    getTodoServiceProvider() {
        const { stapi } = this.configProvider().settings.serviceConfigurations;
        const apiClient = new GatedGetAjaxClient(new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(this.ajaxClient, stapi.authResourceURI, this.loggers, this.getAccessTokenDelegate), stapi.authResourceURI, this.refreshAccessTokenDelegate, this.loggers));
        return this.serviceFactory.getTodoServiceProvider(apiClient, this.configProvider);
    }
    /**
     * Get the User service provider
     */
    getUserServiceProvider() {
        return this.serviceFactory.getUserServiceProvider(this.getGraphAjaxClient(), this.requestPrioritizer, this.configProvider);
    }
    /**
     * Get the Graph ajax client
     */
    getGraphAjaxClient() {
        if (this.graphAjaxClient != null) {
            return this.graphAjaxClient;
        }
        const { graph } = this.configProvider().settings.serviceConfigurations;
        // Create the inner ajax client which sets correlation headers
        let ajaxClient = new CorrelationSettingAjaxClient(this.ajaxClient, RequestHeaders.ClientRequestId, this.loggers);
        // Add auth client
        ajaxClient = new TokenRefreshAndRetryAjaxClient(new AuthenticatedAjaxClient(ajaxClient, graph.authResourceURI, this.loggers, this.getAccessTokenDelegate), graph.authResourceURI, this.refreshAccessTokenDelegate, this.loggers);
        // Use Gated Get Ajax Client to gate all GET calls and avoid duplicate concurrent requests
        const gatedAjaxClient = new GatedGetAjaxClient(ajaxClient);
        const batchAppendHeaders = [
            GraphResponseHeaders.ClientRequestId,
            GraphResponseHeaders.RequestId,
            GraphResponseHeaders.TCorrelationId,
        ];
        /** Wrap with auto-batching client if batching is turned on */
        if (graph.batchAll) {
            this.graphAjaxClient = new GraphBatchQueueAjaxClient(gatedAjaxClient, GraphODataApiVersion.Beta, this.loggers, batchAppendHeaders, this.configProvider);
        }
        else {
            this.graphAjaxClient = gatedAjaxClient;
        }
        return this.graphAjaxClient;
    }
}
