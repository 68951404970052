import { onNotification, SuppressNexus, TelemetryLogger } from "@microsoft/oteljs";
import { BeforeUnload } from "./BeforeUnload";
import { LogHandlerType } from "./types/LogHandlerType";
// Utilities
import findLastIndex from "lodash/findLastIndex";
import { createEventIdentifier, getEventFlags, mapToNewEvents } from "./LoggingHelper";
/** Tenant name or project's namespace shared by Project Web and Planner (Web & Mobile)*/ export const LogTenantName = "Office.ProjectPlanner";
export var LegacyEventType;
(function(LegacyEventType) {
    /* Action event  */ LegacyEventType["Action"] = "Action";
    /* Auth error event */ LegacyEventType["AuthError"] = "AuthError";
    /* Engagement event  */ LegacyEventType["Engagement"] = "Engagement";
    /* EUPL event  */ LegacyEventType["Eupl"] = "EUPL";
    /* Diagnostics event  */ LegacyEventType["Diagnostics"] = "Diagnostics";
    /* Pageinit event  */ LegacyEventType["PageInit"] = "PageInit";
    /* Page transition time event  */ LegacyEventType["Ptt"] = "PTT";
    /* Render measurement event  */ LegacyEventType["Render"] = "Render";
    /* RequireJs error event  */ LegacyEventType["RequireJSError"] = "RequireJSError";
    /* Route event  */ LegacyEventType["Route"] = "Route";
    /** Trace event */ LegacyEventType["Trace"] = "Trace";
    /* Qos event  */ LegacyEventType["Qos"] = "Qos";
    /* Unhandled error event  */ LegacyEventType["UnhandledError"] = "UnhandledError";
    /* Survey event  */ LegacyEventType["Survey"] = "Survey";
})(LegacyEventType || (LegacyEventType = {}));
export var EventType;
(function(EventType) {
    /* Action event  */ EventType["Action"] = "Action";
    /* Diagnostics event  */ EventType["Diagnostics"] = "Diagnostics";
    /* Engagement event  */ EventType["Engagement"] = "Engagement";
    /* Performance event  */ EventType["Performance"] = "Performance";
    /* Qos event  */ EventType["Qos"] = "Qos";
    /* Copilot event  */ EventType["Copilot"] = "Copilot";
    /* Asha event  */ EventType["Asha"] = "ASHA";
})(EventType || (EventType = {}));
/**
 * Utility class for UTel Logging
 */ export class UTelLogHandler {
    /**
     * Initialize UTel logging. All event will be captured by UTelLogger and sent to the UTel Tenant.
     * @param telemetrySink Telemetry sink to be used to send events
     * @param options Options object containing all relevant info needed
     * @param [enableConsoleLogging] Indicates if console logging is enabled
     * @param [flushEvents] If provided, this callback will be called when the page is unloaded
     */ initialize(telemetrySink, options, enableConsoleLogging, flushEvents) {
        if (enableConsoleLogging) {
            this.ConsoleLogging = true;
        }
        this.Logger = new TelemetryLogger(undefined, options.persistentDataFields ?? []);
        // Note: Nexus token was used when sending data on the legacy pipeline, Nexus.
        // The pipeline is pretty much gone today, but there is some code update
        // needed on UTel's side to safely remove its artifacts like NexusTenantToken.
        // That will happen in FY23 at some point. For now, suppress it.
        this.Logger.setTenantToken(LogTenantName, this.LogTenantIdentifier, SuppressNexus);
        this.Logger.addSink(telemetrySink);
        this.ConsoleLogging && onNotification().addListener((event)=>{
            // using console.debug to avoid being hijacked by TT logger
            // eslint-disable-next-line no-console
            console.debug(event.message());
        });
        if (flushEvents) {
            BeforeUnload.init();
            BeforeUnload.registerHandler((unload)=>{
                unload && flushEvents();
                return null;
            });
        }
    }
    /**
     * Log an event
     * @param event Event to be logged
     */ log(event) {
        if (!this.Logger || event == null) {
            return;
        }
        // Mapping to new events incase of legacy Planner and Project events
        const eventType = mapToNewEvents(event.type);
        if (eventType == null) {
            return;
        }
        const utelEvent = {
            eventName: createEventIdentifier(eventType),
            eventFlags: getEventFlags(),
            dataFields: event.payload ?? []
        };
        this.Logger.sendTelemetryEvent(utelEvent);
    }
    /**
     * Set a persistent data field that will be sent with every event
     * @param dataField Data field to be set
     */ setPersistentDataField(dataField) {
        if (this.Logger) {
            const persistentDataFields = this.Logger.persistentDataFields;
            // The last-added data field wins, if the same-named data field is added more than once.
            const dataFieldIndex = findLastIndex(persistentDataFields, (field)=>field.name === dataField.name);
            if (dataFieldIndex >= 0) {
                persistentDataFields[dataFieldIndex] = dataField;
            } else {
                persistentDataFields.push(dataField);
            }
        }
    }
    constructor(){
        /** Local UTel logger to be used to send session start/end events */ this.Logger = null;
        /** Telemetry logger type (Aria, UTel) */ this.type = LogHandlerType.UTel;
        /** Tenant identifier shared between Project Web, Planner Web & Planner Mobile representing all environments */ this.LogTenantIdentifier = "24c1b08831774b999295fd9fd5944757-cfc7f993-81c6-40ec-85f2-94190aa6498f-6796";
        /** Console logging. Turned off by default */ this.ConsoleLogging = false;
    }
}
