/**
 * Enum representing different types of performance entries.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/PerformanceEntry/entryType EntryType}
 */ export var EntryType;
(function(EntryType) {
    /** Represents a performance mark entry. */ EntryType["MarkEntry"] = "mark";
    /** Represents a resource timing entry. */ EntryType["ResourceEntry"] = "resource";
    /** Represents a long task entry. */ EntryType["LongTaskEntry"] = "longtask";
    /** Represents a long animation frame entry. */ EntryType["LongAnimationFrameEntry"] = "long-animation-frame";
    /** Represents a navigation timing entry. */ EntryType["NavigationEntry"] = "navigation";
})(EntryType || (EntryType = {}));
