// Action
import { isTrackableAction } from "../actions/ITrackableAction";
// Errors
import { NotSupportedError } from "@ms/uno-errors/lib/local/errors/NotSupportedError";
// Utilities
import remove from "lodash/remove";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
export class AsyncDispatcher {
    dispatchActionAsync(action) {
        return this.batchDispatchActionsAsync(action);
    }
    async batchDispatchActionsAsync(...actions) {
        const renderMeasurementActionInfo = [];
        const trackableActions = [];
        for (const action of actions){
            if (action.isLoggingEnabled()) {
                this.logAction({
                    name: action.type,
                    exportName: action.getExportName(),
                    payload: action.loggingData()
                });
            }
            if (isTrackableAction(action)) {
                renderMeasurementActionInfo.push({
                    actionId: action.actionId,
                    actionName: action.type
                });
                trackableActions.push(action);
            }
            const storeTypes = this.registry.getStoreTypesForAction(action.type);
            const storePromises = storeTypes.map(async (storeType)=>{
                try {
                    return await this.storeProvider(storeType)();
                } catch (e) {
                    if (e instanceof NotSupportedError) {
                        this.logTrace(0x1e2e0662 /* tag_4l6z8 */ , TraceLevel.Verbose, `Unsupported store found. Ignoring. [ActionType=${action.type}][StoreType=${storeType}]`);
                    } else {
                        throw e;
                    }
                }
            });
            const stores = await Promise.all(storePromises).catch((e)=>{
                this.logTrace(0x1e2e0661 /* tag_4l6z7 */ , TraceLevel.Error, `Error while dispatching action [ActionType=${action.type}][Error=${ErrorUtilities.getMessage(e)}]`);
                throw e;
            });
            for (const store of stores){
                if (store) {
                    try {
                        this.logTrace(0x1e2e0660 /* tag_4l6z6 */ , TraceLevel.Verbose, `Dispatching action [ActionType=${action.type}][Store=${store.name}]`);
                        store.handleAction(action);
                    } catch (e) {
                        this.logTrace(0x1e2e065f /* tag_4l6z5 */ , TraceLevel.Warning, `Error while dispatching action [ActionType=${action.type}][Store=${store.name}][Error=${ErrorUtilities.getMessage(e)}]`);
                    }
                }
            }
        }
        // Clone the subscribers in case a callback removes a subscriber mid-iteration
        const cloneSubscribers = this.onActionDispatchCompleteSubscribers.slice();
        for (const callback of cloneSubscribers){
            callback(renderMeasurementActionInfo);
        }
        const cloneSubscribersForTrackableActions = this.onTrackableActiOnDispatchCompleteSubscribers.slice();
        for (const callback of cloneSubscribersForTrackableActions){
            callback(trackableActions);
        }
    }
    addOnActionDispatchComplete(callback) {
        this.onActionDispatchCompleteSubscribers.push(callback);
    }
    removeOnActionDispatchComplete(callback) {
        remove(this.onActionDispatchCompleteSubscribers, (cb)=>{
            return cb === callback;
        });
    }
    subscribeOnActionDispatchComplete(callback) {
        this.onTrackableActiOnDispatchCompleteSubscribers.push(callback);
    }
    constructor(registry, storeProvider, logAction, logTrace){
        this.registry = registry;
        this.storeProvider = storeProvider;
        this.logAction = logAction;
        this.logTrace = logTrace;
        this.onActionDispatchCompleteSubscribers = [];
        this.onTrackableActiOnDispatchCompleteSubscribers = [];
    }
}
