// Factories
import { BaseServiceFactory } from "../../base/factories/BaseServiceFactory";
import { HostAdaptorAuthService } from "@ms/uno-services/lib/local/services/auth/HostAdaptorAuthService";
/** Implementation of the service provider factory for the Uno Web app */
export class WebServiceFactory extends BaseServiceFactory {
    getAuthServiceProvider(ajaxClient, hostAdaptor, configProvider) {
        return () => {
            if (this.auth) {
                return this.auth;
            }
            this.auth = new HostAdaptorAuthService(hostAdaptor, configProvider);
            return this.auth;
        };
    }
    getOneShellServiceProvider(requestClient, requestPrioritizer, configProvider) {
        return () => this.loader.load(this.getServiceBundleKey("oneshell"), () => import(/* webpackChunkName: "uno-oneshellservice" */ "@ms/uno-services/lib/local/services/oneshell/OneShellService"), (bundle) => new bundle.OneShellService(requestClient, configProvider));
    }
}
