// Factories
import { BaseStoreFactory } from "../../base/factories/BaseStoreFactory";
// Stores
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
/**
 * Factory class for store providers in Teams app
 */
export class TeamsStoreFactory extends BaseStoreFactory {
    constructor() {
        super(...arguments);
        this.loadCreateTaskFromMessageStore = () => this.loader.load(this.getStoreBundleKey(Store.CreateTaskFromMessage), () => import(
        /* webpackChunkName: "uno-createtaskfrommessagestore" */ "@ms/uno-stores/lib/local/stores/createTaskFromMessage/CreateTaskFromMessageStore"), (bundle) => new bundle.CreateTaskFromMessageStore(this.loggers));
    }
    getCreateTaskFromMessageStoreProvider() {
        return this.loadCreateTaskFromMessageStore;
    }
}
