import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for IntelligenceStore, for use in the registry */ export const IntelligenceStoreActionTypes = [
    ActionType.DatetimeRecognizerFailedAction,
    ActionType.DatetimeRecognizerSucceededAction,
    ActionType.FetchFileSuggestions,
    ActionType.FetchFileSuggestionsFailed,
    ActionType.FetchFileSuggestionsSucceeded,
    ActionType.FetchImageRecommendations,
    ActionType.FetchImageRecommendationsFailed,
    ActionType.FetchImageRecommendationsSucceeded,
    ActionType.FileSuggestionsSeen,
    ActionType.CopilotReSyncTilesAction
];
