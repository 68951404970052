/**
 * Platform values used in telemetry
 */ export var TelemetryPlatform;
(function(TelemetryPlatform) {
    /** Web platform */ TelemetryPlatform["Web"] = "Web";
    /** Desktop platform */ TelemetryPlatform["Desktop"] = "Desktop";
    /** iOS platform */ TelemetryPlatform["iOS"] = "iOS";
    /** Android platform */ TelemetryPlatform["Android"] = "Android";
    /** macOS platform */ TelemetryPlatform["macOS"] = "macOS";
    /** Other platform */ TelemetryPlatform["Other"] = "Other";
})(TelemetryPlatform || (TelemetryPlatform = {}));
