/**
 * Config not found error
 */ export class ConfigNotFoundError extends Error {
    constructor(configObjectName, errorMessage){
        super(), this.configObjectName = configObjectName, this.errorMessage = errorMessage;
        if (configObjectName) {
            this.message = `${configObjectName} was not found. Settings will not be loaded. ${errorMessage || ""}`.trim();
        } else {
            this.message = errorMessage || "";
        }
        this.name = "ConfigNotFoundError";
    }
}
