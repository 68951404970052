/**
 * Performance Marker tag enum for all the performance markers
 */ export var MarkerTag;
(function(MarkerTag) {
    // Very first tag to be marked once the app bootstrap gets called.
    MarkerTag["AppBootstrap"] = "AppBootstrap";
    // Marked when the Teams SDK is initialized. This is only applicable for Teams app.
    MarkerTag["InitializedTeamsSDK"] = "InitializedTeamsSDK";
    // Marked in the lifecycle of any smart component.
    MarkerTag["ViewRender"] = "ViewRender";
    // Marked when the app load is started from cache.
    MarkerTag["CachedLoadStart"] = "CachedLoadStart";
    // Marked when the app load completes from cache.
    MarkerTag["CachedLoadComplete"] = "CachedLoadComplete";
})(MarkerTag || (MarkerTag = {}));
export var FetchedDataState;
(function(FetchedDataState) {
    // All data required by the component
    FetchedDataState[FetchedDataState["All"] = 2] = "All";
    // Visually critical data sufficient to make the component feel loaded
    FetchedDataState[FetchedDataState["Critical"] = 1] = "Critical";
    // No data fetched
    FetchedDataState[FetchedDataState["None"] = 0] = "None";
})(FetchedDataState || (FetchedDataState = {}));
