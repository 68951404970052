import { makeStringDataField } from "@microsoft/oteljs";
import forEach from "lodash/forEach";
import { EventType } from "../UTelLogHandler";
import { createExtraDataField, logEvent } from "../LoggingHelper";
export var TraceLevel;
(function(TraceLevel) {
    /** Critical non recoverable error */ TraceLevel[TraceLevel["Error"] = 0] = "Error";
    /** Non critical error */ TraceLevel[TraceLevel["Warning"] = 1] = "Warning";
    /** Informational and default diagnostic level */ TraceLevel[TraceLevel["Info"] = 2] = "Info";
    /** Fine grained verbose logs for detailed debugging */ TraceLevel[TraceLevel["Verbose"] = 3] = "Verbose";
})(TraceLevel || (TraceLevel = {}));
/**
 * Logs the event using UTelJs
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ function LogUTel(event, logHandler) {
    const payload = [
        makeStringDataField("Message", event.message),
        makeStringDataField("LocationTag", event.locationTag),
        makeStringDataField("Level", TraceLevel[event.level]),
        makeStringDataField("Timestamp", event.timestamp.toISOString()),
        makeStringDataField("PreciseMarker", String(event.preciseMarker))
    ];
    forEach(event.extraData, (value, key)=>{
        payload.push(createExtraDataField(EventType.Diagnostics, key, value));
    });
    logHandler.log({
        type: EventType.Diagnostics,
        payload: payload
    });
}
/**
 * Logs the event with all available loggers
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ export function Log(event, logHandler) {
    logEvent(event, LogUTel, logHandler);
}
