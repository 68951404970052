import { ResultTypeEnum } from "./ResultTypeEnum";
// Utilities
import { Async } from "@fluentui/utilities";
import { logEvent } from "../LoggingHelper";
let _id = 0;
const timeoutErrorCode = "Timeout";
function generateQosResult(resultType, resultCode, error) {
    const schema = {
        resultType: resultType
    };
    if (resultCode) {
        schema.resultCode = resultCode;
    }
    if (error) {
        schema.error = error;
    }
    return schema;
}
export var EventType;
(function(EventType) {
    EventType[EventType["start"] = 0] = "start";
    EventType[EventType["end"] = 1] = "end";
})(EventType || (EventType = {}));
export class PairedEventBase {
    setTimeout(ms, data) {
        if (!this.async) {
            this.async = new Async(this);
        }
        this._clearTimeout();
        if (!data) {
            data = generateQosResult(ResultTypeEnum.Failure, timeoutErrorCode);
        }
        this.async.setTimeout(this.end.bind(this, data), ms);
    }
    end(data) {
        // Make sure end can only be called once
        if (!this.endTime) {
            if (data) {
                this._setData(data);
            }
            // Set the end time
            this.endTime = new Date().getTime();
            // Log the event end
            this.log(1);
            this._clearTimeout();
        }
    }
    log(eventType) {
        logEvent(eventType, this._logUTel.bind(this), this.logHandler);
    }
    _setData(data) {
        const stored = this.data;
        for (const key of Object.keys(data)){
            const value = data[key];
            switch(typeof value){
                case "undefined":
                    break;
                default:
                    stored[key] = value;
                    break;
            }
        }
    }
    _clearTimeout() {
        if (this.async && this.timeoutId) {
            this.async.clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }
    constructor(data, logHandler){
        this.logHandler = logHandler;
        this.validationErrors = 0;
        this.data = {};
        this.id = _id++;
        this.startTime = new Date().getTime();
        // Set the data if we have it
        if (data) {
            this._setData(data);
        }
        // Send the start event
        this.log(0);
    }
}
