// Factories
import { BaseActionCreatorFactory } from "../../base/factories/BaseActionCreatorFactory";
/** Factory class for action creator providers in Teams app */
export class TeamsActionCreatorFactory extends BaseActionCreatorFactory {
    getCreateTaskFromTeamsMessageAcProvider(dispatcher, modules, stores, configProvider) {
        return () => this.loader.load(this.getAcBundleKey("createtaskfromteamsmessage"), () => import(
        /* webpackChunkName: "uno-createtaskfromteamsmessageac" */ "@ms/uno-actioncreators/lib/local/actioncreators/createTaskFromTeamsMessage/CreateTaskFromTeamsMessageActionCreator"), (bundle) => new bundle.CreateTaskFromTeamsMessageActionCreator(dispatcher, modules, this.loggers));
    }
    getTeamsTabAcProvider(dispatcher, modules, stores, configProvider, teamsHostAdaptor) {
        return () => this.loader.load(this.getAcBundleKey("teamstab"), () => import(
        /* webpackChunkName: "uno-teamstabac" */ "@ms/uno-actioncreators/lib/local/actioncreators/teamsTab/TeamsTabActionCreator"), (bundle) => new bundle.TeamsTabActionCreator(modules, stores, dispatcher, this.loggers, teamsHostAdaptor, configProvider));
    }
    getCreateTeamsTabViewAcProvider(dispatcher, modules, stores, configProvider, hostAdaptor) {
        return () => this.loader.load(this.getAcBundleKey("createteamstabview"), () => import(
        /* webpackChunkName: "uno-createteamstabviewac" */ "@ms/uno-actioncreators/lib/local/actioncreators/view/CreateTeamsTabViewActionCreator"), (bundle) => new bundle.CreateTeamsTabViewActionCreator(dispatcher, modules, this.loggers, hostAdaptor));
    }
}
