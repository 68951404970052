export var PageTimingKey;
(function(PageTimingKey) {
    PageTimingKey["euplFull"] = "euplFull";
    PageTimingKey["pttFull"] = "pttFull";
})(PageTimingKey || (PageTimingKey = {}));
/**
 * Sets a key value pair in window._pageTimings object for reporting to MS Performance Analyzer Service
 * @param key custom metric key
 * @param value number value
 */ export function setPageTiming(key, value) {
    const pageTimings = window._pageTimings || (window._pageTimings = {});
    pageTimings[key] = value;
}
