// Utilities
/**
 * Compare two given objects based on order hint value readable via the given orderHintAccessor callback
 * @param object1 First object to compare
 * @param object2 Second object to compare
 * @param orderHintAccessor Accessor function used to get the order hint value to use in the comparison
 */ export function compareByOrderHint(object1, object2, orderHintAccessor) {
    const orderHint1 = orderHintAccessor(object1);
    const orderHint2 = orderHintAccessor(object2);
    return compareStringOrdinal(orderHint1, orderHint2);
}
/**
 * Compare given objects by moment date from given dateAccessor callback
 * @param object1 First object to compare
 * @param object2 Second object to compare
 * @param dateAccessor Accessor function used to get the moment value to use in the comparison
 */ export function compareByMomentDate(object1, object2, dateAccessor) {
    const date1 = dateAccessor(object1);
    const date2 = dateAccessor(object2);
    return compareMomentDates(date1, date2);
}
/**
 * Compare two given objects based on displayName value readable via the given displayNameAccessor callback
 * @param object1 First object to compare
 * @param object2 Second object to compare
 * @param displayNameAccessor Accessor function used to get the displayName value to use in the comparison
 */ export function compareByDisplayName(object1, object2, displayNameAccessor) {
    const displayName1 = displayNameAccessor(object1);
    const displayName2 = displayNameAccessor(object2);
    return compareStringForDisplay(displayName1 ? displayName1.toUpperCase() : null, displayName2 ? displayName2.toUpperCase() : null);
}
/**
 * Compare given strings based on ordinal value.
 * Use for comparison of IDs and ordinal values.
 * @param str1 First string to compare
 * @param str2 Second string to compare
 */ export function compareStringOrdinal(str1, str2) {
    if (str1 == null && str2 == null) {
        return 0;
    }
    if (str1 == null) {
        return -1;
    }
    if (str2 == null) {
        return 1;
    }
    return stringOrdinalCompare(str1, str2);
}
/**
 * Compare two orderHint.
 * @param orderHint1
 * @param orderHint2
 */ export function compareOrderHints(orderHint1, orderHint2) {
    return compareStringOrdinal(orderHint1, orderHint2);
}
/**
 * Compare given strings based on case sensitive comparison for display.
 * Use locale to determine sort order.
 * @param str1 First string to compare
 * @param str2 Second string to compare
 */ export function compareStringForDisplay(str1, str2) {
    if (str1 == null && str2 == null) {
        return 0;
    }
    if (str1 == null) {
        return -1;
    }
    if (str2 == null) {
        return 1;
    }
    return str1.localeCompare(str2);
}
/**
 * Compare given strings based on case sensitive comparison for display.
 * Use locale to determine sort order, return reverse order
 * @param str1 First string to compare
 * @param str2 Second string to compare
 */ export function compareStringForDisplayReverse(str1, str2) {
    if (str1 == null && str2 == null) {
        return 0;
    }
    if (str1 == null) {
        return 1;
    }
    if (str2 == null) {
        return -1;
    }
    const localeCompareValue = str1.localeCompare(str2);
    if (localeCompareValue === 0) {
        return 0;
    } else if (localeCompareValue === -1) {
        return 1;
    } else {
        return -1;
    }
}
/**
 * Compare the given numbers to sort in numerical order, ascending.
 * @param num1 the first number to compare
 * @param num2 the second number to compare
 */ export function compareNumbers(num1, num2) {
    return num1 - num2;
}
/**
 * Compare strings in ordinal manner, without case sensitivity. Returns less than 0, 0, or greater than 0 based on the comparison.
 * @param left The left side of the comparison
 * @param right The right side of the comparison
 */ export function stringOrdinalCaseInsensitiveCompare(left, right) {
    return stringOrdinalCompare(left.toLowerCase(), right.toLowerCase());
}
/**
 * Compare strings in ordinal manner, with case sensitivity. Returns less than 0, 0, or greater than 0 based on the comparison.
 * @param left The left side of the comparison
 * @param right The right side of the comparison
 */ export function stringOrdinalCompare(left, right) {
    return left < right ? -1 : left > right ? 1 : 0;
}
/**
 * Compare given strings based on case sensitive comparison, uses locale to determine sort order
 * Wrapper around javascript localeCompare that will also handle nulls
 * @param str1 First string to compare
 * @param str2 Second string to compare
 * @param locales A locale string or array of locale strings. If you omit this parameter, the default locale of the JavaScript runtime is used.
 */ export function stringLocaleCompare(str1, str2, locales) {
    if (str1 == null && str2 == null) {
        return 0;
    }
    if (str1 == null) {
        return -1;
    }
    if (str2 == null) {
        return 1;
    }
    return str1.localeCompare(str2, locales);
}
/**
 * Compare two moment dates
 * @param date1 First date
 * @param date2 Second date
 */ export function compareMomentDates(date1, date2) {
    if (date1 == null) {
        if (date2 == null) {
            return 0;
        } else {
            return 1;
        }
    } else if (date2 == null) {
        return -1;
    } else {
        const comparison = date1.diff(date2);
        return comparison < 0 ? -1 : comparison > 0 ? 1 : comparison;
    }
}
/**
 * Compare two native JS dates
 * @param date1 First date
 * @param date2 Second date
 */ export function compareJSDates(date1, date2) {
    if (date1 == null) {
        if (date2 == null) {
            return 0;
        } else {
            return 1;
        }
    } else if (date2 == null) {
        return -1;
    } else {
        const value = date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
        return value;
    }
}
