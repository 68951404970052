import { RolloutStage } from "./RolloutStage";
export var ClientAppId;
(function(ClientAppId) {
    ClientAppId["PlannerMobile"] = "66375f6b-983f-4c2c-9701-d680650f588f";
    ClientAppId["PlannerWeb"] = "09abbdfd-ed23-44ee-a2d9-a627aa1c90f3";
    ClientAppId["MSTeamsWeb"] = "5e3ce6c0-2b1f-4285-8d4b-75ee78787346";
    ClientAppId["MSTeamsDesktop"] = "1fec8e78-bce4-4aaf-ab1b-5451cc387264";
    /** The "Microsoft Teams Retail Service" client Entra app used to invoke the on-behalf-of mechanism to get access tokens in legacy Teams Tasks app and initially in Teams Uno app */ ClientAppId["MicrosoftTeamsRetailService"] = "75efb5bc-18a1-4e7b-8a66-2ad2503d79c6";
    /** The "Microsoft Planner Client" client Entra app used in Planner/Uno Teams app, Planner/Uno metaOS app, web standalone etc. moving forward */ ClientAppId["MicrosoftPlannerClient"] = "75f31797-37c9-498e-8dc9-53c16a36afca";
    ClientAppId["DeprecatedSharepoint"] = "00000003-0000-0ff1-ce00-000000000000";
    ClientAppId["Sharepoint"] = "08e18876-6177-487e-b8b5-cf950c1e598c";
    ClientAppId["ProjectReplicationService"] = "e62ee429-f250-4aef-a588-7b511fa9a67a";
    ClientAppId["CentroService"] = "00000006-0000-0ff1-ce00-000000000000";
    ClientAppId["ToDo"] = "5754bb84-f388-44f0-b3f7-9233a05bbb34";
    ClientAppId["ApprovalApp"] = "7c316234-ded0-4f95-8a83-8453d0876592";
})(ClientAppId || (ClientAppId = {}));
export var ClientType;
(function(ClientType) {
    ClientType["Teams"] = "Teams";
    ClientType["Web"] = "Web";
    ClientType["Sharepoint"] = "Sharepoint";
    ClientType["Loop"] = "Loop";
    ClientType["Dynamics"] = "Dynamics";
})(ClientType || (ClientType = {}));
export var ClientFlavor;
(function(ClientFlavor) {
    ClientFlavor["App"] = "App";
    ClientFlavor["Tab"] = "Tab";
    ClientFlavor["ConfigTab"] = "ConfigTab";
    ClientFlavor["RemoveTab"] = "RemoveTab";
    ClientFlavor["TaskFromMessage"] = "TaskFromMessage";
    ClientFlavor["Component"] = "Component";
})(ClientFlavor || (ClientFlavor = {}));
export var Environment;
(function(Environment) {
    Environment["Ppe"] = "Ppe";
    Environment["Prod"] = "Prod";
    Environment["Gcc"] = "Gcc";
    Environment["Gcch"] = "Gcch";
    Environment["Dod"] = "Dod";
    Environment["Agc"] = "Agc";
})(Environment || (Environment = {}));
export var TenantRegion;
(function(TenantRegion) {
    TenantRegion["Eu"] = "EU";
    TenantRegion["Row"] = "ROW";
})(TenantRegion || (TenantRegion = {}));
export var SharePointAssociationType;
(function(SharePointAssociationType) {
    SharePointAssociationType["SPWeb"] = "SPWeb";
})(SharePointAssociationType || (SharePointAssociationType = {}));
export var TeamsAppAssociationType;
(function(TeamsAppAssociationType) {
    TeamsAppAssociationType["Board"] = "Board";
})(TeamsAppAssociationType || (TeamsAppAssociationType = {}));
export var Browser;
(function(Browser) {
    Browser["Chrome"] = "Chrome";
    Browser["Edge"] = "Edge";
    Browser["Firefox"] = "Firefox";
    Browser["IE"] = "IE";
    Browser["Opera"] = "Opera";
    Browser["Other"] = "Other";
    Browser["Safari"] = "Safari";
    Browser["Electron"] = "Electron";
})(Browser || (Browser = {}));
/**
 * Constants related to users
 */ export const UnassignedUserId = "Unassigned";
export var UserPolicyFlags;
(function(UserPolicyFlags) {
    /**
     * No user policy
     */ UserPolicyFlags[UserPolicyFlags["None"] = 0] = "None";
    /**
     * User is not authorized to delete tasks that have not been created by the user
     */ UserPolicyFlags[UserPolicyFlags["CannotDeleteTasksNotCreatedBySelf"] = 1] = "CannotDeleteTasksNotCreatedBySelf";
})(UserPolicyFlags || (UserPolicyFlags = {}));
export var RetryAjaxHelperConfigConstants;
(function(RetryAjaxHelperConfigConstants) {
    /** Constant for delay between two requests */ RetryAjaxHelperConfigConstants.delayOnRetryInMiliseconds = 200;
    /** Constant for max retry timeout for all requests */ RetryAjaxHelperConfigConstants.maxRetryTimeoutInMiliseconds = 180000;
    /*Constant so max retry count */ RetryAjaxHelperConfigConstants.maxRetryCount = 2;
})(RetryAjaxHelperConfigConstants || (RetryAjaxHelperConfigConstants = {}));
/** Product id for Project used in Centro service*/ export const ProjectCentroProductId = "CFQ7TTC0HDB1";
export var PlanType;
(function(PlanType) {
    PlanType["Basic"] = "Basic";
    PlanType["Premium"] = "Premium";
    PlanType["ToDo"] = "ToDo";
})(PlanType || (PlanType = {}));
/**
 * Constants for persistent storage schema version
 */ export const persistentStorageSchemaVersion = "1";
/** Document ids with this prefix are stored in SharePoint */ export const SPDocIdPrefix = "SPO_";
/** MSA tenant Id */ export const MsaTenantId = "9188040d-6c67-4c5b-b112-36a304b66dad";
/** MSIT tenant id */ export const MicrosoftTenantId = "72f988bf-86f1-41af-91ab-2d7cd011db47";
/** Sharepoint site base domain for MSFT tenant for SDF*/ export const SPSiteBaseDomainForMSFTSDF = "microsoft.sharepoint-df.com";
/** Sharepoint site base domain for MSFT tenant*/ export const SPSiteBaseDomainForMSFT = "microsoft.sharepoint.com";
/** OneDrive Resource URI*/ export const OneDriveResourceUri = "pushchannel.1drv.ms";
/**
 * A class with lists of supported cultures for different libraries the app uses e.g. Moment
 */ export class CultureConstants {
}
/**
     * List of ll-cc or just ll cultures that are rtl. Entries will be in the ll-cc format or ll format
     * https://lingohub.com/academy/best-practices/rtl-language-list
     */ CultureConstants.RtlCultures = [
    "ar",
    "he",
    "ur",
    "ku",
    "fa"
];
/**
     * A list of the currently supported cultures by the moment library
     */ CultureConstants.MomentCultures = [
    "ar",
    "ar-ly",
    "ar-ma",
    "ar-sa",
    "ar-tn",
    "bg",
    "ca",
    "cs",
    "cy",
    "da",
    "de",
    "de-at",
    "el",
    "en",
    "en-au",
    "en-ca",
    "en-gb",
    "en-ie",
    "en-in",
    "en-nz",
    "es",
    "es-do",
    "et",
    "eu",
    "fi",
    "fil",
    "fr",
    "fr-ca",
    "fr-ch",
    "gl",
    "he",
    "hi",
    "hr",
    "hu",
    "id",
    "is",
    "it",
    "ja",
    "ka",
    "kk",
    "ko",
    "lt",
    "lv",
    "ml",
    "ms-my",
    "nb",
    "nl",
    "nn",
    "no",
    "pl",
    "pt",
    "pt-br",
    "ro",
    "ru",
    "sr-cyrl",
    "sr",
    "sk",
    "sl",
    "sv",
    "th",
    "tr",
    "uk",
    "vi",
    "zh-cn",
    "zh-hk",
    "zh-tw"
];
// The default moment culture
CultureConstants.DefaultMomentCulture = "en";
/**
 * Class for learn more links
 */ export class LearnMoreLinks {
}
/** The learn more link for sensitivity labels */ LearnMoreLinks.sensitivityLabels = "https://go.microsoft.com/fwlink?linkid=2106748";
/** The learn more link for linked plans (PlanContexts) */ LearnMoreLinks.linkedPlans = "https://aka.ms/linkedplans";
/** The learn more link for Plans in Roster containers (lightweight plans) */ LearnMoreLinks.lightWeightPlans = "https://aka.ms/lightweightplans";
/** The learn more link for groups */ LearnMoreLinks.m365Groups = "https://go.microsoft.com/fwlink?linkid=2049051";
/** The learn more link for guest access */ LearnMoreLinks.guestAccess = "https://go.microsoft.com/fwlink?linkid=871797";
/** The learn more link for email and notifications */ LearnMoreLinks.emailAndNotifications = "https://go.microsoft.com/fwlink?linkid=866998";
/** The learn more link for copying and moving tasks */ LearnMoreLinks.copyAndMoveTasks = "https://go.microsoft.com/fwlink?linkid=872349";
/** The learn more link for Try Project */ LearnMoreLinks.getStartedWithProjectForTheWeb = "https://support.microsoft.com/office/get-started-with-project-for-the-web-50bf3e29-0f0d-4b7a-9d2c-7c78389b67ad";
/** The learn more link for IE being deprecated :) */ LearnMoreLinks.ieDeprecated = "https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666";
/** The learn more link for shared plans */ LearnMoreLinks.sharedPlans = "https://go.microsoft.com/fwlink?linkid=2228058";
/** The learn more link for some fields like attachments being absent in personal task editor */ LearnMoreLinks.missingFields = "https://go.microsoft.com/fwlink/?linkid=2245039";
/** The learn more link for adding a forms requirement */ LearnMoreLinks.addFormsRequirement = "https://go.microsoft.com/fwlink/?linkid=2245040";
/** The learn more link for loop planner component */ LearnMoreLinks.usePlannerComponent = "https://go.microsoft.com/fwlink/?linkid=2245448";
/** The learn more link for Project/Premium plan P1 */ LearnMoreLinks.projectP1SubscriptionPlanLearnMoreUrl = "https://go.microsoft.com/fwlink/?linkid=2183548";
/** The learn more link for Project/Premium plan P3 */ LearnMoreLinks.projectP3SubscriptionPlanLearnMoreUrl = "https://go.microsoft.com/fwlink/?linkid=2183549";
/** The learn more link for Project/Premium plan P5 */ LearnMoreLinks.projectP5SubscriptionPlanLearnMoreUrl = "https://go.microsoft.com/fwlink/?linkid=2183649";
/** The learn more link for Project */ LearnMoreLinks.projectLearnMoreUrl = "https://go.microsoft.com/fwlink/?linkid=2261209";
/** The learn more link for importing plans */ LearnMoreLinks.importPlanLearnMoreUrl = "https://go.microsoft.com/fwlink/?linkid=2226056";
/** The planner(uno) help link */ LearnMoreLinks.plannerHelp = "https://go.microsoft.com/fwlink/?linkid=2260017";
/** The link to the license terms */ LearnMoreLinks.licenseTerms = "https://www.microsoft.com/servicesagreement";
/** The link to the privacy statement */ LearnMoreLinks.privacyStatement = "https://privacy.microsoft.com/privacystatement";
/** The link to how to join a group as a member */ LearnMoreLinks.joinGroupAsMember = "https://go.microsoft.com/fwlink/?linkid=2264502";
/** The terms of service link for in app trials */ LearnMoreLinks.trialTermsOfServiceUrl = "https://go.microsoft.com/fwlink?linkid=2204238";
/** The FRE learn more link */ LearnMoreLinks.fre = "https://go.microsoft.com/fwlink/?linkid=2283523";
/** The learn more link for sensitivity labels and Microsoft 365 Copilot */ LearnMoreLinks.sensitivityLabelsAndMicrosoft365Copilot = "https://learn.microsoft.com/en-us/purview/sensitivity-labels#sensitivity-labels-and-microsoft-365-copilot";
export var TeamsAppId;
(function(TeamsAppId) {
    TeamsAppId["Dev"] = "3434be72-1bba-427b-83ca-43daf2ee0d22";
    TeamsAppId["PPE"] = "9c0fcd8d-7cca-466f-b549-9254dac9c036";
    TeamsAppId["Prod"] = "com.microsoft.teamspace.tab.planner";
})(TeamsAppId || (TeamsAppId = {}));
/** Bucket id prefix used for copying / moving into uncategorized bucket */ export const UncategorizedBucketId = "UNCATEGORIZED";
/** The link to the unified group info page on outlook */ export const unifiedGroupLink = "https://outlook.office.com/owa/?path=/group/{0}/people";
/** A mapping of all supported environments to the rollout stages allowed to exist in the respective environments. */ export const allowedRolloutStagesForEnvironment = new Map([
    [
        "Agc",
        [
            RolloutStage.AirGap
        ]
    ],
    [
        "Dod",
        [
            RolloutStage.DoD
        ]
    ],
    [
        "Gcc",
        [
            RolloutStage.Gcc
        ]
    ],
    [
        "Gcch",
        [
            RolloutStage.Gcch
        ]
    ],
    [
        "Ppe",
        [
            RolloutStage.PreProd
        ]
    ],
    [
        "Prod",
        [
            RolloutStage.Demo,
            RolloutStage.Dev,
            RolloutStage.DogfoodDev,
            RolloutStage.DogfoodStaging,
            RolloutStage.DogfoodAll,
            RolloutStage.ProdMSIT,
            RolloutStage.ProdBetaTAP,
            RolloutStage.ProdAll
        ]
    ]
]);
export const AppRootElementId = "root";
export var OfficeBrowserFeedbackConstants;
(function(OfficeBrowserFeedbackConstants) {
    /** Identifier for our app in Office Browser Feedback tool */ OfficeBrowserFeedbackConstants.AppId = 2204;
})(OfficeBrowserFeedbackConstants || (OfficeBrowserFeedbackConstants = {}));
