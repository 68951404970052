// Constants
import { SubRouteKeys, SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
/**
 * Creates a route descriptor from specified IRouteCreateDetails
 * @param details Details on the route to create
 * @param routeNativePersonalViews If true, route to new native personal views. Else route to original TT views
 */ export function createRouteFromDetails(details) {
    const subRouteType = details.subRouteType;
    switch(subRouteType){
        case SubRouteType.BasicPlan:
            return createBasicPlanRoute(details);
        case SubRouteType.PremiumPlan:
            return createPremiumPlanRoute(details);
        case SubRouteType.TodoList:
            return createTodoListRoute(details);
        case SubRouteType.MyTasks:
            return createMyTasksRoute(details);
        case SubRouteType.MyDay:
            return createMyDayRoute(details);
        case SubRouteType.ConfigTeamsTab:
            return createConfigTeamsTabRoute(details);
        case SubRouteType.NewTabPage:
            return createNewTabPageRoute(details);
        case SubRouteType.RemoveTeamsTab:
            return createRemoveTeamsTabRoute(details);
        case SubRouteType.MyPlans:
            return createMyPlansRoute(details);
        default:
            return null;
    }
}
/**
 * Creates a basic plan route descriptor from specified IPlanRouteCreateDetails
 */ function createBasicPlanRoute(details) {
    const subRoute = [
        details.planId
    ];
    if (details.taskId) {
        subRoute.push(SubRouteKeys.Task, details.taskId);
    }
    if (details.view) {
        subRoute.push(SubRouteKeys.View, details.view);
    }
    return createRoute(details.subRouteType, subRoute);
}
/**
 * Creates a premium plan route descriptor from specified IPremiumPlanRouteCreateDetails
 */ function createPremiumPlanRoute(details) {
    const subRoute = [
        details.planId,
        SubRouteKeys.Org,
        details.orgId
    ];
    if (details.taskId) {
        subRoute.push(SubRouteKeys.Task, details.taskId);
    }
    return createRoute(details.subRouteType, subRoute);
}
/**
 * Creates a Todo list route descriptor (NativePersonalViews on) from specified ITodoListRouteCreateDetails
 */ function createTodoListRoute(details) {
    const subRoute = [
        details.personalListId
    ];
    if (details.taskId) {
        subRoute.push(SubRouteKeys.Task, details.taskId);
    }
    return createRoute(details.subRouteType, subRoute);
}
/**
 * Creates a MyTasks route descriptor (NativePersonalViews on) from specified IMyTasksRouteCreateDetails
 */ function createMyTasksRoute(details) {
    const subRoute = [
        details.myTasksPivot
    ];
    if (details.view) {
        subRoute.push(SubRouteKeys.View, details.view);
    }
    if (details.taskId) {
        subRoute.push(SubRouteKeys.Task, details.taskId);
    }
    return createRoute(details.subRouteType, subRoute);
}
/**
 * Creates a MyDay route descriptor (NativePersonalViews on) from specified IMyDayRouteCreateDetails
 */ function createMyDayRoute(details) {
    const subRoute = [];
    if (details.view) {
        subRoute.push(SubRouteKeys.View, details.view);
    }
    if (details.taskId) {
        subRoute.push(SubRouteKeys.Task, details.taskId);
    }
    return createRoute(details.subRouteType, subRoute);
}
/**
 * Creates a ConfigTeamsTab route descriptor from specified IConfigTeamsTabRouteCreateDetails
 */ function createConfigTeamsTabRoute(details) {
    return createRoute(details.subRouteType, []);
}
/**
 * Creates a NewTabPage route descriptor from specified INewTabPageRouteCreateDetails
 */ function createNewTabPageRoute(details) {
    return createRoute(details.subRouteType, []);
}
/**
 * Creates a RemoveTeamsTab route descriptor from specified IRemoveTeamsTabRouteCreateDetails
 */ function createRemoveTeamsTabRoute(details) {
    const subRoute = [];
    if (details.planId) {
        subRoute.push(details.planId);
    }
    if (details.planType) {
        subRoute.push(SubRouteKeys.PlanType, details.planType);
    }
    if (details.orgId) {
        subRoute.push(SubRouteKeys.Org, details.orgId);
    }
    return createRoute(details.subRouteType, subRoute);
}
/**
 * Creates a MyPlans route descriptor from specified IMyPlansRouteCreateDetails
 */ function createMyPlansRoute(details) {
    const subRoute = [];
    if (details.taskId) {
        subRoute.push(SubRouteKeys.Task, details.taskId);
    }
    return createRoute(details.subRouteType, subRoute);
}
/**
 * Helper method for constructing Route Descriptor from the specified subRouteType and subRoute
 */ function createRoute(subRouteType, subRoute) {
    return {
        rootUrl: "",
        subRouteType: subRouteType,
        subRoute: subRoute,
        queryParameters: {}
    };
}
