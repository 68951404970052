/**
 * Authentication token claims
 */ export var AuthTokenClaims;
(function(AuthTokenClaims) {
    /** acct - User's account status in tenant - If the user is a member of the tenant, the value is 0. If they're a guest, the value is 1. */ AuthTokenClaims["Acct"] = "acct";
    /**
     * https://review.learn.microsoft.com/en-us/identity/microsoft-identity-platform/entra-optional-claims?branch=main#first-party-only-claims
     * This claim is set to EU if the tenant is an EU tenant based on the tenant's country or region (countryLetterCode) and the tenant isn't a worldwide tenant with a WW replication scope.
     * The claim won't be present if the tenant isn't in the EU or if the tenant has a WW replication scope.
     * https://msazure.visualstudio.com/One/_git/ESTS-Docs?path=/OptionalClaims/DataBoundaryClaim.md&_a=preview&version=GBmaster
     */ AuthTokenClaims["TelemetryDataBoundary"] = "xms_tdbr";
})(AuthTokenClaims || (AuthTokenClaims = {}));
export var TenantRegionSubScope;
(function(TenantRegionSubScope) {
    TenantRegionSubScope["GCC"] = "GCC";
    TenantRegionSubScope["DOD"] = "DOD";
    TenantRegionSubScope["DODCON"] = "DODCON";
})(TenantRegionSubScope || (TenantRegionSubScope = {}));
/** Expected value of claim acct for a guest user */ export const GuestUserAcctClaimValue = 1;
