import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for ConversationsStore, for use in the registry */ export const ConversationsStoreActionTypes = [
    ActionType.CleanConversationThreadAction,
    ActionType.CreateConversationThreadAction,
    ActionType.CreateConversationThreadFailedAction,
    ActionType.CreateConversationThreadSucceededAction,
    ActionType.FetchConversationThreadAction,
    ActionType.FetchConversationThreadFailedAction,
    ActionType.FetchConversationThreadSucceededAction,
    ActionType.PostConversationThreadReplyAction,
    ActionType.PostConversationThreadReplyFailedAction,
    ActionType.PostConversationThreadReplySucceededAction,
    ActionType.FetchConversationThreadPostSucceededAction
];
