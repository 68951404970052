import { createTheme } from "@fluentui/react";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
/**
 * Partial theme for the Teams light theme.
 * Translated from default v9 Teams Light Theme.
 */ const TeamsLightPartialTheme = {
    palette: {
        themeDarker: "#383966",
        themeDark: "#444791",
        themeDarkAlt: "#4f52b2",
        themePrimary: "#5b5fc7",
        themeSecondary: "#7579eb",
        themeTertiary: "#aab1fa",
        themeLight: "#c5cbfa",
        themeLighter: "#dce0fa",
        themeLighterAlt: "#e8ebfa",
        black: "#000000",
        blackTranslucent40: "rgba(0, 0, 0, 0.4)",
        neutralDark: "#141414",
        neutralPrimary: "#242424",
        neutralPrimaryAlt: "#383838",
        neutralSecondary: "#5c5c5c",
        neutralSecondaryAlt: "#858585",
        neutralTertiary: "#9e9e9e",
        neutralTertiaryAlt: "#c7c7c7",
        neutralQuaternary: "#d1d1d1",
        neutralQuaternaryAlt: "#e0e0e0",
        neutralLight: "#ebebeb",
        neutralLighter: "#f5f5f5",
        neutralLighterAlt: "#fafafa",
        accent: "#5b5fc7",
        white: "#ffffff",
        whiteTranslucent40: "rgba(255, 255, 255, 0.4)",
        yellowDark: "#d39300",
        yellow: "#fde300",
        yellowLight: "#fef7b2",
        orange: "#f7630c",
        orangeLight: "#f98845",
        orangeLighter: "#fdcfb4",
        redDark: "#750b1c",
        red: "#d13438",
        magentaDark: "#6b0043",
        magenta: "#bf0077",
        magentaLight: "#d957a8",
        purpleDark: "#401b6c",
        purple: "#5c2e91",
        purpleLight: "#c6b1de",
        blueDark: "#003966",
        blueMid: "#004e8c",
        blue: "#0078d4",
        blueLight: "#3a96dd",
        tealDark: "#006666",
        teal: "#038387",
        tealLight: "#00b7c3",
        greenDark: "#0b6a0b",
        green: "#107c10",
        greenLight: "#13a10e"
    },
    effects: {
        elevation4: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
        elevation8: "0 0 2px rgba(0,0,0,0.12), 0 4px 8px rgba(0,0,0,0.14)",
        elevation16: "0 0 2px rgba(0,0,0,0.12), 0 8px 16px rgba(0,0,0,0.14)",
        elevation64: "0 0 8px rgba(0,0,0,0.12), 0 32px 64px rgba(0,0,0,0.14)",
        roundedCorner2: "2px",
        roundedCorner4: "4px",
        roundedCorner6: "6px"
    },
    fonts: {
        tiny: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "10px",
            fontWeight: 400
        },
        xSmall: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "10px",
            fontWeight: 400
        },
        small: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "12px",
            fontWeight: 400
        },
        smallPlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "12px",
            fontWeight: 400
        },
        medium: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "14px",
            fontWeight: 400
        },
        mediumPlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "16px",
            fontWeight: 400
        },
        large: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "16px",
            fontWeight: 400
        },
        xLarge: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "20px",
            fontWeight: 600
        },
        xLargePlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "24px",
            fontWeight: 600
        },
        xxLarge: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "28px",
            fontWeight: 600
        },
        xxLargePlus: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "32px",
            fontWeight: 600
        },
        superLarge: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "40px",
            fontWeight: 600
        },
        mega: {
            fontFamily: "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontSize: "68px",
            fontWeight: 600
        }
    },
    spacing: {
        s2: "4px",
        s1: "8px",
        m: "16px",
        l1: "20px",
        l2: "32px"
    },
    isInverted: false,
    disableGlobalClassNames: false,
    semanticColors: {
        bodyBackground: "#ffffff",
        bodyFrameBackground: "#ffffff",
        accentButtonText: "#ffffff",
        buttonBackground: "#ffffff",
        primaryButtonText: "#ffffff",
        primaryButtonTextHovered: "#ffffff",
        primaryButtonTextPressed: "#ffffff",
        inputBackground: "#ffffff",
        inputForegroundChecked: "#ffffff",
        listBackground: "#ffffff",
        menuBackground: "#ffffff",
        cardStandoutBackground: "#ffffff",
        bodyTextChecked: "#242424",
        buttonTextCheckedHovered: "#242424",
        link: "#4f52b2",
        primaryButtonBackground: "#5b5fc7",
        inputBackgroundChecked: "#5b5fc7",
        inputIcon: "#616161",
        inputFocusBorderAlt: "#5b5fc7",
        menuIcon: "#242424",
        menuHeader: "#616161",
        accentButtonBackground: "#5b5fc7",
        primaryButtonBackgroundPressed: "#383966",
        inputBackgroundCheckedHovered: "#4f52b2",
        inputIconHovered: "#616161",
        linkHovered: "#444791",
        primaryButtonBackgroundHovered: "#4f52b2",
        inputPlaceholderBackgroundChecked: "#dce0fa",
        bodyBackgroundChecked: "#ebebeb",
        bodyFrameDivider: "#e0e0e0",
        bodyDivider: "#e0e0e0",
        variantBorder: "#edebe9",
        buttonBackgroundCheckedHovered: "#f5f5f5",
        buttonBackgroundPressed: "#e0e0e0",
        listItemBackgroundChecked: "#ebebeb",
        listHeaderBackgroundPressed: "#e0e0e0",
        menuItemBackgroundHovered: "#f5f5f5",
        menuItemBackgroundPressed: "#fafafa",
        menuItemBackgroundChecked: "#ffffff",
        bodyBackgroundHovered: "#f5f5f5",
        buttonBackgroundHovered: "#f5f5f5",
        buttonBackgroundDisabled: "#f0f0f0",
        buttonBorderDisabled: "#e0e0e0",
        primaryButtonBackgroundDisabled: "#f0f0f0",
        disabledBackground: "#f0f0f0",
        listItemBackgroundHovered: "#f5f5f5",
        listHeaderBackgroundHovered: "#f5f5f5",
        primaryButtonTextDisabled: "#bdbdbd",
        disabledSubtext: "#bdbdbd",
        listItemBackgroundCheckedHovered: "#ebebeb",
        disabledBodyText: "#bdbdbd",
        variantBorderHovered: "#a19f9d",
        buttonTextDisabled: "#bdbdbd",
        inputIconDisabled: "#bdbdbd",
        disabledText: "#bdbdbd",
        bodyText: "#242424",
        actionLink: "#242424",
        buttonText: "#242424",
        inputBorderHovered: "#575757",
        inputText: "#242424",
        listText: "#242424",
        menuItemText: "#242424",
        bodyStandoutBackground: "#fafafa",
        defaultStateBackground: "#fafafa",
        actionLinkHovered: "#242424",
        buttonTextHovered: "#242424",
        buttonTextChecked: "#242424",
        buttonTextPressed: "#242424",
        inputTextHovered: "#242424",
        menuItemTextHovered: "#242424",
        bodySubtext: "#424242",
        focusBorder: "#000000",
        inputBorder: "#616161",
        smallInputBorder: "#605e5c",
        inputPlaceholderText: "#707070",
        buttonBorder: "#d1d1d1",
        disabledBodySubtext: "#bdbdbd",
        disabledBorder: "#e0e0e0",
        buttonBackgroundChecked: "#ebebeb",
        menuDivider: "#e0e0e0",
        cardShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
        cardShadowHovered: "0 0 2px rgba(0,0,0,0.12), 0 4px 8px rgba(0,0,0,0.14)",
        primaryButtonBorder: "transparent",
        errorText: "#a4262c",
        messageText: "#242424",
        messageLink: "#4f52b2",
        messageLinkHovered: "#444791",
        infoIcon: "#605e5c",
        errorIcon: "#A80000",
        blockingIcon: "#FDE7E9",
        warningIcon: "#797775",
        severeWarningIcon: "#D83B01",
        successIcon: "#107C10",
        infoBackground: "#f3f2f1",
        errorBackground: "#FDE7E9",
        blockingBackground: "#FDE7E9",
        warningBackground: "#FFF4CE",
        severeWarningBackground: "#FED9CC",
        successBackground: "#DFF6DD",
        warningHighlight: "#ffb900",
        successText: "#107C10",
        listTextColor: "#242424",
        warningText: "#323130"
    },
    components: {
        Breadcrumb: {},
        CalloutContent: {},
        Check: {},
        Checkbox: {},
        ChoiceGroup: {},
        ChoiceGroupOption: {},
        ColorPickerGridCell: {},
        CommandBar: {},
        CommandBarButton: {},
        CompoundButton: {},
        ContextualMenu: {},
        DefaultButton: {},
        DetailRowCheck: {},
        Dialog: {},
        DialogContent: {},
        Dropdown: {},
        IconButton: {},
        MessageBar: {},
        Modal: {},
        Pivot: {},
        NormalPeoplePicker: {},
        CompactPeoplePicker: {},
        ListPeoplePickerBase: {},
        SearchBox: {},
        Slider: {},
        SpinButton: {},
        Spinner: {},
        TagItem: {},
        TagPicker: {},
        Tag: {},
        TextField: {},
        Toggle: {}
    }
};
/** Teams light theme mapped from Fluent v9 to v8 Theme */ export const getTeamsLightV8Theme = ()=>{
    const theme = createTheme(TeamsLightPartialTheme);
    theme.name = UnoTheme.TeamsLight;
    return theme;
};
