// Actions
// Controls
import { BaseComponent } from "./BaseComponent";
import forEach from "lodash/forEach";
// Constants
import { MarkerTag } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
/**
 * A 'SmartComponent' is one that has access to one or more Stores, and therefore needs to listen to change events from them
 * and update data after receiving those events. This class handles the logic of subscribing and unsubscribing to those Stores.
 */ export class SmartComponent extends BaseComponent {
    /**
     * React lifecycle method called after component mounts. At this point, we want to subscribe to all Stores.
     */ componentDidMount() {
        // subscribe to changes from stores
        forEach(this.storesToSubscribeTo, (pair)=>{
            pair.store.subscribeToStore(pair.callback);
        });
        // subscribe to per-Action events
        this.props.dispatcherEventManagement.addOnActionDispatchComplete(this.actionDispatchCompleteCallback);
        this.tryMarkingComponentRender();
    }
    /**
     * React lifecycle method called before component unmounts. At this point, we want to unsubscribe from all Stores.
     */ componentWillUnmount() {
        // unsubscribe to changes from stores
        forEach(this.storesToSubscribeTo, (pair)=>{
            pair.store.unsubscribeFromStore(pair.callback);
        });
        // unsubscribe to per-Action events
        this.props.dispatcherEventManagement.removeOnActionDispatchComplete(this.actionDispatchCompleteCallback);
    }
    /**
     * When the component updates, set the title of the page
     * @param prevProps The previous props
     * @param prevState The previous state
     */ componentDidUpdate(prevProps, prevState) {
        if (this.shouldResetMarkedComponentRender(prevProps, prevState)) {
            this.isComponentRenderMarked = false;
        }
        this.tryMarkingComponentRender();
    }
    /**
     * The log Render will log the telemetry for rendering/
     * how much time it took for element to render after an action was dispatched
     * @param trackableActionInfos The array of trackableActionInfo
     */ logRender(trackableActionInfo) {
    // Intentionally left blank.The overriding components which
    // actually want to log the render time should provide their custom implementation
    }
    /**
     * Hook for deriving classes. Executed when a store publishes an update event.
     */ storeDidUpdate() {
    // Intentionally left empty - deriving classes can override this functionality
    }
    /**
     * Add stores to our list of stores to subscribe to. Note that Stores in the component's props will be
     * automatically added and therefore you should not call this method with them.
     * @param stores - Array of Stores to subscribe to
     */ addStoresToSubscribeTo(stores) {
        stores.forEach((store)=>{
            this.storesToSubscribeTo.push({
                store: store,
                callback: this.onStoreUpdate.bind(this)
            });
        });
    }
    /**
     * Handler for store publish events that simply sets a flag indicating that the component should update/render in the future.
     */ onStoreUpdate() {
        this.shouldRender = this.shouldRenderComponent();
    }
    /**
     * Deriving classes can override this method to provide custom logic for determining whether or not the component should render on the store update.
     * @returns true if the component should render in future, false otherwise
     */ shouldRenderComponent() {
        return true;
    }
    /**
     * Handler for the onActionDispatchComplete event - will update the component only if a Store that
     * we're subscribed to has published an update event
     */ onActionDispatchComplete(trackableActionInfo) {
        if (this.shouldRender) {
            // Schedule a force update if not already scheduled
            if (!this.isForceUpdateScheduled) {
                this.isForceUpdateScheduled = true;
                // Schedule a force update instead of calling it synchronously
                // to avoid unnecessary back-to-back synchronous render requests.
                setTimeout(()=>{
                    // Reset the flag and force update
                    this.isForceUpdateScheduled = false;
                    this.forceUpdate(()=>{
                        if (trackableActionInfo && trackableActionInfo.length > 0) {
                            this.logRender(trackableActionInfo);
                        }
                    });
                    // Invoke the storeDidUpdate hook, allowing derived classes to add functionality synchronously after a force update.
                    // This ensures that storeDidUpdate is called after the component has rendered with the updated data.
                    this.storeDidUpdate();
                });
            }
            // reset flag
            this.shouldRender = false;
        }
    }
    /**
     * Parse out all the IEventPublishingStore instances from the props
     */ getAllStoresInProps() {
        const storesInProps = [];
        forEach(this.props, (prop)=>{
            // this is a hack to check if the prop is a IEventPublishingStore - TypeScript cannot do runtime interface checks
            // since they are a compile- time concept. Other solutions were considered and this was the lesser of the evils
            if (prop?.subscribeToStore && prop?.unsubscribeFromStore) {
                storesInProps.push(prop);
            }
        });
        return storesInProps;
    }
    /**
     * Determine whether marked component render state needs to be reset on ComponentDidUpdate in the React Lifecycle
     * @param prevProps Previous control properties
     * @param prevState Previous control state
     */ shouldResetMarkedComponentRender(prevProps, prevState) {
        // Default to false. Deriving classes should override this as needed
        return false;
    }
    /**
     * Mark the view render state if it is not marked yet
     */ tryMarkingComponentRender() {
        if (!this.isComponentRenderMarked) {
            // Always log viewName and viewData as extra data for DataFetchingComponent viewRender marker
            const markExtraData = {
                viewName: this.name()
            };
            this.props.loggers.performanceMarker.mark(MarkerTag.ViewRender, markExtraData);
            this.isComponentRenderMarked = true;
        }
    }
    constructor(props, context){
        super(props, context), /** Flag indicating whether or not to update the component after an onActionDispatchComplete event */ this.shouldRender = false, /** Flag indicating if component render marked. */ this.isComponentRenderMarked = false, /** Flag indicating whether a force update is pending */ this.isForceUpdateScheduled = false;
        // parse out IEventPublishingStore instances from props, add them to our list
        this.storesToSubscribeTo = [];
        this.addStoresToSubscribeTo(this.getAllStoresInProps());
        // bind this method once, so subscribe/unsubscribe work as expected
        this.actionDispatchCompleteCallback = this.onActionDispatchComplete.bind(this);
    }
}
