// Utilities
// Constants
import { ProjectClientFlavor, ProjectClientType } from "@ms/uno-constants/lib/local/ServiceConstants";
/**
 * Ajax client that sets project headers for project services
 */ export class ProjectHeaderAjaxClient {
    async executeRequest(url, options) {
        this.setProjectHeader(options);
        return await this.innerAjaxClient.executeRequest(url, options);
    }
    /**
     * Set the project header
     * @param options request options with the request details
     */ setProjectHeader(options) {
        const headers = new Headers(options.headers);
        // This header will allow project service to determine which client made the request
        headers.set(ProjectClientType, this.configProvider().sessionMetaData.appMetadata.clientType);
        headers.set(ProjectClientFlavor, this.configProvider().sessionMetaData.appMetadata.clientFlavor);
        // Add headers to options
        options.headers = headers;
    }
    /**
     * Initializes a new instance of the ProjectHeaderAjaxClient
     * @param innerAjaxClient Ajax client
     */ constructor(innerAjaxClient, configProvider){
        this.innerAjaxClient = innerAjaxClient;
        this.configProvider = configProvider;
    }
}
