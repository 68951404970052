// Constants
import { ClientFlavor } from "@ms/uno-constants/lib/local/AppConstants";
// Modules
import { AuthModule } from "@ms/uno-logicmodules/lib/local/modules/auth/AuthModule";
// Utilities
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
/** Factory class for modules in Base app */
export class BaseLogicModuleFactory {
    /**
     * Initialize the module factory
     * @param loader Component loader
     * @param loggers Loggers for telemetry
     */
    constructor(loader, loggers) {
        this.loader = loader;
        this.loggers = loggers;
    }
    getAdaptiveCardModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("adaptiveCard"), () => import(
        /* webpackChunkName: "uno-adaptivecardmodule" */ "@ms/uno-logicmodules/lib/local/modules/adaptiveCard/AdaptiveCardModule"), (bundle) => new bundle.AdaptiveCardModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getAppContextModuleProvider(dispatcher, services, stores, adaptor, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("appContext"), () => import(
        /* webpackChunkName: "uno-appcontextmodule" */ "@ms/uno-logicmodules/lib/local/modules/appContext/AppContextModule"), (bundle) => new bundle.AppContextModule(dispatcher, this.loggers, services, stores, adaptor, configProvider));
    }
    getAuthModuleProvider(dispatcher, authService, authStore, configProvider) {
        return () => {
            if (this.auth) {
                return this.auth;
            }
            this.auth = new AuthModule(dispatcher, authService, authStore, this.loggers, configProvider);
            return this.auth;
        };
    }
    getBasicBucketModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("basicBucket"), () => import(
        /* webpackChunkName: "uno-basicbucketmodule" */ "@ms/uno-logicmodules/lib/local/modules/planner/bucket/BasicBucketModule"), (bundle) => new bundle.BasicBucketModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getBasicPlanModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("basicPlan"), () => import(
        /* webpackChunkName: "uno-basicplanmodule" */ "@ms/uno-logicmodules/lib/local/modules/planner/plan/BasicPlanModule"), (bundle) => new bundle.BasicPlanModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getBasicTaskModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("basicTask"), () => import(
        /* webpackChunkName: "uno-basictaskmodule" */ "@ms/uno-logicmodules/lib/local/modules/planner/task/BasicTaskModule"), (bundle) => new bundle.BasicTaskModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getCampaignModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("campaign"), () => import(/* webpackChunkName: "uno-campaignmodule" */ "@ms/uno-logicmodules/lib/local/modules/campaign/CampaignModule"), (bundle) => new bundle.CampaignModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getCapabilitiesModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("capabilities"), () => import(
        /* webpackChunkName: "uno-capabilitiesmodule" */ "@ms/uno-logicmodules/lib/local/modules/Capabilities/CapabilitiesModule"), (bundle) => new bundle.CapabilitiesModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getCentroModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("centro"), () => import(/* webpackChunkName: "uno-centromodule" */ "@ms/uno-logicmodules/lib/local/modules/centro/CentroModule"), (bundle) => new bundle.CentroModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getChatSvcAggModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("chatSvcAgg"), () => import(
        /* webpackChunkName: "uno-chatsvcaggmodule" */ "@ms/uno-logicmodules/lib/local/modules/chatSvcAgg/ChatSvcAggModule"), (bundle) => new bundle.ChatSvcAggModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getConversationsModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("conversations"), () => import(
        /* webpackChunkName: "uno-conversationsmodule" */ "@ms/uno-logicmodules/lib/local/modules/conversations/ConversationsModule"), (bundle) => new bundle.ConversationsModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getDeltaSyncModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("deltaSync"), () => import(
        /* webpackChunkName: "uno-deltasyncmodule" */ "@ms/uno-logicmodules/lib/local/modules/planner/deltaSync/DeltaSyncModule"), (bundle) => new bundle.DeltaSyncModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getDriveItemModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("driveItem"), () => import(
        /* webpackChunkName: "uno-driveitemmodule" */ "@ms/uno-logicmodules/lib/local/modules/driveItem/DriveItemModule"), (bundle) => new bundle.DriveItemModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getExcelExportModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("excelExport"), () => Promise.all([
            import(
            /* webpackChunkName: "uno-exportexcelmodule */ "@ms/uno-logicmodules/lib/local/modules/export/ExportToExcelModule"),
            import(
            /* webpackChunkName: "uno-excelfilewriter" */ "@ms/uno-logicmodules/lib/local/utilities/export/ExcelFileWriter"),
        ]), (bundles) => {
            const excelFileWriter = new bundles[1].ExcelFileWriter(this.loggers.traceLogger);
            return new bundles[0].ExportToExcelModule(dispatcher, this.loggers, services, stores, configProvider, excelFileWriter);
        });
    }
    getGroupsModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("groups"), () => import(/* webpackChunkName: "uno-groupsmodule" */ "@ms/uno-logicmodules/lib/local/modules/groups/GroupsModule"), (bundle) => new bundle.GroupsModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getIntelligenceModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("intelligence"), () => import(
        /* webpackChunkName: "uno-intelligencemodule" */ "@ms/uno-logicmodules/lib/local/modules/intelligence/IntelligenceModule"), (bundle) => bundle.IntelligenceModule.createAsync(dispatcher, this.loggers, services, stores, configProvider));
    }
    getLinksModuleProvider(dispatcher, services, stores, hostAdaptor, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("links"), () => import(/* webpackChunkName: "uno-linksmodule" */ "@ms/uno-logicmodules/lib/local/modules/links/LinksModule"), (bundle) => new bundle.LinksModule(dispatcher, this.loggers, services, stores, hostAdaptor, configProvider));
    }
    getLoopModuleProvider(dispatcher, services, stores, configProvider, fetchAccessTokenAsync) {
        return () => this.loader.load(this.getLmBundleKey("loop"), () => import(/* webpackChunkName: "uno-loopmodule" */ "@ms/uno-logicmodules/lib/local/modules/loop/LoopModule"), (bundle) => new bundle.LoopModule(dispatcher, this.loggers, services, stores, configProvider, fetchAccessTokenAsync));
    }
    getMruModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("mru"), () => import(/* webpackChunkName: "uno-mrumodule" */ "@ms/uno-logicmodules/lib/local/modules/mru/MruModule"), (bundle) => new bundle.MruModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getNotificationModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("notification"), () => import(
        /* webpackChunkName: "uno-notificationmodule" */ "@ms/uno-logicmodules/lib/local/modules/notification/NotificationModule"), (bundle) => new bundle.NotificationModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getOrgModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("org"), () => import(/* webpackChunkName: "uno-orgmodule" */ "@ms/uno-logicmodules/lib/local/modules/org/OrgModule"), (bundle) => new bundle.OrgModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getPlanAiModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("planai"), () => import(/* webpackChunkName: "uno-planaimodule" */ "@ms/uno-logicmodules/lib/local/modules/planai/PlanAiModule"), (bundle) => new bundle.PlanAiModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getPortfolioModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("portfolio"), () => import(
        /* webpackChunkName: "uno-portfoliomodule" */ "@ms/uno-logicmodules/lib/local/modules/portfolio/PortfolioModule"), (bundle) => new bundle.PortfolioModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getPlanConversionModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("planConversion"), () => import(
        /* webpackChunkName: "uno-planconversionmodule" */ "@ms/uno-logicmodules/lib/local/modules/upsell/PlanConversionModule"), (bundle) => new bundle.PlanConversionModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getPremiumPlanModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("premiumPlan"), () => import(
        /* webpackChunkName: "uno-premiumplanmodule" */ "@ms/uno-logicmodules/lib/local/modules/project/premiumPlan/PremiumPlanModule"), (bundle) => new bundle.PremiumPlanModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getPremiumTaskModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("premiumTask"), () => import(
        /* webpackChunkName: "uno-premiumtaskmodule" */ "@ms/uno-logicmodules/lib/local/modules/project/premiumTask/PremiumTaskModule"), (bundle) => new bundle.PremiumTaskModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getPublishingModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("publishing"), () => import(
        /* webpackChunkName: "uno-publishingmodule" */ "@ms/uno-logicmodules/lib/local/modules/publishing/PublishingModule"), (bundle) => new bundle.PublishingModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getRoamingModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("roaming"), () => import(/* webpackChunkName: "uno-roamingmodule" */ "@ms/uno-logicmodules/lib/local/modules/roaming/RoamingModule"), (bundle) => new bundle.RoamingModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getRosterModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("roster"), () => import(/* webpackChunkName: "uno-rostermodule" */ "@ms/uno-logicmodules/lib/local/modules/roster/RosterModule"), (bundle) => new bundle.RosterModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getSuiteNavModuleProvider(dispatcher, services, stores, configProvider) {
        return async () => {
            this.loggers.traceLogger.logTrace(0x1e3196c4 /* tag_4mz1e */, TraceLevel.Error, `Shouldn't import suite nav module for base (non-Web) endpoint.`);
            throw new Error("NotSupported");
        };
    }
    getTeamsModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("teams"), () => import(/* webpackChunkName: "uno-teamsmodule" */ "@ms/uno-logicmodules/lib/local/modules/teams/TeamsModule"), (bundle) => new bundle.TeamsModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getTeamsTasksTabsModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("ttTabs"), () => import(
        /* webpackChunkName: "uno-tttabsmodule" */ "@ms/uno-logicmodules/lib/local/modules/teamsTasksTabs/TeamsTasksTabsModule"), (bundle) => new bundle.TeamsTasksTabsModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getTemplateModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("templates"), () => import(/* webpackChunkName: "uno-templatesmodule" */ "@ms/uno-logicmodules/lib/local/modules/templates/TemplateModule"), (bundle) => new bundle.TemplateModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getTenantModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("tenant"), () => import(/* webpackChunkName: "uno-tenantmodule" */ "@ms/uno-logicmodules/lib/local/modules/tenant/TenantModule"), (bundle) => new bundle.TenantModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getTodoModuleProvider(dispatcher, services, stores, configProvider, fetchAccessTokenAsync) {
        return () => this.loader.load(this.getLmBundleKey("todo"), () => Promise.all([
            import(/* webpackChunkName: "uno-todotasksclient" */ "@ms/todo-tasks-client"),
            import(/* webpackChunkName: "uno-todomodule" */ "@ms/uno-logicmodules/lib/local/modules/todo/TodoModule"),
        ]), ([todoClientPackage, todoModule]) => {
            const appContextStore = stores.appContext();
            const userId = appContextStore.getAppContext().userId;
            const tenantId = appContextStore.getAppContext().tenantId;
            const sessionMetadata = configProvider().sessionMetaData;
            const appMetadata = sessionMetadata.appMetadata;
            const flights = configProvider().flights;
            const useCache = flights.EnablePersistenceForTodoTasks;
            /**
             * While we do not need full offline capabilities like native Todo,
             * we do need to enable this to match partial offline behavior
             * that is enabled for Planner tasks. Planner tasks can handle
             * intermittent network outage and subsequent edits using makeRequestToStore
             * mechanism. The request queueing in Todo matches that behavior.
             */
            const useRequestQueueing = appMetadata.clientFlavor !== ClientFlavor.TaskFromMessage;
            const todoClient = new todoClientPackage.TodoTasksClient({
                host: `${appMetadata.clientType}:${appMetadata.clientFlavor}`,
                userId,
                tenantId,
                userType: "aad",
                sessionId: sessionMetadata.sessionId,
                useRequestQueueing,
                useCache: useCache,
                logData: (data) => {
                    this.loggers.traceLogger.logTrace(0x1e35b4a0 /* tag_4n1s6 */, TraceLevel.Info, `TodoTasksClient`, data);
                },
            });
            return new todoModule.TodoModule(dispatcher, this.loggers, services, stores, configProvider, todoClient, generateGuid, fetchAccessTokenAsync);
        });
    }
    getUpsellModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("upsell"), () => import(/* webpackChunkName: "uno-upsellmodule" */ "@ms/uno-logicmodules/lib/local/modules/upsell/UpsellModule"), (bundle) => new bundle.UpsellModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getUserModuleProvider(dispatcher, services, stores, configProvider) {
        return () => this.loader.load(this.getLmBundleKey("user"), () => import(/* webpackChunkName: "uno-usermodule" */ "@ms/uno-logicmodules/lib/local/modules/user/UserModule"), (bundle) => new bundle.UserModule(dispatcher, this.loggers, services, stores, configProvider));
    }
    getLmBundleKey(lmName) {
        return `uno-${lmName}lm`;
    }
}
