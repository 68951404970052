/* eslint-disable no-var */
/*
 * This file sets the __webpack_public_path__ which tells webpack the CDN base url
 * from which to load assets and resources (like images, lazy loaded scripts).
 * This value is determined and set on the TT server and provided in the global js
 * scope of html as msTeamsTasks.config.PublicPath
 * See: https://webpack.js.org/guides/public-path/#on-the-fly
 */
const Config = typeof msTeamsTasks !== "undefined" ? msTeamsTasks?.config : { PublicPath: undefined };
__webpack_public_path__ = Config.PublicPath;
