import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { TeamsTabQueryParams } from "@ms/uno-constants/lib/local/TeamsConstants";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { generateRouteFromDeepLink } from "./DeepLinkUtils";
import { createRouteFromDetails } from "./DeepLinkUtilsHelper";
import { extractPlanRoutingDetailsFromSubRoute } from "./RouteUtilities";
/** This file contains utilities for parsing details from Teams tab data */ /**
 * This supports entityId of type
 * Tasks app tab `teamstasks.channelTab_${channelId}_plan:${planId}`,
 * Tasks(Dev) app tab `teamstasksdev.channelTab_${channelId}_plan:${planId}`,
 * tabs created by publishing flow - `teamstasks.published.channelTab_${channelId}_plan:${planId}`,
 * Tasks planner tab `tt.c_${channelId}_p_${partnerId}_h_${hash}`,
 * Tasks planner published tab `tt.pub.c_${channelId}_p_${partnerId}_h_${hash}`,
 */ const entityIdPatternRegex = /^teamstasks.*.channelTab_(.+?)_plan\:(.+)$|^ttd?\.(?:pub)?\.?c_(.+?)_p_(.+)_h_[A-Za-z0-9]+$/;
/**
 * Extracts the planId from the page id / entityId
 * @param pageId The teams context page id / entityId to extract the planId from
 */ export function getPlanIdFromEntityId(pageId) {
    if (!pageId) {
        return null;
    }
    const matches = pageId.match(entityIdPatternRegex);
    let planId = null;
    if (matches && matches.length > 2) {
        planId = matches[2];
        if (!planId && matches.length > 4) {
            planId = matches[4] || null;
        }
    }
    // Note: Basic Plan ID is always 28 characters long (https://learn.microsoft.com/en-us/graph/api/resources/plannerplan?view=graph-rest-1.0)
    // There is a bug with parsing Channel Tab metadata (that has multiple "_p_") where our PlanId needs further parsing
    // Example: "tt.c_19:266306be1bd346ffa6b647420423b64b_p_abc@thread.skype_p_J65U8vK8SES0AAr8UrZs15UAHxVC_h_1667845670551"
    // has planId from above regex as "abc@thread.skype_p_J65U8vK8SES0AAr8UrZs15UAHxVC" and we actually want "J65U8vK8SES0AAr8UrZs15UAHxVC"
    return planId?.slice(-28) ?? null; // Note: Minus 28 to take the last 28 characters
}
/** The prefix indicating the entityId format for an Uno tab */ const UNO_TAB_PREFIX = "planner.v1.";
/**
 * Returns true if the entity ID indicates that we're using the v1 tab format:
 * EntityId = `planner.v1.{uuid}`, contentUrl contains `planRoute={deepLink}`
 */ export function isV1UnoEntityId(entityId) {
    return entityId.startsWith(UNO_TAB_PREFIX);
}
/**
 * Returns a route for the given Teams tab content URL. Falls back to NewTabPage if a route can't be parsed.
 * This logic is applicable when the entity ID uses the v1 tab format.
 */ export function getRouteFromTabContentUrl(contentUrl, loggers) {
    const defaultRoute = createRouteFromDetails({
        subRouteType: SubRouteType.NewTabPage
    });
    try {
        // Grab planRoute from the query parameters
        const url = new URL(contentUrl);
        const planRoute = url.searchParams.get(TeamsTabQueryParams.PlanRoute) ?? undefined;
        // If planRoute is not set, route to NewTabPage
        if (!planRoute) {
            loggers?.traceLogger.logTrace(0x1e2de6e0 /* tag_4l416 */ , TraceLevel.Info, "getRouteFromTabContentUrl - planRoute not set");
            return defaultRoute;
        }
        // Parse planRoute as a deep link, falling back to NewTabPage if parsing fails
        const route = generateRouteFromDeepLink(planRoute, loggers);
        if (!route) {
            loggers?.traceLogger.logTrace(0x1e2de6df /* tag_4l415 */ , TraceLevel.Error, "getRouteFromTabContentUrl - Unable to parse planRoute as deep link");
            return defaultRoute;
        }
        return route;
    } catch  {
        loggers?.traceLogger.logTrace(0x1e2de6de /* tag_4l414 */ , TraceLevel.Error, "getRouteFromTabContentUrl - Unable to parse contentUrl");
        return defaultRoute;
    }
}
/** Gets the plan routing details for the given Teams tab details, or null if one can't be parsed */ export function getPlanRouteDetailsForTab(entityId, contentUrl) {
    let route;
    if (isV1UnoEntityId(entityId)) {
        route = getRouteFromTabContentUrl(contentUrl, null);
    } else {
        const planId = getPlanIdFromEntityId(entityId);
        route = planId == null ? null : createRouteFromDetails({
            subRouteType: SubRouteType.BasicPlan,
            planId
        });
    }
    return route && extractPlanRoutingDetailsFromSubRoute(route.subRoute, route.subRouteType);
}
