/**
 * High contrast mode style constants
 */ /** Button background color */ export const HighContrastButtonFace = "ButtonFace";
/** Button text font color */ export const HighContrastButtonText = "ButtonText";
/** Selected or highlighted text background color */ export const HighContrastHighlight = "Highlight";
/** Selected or highlighted text color */ export const HighContrastHighlightText = "HighlightText";
/** Link text color */ export const HighContrastLinkText = "LinkText";
/** Disabled text color */ export const HighContrastGrayText = "GrayText";
/** Background color */ export const HighContrastWindow = "Window";
/** Text content font color */ export const HighContrastWindowText = "WindowText";
