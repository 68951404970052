/**
 * Argument error
 */ export class ArgumentError extends Error {
    constructor(argumentName, errorMessage){
        super(), this.argumentName = argumentName, this.errorMessage = errorMessage;
        if (argumentName) {
            this.message = `${argumentName} was invalid. ${errorMessage || ""}`.trim();
        } else {
            this.message = errorMessage || "";
        }
        this.name = "ArgumentError";
    }
}
