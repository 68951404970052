// Constants
import { PlanType } from "@ms/uno-constants/lib/local/AppConstants";
import { PortfolioViewType, SubRouteKeys, SubRouteType, ViewType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { TemplateCategory } from "@ms/uno-constants/lib/local/TemplateConstants";
import { ModalType } from "@ms/uno-models/lib/local/client/modal/ModalType";
import { ContextScenarioConstants } from "@ms/uno-constants/lib/local/ExternalSourceConstants";
// Models
import { DefaultMyTasksViewPivotType, MyPlansPagePivotType, MyTasksViewPivotType } from "@ms/uno-constants/lib/local/ViewPivots";
import { TaskAccessContext } from "@ms/uno-models/lib/local/client/planner/task/TaskAccessContext";
// Utilities
import { stringToEnum } from "@ms/uno-utilities/lib/local/EnumUtilities";
import { getViewKey } from "@ms/uno-utilities/lib/local/StringUtilities";
/**
 * Extract the plan id from the sub route
 * @param subRoute The sub route
 * @param subRouteType The sub route type
 */ export function extractPlanRoutingDetailsFromSubRoute(subRoute, subRouteType) {
    switch(subRouteType){
        case SubRouteType.BasicPlan:
            return extractBasicPlanRoutingDetailsFromSubRoute(subRoute);
        case SubRouteType.PremiumPlan:
            return extractPremiumPlanRoutingDetailsFromSubRoute(subRoute);
        case SubRouteType.TodoList:
            return extractTodoListRoutingDetailsFromSubRoute(subRoute);
        case SubRouteType.RemoveTeamsTab:
            return extractRemoveTeamsTabRoutingDetailsFromSubRoute(subRoute);
        default:
            return null;
    }
}
/**
 * Extract the view key from the sub route
 * @param subRoute The sub route
 * @param subRouteType The sub route type
 * @param currentUserId The current user id
 */ export function extractViewKeyFromSubRoute(subRoute, subRouteType, currentUserId) {
    switch(subRouteType){
        case SubRouteType.BasicPlan:
        case SubRouteType.TodoList:
        case SubRouteType.PremiumPlan:
        case SubRouteType.Portfolio:
            return getViewKey(TaskAccessContext.Plan.toString(), subRoute[0]);
        case SubRouteType.MyDay:
            return getViewKey(TaskAccessContext.MyDay.toString(), currentUserId);
        case SubRouteType.MyTasks:
            return getViewKey(TaskAccessContext.AssignedToUser.toString(), currentUserId);
        default:
            return subRouteType;
    }
}
/**
 * Serializes route descriptor as a URL route path
 * @param routeDescriptor Route descriptor to serialize
 * @param routePathPrefix Route path to prefix the route with
 */ export function serializeRouteDescriptor(routeDescriptor, routePathPrefix) {
    const { subRouteType, subRoute, queryParameters } = routeDescriptor;
    let routePath = "";
    // TODO[UnoWeb] ADO#9153353 - We only need to do this while we don't have AFD mapping
    if (routePathPrefix) {
        routePath += `/${routePathPrefix}`;
    }
    if (subRouteType) {
        routePath += `/${subRouteType}`;
    }
    if (subRoute && subRoute.length > 0) {
        routePath += `/${subRoute.join("/")}`;
    }
    if (queryParameters && Object.keys(queryParameters).length > 0) {
        routePath += `?${serializeQueryParameters(queryParameters)}`;
    }
    return routePath;
}
/**
 * Serialize query parameters into a query string
 * @param queryParameters Query parameters to be serialized
 */ function serializeQueryParameters(queryParameters) {
    return Object.entries(queryParameters).map(([key, value])=>{
        return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    }).join("&");
}
/**
 * Parses the route path to get a route descriptor
 * @param routePath Route path to parse
 * @param routePathPrefix Route path prefix to remove from the route path when parsing
 */ export function parseRouteDescriptor(routePath, routePathPrefix) {
    // Let's strip off the Hash part
    routePath = routePath.split("#")[0];
    const [path, queryString] = routePath.split("?");
    // TODO[UnoWeb] ADO#9153353 - Until we have AFD mapping, there is webui prefix that we need to adjust for
    const pathWithoutPrefix = removePathPrefixIfPresent(path, routePathPrefix);
    const pathSegments = pathWithoutPrefix.split("/").filter((segment)=>segment !== "");
    if (pathSegments.length === 0) {
        // No path segments, so not a valid routeDescriptor
        return null;
    }
    const subRouteType = stringToEnum(pathSegments[0], SubRouteType);
    if (subRouteType == null) {
        // Not a recognized subRouteType, so not a valid routeDescriptor
        return null;
    }
    // Slice out the subRouteType that's already parsed above
    const subRoute = pathSegments.slice(1);
    // Parse query parameters (if any)
    let queryParameters = {};
    if (queryString) {
        queryParameters = parseQueryParameters(queryString);
    }
    return {
        rootUrl: "",
        subRouteType,
        subRoute,
        queryParameters
    };
}
/**
 * Parse query string into query parameters
 * @param queryString query string to parse
 */ function parseQueryParameters(queryString) {
    return queryString.split("&").reduce((params, param)=>{
        const [key, value] = param.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value);
        return params;
    }, {});
}
/**
 * Removes a prefix from a path (if it's present)
 * @param path path to remove prefix from
 * @param pathPrefix prefix to remove
 */ export function removePathPrefixIfPresent(path, pathPrefix) {
    if (!pathPrefix) {
        // Nothing to remove
        return path;
    }
    const pathPrefixWithSlash = `/${pathPrefix}`;
    if (path.toLowerCase().startsWith(pathPrefixWithSlash.toLowerCase())) {
        return path.substring(pathPrefixWithSlash.length);
    }
    return path;
}
/**
 * Extract the My Plans routing details from the sub route
 * @param subRoute The sub route
 */ export function extractMyPlansRoutingDetailsFromSubRoute(subRoute) {
    // We need to have at least the first element (it's the MyPlansPagePivotType)
    if (subRoute.length < 1) {
        return null;
    }
    const myPlansPagePivot = subRoute[0];
    const myPlansPagePivotType = stringToEnum(myPlansPagePivot, MyPlansPagePivotType) ?? undefined;
    if (myPlansPagePivotType == null) {
        return null;
    }
    const routingDetails = {
        pivotType: myPlansPagePivotType
    };
    // Check if subroute contains a dialog type
    const dialogType = getEnumTypeValueForKey(subRoute, SubRouteKeys.Dialog, ModalType);
    if (dialogType != null) {
        routingDetails.dialogType = dialogType;
        // Check for template type
        const templateType = getEnumTypeValueForKey(subRoute, SubRouteKeys.TemplateType, TemplateCategory);
        if (templateType != null) {
            routingDetails.templateType = templateType;
        }
    }
    return routingDetails;
}
/**
 * Extract the basic plan routing details from the sub route
 * @param subRoute The sub route
 */ export function extractBasicPlanRoutingDetailsFromSubRoute(subRoute) {
    // We need to have at least the first element (it's the PlanId)
    if (subRoute.length < 1) {
        return null;
    }
    const routingDetails = {
        planId: subRoute[0],
        planType: PlanType.Basic
    };
    // Check if subroute contains a view type
    const viewType = getEnumTypeValueForKey(subRoute, SubRouteKeys.View, ViewType);
    if (viewType != null) {
        routingDetails.viewType = viewType;
    }
    // Check if subroute contains a task
    const taskId = getValueForKey(subRoute, SubRouteKeys.Task);
    if (taskId != null) {
        routingDetails.taskId = taskId;
    }
    return routingDetails;
}
/**
 * Extract the premium plan routing details from the sub route
 * @param subRoute The sub route
 */ export function extractPremiumPlanRoutingDetailsFromSubRoute(subRoute) {
    // Premium plan follows structure /v1/premiumPlan/<PlanId>/Org/<OrgId>/task/<TaskId>"
    // or /v1/premiumPlan/<PlanId>/Org/<OrgId>/view/<ViewType>/task/<TaskId>"
    if (subRoute.length < 3) {
        return null;
    }
    const orgId = getValueForKey(subRoute, SubRouteKeys.Org);
    if (orgId == null) {
        return null;
    }
    const routeDetails = {
        planId: subRoute[0],
        planType: PlanType.Premium,
        orgId
    };
    // Check if subroute contains a view type
    const viewType = getEnumTypeValueForKey(subRoute, SubRouteKeys.View, ViewType);
    if (viewType != null) {
        routeDetails.viewType = viewType;
    }
    // Check if subroute contains a task
    const taskId = getValueForKey(subRoute, SubRouteKeys.Task);
    if (taskId != null) {
        routeDetails.taskId = taskId;
    }
    return routeDetails;
}
/**
 * Extract the portfolio routing details from the sub route
 * @param subRoute The sub route
 */ export function extractPortfolioRoutingDetailsFromSubRoute(subRoute) {
    if (subRoute.length < 1) {
        return null;
    }
    const routeDetails = {
        portfolioId: subRoute[0]
    };
    // Check if subroute contains a portfolio view type
    const viewType = getEnumTypeValueForKey(subRoute, SubRouteKeys.View, PortfolioViewType);
    if (viewType != null) {
        routeDetails.viewType = viewType;
    }
    return routeDetails;
}
/**
 * Extract the Todo list routing details from the sub route
 * @param subRoute The sub route
 */ export function extractTodoListRoutingDetailsFromSubRoute(subRoute) {
    // We need to have at least the first element (it's the PlanId)
    if (subRoute.length < 1) {
        return null;
    }
    const routingDetails = {
        planId: subRoute[0],
        planType: PlanType.ToDo
    };
    // Check if subroute contains a view type
    const viewType = getEnumTypeValueForKey(subRoute, SubRouteKeys.View, ViewType);
    if (viewType != null) {
        routingDetails.viewType = viewType;
    }
    // Check if subroute contains a task
    const taskId = getValueForKey(subRoute, SubRouteKeys.Task);
    if (taskId != null) {
        routingDetails.taskId = taskId;
    }
    return routingDetails;
}
/**
 * Extract the remove teams tab routing details from the sub route
 * @param subRoute The sub route
 * @returns null if it is landing page tab, else returns the routing details
 */ function extractRemoveTeamsTabRoutingDetailsFromSubRoute(subRoute) {
    /**
     * The structure of the Premium Plan subroute is /<PlanId>/planType/<PlanType>/Org/<OrgId>
     * The structure of the Basic Plan subroute is /<PlanId>/planType/<PlanType>
     * For tab creation page, the subroute is empty
     */ if (subRoute.length === 0 || subRoute.indexOf(SubRouteKeys.PlanType) === -1) {
        return null;
    }
    const planType = getValueForKey(subRoute, SubRouteKeys.PlanType);
    const planEnumType = stringToEnum(planType, PlanType);
    if (planEnumType === PlanType.Basic) {
        return {
            planId: subRoute[0],
            planType: PlanType.Basic
        };
    }
    if (planEnumType === PlanType.Premium) {
        const orgId = getValueForKey(subRoute, SubRouteKeys.Org);
        if (orgId == null) {
            // invalid subroute, Premium plan should have an orgId
            return null;
        }
        return {
            planId: subRoute[0],
            orgId: orgId,
            planType: PlanType.Premium
        };
    }
    return null;
}
/**
 * Get the value after a key in the subroute
 * @param subRoute Subroute array
 * @param key key to search for
 * @returns value for that key
 */ export function getValueForKey(subRoute, key) {
    const keyIndex = subRoute.map((route)=>route.toLowerCase()).indexOf(key.toLowerCase());
    if (keyIndex !== -1 && keyIndex + 1 < subRoute.length) {
        return subRoute[keyIndex + 1];
    }
    return null;
}
/**
 * Get the enum typed value after a key in the subroute
 * @param subRoute Subroute array
 * @param key key to search for
 * @param enumType The enum type to convert to
 * @returns value for that key
 */ export function getEnumTypeValueForKey(subRoute, key, enumType) {
    const enumString = getValueForKey(subRoute, key);
    if (enumString != null) {
        return stringToEnum(enumString, enumType) ?? null;
    }
    return null;
}
/**
 * Extract the my tasks routing details from the sub route
 * @param subRoute The sub route
 * @param subRouteType The sub route type
 */ export function extractMyTasksRoutingDetailsFromSubRoute(subRoute, subRouteType) {
    switch(subRouteType){
        case SubRouteType.MyTasks:
            if (subRoute.length === 0) {
                return null;
            }
            let pivotType;
            switch(subRoute[0]){
                case "all":
                    pivotType = MyTasksViewPivotType.All;
                    break;
                case "flaggedemails":
                    pivotType = MyTasksViewPivotType.FlaggedEmails;
                    break;
                case "privatetasks":
                    pivotType = MyTasksViewPivotType.PrivateTasks;
                    break;
                case "assignedtome":
                    pivotType = MyTasksViewPivotType.AssignedToMe;
                    break;
                default:
                    pivotType = DefaultMyTasksViewPivotType;
                    break;
            }
            const routeDetails = {
                pivotType: pivotType
            };
            // Check if subroute contains a view type
            const view = getValueForKey(subRoute, SubRouteKeys.View);
            if (view != null) {
                const viewType = stringToEnum(view, ViewType) ?? undefined;
                if (viewType != null) {
                    routeDetails.viewType = viewType;
                }
            }
            // Check if subroute contains a task
            const taskId = getValueForKey(subRoute, SubRouteKeys.Task);
            if (taskId != null) {
                routeDetails.task = taskId;
            }
            return routeDetails;
        default:
            return null;
    }
}
/**
 * Extract the my day routing details from the sub route
 * @param subRoute The sub route
 * @param subRouteType The sub route type
 */ export function extractMyDayRoutingDetailsFromSubRoute(subRoute, subRouteType) {
    switch(subRouteType){
        case SubRouteType.MyDay:
            if (subRoute.length === 0) {
                return null;
            }
            let viewType = undefined;
            if (subRoute.length >= 2 && subRoute[0] === SubRouteKeys.View) {
                viewType = stringToEnum(subRoute[1], ViewType) ?? undefined;
            }
            return {
                viewType: viewType
            };
        default:
            return null;
    }
}
/**
 * Remove a key from the sub route
 * @param subRoute The sub route
 * @param key The key to remove
 */ export function removeKeyFromSubRoute(subRoute, key) {
    const updatedSubRoute = [
        ...subRoute
    ];
    const keyIndex = updatedSubRoute.map((part)=>part.toLowerCase()).indexOf(key);
    if (keyIndex !== -1) {
        updatedSubRoute.splice(keyIndex, 2);
    }
    return updatedSubRoute;
}
/**
 * Fill the sub route with default values
 * @param subRoute The sub route
 * @param subRouteType The sub route type
 */ export function fillSubRouteDefaults(subRoute, subRouteType) {
    switch(subRouteType){
        case SubRouteType.MyTasks:
            let routingDetails = extractMyTasksRoutingDetailsFromSubRoute(subRoute, subRouteType);
            // Overall default
            routingDetails = routingDetails ?? {
                pivotType: DefaultMyTasksViewPivotType,
                viewType: ViewType.Grid
            };
            // View default
            routingDetails.viewType = routingDetails.viewType ?? ViewType.Grid;
            return generateSubRouteFromRoutingDetailsForMyTasks(routingDetails);
        // TODO: Handle defaulting for other sub route types
        default:
            return subRoute;
    }
}
/**
 * Set a key value pair in the sub route
 * @return The updated sub route
 */ export function setSubRouteKeyValue(subRoute, key, value) {
    const updatedSubRoute = [
        ...subRoute
    ];
    const keyIndex = updatedSubRoute.map((part)=>part.toLowerCase()).indexOf(key);
    if (keyIndex !== -1) {
        if (keyIndex + 1 < subRoute.length) {
            updatedSubRoute[keyIndex + 1] = value;
        } else {
            updatedSubRoute.push(value);
        }
    } else {
        updatedSubRoute.push(key, value);
    }
    return updatedSubRoute;
}
/**
 * Generate a route descriptor from the plan routing details
 * @param routingDetails The plan routing details
 * @param queryParams Optional query parameters to be added to the route
 * @param defaultPlanId Optional default plan id to use for MyTasksPage pivot routing to Private tasks
 * @param flaggedEmailPlanId Optional flagged emails plan id to use for MyTasksPage pivot routing to Flagged emails
 */ export function generateRouteDescriptorFromRoutingDetailsForPlan(routingDetails, queryParams, defaultPlanId, flaggedEmailPlanId) {
    switch(routingDetails.planType){
        case PlanType.Basic:
            return {
                subRouteType: SubRouteType.BasicPlan,
                subRoute: generateSubRouteFromRoutingDetailsForBasicPlan(routingDetails),
                queryParameters: queryParams
            };
        case PlanType.Premium:
            return {
                subRouteType: SubRouteType.PremiumPlan,
                subRoute: generateSubRouteFromRoutingDetailsForPremiumPlan(routingDetails),
                queryParameters: queryParams
            };
        case PlanType.ToDo:
            // Note: for Private tasks and Flagged emails, the default ViewType is not handled when routing between the pivots on MyTasksPage,
            // when navigating from the Task Editor plan title link, so we are specifying the Grid ViewType in the subRoute.
            // This is to align with the MyTasksPage onMyTasksPagePivotItemChanged callback, which specifies the Grid ViewType in the subRoute.
            if (routingDetails.planId === defaultPlanId || routingDetails.planId === flaggedEmailPlanId) {
                const pivotType = routingDetails.planId === defaultPlanId ? MyTasksViewPivotType.PrivateTasks : MyTasksViewPivotType.FlaggedEmails;
                return {
                    subRouteType: SubRouteType.MyTasks,
                    subRoute: [
                        pivotType,
                        SubRouteKeys.View,
                        ViewType.Grid
                    ],
                    queryParameters: queryParams
                };
            }
            return {
                subRouteType: SubRouteType.TodoList,
                subRoute: generateSubRouteFromRoutingDetailsForTodoList(routingDetails),
                queryParameters: queryParams
            };
        default:
            return {
                subRouteType: SubRouteType.MyTasks,
                subRoute: []
            };
    }
}
/**
 * Generate a route descriptor from the portfolio routing details
 * @param routingDetails The portfolio routing details
 * @param queryParams Optional query parameters to be added to the route
 */ export function generateRouteDescriptorFromRoutingDetailsForPortfolio(routingDetails, queryParams) {
    return {
        subRouteType: SubRouteType.Portfolio,
        subRoute: generateSubRouteFromRoutingDetailsForPortfolio(routingDetails),
        queryParameters: queryParams
    };
}
/**
 * Generate a sub route from the My Plans routing details
 * @param routingDetails The My Plans routing details
 * @returns string array representing the sub route
 */ export function generateSubRouteFromRoutingDetailsForMyPlans(routingDetails) {
    const subRoute = [
        routingDetails.pivotType
    ];
    if (routingDetails.dialogType) {
        subRoute.push(SubRouteKeys.Dialog);
        subRoute.push(routingDetails.dialogType);
        if (routingDetails.templateType) {
            subRoute.push(SubRouteKeys.TemplateType);
            subRoute.push(routingDetails.templateType);
        }
    }
    return subRoute;
}
/**
 * Generate a sub route from the plan routing details
 * @param routingDetails The plan routing details
 * @returns string array representing the sub route
 */ export function generateSubRouteFromRoutingDetailsForBasicPlan(routingDetails) {
    const subRoute = [
        routingDetails.planId
    ];
    if (routingDetails.viewType) {
        subRoute.push(SubRouteKeys.View);
        subRoute.push(routingDetails.viewType);
    }
    if (routingDetails.taskId) {
        subRoute.push(SubRouteKeys.Task);
        subRoute.push(routingDetails.taskId);
    }
    return subRoute;
}
/**
 * Generate a sub route from todo list routing details
 * @param routingDetails The todo list routing details
 * @returns string array representing the sub route
 */ export function generateSubRouteFromRoutingDetailsForTodoList(routingDetails) {
    const subRoute = [
        routingDetails.planId
    ];
    if (routingDetails.viewType) {
        subRoute.push(SubRouteKeys.View);
        subRoute.push(routingDetails.viewType);
    }
    if (routingDetails.taskId) {
        subRoute.push(SubRouteKeys.Task);
        subRoute.push(routingDetails.taskId);
    }
    return subRoute;
}
/**
 * Generate a sub route from the plan routing details
 * @param routingDetails The plan routing details
 * @returns string array representing the sub route
 */ export function generateSubRouteFromRoutingDetailsForPremiumPlan(routingDetails) {
    const subRoute = [
        routingDetails.planId
    ];
    if (routingDetails.orgId) {
        subRoute.push(SubRouteKeys.Org);
        subRoute.push(routingDetails.orgId);
    }
    if (routingDetails.viewType) {
        subRoute.push(SubRouteKeys.View);
        subRoute.push(routingDetails.viewType);
    }
    if (routingDetails.taskId) {
        subRoute.push(SubRouteKeys.Task);
        subRoute.push(routingDetails.taskId);
    }
    return subRoute;
}
/**
 * Generate a sub route from the portfolio routing details
 * @param routingDetails The portfolio routing details
 * @returns string array representing the sub route
 */ function generateSubRouteFromRoutingDetailsForPortfolio(routingDetails) {
    const subRoute = [];
    subRoute.push(routingDetails.portfolioId);
    if (routingDetails.viewType) {
        subRoute.push(SubRouteKeys.View);
        subRoute.push(routingDetails.viewType);
    }
    return subRoute;
}
/**
 * Generate a sub route from the My Tasks routing details
 * @param routingDetails The My Tasks routing details
 * @returns string array representing the sub route
 */ export function generateSubRouteFromRoutingDetailsForMyTasks(routingDetails) {
    const subRoute = [
        routingDetails.pivotType
    ];
    if (routingDetails.viewType) {
        subRoute.push(SubRouteKeys.View);
        subRoute.push(routingDetails.viewType);
    }
    if (routingDetails.task) {
        subRoute.push(SubRouteKeys.Task);
        subRoute.push(routingDetails.task);
    }
    return subRoute;
}
/**
 * Map sub route type to plan type
 */ function mapSubRouteTypeToPlanType(subRouteType) {
    switch(subRouteType){
        case SubRouteType.BasicPlan:
            return PlanType.Basic;
        case SubRouteType.PremiumPlan:
            return PlanType.Premium;
        case SubRouteType.TodoList:
            return PlanType.ToDo;
        default:
            return PlanType.Basic;
    }
}
/**
 * Method to get plan routing details
 */ export function getPlanRoutingDetails(subRouteType, planId, propBag) {
    return {
        ...propBag,
        planId: planId,
        planType: mapSubRouteTypeToPlanType(subRouteType)
    };
}
/**
 * Returns the task external details for a task
 * @param task The task for which we want to extract data
 * @returns IPremiumPlanRoutingDetails- the routing details for the premium plan task
 */ export function extractPremiumPlanRoutingDetailsFromExternalTaskDetails(task) {
    if (task == null || task.creationSource == null) {
        return null;
    }
    const { external } = task.creationSource;
    if (external?.contextScenarioId !== ContextScenarioConstants.project) {
        return null;
    }
    const externalObjectId = external?.externalObjectId;
    const externalContextId = external?.externalContextId;
    if (externalObjectId == null || externalContextId == null) {
        return null;
    }
    const [orgId, , premiumPlanTaskId] = externalObjectId.split("|");
    const [, premiumPlanId] = externalContextId.split("_");
    if (!orgId || !premiumPlanTaskId || !premiumPlanId) {
        return null;
    }
    const routeChange = {
        planType: PlanType.Premium,
        orgId: orgId,
        taskId: premiumPlanTaskId.toUpperCase(),
        planId: premiumPlanId
    };
    return routeChange;
}
/**
 * Whether the view corresponding to the sub route type is a Uno non-native view
 * @param subRouteType The sub route type
 * @param configProvider The configuration provider
 */ export function checkIfNonNativeViewFromSubRouteType(subRouteType, configProvider) {
    return !configProvider().flights.EnableNativeBasicPlanViews && subRouteType === SubRouteType.BasicPlan || subRouteType === SubRouteType.PremiumPlan || subRouteType === SubRouteType.Portfolio || subRouteType === SubRouteType.Publishing;
}
