import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for MruStore, for use in the registry */ export const MruStoreActionTypes = [
    ActionType.CreateMruItem,
    ActionType.CreateMruItemFailed,
    ActionType.CreateMruItemSucceeded,
    ActionType.DeleteMruItem,
    ActionType.DeleteMruItemFailed,
    ActionType.DeleteMruItemSucceeded,
    ActionType.FetchMruItems,
    ActionType.FetchMruItemsFailed,
    ActionType.FetchMruItemsSucceeded,
    ActionType.UpdateMruItem,
    ActionType.UpdateMruItemFailed,
    ActionType.UpdateMruItemSucceeded
];
