import { CoreAjaxClient } from "./CoreAjaxClient";
// Utilities
import { fetchWithTimeout } from "../utilities/ServiceUtilities";
/**
 * Basic network layer logic for executing AJAX requests using the fetch API
 * --- Provides timeout facility
 * --- Provides api qos logging
 */ export class BasicAjaxClient extends CoreAjaxClient {
    /**
     * Create an instance of BasicAjaxClient
     * @param globalTimeout Fallback global timeout value in MS
     * @param loggers : Loggers for telemetry
     */ constructor(globalTimeout, loggers){
        const executor = (url, options)=>fetchWithTimeout(url, options, this.globalTimeout);
        super(executor, loggers), this.globalTimeout = globalTimeout;
    }
}
