/**
 * Existence Status of an entity
 */ export var ExistenceStatus;
(function(ExistenceStatus) {
    /**
     * Entity has been created
     */ ExistenceStatus[ExistenceStatus["Created"] = 0] = "Created";
    /**
     * Entity has been deleted in the client but not propagated to the authoritative store yet
     */ ExistenceStatus[ExistenceStatus["SoftDeleted"] = 1] = "SoftDeleted";
})(ExistenceStatus || (ExistenceStatus = {}));
