/**
 * Possible data types for Http Requests.
 */ export var DataTypes;
(function(DataTypes) {
    DataTypes["Text"] = "text";
    DataTypes["Json"] = "json";
})(DataTypes || (DataTypes = {}));
export var HttpMethods;
(function(HttpMethods) {
    HttpMethods["Get"] = "GET";
    HttpMethods["Delete"] = "DELETE";
    HttpMethods["Patch"] = "PATCH";
    HttpMethods["Post"] = "POST";
    HttpMethods["Put"] = "PUT";
})(HttpMethods || (HttpMethods = {}));
export var HttpHeaders;
(function(HttpHeaders) {
    HttpHeaders["Accept"] = "Accept";
    HttpHeaders["Authorization"] = "Authorization";
    HttpHeaders["Bearer"] = "Bearer";
    HttpHeaders["ContentRange"] = "Content-Range";
    HttpHeaders["ContentType"] = "Content-Type";
    HttpHeaders["Date"] = "Date";
    HttpHeaders["IfMatch"] = "If-Match";
    HttpHeaders["Location"] = "Location";
    HttpHeaders["RetryAfter"] = "Retry-After";
    HttpHeaders["XSPSiteRootUrl"] = "X-SPSiteRootUrl";
})(HttpHeaders || (HttpHeaders = {}));
export var HttpContentType;
(function(HttpContentType) {
    HttpContentType["Json"] = "application/json";
    HttpContentType["TextPlain"] = "text/plain";
    HttpContentType["OctetStream"] = "application/octet-stream";
})(HttpContentType || (HttpContentType = {}));
