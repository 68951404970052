/**
 * Retry a function in a configurable fashion
 * @param fn Function to retry
 * @param retriesLeft Number of retries left
 * @param interval Interval to wait between retries
 * @param canRetry Delegate to determine if an error is retryable or not
 */ export function retry(fn, retriesLeft = 5, interval = 1000, canRetry = ()=>true) {
    return new Promise((resolve, reject)=>{
        fn().then(resolve).catch((error)=>{
            if (retriesLeft === 0 || canRetry(error) === false) {
                reject(error);
                return;
            }
            setTimeout(()=>{
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
            }, interval);
        });
    });
}
