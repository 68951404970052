import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for NotificationStore, for use in the registry */ export const NotificationStoreActionTypes = [
    ActionType.ClearNotificationsAction,
    ActionType.DismissToastNotificationAction,
    ActionType.EnqueueToastNotificationAction,
    ActionType.OpenModalAction,
    ActionType.ShowErrorAction,
    ActionType.ShowInfoAction,
    ActionType.ShowWarningAction
];
