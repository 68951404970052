/**
 * The possible status codes: https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html
 * C# enum System.Net.HttpStatusCode: https://msdn.microsoft.com/en-us/library/system.net.httpstatuscode(v=vs.110).aspx
 */ export var HttpStatus;
(function(HttpStatus) {
    // Informational
    HttpStatus[HttpStatus["Continue"] = 100] = "Continue";
    HttpStatus[HttpStatus["SwitchingProtocols"] = 101] = "SwitchingProtocols";
    // Success
    HttpStatus[HttpStatus["OK"] = 200] = "OK";
    HttpStatus[HttpStatus["Created"] = 201] = "Created";
    HttpStatus[HttpStatus["Accepted"] = 202] = "Accepted";
    HttpStatus[HttpStatus["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
    HttpStatus[HttpStatus["NoContent"] = 204] = "NoContent";
    HttpStatus[HttpStatus["ResetContent"] = 205] = "ResetContent";
    HttpStatus[HttpStatus["PartialContent"] = 206] = "PartialContent";
    HttpStatus[HttpStatus["AlreadyReported"] = 208] = "AlreadyReported";
    // Redirection
    HttpStatus[HttpStatus["Ambiguous"] = 300] = "Ambiguous";
    HttpStatus[HttpStatus["Moved"] = 301] = "Moved";
    HttpStatus[HttpStatus["MovedPermanently"] = 301] = "MovedPermanently";
    HttpStatus[HttpStatus["Found"] = 302] = "Found";
    HttpStatus[HttpStatus["Redirect"] = 302] = "Redirect";
    HttpStatus[HttpStatus["RedirectMethod"] = 303] = "RedirectMethod";
    HttpStatus[HttpStatus["SeeOther"] = 303] = "SeeOther";
    HttpStatus[HttpStatus["NotModified"] = 304] = "NotModified";
    HttpStatus[HttpStatus["UseProxy"] = 305] = "UseProxy";
    HttpStatus[HttpStatus["Unused"] = 306] = "Unused";
    HttpStatus[HttpStatus["RedirectKeepVerb"] = 307] = "RedirectKeepVerb";
    HttpStatus[HttpStatus["TemporaryRedirect"] = 307] = "TemporaryRedirect";
    // Client Error
    HttpStatus[HttpStatus["BadRequest"] = 400] = "BadRequest";
    HttpStatus[HttpStatus["Unauthorized"] = 401] = "Unauthorized";
    HttpStatus[HttpStatus["PaymentRequired"] = 402] = "PaymentRequired";
    HttpStatus[HttpStatus["Forbidden"] = 403] = "Forbidden";
    HttpStatus[HttpStatus["NotFound"] = 404] = "NotFound";
    HttpStatus[HttpStatus["MethodNotAllowed"] = 405] = "MethodNotAllowed";
    HttpStatus[HttpStatus["NotAcceptable"] = 406] = "NotAcceptable";
    HttpStatus[HttpStatus["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
    HttpStatus[HttpStatus["RequestTimeout"] = 407] = "RequestTimeout";
    HttpStatus[HttpStatus["Conflict"] = 409] = "Conflict";
    HttpStatus[HttpStatus["Gone"] = 410] = "Gone";
    HttpStatus[HttpStatus["LengthRequired"] = 411] = "LengthRequired";
    HttpStatus[HttpStatus["PreconditionFailed"] = 412] = "PreconditionFailed";
    HttpStatus[HttpStatus["RequestEntityTooLarge"] = 413] = "RequestEntityTooLarge";
    HttpStatus[HttpStatus["RequestUriTooLong"] = 414] = "RequestUriTooLong";
    HttpStatus[HttpStatus["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
    HttpStatus[HttpStatus["RequestedRangeNotSatisfiable"] = 416] = "RequestedRangeNotSatisfiable";
    HttpStatus[HttpStatus["ExpectationFailed"] = 417] = "ExpectationFailed";
    HttpStatus[HttpStatus["UnprocessableEntity"] = 422] = "UnprocessableEntity";
    HttpStatus[HttpStatus["Locked"] = 423] = "Locked";
    HttpStatus[HttpStatus["UpgradeRequired"] = 426] = "UpgradeRequired";
    HttpStatus[HttpStatus["TooManyRequests"] = 429] = "TooManyRequests";
    // Server Error
    HttpStatus[HttpStatus["InternalServerError"] = 500] = "InternalServerError";
    HttpStatus[HttpStatus["NotImplemented"] = 501] = "NotImplemented";
    HttpStatus[HttpStatus["BadGateway"] = 502] = "BadGateway";
    HttpStatus[HttpStatus["ServiceUnavailable"] = 503] = "ServiceUnavailable";
    HttpStatus[HttpStatus["GatewayTimeout"] = 504] = "GatewayTimeout";
    HttpStatus[HttpStatus["HttpVersionNotSupported"] = 505] = "HttpVersionNotSupported";
})(HttpStatus || (HttpStatus = {}));
export var HttpStatusText;
(function(HttpStatusText) {
    HttpStatusText["Unauthorized"] = "Unauthorized";
    HttpStatusText["NotImplemented"] = "Not Implemented";
})(HttpStatusText || (HttpStatusText = {}));
