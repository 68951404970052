// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../ActionType";
export class NavigateToViewAction extends TypedAction(ActionType.NavigateToView) {
    loggingData() {
        return {
            subRouteType: this.routeChangeDescriptor.subRouteType,
            subRoute: this.routeChangeDescriptor.subRoute.join("/")
        };
    }
    constructor(routeChangeDescriptor, timestamp = Date.now(), skipHistoryPushState){
        super(), this.routeChangeDescriptor = routeChangeDescriptor, this.timestamp = timestamp, this.skipHistoryPushState = skipHistoryPushState;
    }
}
