// Constants
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { MyTasksViewPivotType } from "@ms/uno-constants/lib/local/ViewPivots";
// Teams
import { SmartListIdType, SmartSubListIdType, TaskIdRegex, TaskListType, isRawTaskId, parseSubEntityPath } from "../teams/legacyBootRoutes";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utils
import { createRouteFromDetails } from "@ms/uno-routing";
/**
 * Maps Legacy SubListId to MyTasksPivot
 */ const legacySubListIdToMyTasksPivotMap = {
    [SmartSubListIdType.AllMyTasks]: MyTasksViewPivotType.All,
    [SmartSubListIdType.AssignedToMe]: MyTasksViewPivotType.AssignedToMe,
    [SmartSubListIdType.FlaggedEmail]: MyTasksViewPivotType.FlaggedEmails,
    [SmartSubListIdType.PrivateTasks]: MyTasksViewPivotType.PrivateTasks
};
/**
 * Converts a legacy subListId to a MyTasksViewPivotType
 */ export function legacySubListIdToMyTasksPivot(subListId) {
    return legacySubListIdToMyTasksPivotMap[subListId] || null;
}
const isChannelTabRegex = /channeltab|^ttd?\.(?:pub\.)?c_/i;
/**
 * Parses the specified deepLink and returns its corresponding Route (or null, if no valid route parsed)
 * @param deepLink The deepLink to parse
 * @param loggers Loggers to use for logging
 */ export function generateRouteFromLegacyDeepLink(deepLink, loggers) {
    // TODO [ADO#8396519] - Update to include Publishing DeepLink URL schema handling
    if (!deepLink) {
        return null;
    }
    let routeParams = null;
    try {
        routeParams = parseSubEntityPath(deepLink);
    } catch (e) {
        loggers?.traceLogger.logTrace(0x1e30b4df /* tag_4mlt5 */ , TraceLevel.Warning, "generateRouteFromLegacyDeepLink - Error parsing deepLink");
    }
    if (!routeParams) {
        loggers?.traceLogger.logTrace(0x1e30b4de /* tag_4mlt4 */ , TraceLevel.Warning, "generateRouteFromLegacyDeepLink - No routeParams parsed from deepLink");
        return null;
    }
    // DeepLink to AssignedToMe view (with possible Task)
    if (routeParams.taskListType === TaskListType.smartList && (routeParams.taskListId === SmartListIdType.AssignedToMe || routeParams.subListId === SmartSubListIdType.AssignedToMe)) {
        return createRouteFromDetails({
            subRouteType: SubRouteType.MyTasks,
            myTasksPivot: MyTasksViewPivotType.AssignedToMe,
            planId: routeParams.planId || undefined,
            taskId: routeParams.taskId || undefined
        });
    }
    // DeepLink to All Tasks/Private Tasks/Flagged Emails view (with possible Task)
    if (routeParams.taskListType === TaskListType.smartList && routeParams.taskListId === SmartListIdType.MyTasks && routeParams.subListId) {
        const myTasksPivot = legacySubListIdToMyTasksPivot(routeParams.subListId);
        if (myTasksPivot) {
            return createRouteFromDetails({
                subRouteType: SubRouteType.MyTasks,
                myTasksPivot: myTasksPivot,
                personalListId: routeParams.personalListId || undefined,
                planId: routeParams.planId || undefined,
                taskId: routeParams.taskId || undefined
            });
        }
    }
    // DeepLink to MyDay (with possible Task)
    if (routeParams.taskListType === TaskListType.smartList && routeParams.taskListId === SmartListIdType.MyDay) {
        return createRouteFromDetails({
            subRouteType: SubRouteType.MyDay,
            taskId: routeParams.taskId || undefined,
            planId: routeParams.planId || undefined,
            personalListId: routeParams.personalListId || undefined
        });
    }
    // DeepLink to Todo List view (with possible Task)
    if (routeParams.taskListType === TaskListType.personalList && routeParams.personalListId) {
        return createRouteFromDetails({
            subRouteType: SubRouteType.TodoList,
            personalListId: routeParams.personalListId,
            taskId: routeParams.taskId || undefined
        });
    }
    // DeepLink to Basic Plan view (with possible Task)
    if (routeParams.taskListType === TaskListType.teamTaskList && routeParams.planId) {
        return createRouteFromDetails({
            subRouteType: SubRouteType.BasicPlan,
            planId: routeParams.planId,
            taskId: routeParams.taskId || undefined
        });
    }
    return null;
}
/**
 * Extracts the task Id from the subPageId
 * @param subPageId The teams context subPageId / subEntityId to extract the taskId from
 */ export function getTaskIdFromSubEntityId(loggers, subPageId) {
    if (!subPageId) {
        return null;
    }
    // If it's a task deeplink like ".../task/:taskId" parse the taskId out
    const taskMatches = subPageId.match(TaskIdRegex);
    if (taskMatches) {
        return taskMatches.length ? taskMatches[1] : null;
    }
    // If couldn't parse any params from subPageId/subEntityId yet, it could be
    // a (legacy) Planner iframe created task link which has only "taskId" as subPageId/subEntityId
    // (user copied from Planner iframe for Board/Chart/Schedule view before copylink planner-frame-api was shipped)
    if (isRawTaskId(subPageId)) {
        loggers?.traceLogger.logTrace(0x1e30b4dd /* tag_4mlt3 */ , TraceLevel.Info, "getTaskIdFromSubEntityId - subPageId is a raw taskId");
        return subPageId;
    }
    return null;
}
/**
 * Checks if the entityId indicates a channel tab
 * @param entityId The teams context entityId to check
 */ export function isChannelTab(entityId) {
    return isChannelTabRegex.test(entityId);
}
