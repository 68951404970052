// Actions
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for AggregatesStore, for use in the registry */ export const AggregatesStoreActionTypes = [
    ActionType.CreateBasicPlanSucceededAction,
    ActionType.DeleteBasicPlanSucceededAction,
    ActionType.DeltaSyncDeleteBasicPlanAction,
    ActionType.CreateBasicTaskAction,
    ActionType.CreateBasicTaskFailedAction,
    ActionType.DeleteBasicTaskAction,
    ActionType.DeleteBasicTaskFailedAction,
    ActionType.DeltaSyncCreateBasicTaskAction,
    ActionType.DeltaSyncDeleteBasicTaskAction,
    ActionType.DeltaSyncUpdateBasicTaskAction,
    ActionType.FetchBasicTasksForPlanSucceededAction,
    ActionType.UpdateBasicTaskAction,
    ActionType.UpdateBasicTaskFailedAction
];
