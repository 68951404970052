/**
 * OneDS SDK's environment to set up with
 */ export var OneDSEnvironment;
(function(OneDSEnvironment) {
    OneDSEnvironment["CustomerContent"] = "CUSTOMER_CONTENT";
    OneDSEnvironment["EUDB"] = "EUDB";
    OneDSEnvironment["Public"] = "PUBLIC";
    OneDSEnvironment["DoD"] = "USGOV_DOD";
    OneDSEnvironment["DoJ"] = "USGOV_DOJ";
})(OneDSEnvironment || (OneDSEnvironment = {}));
export var LoggingStrings;
(function(LoggingStrings) {
    /** Product name */ LoggingStrings["ProductName"] = "Microsoft Planner";
})(LoggingStrings || (LoggingStrings = {}));
