export var RequestType;
(function(RequestType) {
    /**
     * If the request is for creating an entity and returns the created entity
     * In this case we try to update the entity reference in the pending requests
     */ RequestType[RequestType["Create"] = 0] = "Create";
    /**
     * If the request is for updating an entity
     */ RequestType[RequestType["Update"] = 1] = "Update";
    /**
     * If the request is for deleting an entity
     */ RequestType[RequestType["Delete"] = 2] = "Delete";
    /**
     * If the request is for reading an entity
     */ RequestType[RequestType["Read"] = 3] = "Read";
})(RequestType || (RequestType = {}));
/**
 * Defines a request
 */ export class Request {
    /**
     * Initializes a new instance of Request
     * @param entityId - The identifier for the entity on which this request applies.
     * @param requestMethod - The method which makes the request to the server
     * @param requestParams - The parameters passed to the request method
     * @param deferred - The deferred object to resolve/reject the promise that needs to be resolved/rejected after this request completes
     * @param requestType - Request type (Create/Update/Delete)
     * @param dependentEntityIds - The list of identifiers for the entity on which this entity depends
     */ constructor(entityId, requestMethod, requestParams, deferred, requestType, dependentEntityIds){
        this.entityId = entityId;
        this.requestParams = requestParams;
        this.requestMethod = requestMethod;
        this.dependentEntityIds = dependentEntityIds;
        this.requestType = requestType;
        this.deferred = deferred;
    }
}
