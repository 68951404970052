/**
 * The function returns whether an action is of type ITrackableAction.
 * Those actions which will implement ITrackableAction and will have isTrackingEnabled
 * as true will be of type ITrackableAction
 * @export
 * @param {Action} action
 * @returns {action is ITrackableAction} Whether or not the action is a trackable action
 */ export function isTrackableAction(action) {
    return action && action.isTrackingEnabled;
}
