// Dispatcher
/**
 * Base class for all logic modules
 */ export class BaseLogicModule {
    /**
     * Get the current app configuration
     */ get config() {
        return this.configProvider();
    }
    /**
     * Initializes an instance of an Entity Module
     * @param dispatcher The dispatcher the SPA uses to dispatch actions
     * @param loggers Loggers for telemetry
     * @param configProvider Delegate to retrieve the app configuration
     */ constructor(dispatcher, loggers, configProvider){
        this.dispatcher = dispatcher;
        this.loggers = loggers;
        this.configProvider = configProvider;
    }
}
