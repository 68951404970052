/** Converts a string to an enum value, or null if the string is not a valid enum value
 * @param value The string value to convert
 * @param enum The enum to convert to
 */ export function stringToEnum(stringValue, enumType) {
    for(const key in enumType){
        if (stringValue === enumType[key]) {
            return enumType[key];
        }
    }
    return null;
}
