/**
 * Utilities for styling
 */ /**
 * Append "px" to a number for use in a style
 * @param pxNumber px value as a number
 */ export const numberToPx = (pxNumber)=>isNaN(pxNumber) ? "0px" : `${pxNumber}px`;
/**
 * Parses "px" string into number to use in calculations
 * @param pxString px value as a string - e.g. "20px"
 */ export const pxToNumber = (pxString)=>parseFloat(pxString);
/**
 * Get the IStyleSet object from IStyleFunctionOrObject
 * @param customStyles IStyleFunctionOrObject
 * @param customStyleProps style props for the IStyleFunction
 */ export function getStyleObject(customStyles, customStyleProps) {
    if (customStyles) {
        return typeof customStyles === "function" ? customStyles(customStyleProps) : customStyles;
    }
}
