/**
 * Checks if the token expiry is within the buffer period from current time.
 * @param expiry The token expiry time.
 * @param bufferSeconds The buffer period in seconds.
 */ export function isTokenExpiryWithinBufferPeriod(expiry, bufferSeconds) {
    if (expiry == null) {
        // Assume no expiry if expiry is null.
        return false;
    }
    return expiry.valueOf() < Date.now() + bufferSeconds * 1000;
}
